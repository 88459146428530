import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { useAuth } from "../../providers/authentication";
import Header from "../PlayerProfilePage/Header";
import { decodeToken } from "../../hooks/jwt";
import Parameters from "./parameters";
import translate from "../../providers/i18n/translate";
import Simulation from "./simulation";
import { ThemeContext } from '../../providers/theme';
import { useIntl } from "react-intl";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";
import { useRef } from "react";
import Modal from "../../components/modals/modal";
import TableSimulation from "../../components/simulation/TableSimulation";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  max-width: ${(props) => `${props.theme.breakpoints.desktopPlus}px`};
  margin: 0 auto;
  padding: 0 20px;
  @media (min-width: 1024px) {
    padding: 0 50px;
  }
`;

const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 1.25rem;
  margin: 10px 0 20px 0;
  border-bottom: 1px solid ${props => props.theme.palette.details};
  @media (min-width: 600px) {
    font-size: 2rem;
    margin: 0 0 20px 0;
  }
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

const Action = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: museo-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    text-transform: uppercase;
    color: ${(props) => props.theme.palette.base};
    border-radius: 35px;
    padding: 15px 0;
    width: 40px;
    height: 40px;
    background-color: ${(props) => props.theme.palette.alternative_accent};
    box-shadow: none;
    border: ${(props) => props.border ? props.border : 'none'};
    margin: ${(props) => props.margin ? props.margin : '10px;'};
    cursor: pointer;
    &:disabled {
      color: ${(props) => props.theme.palette.base};
      background: #ECECEC;
      cursor: not-allowed;
    }
    @media (min-width: 600px) {
        font-size: 1.25rem;
    }
`

export default function SimulationPage() {

  const intl = useIntl();
  const dispatch = useDispatch();
  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const theme = React.useContext(ThemeContext);

  const { token } = useAuth();

  const simulationRef = useRef();

  const userData = decodeToken(token);

  const [loading, setLoading] = React.useState(true);

  const [open, setOpen] = React.useState(false);
  const [save, setSave] = React.useState(true);
  const [simulation, setSimulation] = React.useState(null);
  const [simulatedData, setSimulatedData] = React.useState(null);

  const simulate = (data) => {
    setSimulatedData(null);
    setSimulation(data);
    setSave(false)
    setLoading(false);
  }

  const loadSimulatedData = (data) => {
    setSimulatedData(data);
    setLoading(false);
    setSave(true)
    setOpen(false);
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const closeModalOpen = () => {
    setOpen(false);
  }

  const onClear = () => {
    setSimulatedData(null);
  }

  const handleSave = () => {
    if (simulationRef.current) {
      simulationRef.current.save();
    } else {
      alertMessage(intl.formatMessage({ id: "You must do the simulation before saving" }), alertConstants.ERROR)
    }
  }

  return (
    <Root>
      <Header id={userData?.id} />
      <Actions>
        <Action onClick={handleOpen}>{theme.icons.open} </Action>
        <Action onClick={handleSave} disabled={save}>{theme.icons.save}</Action>
      </Actions>
      <Title>{translate("P1 (Priority 1)")}</Title>
      <Parameters action={simulate} clearSimulatedData={onClear} simulatedParameters={simulatedData?.simulationDUE} />
      {
        simulation || simulatedData ?
          !loading ?
            <Simulation data={simulation} simulatedData={simulatedData} ref={simulationRef} />
            :
            <h2>{translate("loading")}...</h2>
          :
          null
      }
      <Modal open={open} handleClose={closeModalOpen} height="auto" width="80%" >
        <TableSimulation loadSimulatedData={loadSimulatedData} />
      </Modal>
    </Root>
  );
}
