/* General imports */
import React from "react";
import styled from "styled-components";
import GSMIcon from "./gsm_img.png";
import translate from "../../../providers/i18n/translate";

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.background ? props.background : props.theme.palette.principal};
  border-radius: 10px;
  width: 80%;
  height: auto;
  padding: 20px;
  @media (min-width: 600px) {
    width: 100%;
    flex-direction: ${(props) =>
    props.orientation === "vertical" ? "column" : "row"};
    justify-content: center;
  }
`;

const Sem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : "200px")};
  height: ${(props) => (props.width ? props.width : "200px")};
  margin: 10px 20px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  background: ${(props) =>
    props.color1 && props.color2
      ? `linear-gradient(0deg, ${props.color1} 0%, ${props.color2} ${100 - parseFloat(props.value) * 100
      }%, ${props.color2} ${100 - parseFloat(props.value) * 100}%, transparent 100%)`
      : "#ababab"};
      
  box-sizing: border-box;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? `calc(${props.width} - 25px)` : "200px")};
    height: ${(props) => (props.width ? `calc(${props.width} - 25px)` : "200px")};
  }
  @media (min-width: 1440px) {
    width: ${(props) => (props.width ? props.width : "200px")};
    height: ${(props) => (props.width ? props.width : "200px")};
  }
`;

const ContentImage = styled.div`
  position: relative;
  width: 90%;
  height: 90%;
  background: transparent;
  transition: all 500ms ease-in-out;
  img {
    width: 100%;
    height: 100%;
    transition: all 500ms ease-in-out;
    object-fit: contain;
  }
  &:hover {
    img {
      transform: rotate(360deg);
    }
  }
`;

const Msg = styled.p`
  width: 100%;
  text-align: center;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1.5rem")};
  font-family: museo-sans, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: ${(props) => (props.fontColor ? props.fontColor : "#FFFFFF")};
  margin: 10px 0;
  @media (min-width: 1024px) {
    width: 60%;
    flex-direction: row;
    justify-content: center;
  }
`;

const Semaphore = (props) => {
  const getColor1 = () => {
    const val = parseFloat(props.value);
    switch (true) {
      case val >= 0.76:
        return "#a41f01";
      case val >= 0.66 && val < 0.76:
        return "#f02e01";
      case val >= 0.57 && val < 0.66:
        return "#f17205";
      case val >= 0.49 && val < 0.57:
        return "#DCFE54";
      case val >= 0.42 && val < 0.49:
        return "#B4ED00";
      case val < 0.42:
        return "#00ff00";
      default:
        break;
    }
  };

  const getColor2 = () => {
    const val = parseFloat(props.value);
    switch (true) {
      case val >= 0.76:
        return "#ff704f";
      case val >= 0.66 && val < 0.76:
        return "#ffae9b";
      case val >= 0.57 && val < 0.66:
        return "#ffd8b6";
      case val >= 0.49 && val < 0.57:
        return "#eeffa8";
      case val >= 0.42 && val < 0.49:
        return "#58D68D";
      case val < 0.42:
        return "#b2ffb2";
      default:
        break;
    }
  };

  return (
    <Root vertical={props.vertical} print={props.print}>
      <Container background={props.background} orientation={props.orientation}>
        <Sem
          color1={getColor1()}
          color2={getColor2()}
          value={props.value}
          width={props.width}
        >
          <ContentImage>
            <img src={GSMIcon} alt="Semaphore" />
          </ContentImage>
        </Sem>
        <Msg fontSize={props.fontSize} widthMsg={props.widthMsg}>
          {translate(props.msg)}
        </Msg>
      </Container>
    </Root>
  );
};

export default Semaphore;
