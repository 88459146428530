import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { ThemeContext } from "../../../providers/theme";

const Root = styled.div`
    position: relative;
    width: 100%;
    height: auto;
`

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    margin: 0 auto;
    height: 40px;
    border-bottom: 1px solid rgba(0,0,0,0.8);
    overflow-x: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    @media (min-width: 600px) {
        width: 95%;
    }
    @media (min-width: 1440px) {
        height: 50px;
        font-size: 1.25rem;
        width: 100%;
    }
`

const StyledNavLink = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;
    text-decoration: none;
    color: ${props => props.theme.palette.principal};
    padding: 0 10px;
    text-transform: uppercase;
    transition: all 300ms ease-in-out;
    opacity: 0.5;
    font-size: 0.75rem;
    text-align: center;
    &.active {
        opacity: 1;
        font-weight: 700;
        border-bottom: 1px solid ${props => props.theme.palette.principal};
    }
    @media (min-width: 600px) {
        font-size: 0.95rem;
        padding: 0 10px;
    }
    @media (min-width: 1024px) {
        font-size: 1rem;
        padding: 0 20px;
    }
`

const Forward = styled.div`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    svg {
        width: 15px;
        height: 15px;
    }
    color: ${props => props.theme.palette.alternative_details};
    @media (min-width: 600px) {
        display: none;
    }
`

const Back = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    svg {
        width: 15px;
        height: 15px;
    }
    color: ${props => props.theme.palette.alternative_details};
    @media (min-width: 600px) {
        display: none;
    }
`

const Submenu = ({data}) => {

    const theme = React.useContext(ThemeContext);

    return (
        <Root>
            {
                data.length > 4 &&
                <Back>{theme.icons.arrowBack}</Back>
            }
            <Container>
                {
                    data && data.map((item, index) =>
                        <StyledNavLink key={`submenu_${index}`} end={item.exact} to={item.link} >{item.name}</StyledNavLink>
                    )
                }
            </Container>
            {
                data.length > 4 &&
                <Forward>{theme.icons.arrowForward}</Forward>
            }
        </Root>
    );
}

export default Submenu;