import React from "react";
import styled from "styled-components";

const Root = styled.div`
    width: 100%;
    min-height: ${props => props.expanded ? "50px" : 0};
    max-height: ${props => props.expanded ? "auto" : 0};
    transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
    overflow-y: hidden;
`

const Content = styled.div`
    width: 100%;
    padding: 20px;
`

const AccordionDetails = ({children, expanded}) => {
    return (
        <Root expanded={expanded}>
            <Content>
                {children}
            </Content>
        </Root>
    );
}

export default AccordionDetails;