import axios from "axios";
import React from "react";
import styled from "styled-components";
import MatchPerformanceChart from "../../components/gsmcharts/matchPerformanceChart";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import {
  formatSimulationlData,
  getTooltipHistoricalPerformance,
} from "../../components/gsmcharts/matchPerformanceChart/config";
import { formatData } from "../../components/gsmcharts/miniMatchesWithSemaphoreChart/config";
import { MiniMatchesWithSemaphoreChart } from "../../components/gsmcharts";
import { formatHistoricalData as formatHistoricalSEMData } from "../../components/gsmcharts/semiCircleChart/config";
import SemiCircleChart from "../../components/gsmcharts/semiCircleChart";
import { formatHistoricalDataREM } from "../../components/gsmcharts/semiCircleChart/config";
import translate from "../../providers/i18n/translate";
import { useIntl } from "react-intl";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import Modal from "../../components/modals/modal";
import FormSimulation from "../../components/simulation/FormSimulation";

const Root = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  height: auto;
  width: 100%;
  padding: 0 0 50px 0;
  @media (min-width: 1024px) {
  }
`;

const HeaderChart = styled.div`
  margin: 50px 0 20px 0;
  width: 100%;
  height: auto;
  order: ${props => props.order};
  @media (min-width: 1024px) {
    order: inherit;
    width: ${(props) => (props.width ? props.width : "100%")};
  }
`;

const ChartTitle = styled.h2`
  grid-column: 1/3;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.principal};
  font-size: 1.25rem;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 1.5rem;
    text-align: left;
  }
`;

const ChartDescription = styled.p`
  grid-column: 1/3;
  color: ${(props) => props.theme.palette.principal};
  font-size: 1rem;
  text-align: center;
  margin-top: 10px;
  @media (min-width: 1024px) {
    font-size: 1.25rem;
    text-align: left;
  }
`;

const ContainerChart = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  margin: auto;
  .apexcharts-canvas {
    margin: 0 auto;
  }
  @media (min-width: 600px) {
    height: 100%;
  }
  @media (min-width: 1024px) {
    height: 100%;
  }
  @media (min-width: 1440px) {
    height: 100%;
  }
`;

const ContainerPerformanceChart = styled(ContainerChart)`
  width: 100%;
  grid-column: 2/4;
  background: ${(props) => props.theme.palette.principal};
  height: 100%;
  border-radius: 20px;
  @media (min-width: 600px) {
    height: 100%;
  }
  @media (min-width: 1024px) {
    height: 100%;
  }
  @media (min-width: 1440px) {
    height: 100%;
  }
`;

const ContainerMiniMatchesChart = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  margin: auto;
  .apexcharts-canvas {
    margin: 0 auto;
  }
  @media (min-width: 600px) {
    height: 600px;
  }
  @media (min-width: 1024px) {
    height: 350px;
  }
  @media (min-width: 1440px) {
    height: 550px;
  }
`;

const ContainerSemiCircleChart = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  background: ${(props) => props.theme.palette.principal};
  border-radius: 20px;
  padding: 20px 0;
  order: ${props => props.order};
  margin: 0 auto;
  @media (min-width: 600px) {
    height: 450px;
  }
  @media (min-width: 1024px) {
    height: 250px;
    padding: 0;
    width: 45%;
    margin: 0;
    order: inherit;
  }
  @media (min-width: 1440px) {
    height: 350px;
    padding: 0;
  }
`;

const Simulation = React.forwardRef(({ data, simulatedData }, ref) => {
  const MATCHES_LIMIT = 10;
  const { token } = useAuth();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { alertMessage } = bindActionCreators(actionCreators, dispatch);
  const [simulationData, setSimulationData] = React.useState(simulatedData ? simulatedData : {
    historicalDUE: data.historicalDUE,
    simulationDUE: data.simulationDUE,
    performance: null,
    minimatches: null,
    sem: null,
    rem: null
  });


  const [openSave, setOpenSave] = React.useState(false);

  const optionsHistoricalPerformance = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return getTooltipHistoricalPerformance(
          series,
          seriesIndex,
          dataPointIndex,
          w
        );
      },
    },
  };

  const openModalSave = (e) => {
    setOpenSave(true);
  }

  const closeModalSave = () => {
    setOpenSave(false);
  }

  React.useEffect(() => { }, [JSON.stringify(data), JSON.stringify(simulatedData)]);

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  React.useImperativeHandle(ref, () => ({
    save() {
      if (simulationData.performance && simulationData.minimatches && simulationData.sem && simulationData.rem) {
        openModalSave();
      } else {
        alertMessage(intl.formatMessage({ id: "You must wait until the simulation is over" }), alertConstants.WARNING)
      }
    }
  }));

  return (
    <Root>
      <HeaderChart>
        <ChartTitle>
          {translate("Match performance")}
        </ChartTitle>
        <ChartDescription>
          {translate("The graph shows the player's level of play from each of the last 10 matches. On this basis, the performance average is calculated.")}
        </ChartDescription>
      </HeaderChart>
      <ContainerPerformanceChart>
        <MatchPerformanceChart
          data={(query) =>
            new Promise((resolve, reject) => {
              if (simulatedData) {
                resolve(simulatedData.simulationPerformance);
              } else {
                axios
                  .get(
                    `${API_BACKEND}/simulation/historicalperformance?page=0&limit=${MATCHES_LIMIT}&filters=[]&historicalDUE=${JSON.stringify(
                      data.historicalDUE
                    )}&simulationDUE=${JSON.stringify(data.simulationDUE)}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatSimulationlData(
                      result.data,
                      query.desiredMargin
                    );
                    setSimulationData(state => {
                      return {
                        ...state,
                        minimatches: state.minimatches,
                        sem: state.sem,
                        rem: state.rem,
                        performance: chartData
                      }
                    })
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
          }
          simulation={simulatedData ? simulatedData : data}
          type="line"
          width={500}
          height={500}
          options={optionsHistoricalPerformance}
        />
      </ContainerPerformanceChart>
      <HeaderChart>
        <ChartTitle>
          {translate("minimatches")}
        </ChartTitle>
        <ChartDescription>
          {translate("Effectiveness in points played with the first and second serve and with the return of the first and second serve.")}
        </ChartDescription>
      </HeaderChart>
      <ContainerMiniMatchesChart>
        <MiniMatchesWithSemaphoreChart
          width={500}
          height={600}
          type="bar"
          simulation={simulatedData ? simulatedData : data}
          data={(query) =>
            new Promise((resolve, reject) => {
              if (simulatedData) {
                resolve(simulatedData.simulationMinimatches);
              } else {
                axios
                  .get(
                    `${API_BACKEND}/simulation/historicalminimatches?page=0&limit=${MATCHES_LIMIT}&filters=[]&historicalDUE=${JSON.stringify(
                      data.historicalDUE
                    )}&simulationDUE=${JSON.stringify(data.simulationDUE)}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatData({
                      dataMiniMatches: result.data.dataMiniMatches,
                      historicalMiniMatches: {},
                    });
                    setSimulationData(state => {
                      return {
                        ...state,
                        performance: state.performance,
                        sem: state.sem,
                        rem: state.rem,
                        minimatches: chartData
                      }
                    })
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
          }
        />
      </ContainerMiniMatchesChart>
      <HeaderChart width="45%" order={1}>
        <ChartTitle>
          {translate("Service Effective Margin (S.E.M.)")}
        </ChartTitle>
        <ChartDescription>
          {translate(
            "The service effective margin refers to the effectiveness in game services, including the performance of the first and second serve. On this basis, the probability that the service will be broken is calculated."
          )}
        </ChartDescription>
      </HeaderChart>
      <HeaderChart width="45%" order={3}>
        <ChartTitle>{translate("Return Effective Margin (R.E.M.)")}</ChartTitle>
        <ChartDescription>
          {translate(
            "The return effective margin refers to the effectiveness in game's return, including the performance of the first and second serve return. On this basis, the probability that the opponent serve will be broken is calculated."
          )}
        </ChartDescription>
      </HeaderChart>
      <ContainerSemiCircleChart order={2}>
        <SemiCircleChart
          simulation={simulatedData ? simulatedData : data}
          data={(query) =>
            new Promise((resolve, reject) => {
              if (simulatedData) {
                resolve(simulatedData.simulationSEM);
              } else {
                axios
                  .get(
                    `${API_BACKEND}/simulation/historicalsem?page=0&limit=${MATCHES_LIMIT}&filters=[]&historicalDUE=${JSON.stringify(
                      data.historicalDUE
                    )}&simulationDUE=${JSON.stringify(data.simulationDUE)}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatHistoricalSEMData(result.data);
                    setSimulationData(state => {
                      return {
                        ...state,
                        performance: state.performance,
                        minimatches: state.minimatches,
                        rem: state.rem,
                        sem: chartData
                      }
                    })
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
          }
          type="radialBar"
          labels={["S.E.M."]}
          width={500}
          height={500}
        />
      </ContainerSemiCircleChart>
      <ContainerSemiCircleChart order={4}>
        <SemiCircleChart
          simulation={simulatedData ? simulatedData : data}
          data={(query) =>
            new Promise((resolve, reject) => {
              if (simulatedData) {
                resolve(simulatedData.simulationREM);
              } else {
                axios
                  .get(
                    `${API_BACKEND}/simulation/historicalrem?page=0&limit=${MATCHES_LIMIT}&filters=[]&historicalDUE=${JSON.stringify(
                      data.historicalDUE
                    )}&simulationDUE=${JSON.stringify(data.simulationDUE)}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatHistoricalDataREM(result.data);
                    setSimulationData(state => {
                      return {
                        ...state,
                        performance: state.performance,
                        minimatches: state.minimatches,
                        sem: state.sem,
                        rem: chartData
                      }
                    })
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
          }
          type="radialBar"
          labels={["R.E.M."]}
          width={500}
          height={500}
        />
      </ContainerSemiCircleChart>
      <Modal open={openSave} handleClose={closeModalSave} height="auto" width="auto" >
        <FormSimulation data={simulationData} handleClose={closeModalSave} />
      </Modal>
    </Root>
  );
});

export default Simulation;
