import styled from "styled-components";

export const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  p {
    margin-bottom: 10px;
  }
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
  
`