import React, {useState} from "react";
import styled from "styled-components";
import axios from "axios";
import { API_BACKEND } from "../../../../config";
import { useAuth } from '../../../../providers/authentication';
import ExpandedPlayer from "./ExpandedPlayer";
import Score from "./Score";
import TotalPoints from "./TotalPoints"
import Sets from "./sets";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../../store/action-creators";
import { matchDataConstants } from "../../../../store/reducers/matchData/matchDataConstants";
import { getSetsList } from "../../../../utils/sets";
import CompressedPlayer from "./CompressedPlayer";

const Root = styled.div`
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 30% 40% 30%;
    gap: 0;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    background: ${props => props.theme.palette.base};
    z-index: 9;
    @media (min-width: 600px) {
      grid-template-columns: 25% 50% 25%;
    }
`

const MatchFilters = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    gap: 10px;
`

const ScorePointsContainer = styled.div`
    width: auto;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 25px;
    gap: 5px;
    @media (min-width: 600px) {
      grid-template-columns: ${props => props.expand ? '1fr' : '1fr 1fr' };
        grid-auto-rows: 35px;
        gap: 12px;
    }
    @media (min-width: 1440px) {
        grid-auto-rows: 45px;
        gap: 20px;
    }
`

const MatchData = ({ id, defaultExpand = false }) => {

    const [ expand, setExpand ] = useState(defaultExpand);

    const { match } = useSelector(state => state.matchData);
    const { sets } = useSelector(state => state.selectedSets);

    const dispatch = useDispatch();

    const { changeMatchData, switchSets } = bindActionCreators(actionCreators, dispatch);

    React.useEffect(() => {
        if (id !== match.idMatch) {
            axios.get(`${API_BACKEND}/matchplayers?idMatch=${id}`)
                .then(result => {
                    changeMatchData(matchDataConstants.SET, { idMatch: id, player1: result.data.player1, player2: result.data.player2 });
                })
                .catch(err => {
                    console.log(err);
                })
        }
        switchSets(matchDataConstants.SWITCH_SETS, true);
    }, [])

    return (
        match.idMatch ?
        <Root>
            {
                expand && match.player1 && match.player2 ?
                    <ExpandedPlayer data={match.player1} playerNumber={1} enabled={match.enabledPlayers} />
                    :
                    <CompressedPlayer data={match.player1} playerNumber={1} enabled={match.enabledPlayers} />
            }
            <MatchFilters>
                <ScorePointsContainer expand={expand}>
                    <Score id={id} player1={match.player1} />
                    {sets && sets.length > 0 ?
                        <TotalPoints id={id} sets={sets} data={
                            (query) => new Promise((resolve, reject) => {
                                axios.get(`${API_BACKEND}/matchpointsbyset?idMatch=${id}&sets=${getSetsList(sets)}`)
                                    .then(result => {
                                        resolve(result.data);
                                    })
                                    .catch(err => {
                                        console.log(err);
                                    })
                            })
                        } /> : null
                    }
                </ScorePointsContainer>
                <Sets id={id} enabled={match.enabledSets} />
            </MatchFilters>
            {
                expand ?
                    <ExpandedPlayer opponent data={match.player2} playerNumber={2} enabled={match.enabledPlayers} />
                    :
                    <CompressedPlayer opponent data={match.player2} playerNumber={2} enabled={match.enabledPlayers} />
            }
        </Root>
            : null
    );
}

export default MatchData;