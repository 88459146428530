import React from "react";
import styled from "styled-components";
import * as d3 from "d3";

const Container = styled.div`
  position: relative;
  width: 320px;
  height: 350px;
  overflow: hidden;
  margin: 0 auto;
  .tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #dcfe54;
    h4 {
      font-size: 0.65rem;
      text-transform: uppercase;
    }
    span {
      font-size: 2.5rem;
    }
    p {
      font-size: 0.75rem;
    }
  }
  .sunburstlabel {
  }
  svg {
    transform: translate(-9%, -6%);
    .title-text {
      background-color: purple;
    }
  }
  @media (min-width: 600px) {
    width: 500px;
    height: 500px;
    svg {
    transform: translate(-5%, -5%);
    .title-text {
      background-color: purple;
    }
  }
  }
  @media (min-width: 1024px) {
    width: 550px;
    height: 550px;
    .tooltip {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #dcfe54;
      h4 {
        font-size: 0.8rem;
        text-transform: uppercase;
      }
      span {
        font-size: 3rem;
      }
      p {
        font-size: 0.75rem;
      }
    }
    svg {
    transform: translate(-1%, -2%);
    .title-text {
      background-color: purple;
    }
  }
  }
  @media (min-width: 1440px) {
    width: 600px;
    height: 600px;
    .tooltip {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #dcfe54;
      h4 {
        font-size: 0.8rem;
        text-transform: uppercase;
      }
      span {
        font-size: 3rem;
      }
      p {
        font-size: 0.75rem;
      }
    }
    svg {
    transform: translate(-7%, -7%);
    .title-text {
      background-color: purple;
    }
  }
  }
`;

const Legend = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const LegendLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  margin: 0 10px 10px 0;
`;

const Dot = styled.div`
  width: 1rem;
  height: 1rem;
  background: ${(props) => (props.color ? props.color : "#000")};
  border-radius: 50%;
  margin-right: 5px;
`;

const Chart = ({ id, size, data }) => {
  const svgRef = React.useRef(null);
  const SIZE = size;
  const RADIUS = SIZE / 8;

  const [actualNode, setActualNode] = React.useState(null);

  const getColor = (c) => {
    switch (c) {
      case "forehand":
        return "#40CBE6";
      case "backhand":
        return "#FFBB8D";
      case "won":
        return "#b4dc90";
      case "lose":
        return "#ff0000";
      case "topspin/flat":
        return "#0E6778";
      case "slice":
        return "#775DFF";
      case "volley":
        return "#463794";
      case "overhead":
        return "#C4E63A";
      case "lob":
        return "#10A9C7";
      case "drop shot":
        return "#96B129";
      case "passing shot":
        return "#B6BFC1";
      case "winner":
        return "#03AC13";
      case "forcing an error":
        return "#DFFE00";
      case "unforced error":
        return "#900603";
      default:
        return color(c);
    }
  };

  const color = d3.scaleOrdinal(
    d3.quantize(d3.interpolateRainbow, data.children.length + 1)
  );

  const partition = (data) => {
    const newroot = d3
      .hierarchy(data)
      .sum((d) => d.value)
    return d3.partition().size([2 * Math.PI, newroot.height + 1])(newroot);
  };

  var x = d3.scaleLinear().range([0, 2 * Math.PI]);
  var y = d3.scaleSqrt().range([0, RADIUS]);

  const format = d3.format(",d");

  const arc = d3
    .arc()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.05))
    .padRadius(RADIUS * 1.5)
    .innerRadius((d) => d.y0 * RADIUS)
    .outerRadius((d) => Math.max(d.y0 * RADIUS, d.y1 * RADIUS - 5));

  const getAutoBox = () => {
    if (!svgRef.current) {
      return "";
    }

    const { x, y, width, height } = svgRef.current.getBBox();
    return [0, 0, width, height].toString();
  };

  React.useEffect(() => {
    document
      .querySelector(`#sunburst_${id}`)
      .querySelectorAll("g")
      .forEach((node) => {
        node.remove();
      });
    const root = partition(data);
    setActualNode(root);
    root.each((d) => (d.current = d));
    //   We already created svg element and will select its ref
    const svg = d3.select(svgRef.current);

    const g = svg
      .append("g")
      .attr("transform", `translate(${SIZE / 2},${SIZE / 2})`);

    const path = g
      .append("g")
      .selectAll("path")
      .data(root.descendants().slice(1))
      .join("path")
      .attr("fill", (d) => {
        return getColor(d.data.name);
      })
      .attr("fill-opacity", (d) => {
        return arcVisible(d.current)
          ? d.current.depth > 0
            ? 0.8 / d.current.depth
            : 1
          : 0;
      })
      .attr("pointer-events", (d) => (arcVisible(d.current) ? "auto" : "none"))
      .attr("d", (d) => arc(d.current));

    // Define the div for the tooltip
    var tooltip = null;

    const onHover = (event, p) => {
      tooltip = d3
        .select(`#sunburst_${id}`)
        .append("div")
        .attr("class", "tooltip")
        .style("position", "absolute")
        .style("opacity", 1)
        .style("user-select", "none")
        .style("left", "50%")
        .style("top", "50%")
        .style("transform", "translate(-50%, -50%)")
        .style("opacity", 1)
        .html(
          () => {
            const parentvalue = p.parent ? p.parent.data.total : 100;
            const currentvalue = p.data.total || p.data.value;
            return `<h4>${p.data.name}</h4><span>${((currentvalue / parentvalue) * 100).toFixed(0)}%</span>`;
          }
        );
      parent.attr("fill", "#03232D");
    };

    const onOut = (event, p) => {
      tooltip.remove();
      parent.attr("fill", "none");
    };

    path
      .filter((d) => d.children)
      .style("cursor", "pointer")
      .on("dblclick", clicked);

    path.on("mouseover", onHover).on("mouseout", onOut);

    /* path.append("title").text(
      (d) =>
        `${d
          .ancestors()
          .map((d) => d.data.name)
          .reverse()
          .join("/")}\n${format(d.value)}`
    ); */

    const label = g
      .append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(root)
      .join("text")
      .attr("dy", "1em")
      .attr("fill-opacity", (d) => +labelVisible(d.current))
      .attr("transform", (d) => labelTransform(d.current))
      .attr("font-weight", 900)
      .attr("fill", "#03232D")
      .attr("font-size", "1.15em")
      .text((d) => {
        return `${d.data.total ? d.data.total : d.data.value}`;
      });

    const parent = g
      .append("circle")
      .datum(root)
      .attr("r", RADIUS)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("dblclick", clicked);

    function clicked(event, p) {
      parent.datum(p.parent || root);
      setActualNode(p);
      root.each(
        (d) =>
        (d.target = {
          x0:
            Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          x1:
            Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth),
        })
      );

      const t = g.transition().duration(750);

      // Transition the data on all arcs, even the ones that aren’t visible,
      // so that if this transition is interrupted, entering arcs will start
      // the next transition from the desired position.
      path
        .transition(t)
        .tween("data", (d) => {
          const i = d3.interpolate(d.current, d.target);
          return (t) => (d.current = i(t));
        })
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || arcVisible(d.target);
        })
        .attr("fill-opacity", (d) =>
          arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0
        )
        .attr("pointer-events", (d) => (arcVisible(d.target) ? "auto" : "none"))
        .attrTween("d", (d) => () => arc(d.current));

      label
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || labelVisible(d.target);
        })
        .transition(t)
        .attr("fill-opacity", (d) => +labelVisible(d.target))
        .attrTween("transform", (d) => () => labelTransform(d.current));
    }

    function arcVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
    }

    function labelTransform(d) {
      const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
      const y = ((d.y0 + d.y1) / 2) * RADIUS;
      return `rotate(${x - 90}) translate(${y},0) rotate(${90 - x})`;
    }

    //   We don't need to return svg node anymore
    svg.attr("viewBox", getAutoBox);
  }, [data, size]);

  const noRepeatLegend = (index, indexchild, name) => {
    var found = false;
    var ind = 0;
    var indchild = 0;
    while (!found && ind <= index) {
      if (actualNode.children[ind].data.name === name) {
        found = true;
      } else {
        while (!found && indchild <= indexchild) {
          if (actualNode.children[ind].children[indexchild] && actualNode.children[ind].children[indexchild].data.name === name) {
            found = true;
          }
          indchild += 1;
        }
      }
      ind += 1;
    }
    return found;
  }

  return (
    <React.Fragment>
      <Container id={`sunburst_${id}`} size={size}>
        <svg width={SIZE} height={SIZE} ref={svgRef} />;
      </Container>
      <Legend>
        {actualNode &&
          actualNode.children &&
          actualNode.children
          .slice() // Copia el arreglo para no modificar el original
          .reverse() // Invierte el orden de los elementos en el arreglo
          .map((child, index) => (
            <React.Fragment key={`level0_${index}`}>
              <LegendLabel>
                <Dot color={getColor(child.data.name)} />
                {child.data.name}
              </LegendLabel>
              {child &&
                child.children &&
                child.children.map((subchild, indexchild) => (
                  !noRepeatLegend(index, indexchild, subchild.data.name) &&
                  <LegendLabel key={`level1_${indexchild}`}>
                    <Dot color={getColor(subchild.data.name)} />
                    {subchild.data.name}
                  </LegendLabel>
                ))}
            </React.Fragment>
          ))}
      </Legend>
    </React.Fragment>
  );
};

export default Chart;
