import React from "react";
import styled from "styled-components";
import {
  DynamicBarChart,
  RallyEffectivenessChart,
} from "../components/gsmcharts";
import translate from "../providers/i18n/translate";
import axios from "axios";
import { API_BACKEND } from "../config";
import { formatData as formatDynamicChartData } from "../components/gsmcharts/dynamicBarChart/config";
import { useAuth } from "../providers/authentication";
import { useParams } from "react-router-dom";
import { formatData as formatRallyEffectivenessData } from "../components/gsmcharts/rallyEffectivenessChart/config";
import { matchDataConstants } from "../store/reducers/matchData/matchDataConstants";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../store/action-creators";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  gap: 50px 20px;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: repeat(6, 50px 425px);
    gap: 20px;
  }
`;

const Title = styled.h2`
  width: 100%;
  height: 50px;
  grid-column: inherit;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.details};
  font-size: 1.25rem;
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  @media (min-width: 1024px) {
    display: flex;
    align-items: flex-end;
    font-size: 1.5rem;
    text-align: left;
    height: 100%;
    grid-template-columns: 60% 40%;
    grid-column: 1/3;
  }
`;

const ContainerChart = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  @media (min-width: 1024px) {
  }
`;

export default function RallyPage() {
  const { token } = useAuth();
  const { id } = useParams();

  const { sets } = useSelector((state) => state.selectedSets);
  const { player } = useSelector((state) => state.selectedPlayer);

  const dispatch = useDispatch();
  const { switchPlayers, switchSets } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const getSetsList = (s) => {
    let setlist = "";
    for (let index = 0; index < sets.length; index++) {
      if (sets[index] === true) {
        setlist = setlist + (index + 1);
        if (sets.length > 1 && index !== sets.length - 1) {
          setlist = setlist + ",";
        }
      }
    }
    return setlist;
  };

  React.useEffect(() => {
    switchPlayers(matchDataConstants.SWITCH_PLAYERS, true);
    switchSets(matchDataConstants.SWITCH_SETS, true);
  }, []);

  return (
    sets &&
    sets.length > 0 && (
      <Root>
        <Title>{translate("first serve rally length")}</Title>
        <ContainerChart>
          <DynamicBarChart
            sets={sets}
            player={player}
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/rallylength?idMatch=${id}&sets=${getSetsList(
                      sets
                    )}&playerNumber=${player}&serveReturn=serve&type=first`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatDynamicChartData(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
            name="firstserve"
            type="bar"
          />
        </ContainerChart>
        <ContainerChart>
          <RallyEffectivenessChart
            sets={sets}
            player={player}
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/rallylength?idMatch=${id}&sets=${getSetsList(
                      sets
                    )}&playerNumber=${player}&serveReturn=serve&type=first&order=${
                      query.order
                    }`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatRallyEffectivenessData(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
            width={500}
            height={400}
            type="bar"
          />
        </ContainerChart>
        <Title>{translate("second serve rally length")}</Title>
        <ContainerChart>
          <DynamicBarChart
            sets={sets}
            player={player}
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/rallylength?idMatch=${id}&sets=${getSetsList(
                      sets
                    )}&playerNumber=${player}&serveReturn=serve&type=second`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatDynamicChartData(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
            name="secondserve"
            type="bar"
          />
        </ContainerChart>
        <ContainerChart>
          <RallyEffectivenessChart
            sets={sets}
            player={player}
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/rallylength?idMatch=${id}&sets=${getSetsList(
                      sets
                    )}&playerNumber=${player}&serveReturn=serve&type=second&order=${
                      query.order
                    }`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatRallyEffectivenessData(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
            width={500}
            height={400}
            type="bar"
          />
        </ContainerChart>
        <Title>{translate("first serve return rally length")}</Title>
        <ContainerChart>
          <DynamicBarChart
            sets={sets}
            player={player}
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/rallylength?idMatch=${id}&sets=${getSetsList(
                      sets
                    )}&playerNumber=${player}&serveReturn=return&type=first`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatDynamicChartData(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
            name="firstservereturn"
            type="bar"
          />
        </ContainerChart>
        <ContainerChart>
          <RallyEffectivenessChart
            sets={sets}
            player={player}
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/rallylength?idMatch=${id}&sets=${getSetsList(
                      sets
                    )}&playerNumber=${player}&serveReturn=return&type=first&order=${
                      query.order
                    }`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatRallyEffectivenessData(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
            width={500}
            height={400}
            type="bar"
          />
        </ContainerChart>
        <Title>{translate("second serve return rally length")}</Title>
        <ContainerChart>
          <DynamicBarChart
            sets={sets}
            player={player}
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/rallylength?idMatch=${id}&sets=${getSetsList(
                      sets
                    )}&playerNumber=${player}&serveReturn=return&type=second`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatDynamicChartData(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
            name="secondservereturn"
            type="bar"
          />
        </ContainerChart>
        <ContainerChart>
          <RallyEffectivenessChart
            sets={sets}
            player={player}
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/rallylength?idMatch=${id}&sets=${getSetsList(
                      sets
                    )}&playerNumber=${player}&serveReturn=return&type=second&order=${
                      query.order
                    }`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatRallyEffectivenessData(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
            width={500}
            height={400}
            type="bar"
          />
        </ContainerChart>
        <Title>{translate("rally on first serve and return")}</Title>
        <ContainerChart>
          <DynamicBarChart
            sets={sets}
            player={player}
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/rallylength?idMatch=${id}&sets=${getSetsList(
                      sets
                    )}&playerNumber=${player}&serveReturn=serveReturn&type=first`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatDynamicChartData(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
            name="servereturnfirst"
            type="bar"
          />
        </ContainerChart>
        <ContainerChart>
          <RallyEffectivenessChart
            sets={sets}
            player={player}
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/rallylength?idMatch=${id}&sets=${getSetsList(
                      sets
                    )}&playerNumber=${player}&serveReturn=serveReturn&type=first&order=${
                      query.order
                    }`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatRallyEffectivenessData(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
            width={500}
            height={400}
            type="bar"
          />
        </ContainerChart>
        <Title>{translate("rally on second serve and return")}</Title>
        <ContainerChart>
          <DynamicBarChart
            sets={sets}
            player={player}
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/rallylength?idMatch=${id}&sets=${getSetsList(
                      sets
                    )}&playerNumber=${player}&serveReturn=serveReturn&type=second`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatDynamicChartData(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
            name="servereturnsecond"
            type="bar"
          />
        </ContainerChart>
        <ContainerChart>
          <RallyEffectivenessChart
            sets={sets}
            player={player}
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/rallylength?idMatch=${id}&sets=${getSetsList(
                      sets
                    )}&playerNumber=${player}&serveReturn=serveReturn&type=second&order=${
                      query.order
                    }`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatRallyEffectivenessData(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
            width={500}
            height={400}
            type="bar"
          />
        </ContainerChart>
      </Root>
    )
  );
}
