import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import { _options } from "./config";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import ReactApexChart from "react-apexcharts";
import translate from "../../../providers/i18n/translate";
import Reference from "./reference";

const Root = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: ${props => props.print ? "grid" : "flex"};
    grid-template-columns: ${props => props.print ? "1fr 1fr" : "inherit"};
    flex-direction: ${props => props.print ? "row" : "column"};
    opacity: ${props => props.loading === "true" ? 0.3 : 1};
    padding: 20px;
    overflow: hidden;
    border-radius: 20px;
    gap: ${props => props.print ? "0 20px" : 0};
    align-items: flex-start;
    justify-content: center;
`

const ContainerChart = styled.div`
    width: 100%;
    height: ${props => props.print ? "100%" : "300px"};
    margin: auto 0;
    .apexcharts-canvas {
        margin: 0 auto;
    }
    @media (min-width: 600px) {
        height: ${props => props.print ? "100%" : "400px"};
    }
    @media (min-width: 1024px) {
        height: ${props => props.print ? "100%" : "350px"};
    }
    @media (min-width: 1440px) {
        height: ${props => props.print ? "100%" : "350px"};
    }
`

const Title = styled.h5`
    display: ${props => props.print ? "none" : "inherit"};
    font-size: 1.25rem;
    color: ${props => props.theme.palette.accent};
    margin: 10px 0 0 0;
    width: 70%;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0 0 10px 0;
    @media print {
        display: none;
    }
`


const RadarChart = ({data, rawData, width, height, options, type, print}) => {

    const [ series, setSeries ] = React.useState(null);
    const [ loading, setLoading ] = React.useState(true);
    
    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const getData = () => {
        setLoading(true);
        if(data){
            data()
            .then(result => {
                setSeries(result.series);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                alertMessage(err.response.data.msg, alertConstants.ERROR);
                setLoading(false);
            })
        } else {
            if(rawData){
                setSeries(rawData.series);
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <Root loading={loading ? "true" : "false"} print={print}>
            <Title print={print}>{translate("Skillset")}</Title>
            <ContainerChart height={height} print={print}>
                {
                    series &&
                    <ReactApexChart
                        width="100%"
                        height="100%"
                        options={options ?
                            {
                                ..._options,
                                chart: {
                                    ..._options.chart,
                                    with: width,
                                    height: height
                                },
                                ...options
                            }
                            :
                            {
                                ..._options, 
                                chart: {
                                    ..._options.chart,
                                    with: width,
                                    height: height
                                }
                            }
                        } 
                        series={series} 
                        type={type ? type : "radar"} 
                    />
                }
            </ContainerChart>
            <Reference print={print} />
        </Root>
    );
}

RadarChart.propTypes = {
    data: PropTypes.any,
    rawData: PropTypes.any,
    width: PropTypes.number, 
    height: PropTypes.number,
    options: PropTypes.any, 
    type: PropTypes.string
}

export default RadarChart;