import React from "react";
import axios from 'axios';
import { useAuth } from "../../../../providers/authentication";
import { API_BACKEND } from "../../../../config";
import BasicModal from "../../../modals/modal";
import NewDesiredMargin from "./NewDesiredMargin";
import ConfirmNewMargin from "./ConfirmNewMargin";

const ModalDesiredMargin = ({ open, handleClose, performanceAverage, desiredMargin, handleDesiredMargin }) => {
  const { token } = useAuth();

  const [desiredMarginGoal, setDesiredMarginGoal] = React.useState(null);

  React.useEffect(() => {
    axios.get(`${API_BACKEND}/playerTraining/goal/searchByType?type=desiredMargin`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(result => {
        setDesiredMarginGoal(result.data)
      })
      .catch(err => {
        console.log(err);
      })
  }, [desiredMargin])

  return (
    desiredMarginGoal ?
      <BasicModal open={open} handleClose={handleClose} width="40%" height="35%" alignItems="flex-start" justifyContent="flex-start">
        <ConfirmNewMargin handleClose={handleClose} desiredMarginGoal={desiredMarginGoal} handleDesiredMargin={handleDesiredMargin}> </ConfirmNewMargin>
      </BasicModal>
      :
      <BasicModal open={open} handleClose={handleClose} width="60%" height="550px" alignItems="flex-start" justifyContent="flex-start">
        <NewDesiredMargin handleClose={handleClose} performanceAverage={performanceAverage} handleDesiredMargin={handleDesiredMargin}></NewDesiredMargin>
      </BasicModal>
  );
};

export default ModalDesiredMargin;
