import React from "react";
import styled from "styled-components";
import Notification from "./Notification";
import translate from '../../providers/i18n/translate'

const TOP_SIDEBAR = "50px";

const LEFT_SIDEBAR = "50px";

const Root = styled.div`
    position: fixed;
    top: ${TOP_SIDEBAR};
    right: 0;
    width: ${props => props.active ? "100%" : 0};
    opacity: ${props => props.active ? 1 : 0};
    height: ${`calc(100% - ${TOP_SIDEBAR})`};
    background-color: #FFF;
    z-index: 9999;
    overflow-x: hidden;
    transition: all 300ms ease-in-out;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    overflow-y: auto;
    @media (min-width: 1024px) {
        top: 0;
        left: ${LEFT_SIDEBAR};
        width: ${props => props.active ? "300px" : 0};
        height: 100%;
    }
`

const Title = styled.h2`
    position: relative;
    width: 95%;
    font-size: 1.5rem;
    line-height: 2rem;
    text-transform: capitalize;
    border-bottom: 2px solid ${props => props.theme.palette.accent};
    margin: 5px auto 10px auto;
`

const NotificationsSideBar = ({open, notifications, deleteNotification}) => {

    return (
        <Root active={open} >
            <Title>{translate('notifications')}</Title>
            {
                notifications && notifications.map((n, index) =>
                    <Notification key={`notification_${index}`} data={n} onDelete={deleteNotification} />
                )
            }
        </Root>
    );
}

export default NotificationsSideBar;