import React from "react";
import styled from "styled-components";
import { Outlet, useParams } from "react-router-dom";
import { useIntl } from 'react-intl';
import Submenu from "./Submenu";
import MatchData from "./matchData";
import MatchFilters from "./matchFilters";

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 20px 10px;
    max-width: ${props => `${props.theme.breakpoints.desktopPlus}px`};
    margin: 0 auto;
    @media (min-width: 600px) {
        padding: 20px 30px
    }
    @media (min-width: 1024px) {
        padding: 20px 50px
    }
`

const MatchLayout = ({children}) => {

    const intl = useIntl();

    const { id } = useParams();

    const submenues = [
        {
            name: intl.formatMessage({ id: 'performance' }),
            link: `/admin/match/${id}/performance`
        },
        {
            name: intl.formatMessage({ id: 'P1' }),
            link: `/admin/match/${id}/unforcederrors`
        },
        {
            name: intl.formatMessage({ id: 'M4' }),
            link: `/admin/match/${id}/minimatches`
        },
        {
            name: intl.formatMessage({ id: 'rally' }),
            link: `/admin/match/${id}/rally`
        },
        {
            name: intl.formatMessage({ id: 'flow' }),
            link: `/admin/match/${id}/flow`
        },
        {
            name: intl.formatMessage({ id: 'serve' }),
            link: `/admin/match/${id}/serve`
        },
        {
            name: intl.formatMessage({ id: 'sem' }),
            link: `/admin/match/${id}/sem`
        },
        {
            name: intl.formatMessage({ id: 'return' }),
            link: `/admin/match/${id}/return`
        },

        {
            name: intl.formatMessage({ id: 'rem' }),
            link: `/admin/match/${id}/rem`
        },
        {
            name: intl.formatMessage({ id: 'court' }),
            link: `/admin/match/${id}/court`
        }
    ]

    const filtersChildren = React.Children.map(children, (child) => {
        if(child.type === MatchFilters){
            return React.cloneElement(child);
        }
        return null;
    })

    return (
        <Root>
            <MatchData id={id}/>
            <Submenu data={submenues} />
            {filtersChildren}
            <Outlet />
        </Root>
    );
}

export default MatchLayout;

