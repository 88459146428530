import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { _options, _options_result, formatData } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import ReactApexChart from "react-apexcharts";

const Root = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ChartRallys = styled.div`
  width: ${props => props.print ? "200px" : "300px"};
  height: 400px;
  position: relative;
  transition: all 1s ease-in-out;
  z-index: 3;
  transform: ${props => props.print ? "translate(50%, 0)" : "translateY(10%)"};
  &.chart-rally-result-activated {
    transform: ${props => props.print ? "translateX(0)" : "translateY(0)"};
  }
  @media (min-width: 650px) {
    transform: translate(50%, 0);
    &.chart-rally-result-activated {
      transform: translateX(0);
    }
  }
  @media (min-width: 1024px) {
    width: ${props => props.print ? "200px" : "250px"};
    &.chart-rally-result-activated {
      transform: translateX(0);
    }
  }
  @media (min-width: 1440px) {
    width: ${props => props.print ? "225px" : "350px"};
  }
`;

const ChartRallyResult = styled.div`
  width: ${props => props.print ? "250px" : "300px"};
  height: ${props => props.print ? "400px" : "300px"};
  z-index: -2;
  transition: all 300ms ease-in-out;
  opacity: 0;
  transform: ${props => props.print ? "translate(-50%, 0)" : "translateY(-100%)"};
  &.active {
    z-index: 1;
    opacity: 1;
    transform: ${props => props.print ? "translateX(0)" : "translateY(0)"};
  }
  @media (min-width: 550px) {
    transform: translate(-50%, 0);
    &.active {
      z-index: 1;
      opacity: 1;
      transform: translateX(0);
    }
  }
  @media (min-width: 1024px) {
    width: ${props => props.print ? "200px" : "250px"};
    height: 400px;
  }
  @media (min-width: 1440px) {
    width: ${props => props.print ? "225px" : "350px"};
  }
`;

const DynamicBarChart = ({
  name,
  data,
  rawData,
  width,
  height,
  options,
  optionsresult,
  type,
  sets,
  player,
  print
}) => {
  const ApexCharts = window.ApexCharts;
  const intl = useIntl();

  const [series, setSeries] = React.useState(null);
  const [colors, setColors] = React.useState(null);

  const dispatch = useDispatch();
  const { alertMessage } = bindActionCreators(actionCreators, dispatch);
  const { appliedFilters } = useSelector(
    (state) => state.selectedDashboardFilters
  );

  const selectDataPoint = (e, chart, opts) => {
    var rallyChart = document.querySelector(`#chart-rallys-${name}`);
    var rallyResultChart = document.querySelector(
      `#chart-rally-result-${name}`
    );
    if (opts.selectedDataPoints[opts.seriesIndex].length === 1) {
      if (rallyResultChart.classList.contains("active")) {
        updateWonLoseChart(chart, `barWonLose-${name}`);
      } else {
        rallyChart.classList.add(`chart-rally-result-activated`);
        rallyResultChart.classList.add("active");
        updateWonLoseChart(chart, `barWonLose-${name}`);
      }
    } else {
      updateWonLoseChart(chart, `barWonLose-${name}`);
    }
    // Delete the active class if there are no selected points
    let deleteActive = true;
    for (let index = 0; index < opts.selectedDataPoints.length; index++) {
      if (opts.selectedDataPoints[index].length > 0) {
        deleteActive = false;
      }
    }
    if (deleteActive) {
      rallyChart.classList.remove(`chart-rally-result-activated`);
      rallyResultChart.classList.remove("active");
    }
  };

  const updated = (chart) => {
    updateWonLoseChart(chart, `barWonLose-${name}`);
  };

  const updateWonLoseChart = (sourceChart, destChartIDToUpdate) => {
    var series = [];
    var colorslist = [];

    var selectedPoints = sourceChart.w.globals.selectedDataPoints;
    for (
      let selectedDataPointIndex = 0;
      selectedDataPointIndex < selectedPoints.length;
      selectedDataPointIndex++
    ) {
      if (sourceChart.w.globals.selectedDataPoints[selectedDataPointIndex]) {
        for (
          var i = 0;
          i < selectedPoints[selectedDataPointIndex].length;
          i++
        ) {
          var selectedIndex = selectedPoints[selectedDataPointIndex][i];
          var rallySeries = sourceChart.w.config.series[selectedDataPointIndex];
          const existSeries = series.find(
            (s) => s.name === rallySeries.data[selectedIndex].x
          );
          if (existSeries) {
            existSeries.data = [
              {
                x: "WON",
                y:
                  existSeries.data[0].y +
                  rallySeries.data[selectedIndex].rallyResult[0].y,
              },
              {
                x: "LOSE",
                y:
                  existSeries.data[1].y +
                  rallySeries.data[selectedIndex].rallyResult[1].y,
              },
            ];
          } else {
            series.push({
              name: rallySeries.data[selectedIndex].x,
              data: rallySeries.data[selectedIndex].rallyResult,
            });
          }
          colorslist.push(rallySeries.data[selectedIndex].color);
        }
      }
    }

    if (series.length === 0) {
      series = [
        {
          data: [],
        },
      ];
    }
    const newOptions = {
      series: series,
      colors: colorslist,
      fill: {
        colors: colorslist,
      },
    };
    return ApexCharts.exec(destChartIDToUpdate, "updateOptions", newOptions);
  };

  const getData = () => {
    if (data) {
      data()
        .then((result) => {
          setSeries(result.series);
          setColors(result.colors);
        })
        .catch((err) => {
          console.log(err);
          alertMessage(err.response.data.msg, alertConstants.ERROR);
        });
    } else {
      if (rawData) {
        setSeries(rawData.series);
        setColors(rawData.colors);
      }
    }
  };

  React.useEffect(() => {
    getData();
  }, [JSON.stringify(sets), player, JSON.stringify(appliedFilters)]);

  return (
    series &&
    colors && (
      <Root>
        <ChartRallys id={`chart-rallys-${name}`} print={print}>
          <ReactApexChart
            height="100%"
            width="100%"
            options={
              options
                ? {
                    ..._options,
                    ...options,
                    chart: {
                      ..._options.chart,
                      ...options.chart,
                      events: {
                        dataPointSelection: selectDataPoint,
                        updated: updated,
                      },
                    },
                    colors: colors,
                  }
                : {
                    ..._options,
                    chart: {
                      ..._options.chart,
                      events: {
                        dataPointSelection: selectDataPoint,
                        updated: updated,
                      },
                    },
                    colors: colors,
                    subtitle: {
                      ..._options.subtitle,
                      text: intl.formatMessage({
                        id: "click on bar to see details",
                      }),
                    },
                  }
            }
            series={series}
            type={type ? type : "bar"}
          />
        </ChartRallys>
        <ChartRallyResult id={`chart-rally-result-${name}`} print={print}>
          <ReactApexChart
            height="100%"
            width="100%"
            options={
              optionsresult
                ? {
                    ..._options_result,
                    ...optionsresult,
                    chart: {
                      ..._options_result.chart,
                      ...optionsresult.chart,
                      id: `barWonLose-${name}`,
                    },
                  }
                : {
                    ..._options_result,
                    chart: {
                      ..._options_result.chart,
                      id: `barWonLose-${name}`,
                    },
                  }
            }
            series={[{ data: [] }]}
            type={type ? type : "bar"}
          />
        </ChartRallyResult>
      </Root>
    )
  );
};

DynamicBarChart.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.any,
  rawData: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number,
  options: PropTypes.any,
  optionsresult: PropTypes.any,
  type: PropTypes.string.isRequired,
};

export default DynamicBarChart;
