/* General imports */
import React from 'react';
import styled, { keyframes } from 'styled-components';
import backgroundHero from '../../assets/court_home.webp';
import headLogo from '../../assets/logoHEAD.webp';
import nikeLogo from '../../assets/logoNIKE.webp';

/* Styled components */
const Root = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    color: #fff;
    background-image: linear-gradient(#40CBE6,transparent),url(${backgroundHero});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    scroll-behavior: smooth;
    padding: 10px;
    box-sizing: border-box;
    @media screen and (min-width: 800px){
        background-attachment: fixed;
    }
`

const textAnimation = keyframes`
    from { opacity:0; }
    to { opacity: 1; transform: translateY(-50px); }
`

const imageAnimation = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`

const Title = styled.div`
    width: 100%;
    font-family: museo-sans-rounded, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-stretch: normal;
    color: #FFF;
    font-size: 2.5rem;
    padding: 20px 0;
    line-height: 100%;
    text-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    transform: translateY(50px);
    opacity: 0;
    text-align: center;
    animation-name: ${textAnimation};
    animation-duration: 5s;
    animation-fill-mode: forwards;
    @media (min-width: 600px) {
        font-size: 3.5rem;
    }
    @media (min-width: 1440px) {
        font-size: 4.5rem;
    }
`

const PreTitle = styled(Title)`
    font-size: 1.1rem;
    color: #DCFE54;
    @media (min-width: 600px) {
        font-size: 1.5rem;
    }
`

const Subtitle = styled(Title)`
    font-size: 1.5rem;
    text-transform: uppercase;
`

const ContentData = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
`;

const ContentLogoData = styled.div`
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    @media (min-width: 800px) {
        height: 100%;
        width: 75%;
    }
    @media (min-width: 1024px) {
        height: 100%;
        width: 100%;
    }
`;

const ContentImage = styled.div`
    position: relative;
    display: flex;
    width: auto;
    height: 70px;
    a {
        width: 100%;
        height: 100%;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        animation-name: ${imageAnimation};;
        animation-duration: 10s;
    }
    @media (min-width: 600px) {
        width: auto;
        height: 100px;
    }
    @media (min-width: 1024px) {
        width: auto;
        height: 125px;
    }
    @media (min-width: 1440px) {
        width: auto;
        height: 150px;
    }
`;

const HeroBanner = ({ pretitle, title, subtitle }) => {
    return (
        <Root>
            <ContentData>
                {
                    pretitle && <PreTitle>{pretitle}</PreTitle>
                }
                {
                    title && <Title>{title}</Title>
                }
                {
                    subtitle && <Subtitle>{subtitle}</Subtitle>
                }
            </ContentData>
            <ContentLogoData>
                <ContentImage>
                    <a href="https://www.head.com/en/" target="_blank" rel="noreferrer">
                        <img src={headLogo} alt="head-logo" />
                    </a>
                </ContentImage>
                <ContentImage>
                    <a href="https://www.nike.com" target="_blank" rel="noreferrer">
                        <img src={nikeLogo} alt="nike-logo" />
                    </a>
                </ContentImage>
            </ContentLogoData>
        </Root>
    );
}

export default HeroBanner;