import React from "react";
import styled from "styled-components";
import translate from "../../../providers/i18n/translate";
import { ThemeContext } from "../../../providers/theme";

const ReferenceContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: ${(props) => (props.show ? "auto" : "30px")};
  overflow-y: hidden;
  padding-top: 30px;
  box-sizing: border-box;
  transition: all 1s ease-in-out;
  @media (max-width: 600px) {
    height: ${(props) => (props.show ? "auto" : "35px")};
  }
`;

const ReferenceIcon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 10px;
  width: auto;
  height: 20px;
  cursor: pointer;
  span {
    font-size: 0.65rem;
    color: ${(props) => props.theme.palette.accent};
  }
  svg {
    color: ${(props) => props.theme.palette.accent};
  }
`;

const BoxText = styled.p`
  font-weight: 300;
  color: ${(props) => props.theme.palette.base};
  font-size: 0.75rem;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 1.5)};
  span {
    margin: 5px;
  }
  u {
    cursor: pointer;
  }
`;

const BoxTitle = styled.span`
  font-weight: 500;
  color: ${(props) => props.theme.palette.alternative_accent};
  font-size: 1rem;
  margin: ${(props) => (props.margin ? props.margin : "0 0 1rem 0")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 1)};
`;

const Reference = ({print}) => {
  const [showReference, setShowReference] = React.useState(false);

  const theme = React.useContext(ThemeContext);

  return (
    <ReferenceContainer show={print ? true : showReference}>
      <ReferenceIcon onClick={() => setShowReference(!showReference)}>
        <span>{translate("References")}</span>
        {!print && showReference ? theme.icons.arrowUp : theme.icons.arrowDown}
      </ReferenceIcon>
      <BoxTitle margin="5px 0 2px 0">{translate("% SWP")}</BoxTitle>
      <BoxText margin="2px 0 2px 0">
        {translate("Percentage of won points in service games")}
      </BoxText>
      <BoxTitle margin="5px 0 2px 0">{translate("% RWP")}</BoxTitle>
      <BoxText margin="2px 0 2px 0">
        {translate("Percentage of won points in return games")}
      </BoxText>
      <BoxTitle margin="5px 0 2px 0">{translate("% WM")}</BoxTitle>
      <BoxText margin="2px 0 2px 0">
        {translate("Percentage of won matches")}
      </BoxText>
      <BoxTitle margin="5px 0 2px 0">{translate("% WP")}</BoxTitle>
      <BoxText margin="2px 0 2px 0">
        {translate("Percentage of won points")}
      </BoxText>
      <BoxTitle margin="5px 0 2px 0">{translate("% RA")}</BoxTitle>
      <BoxText margin="2px 0 2px 0">
        {translate("Effectiveness in the average range of rallies")}
      </BoxText>
      <BoxTitle margin="5px 0 2px 0">{translate("% RM")}</BoxTitle>
      <BoxText margin="2px 0 2px 0">
        {translate(
          "Effectiveness in the range of rallies that is repeated the most in each match (Mode)"
        )}
      </BoxText>
    </ReferenceContainer>
  );
};

export default Reference;
