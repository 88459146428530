import React from 'react';
import styled from 'styled-components';
import Set from './Set';
import axios from 'axios';
import { API_BACKEND } from '../../../../../config';
import { useAuth } from '../../../../../providers/authentication';
import {useSelector, useDispatch} from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../../../store/action-creators";
import { setsConstants } from '../../../../../store/reducers/sets/setsConstants';

const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    @media (min-width: 600px) {
        justify-content: space-around;
    }
`

const Sets = ({id, enabled}) => {

    const { token } = useAuth();

    const {sets} = useSelector(state => state.selectedSets);

    const dispatch = useDispatch();

    const { changeSets } = bindActionCreators(actionCreators, dispatch);

    const switchSet = (indexSet) => {
        const newSets = sets;
        newSets[indexSet] = !sets[indexSet];
        changeSets(setsConstants.SET, newSets);
    }

    React.useEffect(() => {
        axios.get(`${API_BACKEND}/sets?idMatch=${id}`,
        {headers: { 
            'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            const setList = [];
            for (let index = 0; index < result.data; index++) {
                setList.push(true)                
            }
            changeSets(setsConstants.SET, setList);
        })
        .catch(err => {
            console.log(err);
        })
    }, [])

    return (
        sets && sets.length > 0 && enabled &&
        <Root>
            {
                sets.map((set, index) => 
                    <Set key={`set_button_${index + 1}`} nro={index} active={set} switchSet={switchSet} />
                )
            }
        </Root>
    );
}

export default Sets;