import React from 'react';
import styled from 'styled-components';
import translate from '../../../providers/i18n/translate';
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import { ThemeContext } from "../../../providers/theme";
import { useIntl } from 'react-intl';
import Popup from "../../popup/Popup";

const Root = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    opacity: ${props => props.loading === "true" ? 0.3 : 1};
`

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
`

const Total = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: ${props => props.theme.palette.principal};
    border-radius: 20px;
    padding: 20px;
    span {
        margin: auto;
        font-size: 5rem;
        font-weight: 900;
        color: ${props => props.theme.palette.base};
    }
    @media (min-width: 600px) {
        span {
            font-size: 9rem;
        }
    }
    @media (min-width: 1024px) {
        span {
            font-size: 5rem;
        }
    }
    @media (min-width: 1440px) {
        span {
            font-size: 9rem;
        }
    }
`

const Title = styled.h5`
    font-size: 1rem;
    color: ${props => props.theme.palette.accent};
    margin: 50px 0 0 0;
    width: 100%;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    height: 20%;
    @media (min-width: 600px) {
        font-size: 2rem;
    }
    @media (min-width: 1024px) {
        margin: 40px 0 0 0;
        font-size: 1.25rem;
    }
    @media (min-width: 1440px) {
        margin: 50px 0 0 0;
        font-size: 2rem;
    }
`

const UnforcedErrorsTable = ({ data, rawData, sets, player, matchId }) => {
    const intl = useIntl();
    const [totalPoints, setTotalPoints] = React.useState(null);
    const [totalChains, setTotalChains] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const theme = React.useContext(ThemeContext);

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);
    const { appliedFilters } = useSelector(state => state.selectedDashboardFilters);

    const getData = () => {
        setLoading(true);
        if (data) {
            data()
                .then(result => {
                    setTotalPoints(result.totalPoints);
                    setTotalChains(result.totalChains);
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                    alertMessage(err.response.data.msg, alertConstants.ERROR);
                    setLoading(false);
                })
        } else {
            if (rawData) {
                setTotalPoints(rawData.totalPoints);
                setTotalChains(rawData.totalChains);
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, [JSON.stringify(sets), player, JSON.stringify(appliedFilters), matchId]);


    return (
        <Root loading={loading ? "true" : "false"}>
            <Container>
                <Popup
                    title={intl.formatMessage({ id: 'Total points in a row' })}
                    content={`<p>${intl.formatMessage({ id: 'help total points in a row' })}</p>`}
                />
                <Total>
                    <Title>{translate("Total points in a row")}</Title>
                    <span>{totalPoints}</span>
                </Total>
            </Container>
            <Container>
                <Popup
                    title={intl.formatMessage({ id: 'Total chains' })}
                    content={`<p>${intl.formatMessage({ id: 'help total chains' })}</p>`}
                />
                <Total>
                    <Title>{translate("Total chains")}</Title>
                    <span>{totalChains}</span>
                </Total>
            </Container>
        </Root>
    );
}

export default UnforcedErrorsTable;