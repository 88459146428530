import React from "react";
import styled from "styled-components";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import {
  formatDataDeuceAd,
  formatDataFirstServeReturn,
  formatDataSecondServeReturn,
  formatDataFirstServeReturnIn,
  formatDataSecondServeReturnIn,
  getTooltip
} from "../../components/gsmcharts/lineAnnotationsChart/config";
import LineAnnotationsChart from "../../components/gsmcharts/lineAnnotationsChart";
import translate from "../../providers/i18n/translate";
import { useSelector } from "react-redux";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  gap: 50px 20px;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
`;

const PageTitle = styled.h2`
  grid-column: 1/3;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.principal};
  font-size: 1.25rem;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 1.5rem;
    text-align: left;
  }
`;

const PageDescription = styled.p`
  grid-column: 1/3;
  color: ${(props) => props.theme.palette.principal};
  font-size: 1rem;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 1.25rem;
    text-align: left;
  }
`;

const Title = styled.h2`
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.details};
  font-size: 1.25rem;
  text-align: center;
  order: ${props => props.order ? props.order : "inherit"};
  @media (min-width: 1024px) {
    font-size: 1.5rem;
    text-align: left;
    order: inherit;
  }
`;

const ContainerChart = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  background: ${(props) => props.theme.palette.principal};
  border-radius: 20px;
  padding: 20px 0;
  order: ${props => props.order ? props.order : "inherit"};
  @media (min-width: 600px) {
    height: auto;
  }
  @media (min-width: 1024px) {
    padding: 0;
    order: inherit;
  }
  @media (min-width: 1440px) {
    height: 100%;
    padding: 0;
    order: inherit;
  }
`;

const Return = () => {
  const { token } = useAuth();

  const MATCHES_LIMIT = 10;

  const { appliedFilters } = useSelector(state => state.selectedDashboardFilters);

  const optionsServeReturn = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return getTooltip(series, seriesIndex, dataPointIndex, w);
      },
    },
  };

  const optionsDeuceAd = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return getTooltip(series, seriesIndex, dataPointIndex, w);
      },
    },
  };

  return (
    <Root>
      <PageTitle>{translate("return")}</PageTitle>
      <PageDescription>
        {translate(
          "Detail of the percentage of won points with first and second serve return. It also includes the percentage of won points with the first and second serve return on the deuce and ad court."
        )}
      </PageDescription>
      <Title order={1}>{translate("1st Serve Return In")}</Title>
      <Title order={3}>{translate("Deuce and Ad Points on 1st Serve Return In")}</Title>
      <ContainerChart order={2}>
        <LineAnnotationsChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalfirstservereturnin?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDataFirstServeReturnIn(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="line"
          width={500}
          height={500}
          options={optionsServeReturn}
        />
      </ContainerChart>
      <ContainerChart order={4}>
        <LineAnnotationsChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicaldeuceadfirstservereturnin?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDataDeuceAd(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="line"
          width={500}
          height={500}
          options={optionsDeuceAd}
        />
      </ContainerChart>
      <Title order={5}>{translate("1st Serve Return Effectiveness")}</Title>
      <Title order={7}>{translate("Deuce and Ad Points Won on 1st Serve Return")}</Title>
      <ContainerChart order={6}>
        <LineAnnotationsChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalservereturn?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveType=firstServe`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDataFirstServeReturn(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="line"
          width={500}
          height={500}
          options={optionsServeReturn}
        />
      </ContainerChart>
      <ContainerChart order={8}>
        <LineAnnotationsChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicaldeuceadservereturn?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturnType=firstServeReturn`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDataDeuceAd(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="line"
          width={500}
          height={500}
          options={optionsDeuceAd}
        />
      </ContainerChart>
      <Title order={9}>{translate("2nd Serve Return In")}</Title>
      <Title order={11}>{translate("Deuce and Ad Points on 2nd Serve Return In")}</Title>
      <ContainerChart order={10}>
        <LineAnnotationsChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalsecondservereturnin?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDataSecondServeReturnIn(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="line"
          width={500}
          height={500}
          options={optionsServeReturn}
        />
      </ContainerChart>
      <ContainerChart order={12}>
        <LineAnnotationsChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicaldeuceadsecondservereturnin?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDataDeuceAd(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="line"
          width={500}
          height={500}
          options={optionsDeuceAd}
        />
      </ContainerChart>
      <Title order={13}>{translate("2nd Serve Return Effectiveness")}</Title>
      <Title order={15}>{translate("Deuce and Ad Points Won on 2nd Serve Return")}</Title>
      <ContainerChart order={14}>
        <LineAnnotationsChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalservereturn?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveType=secondServe`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDataSecondServeReturn(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="line"
          width={500}
          height={500}
          options={optionsServeReturn}
        />
      </ContainerChart>
      <ContainerChart order={16}>
        <LineAnnotationsChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicaldeuceadservereturn?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturnType=secondServeReturn`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDataDeuceAd(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="line"
          width={500}
          height={500}
          options={optionsDeuceAd}
        />
      </ContainerChart>
    </Root>
  );
};

export default Return;
