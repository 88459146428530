import React from "react";
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { ThemeContext } from "../providers/theme";
import translate from "../providers/i18n/translate";
import { SearchInput, Table } from "../components/tables/table";
import axios from 'axios';
import { API_BACKEND } from '../config';
import { useAuth } from '../providers/authentication';
import MatchCard from "../components/match/MatchCard";
import { useNavigate } from "react-router-dom";
import {useDispatch} from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../store/action-creators";
import { alertConstants } from "../store/reducers/messageBar/alertConstants";

/* Styled components */
const Root = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    padding: 20px;
`

const NewIcon = styled.div`
    position: fixed;
    right: 20px;
    top: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 50%;
    background-color: ${props => props.bg ? props.bg : "7A7A7A"};
    color: ${props => props.color ? props.color : "#FFF"};
    cursor: pointer;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    width: 50px;
    height: 50px;
    &:hover {
        transform: scale(1.1);
    }
    z-index: 99;
    @media (min-width: 1024px) {
        right: 50px;
        bottom: 50px;
    }
`

const Won = styled.div`
    padding: 0 5px;
`

export default function MatchesPage()  {

    const intl = useIntl();

    let navigate = useNavigate();

    const theme = React.useContext(ThemeContext);

    const tableRef = React.useRef();

    const [ search, setSearch ] = React.useState('');
    const [ filters, setFilters ] = React.useState([]);

    const { token } = useAuth();

    const dispatch = useDispatch();

    const {alertMessage} = bindActionCreators(actionCreators, dispatch);

    const [ selectedView, setSelectedView ] = React.useState("list");
    
    const columnsTable = [
        { title: 'ID', field: 'id',width: "8%", order: true, hidden: false},
        { title: translate("opponent"), field: 'playerOpponent.name', order: false, width: "20%",  hidden: false },
        { title: translate("date"), field: 'date', order: true, type: "date", width: "10%", hidden: false },
        { title: translate("startTime"), field: 'startTime',width: "10%", hidden: true, mobile: false },
        { title: translate("endTime"), field: 'endTime',width: "10%", hidden: true, mobile: false },
        { title: translate("won"), field: 'won',width: "10%", align: "center", cellAlign: "center", order: false, hidden: false, mobile: true, 
            render: (d) => <Won>{d.won ? theme.icons.check : theme.icons.close}</Won>
        },
        { title: translate("tournament"), field: 'tournament',width: "20%", hidden: false, mobile: false },
        { title: translate("surface"), field: 'surface',width: "10%", order: true, hidden: false, mobile: false },
        { title: translate("ball"), field: 'ball',width: "10%", hidden: false, mobile: false },
        { title: translate("umpire"), field: 'umpire',width: "10%", hidden: true, mobile: false },
        { title: translate("actions"), actions: true, width: "auto", hidden: false }
    ]

    const changeView = (value) => {
        setSelectedView(value);
    }

    const handleNewItem = (data) => {
        navigate(`/admin/uploadmatch`);
    }
    
    const handleEditItem = (data) => {
        navigate(`/admin/match/${data.id}/edit`);
    }

    const handleDeleteItem = (data) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(`${intl.formatMessage({ id: 'Do you want to delete match' })} ${data.id}?`)) {
            const url = `${API_BACKEND}/matches?idMatch=${data.id}`;
            axios.delete(url,{headers: {'Authorization': `Bearer ${token}`}})
            .then(function (response) {
                tableRef.current.clean();
                alertMessage(intl.formatMessage({ id: "You have successfully deleted your match" }), alertConstants.SUCCESS);
            })
            .catch(error => {
                alertMessage(error.response.data.msg, alertConstants.ERROR);
            });
        }
    }

    const onSearchChange = (value) => {
        setSearch(value);
    }

    const handleSearch = () => {
        tableRef.current.clean();
    }

    const goToMatch = (data) => {
        navigate(`/admin/match/${data.id}/performance`);
    }

    const actionsTable={
        edit: {
            icon: theme.icons.edit,
            tooltip: intl.formatMessage({ id: 'edit' }),
            onClick: handleEditItem,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: false
        },
        delete: {
            icon: theme.icons.delete,
            tooltip: intl.formatMessage({ id: 'delete' }),
            onClick: handleDeleteItem,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: false
        }
    }

    return(
        <Root>
            <Table 
                ref={tableRef}
                disableTotalTitle
                columns={columnsTable}
                actionsTable={actionsTable}
                title={intl.formatMessage({ id: 'matches' })}
                viewTable={selectedView}
                changeView={changeView}
                gridCard={(data) => <MatchCard data={data} actionsTable={actionsTable} />}
                onRowClick={goToMatch}
                defaultOrder={
                    {
                        field: "date",
                        order: "DESC"
                    }
                }
                data={
                    (query) => new Promise((resolve, reject) => {
                        axios.get(`${API_BACKEND}/matches?page=${query.page -1}&limit=${query.pageSize}&search=${search}&filters=[]&order=${JSON.stringify(query.order)}`,
                        {headers: { 
                            'Authorization': `Bearer ${token}`
                          }
                        })
                        .then(result => {
                            resolve({
                                rows: result.data.data,
                                total: result.data.count
                            });
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    })
                }
            >
                <SearchInput search={search} onChange={onSearchChange} onSearch={handleSearch} />
            </Table>
            <NewIcon color={theme.palette.base} bg={theme.palette.accent} onClick={handleNewItem}>{theme.icons.add}</NewIcon>
        </Root>
    )
};