import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: museo-sans-rounded, sans-serif;
  }
  html {
    min-width: 300px;
  }
`
export default GlobalStyle;