import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from '../../providers/theme';
import Modal from "../../components/modals/modal";
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';
import { API_BACKEND } from "../../config";
import { useIntl } from 'react-intl';
import { useAuth } from "../../providers/authentication";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";

const Root = styled.button`
    margin: 15px 0 10px 0;
    border: none;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    background: ${props => props.theme.palette.details};
    color: ${props => props.theme.palette.base};
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    &:hover {
        box-shadow: 0 0 5px rgba(0,0,0,0.8);
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 50px;
`

const Title = styled.h2`
    color: ${props => props.theme.palette.details};
    text-transform: uppercase;
    margin: 0 0 30px 0;
`

const StyledFormControl = styled(FormControl)`
  margin: ${(props) => props.margin ? props.margin : '15px 0!important'};
  width: 100%;
  @media (min-width: 600px) {
    ${(props) => props.width ? `width: ${props.width};` : ''}
  }
`

const StyledButton = styled.button`
    padding: 10px 20px;
    border: none;
    background: ${props => props.theme.palette.details};
    border-radius: 8px;
    margin: 20px 0 5px 0;
    color: ${props => props.theme.palette.base};
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    outline: none;
    &:hover {
        box-shadow: 0 0 5px rgba(0,0,0,0.8);
    }
`

const ChangePassword = ({ idUser }) => {

    const theme = React.useContext(ThemeContext);
    const dispatch = useDispatch();
    const intl = useIntl();
    const { token } = useAuth();
    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const [open, setOpen] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState({
        currentPassword: false,
        newPassword: false,
        repeatNewPassword: false
    })

    const [formData, setFormData] = React.useState({
        currentPassword: '',
        newPassword: '',
        repeatNewPassword: ''
    })

    const onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleClickShowPassword = (e) => {
        const { name } = e.currentTarget;
        setShowPassword({
            ...showPassword,
            [name]: !showPassword[name]
        })
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const openModal = (e) => {
        e.preventDefault();
        setOpen(true);
    }

    const closeModal = () => {
        setFormData({
            currentPassword: '',
            newPassword: '',
            repeatNewPassword: ''
        })
        setOpen(false);
    }

    const submit = (e) => {
        e.preventDefault();
        const url = `${API_BACKEND}/users/changePassword`;
        axios.put(url, formData,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(function (response) {
                alertMessage(intl.formatMessage({ id: "You have successfully changed your password" }), alertConstants.SUCCESS);
                closeModal()
            })
            .catch(error => {
                alertMessage(error.response.data.msg, alertConstants.ERROR);
            });
    }

    return (
        <React.Fragment>
            <Root onClick={openModal}>
                {translate("change password")}

            </Root>
            <Modal open={open} handleClose={closeModal} height="auto" width="auto" >
                <Content>
                    <Title>{translate("change password")}</Title>
                    <StyledFormControl variant="standard">
                        <InputLabel htmlFor="currentPassword">{translate("current password")}</InputLabel>
                        <Input
                            fullWidth
                            required
                            id="currentPassword"
                            name="currentPassword"
                            type={showPassword.currentPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            value={formData.currentPassword}
                            onChange={onChange}
                            variant="standard"
                            margin='dense'
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        name="currentPassword"
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword.currentPassword ? theme.icons.visibility : theme.icons.visibilityoff}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </StyledFormControl>
                    <StyledFormControl variant="standard">
                        <InputLabel htmlFor="newPassword">{translate("new password")}</InputLabel>
                        <Input
                            fullWidth
                            required
                            id="newPassword"
                            name="newPassword"
                            type={showPassword.newPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            value={formData.newPassword}
                            onChange={onChange}
                            variant="standard"
                            margin='dense'
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        name="newPassword"
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword.newPassword ? theme.icons.visibility : theme.icons.visibilityoff}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </StyledFormControl>
                    <StyledFormControl variant="standard">
                        <InputLabel htmlFor="repeatNewPassword">{translate("repeat new password")}</InputLabel>
                        <Input
                            fullWidth
                            required
                            id="repeatNewPassword"
                            name="repeatNewPassword"
                            type={showPassword.repeatNewPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            value={formData.repeatNewPassword}
                            onChange={onChange}
                            variant="standard"
                            margin='dense'
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        name="repeatNewPassword"
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword.repeatNewPassword ? theme.icons.visibility : theme.icons.visibilityoff}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </StyledFormControl>
                    <StyledButton onClick={submit}>{translate("apply")}</StyledButton>
                </Content>
            </Modal>
        </React.Fragment>
    );
}

export default ChangePassword;