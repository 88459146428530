import React from "react";
import styled from "styled-components";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import MatchPerformanceChart from "../../components/gsmcharts/matchPerformanceChart";
import {
  formatHistoricalData,
  getTooltipHistoricalPerformance,
} from "../../components/gsmcharts/matchPerformanceChart/config";
import { formatData as formatLeadershipData } from "../../components/gsmcharts/leadershipChart/config";
import LeadershipChart from "../../components/gsmcharts/leadershipChart";
import SemiCircleChart from "../../components/gsmcharts/semiCircleChart";
import { formatTotalPointsWonData } from "../../components/gsmcharts/semiCircleChart/config";
import { useSelector } from "react-redux";

const Root = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  gap: 10px;
`;

const PerformanceContainer = styled.div`
  position: relative;
  width: 100%;
  grid-column: 1/3;
  background: ${(props) => props.theme.palette.principal};
  height: 380px;
  border-radius: 20px;
`;

const LeaderShipContainer = styled.div`
  position: relative;
  width: 100%;
  background: ${(props) => props.theme.palette.principal};
  height: 325px;
  border-radius: 20px;
`;

const MatchQualityContainer = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
  border-radius: 20px;
`;

const SemiCircleContainer = styled.div`
  position: relative;
  width: 100%;
  background: ${(props) => props.theme.palette.principal};
  height: 325px;
  border-radius: 20px;
`;

const Performance = ({onReady}) => {
  const { token } = useAuth();

  const MATCHES_LIMIT = 10;
  const NAME = "performance";

  const { appliedFilters } = useSelector(
    (state) => state.selectedDashboardFilters
  );

  const optionsHistoricalPerformance = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return getTooltipHistoricalPerformance(
          series,
          seriesIndex,
          dataPointIndex,
          w
        );
      },
    },
  };

  const optionsSemiCircle = {
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          size: 50,
          background: "transparent",
          margin: 0,
        },
        track: {
          background: "#3C555C",
          strokeWidth: "75%",
          startAngle: -90,
          endAngle: 90,
          opacity: 0.25,
        },
        dataLabels: {
          show: true,
          name: {
            show: true,
            color: "#DCFE54",
            offsetY: -70,
            fontSize: "1rem",
          },
          value: {
            show: true,
            color: "#FFFFFF",
            offsetY: -25,
            fontSize: "3rem",
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                show: true,
                name: {
                  show: true,
                  color: "#DCFE54",
                  offsetY: -60,
                  fontSize: '0.70rem',
                },
                value: {
                  show: true,
                  color: "#FFFFFF",
                  offsetY: -30,
                  fontSize: '2.5rem',
                  formatter: function (val) {
                    return val + '%'
                  }
                }
              }
            }
          },
        },
      },
      {
        breakpoint: 1023,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                show: true,
                name: {
                  show: true,
                  color: "#DCFE54",
                  offsetY: -80,
                  fontSize: '1.25rem',
                },
                value: {
                  show: true,
                  color: "#FFFFFF",
                  offsetY: -30,
                  fontSize: '3.25rem',
                  formatter: function (val) {
                    return val + '%'
                  }
                }
              }
            }
          },
        },
      },
      {
        breakpoint: 1440,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                show: true,
                name: {
                  show: true,
                  color: "#DCFE54",
                  offsetY: -55,
                  fontSize: '0.85rem',
                },
                value: {
                  show: true,
                  color: "#FFFFFF",
                  offsetY: -30,
                  fontSize: '2.5rem',
                  formatter: function (val) {
                    return val + '%'
                  }
                }
              }
            }
          },
        },
      },
      {
        breakpoint: 1441,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                show: true,
                name: {
                  show: true,
                  color: "#DCFE54",
                  offsetY: -70,
                  fontSize: '1rem',
                },
                value: {
                  show: true,
                  color: "#FFFFFF",
                  offsetY: -30,
                  fontSize: '3rem',
                  formatter: function (val) {
                    return val + '%'
                  }
                }
              }
            }
          },
        },
      }
    ]
  };

  const allReady = [false,false,false];

  const handleReady = () => {
    if(allReady[0] && allReady[1] && allReady[2]){
      onReady(NAME);
    }
  }

  React.useEffect(() => {}, [JSON.stringify(appliedFilters)]);

  return (
    <Root>
      <PerformanceContainer>
        <MatchPerformanceChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalperformance?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(
                    appliedFilters
                  )}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatHistoricalData(result.data);
                  allReady[0] = true;
                  handleReady();
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="line"
          width={500}
          height={300}
          heightChart="300px"
          options={optionsHistoricalPerformance}
        />
      </PerformanceContainer>
      <LeaderShipContainer>
        <LeadershipChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalleadershipmargin?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(
                    appliedFilters
                  )}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatLeadershipData(result.data);
                  allReady[1] = true;
                  handleReady();
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="pie"
          width={500}
          height={500}
        />
      </LeaderShipContainer>
      <SemiCircleContainer>
        <SemiCircleChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalpointswon?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(
                    appliedFilters
                  )}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatTotalPointsWonData(result.data);
                  allReady[2] = true;
                  handleReady();
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="radialBar"
          labels={["% Total Points Won"]}
          width={500}
          height={500}
          options={optionsSemiCircle}
        />
      </SemiCircleContainer>
    </Root>
  );
};

export default Performance;
