import React from "react";
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";
import { _options, _optionsV2 } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";

const Root = styled.div`
    width: 100%;
    height: auto;
    margin: auto;
    .apexcharts-canvas {
        margin: 0 auto;
    }
`;

const MiniMatchesChart = ({ width, height, data, rawData, options, type, sets, player, simulation, matchId, whiteLegend }) => {

    const [series, setSeries] = React.useState(null);
    const [categories, setCategories] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);
    const { appliedFilters } = useSelector(state => state.selectedDashboardFilters);

    const chosenOptions = whiteLegend ? _optionsV2 : _options;

    const getData = () => {
        setLoading(true);
        if (data) {
            data()
                .then(result => {
                    setSeries(result.series);
                    setCategories(result.categories);
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                    alertMessage(err.response.data.msg, alertConstants.ERROR);
                    setLoading(false);
                })
        } else {
            if (rawData) {
                setSeries(rawData.series);
                setCategories(rawData.categories);
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, [JSON.stringify(sets), player, JSON.stringify(appliedFilters), JSON.stringify(simulation), matchId]);

    return (
        <Root loading={loading ? "true" : "false"}>
            {series && categories && (
                <ReactApexChart
                    options={
                        options
                            ? {
                                ...chosenOptions,
                                ...options,
                            }
                            : {
                                ...chosenOptions,
                                xaxis: {
                                    ...chosenOptions.xaxis,
                                    categories: categories,
                                },
                                chart: {
                                    ...chosenOptions.chart,
                                    width: width,
                                    height: height,
                                },
                            }
                    }
                    series={series}
                    type={type ? type : "bar"}
                />
            )}
        </Root>
    );
};

export default MiniMatchesChart;