import AccessDeniedPage from '../../pages/AccessDeniedPage';
import { useJwt } from "../../hooks/jwt";
import { useAuth } from "../../providers/authentication";
import { useParams } from "react-router-dom";
  
const Private = ({ children, scope, nullReturn }) => {

   

    /* const SCOPE_SEPARATOR = " ";  */

    const { token } = useAuth();
    
    const { id } = useParams();

    const { decodedToken } = useJwt(token);

    /* if(decodedToken && decodedToken.scopes.split(SCOPE_SEPARATOR).includes("root")){
        return children;
    }

    if ((decodedToken && !decodedToken.scopes.split(SCOPE_SEPARATOR).includes(scope))) {
        return nullReturn ? null : <AccessDeniedPage />;
    } */

    if (id && decodedToken && parseInt(id) !== decodedToken?.id) {
        return nullReturn ? null : <AccessDeniedPage />;
    }

    return children;
};

export default Private;