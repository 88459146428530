import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import styled from "styled-components";
import translate from "../../../providers/i18n/translate";
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { dashboardFiltersConstants } from "../../../store/reducers/dashboardFilters/dashboardFiltersConstants";

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  padding: 14px;
  border-radius: 10px;
`;

const Title = styled.h2`
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
`;

const InputsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const DateFilter = () => {
  const { filters } = useSelector((state) => state.selectedDashboardFilters);

  const [value, setValue] = React.useState([new Date(), new Date()]);

  const dispatch = useDispatch();
  const { changeDashboardFilters } = bindActionCreators(actionCreators, dispatch);

  const handleChangeFrom = (newValue) => {
    setValue([newValue, value[1]]);
    const selectedRange = filters.find((fil) => fil.name === "date");
    if (selectedRange) {
      selectedRange.values = [newValue.format("YYYY-MM-DD"), value[1]];
      changeDashboardFilters(dashboardFiltersConstants.SET, filters);
    } else {
      const newFilter = {
        name: "date",
        values: [newValue.format("YYYY-MM-DD"), value[1]],
        condition: "between",
        type: "date",
      };
      changeDashboardFilters(dashboardFiltersConstants.SET, [...filters, newFilter]);
    }
  };

  const handleChangeTo = (newValue) => {
    setValue([value[0], newValue]);
    const selectedRange = filters.find((fil) => fil.name === "date");
    if (selectedRange) {
      selectedRange.values = [value[0], newValue.format("YYYY-MM-DD")];
      changeDashboardFilters(dashboardFiltersConstants.SET, filters);
    } else {
      const newFilter = {
        name: "date",
        values: [value[0], newValue.format("YYYY-MM-DD")],
        condition: "between",
        type: "date",
      };
      changeDashboardFilters(dashboardFiltersConstants.SET, [...filters, newFilter]);
    }
  };

  return (
    <Root>
      <Title>{translate("Date")}</Title>
      <InputsGroup>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="From"
            name="from"
            value={value[0]}
            onChange={handleChangeFrom}
            renderInput={(params) => <TextField margin="dense" size="small" {...params} />}
          />
          <DatePicker
            label="To"
            name="to"
            value={value[1]}
            onChange={handleChangeTo}
            renderInput={(params) => <TextField margin="dense" size="small" {...params} />}
          />
        </LocalizationProvider>
      </InputsGroup>
    </Root>
  );
};

export default DateFilter;
