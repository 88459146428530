import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import { useIntl } from 'react-intl';
import { formatData, _options } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import ReactApexChart from "react-apexcharts";
import SelectInput from "../../inputs/SelectInput";
import { ThemeContext } from "../../../providers/theme";
import Popup from "../../popup/Popup";

const Root = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
`

const ContainerChart = styled.div`
    width: 100%;
    height: ${props => props.height ? `calc(${props.height}px - 50px)` : "350px"};
    .apexcharts-canvas {
        margin: 0 auto;
    }
`

const RallyEffectivenessChart = ({ data, rawData, width, height, options, type, sets, player }) => {

    const intl = useIntl();
    const theme = React.useContext(ThemeContext);
    const [series, setSeries] = React.useState(null);
    const [categories, setCategories] = React.useState(null);
    const [colors, setColors] = React.useState(null);
    const [order, serOrder] = React.useState({
        label: 'points',
        value: "points"
    });

    const ordersList = [
        {
            id: "points",
            name: 'points'
        },
        {
            id: "effectiveness",
            name: "effectiveness"
        }
    ]

    const handleChangeAutocomplete = (name, value) => {
        serOrder(value);
    }

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);
    const { appliedFilters } = useSelector(state => state.selectedDashboardFilters);

    const getData = () => {
        if (data) {
            data({ order: order.value })
                .then(result => {
                    setSeries(result.series);
                    setCategories(result.categories);
                    setColors(result.colors);
                })
                .catch(err => {
                    console.log(err);
                    alertMessage(err.response.data.msg, alertConstants.ERROR);
                })
        } else {
            if (rawData) {
                setSeries(rawData.series);
                setCategories(rawData.categories);
                setColors(rawData.colors);
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, [JSON.stringify(sets), player, order, JSON.stringify(appliedFilters)]);

    return (
        series && categories && colors &&
        <Root>
            <SelectInput value={order} fieldlabel="name" fieldvalue="id" data={ordersList} label={intl.formatMessage({ id: 'sort by' })} name="order" onChange={handleChangeAutocomplete} width="200px" margin="dense" size="small" disableClearable />
            <ContainerChart height={height}>
                <Popup
                    title={intl.formatMessage({ id: 'rally effectiveness chart' })}
                    content={`<p>${intl.formatMessage({ id: 'help rally effectiveness chart' })}</p>`}
                    color={theme.palette.principal}
                />
                <ReactApexChart
                    width="100%"
                    height="100%"
                    options={options ?
                        {
                            ..._options,
                            ...options,
                            xaxis: {
                                ..._options.xaxis,
                                ...options.xaxis,
                                categories: categories
                            },
                            colors: colors
                        }
                        :
                        {
                            ..._options,
                            xaxis: {
                                ..._options.xaxis,
                                categories: categories
                            },
                            chart: {
                                ..._options.chart,
                                with: width,
                                height: height
                            },
                            colors: colors
                        }
                    }
                    series={series}
                    type={type ? type : "bar"}
                />
            </ContainerChart>
        </Root>
    );
}

RallyEffectivenessChart.propTypes = {
    data: PropTypes.any,
    rawData: PropTypes.any,
    width: PropTypes.number,
    height: PropTypes.number,
    options: PropTypes.any,
    type: PropTypes.string.isRequired
}

export default RallyEffectivenessChart;