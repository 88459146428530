export const semPlayerData = {
    "historicalSEM": [
        {
            "opponent": "Bencic Belinda",
            "value": 52.22,
            "message": "The serve will probably be broken in 1 of 2 games"
        }
    ],
    "averageSEM": {
        "value": "52.22",
        "message": "The serve will probably be broken in 1 of 2 games"
    }
}

export const semOpponentData = {
    "historicalSEM": [
        {
            "opponent": "Bencic Belinda",
            "value": 59.3,
            "message": "You will probably break the opponent serve in 1 of 2 games"
        }
    ],
    "averageSEM": {
        "value": "59.30",
        "message": "You will probably break the opponent serve in 1 of 2 games"
    }
}

