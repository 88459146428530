import React from "react";
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";
import { _options } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import Semaphore from "./semaphore";

const ChartsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  @media (min-width: 1440px) {
    flex-direction: row;
    display: grid;
    grid-template-columns: 75% 25%;
  }
`;

const HorizontalChartContainer = styled.div`
  position: relative;
    width: 100%;
    height: auto;
    margin: auto;
    .apexcharts-canvas {
        margin: 0 auto;
    }
`;

const HorizontalBarChart = ({ width, height, data, rawData, options, type, sets, player, simulation, matchId, calcColor1, calcColor2 }) => {

    const [series, setSeries] = React.useState(null);
    const [semaphore, setSemaphore] = React.useState(null);
    const [categories, setCategories] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);
    const { appliedFilters } = useSelector(state => state.selectedDashboardFilters);

    const getData = () => {
        setLoading(true);
        if (data) {
            data()
                .then(result => {
                    setSeries(result.series);
                    setCategories(result.categories);
                    setSemaphore(result.semaphore)
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                    alertMessage(err.response.data.msg, alertConstants.ERROR);
                    setLoading(false);
                })
        } else {
            if (rawData) {
                setSeries(rawData.series);
                setCategories(rawData.categories);
                setSemaphore(rawData.semaphore)
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, [JSON.stringify(sets), player, JSON.stringify(appliedFilters), JSON.stringify(simulation), matchId]);

    return (
        <ChartsWrapper>
            <HorizontalChartContainer loading={loading ? "true" : "false"}>
                {series && categories && (
                    <ReactApexChart
                        options={
                            options
                                ? {
                                    ..._options,
                                    ...options,
                                }
                                : {
                                    ..._options,
                                    xaxis: {
                                        ..._options.xaxis,
                                        categories: categories,
                                    },
                                    chart: {
                                        ..._options.chart,
                                        width: width,
                                        height: height,
                                    },
                                }
                        }
                        series={series}
                        type={type ? type : "bar"}
                    />
                )}
            </HorizontalChartContainer>
            {
                semaphore && semaphore.sum ?
                    <Semaphore background="transparent" orientation="vertical" width="60px" fontSize="1.5rem" widthMsg={'60%'} value={semaphore.sum} calculateColor1={calcColor1} calculateColor2={calcColor2} />
                : null
            }
        </ChartsWrapper>
    );
};

export default HorizontalBarChart;