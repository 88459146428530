import { matchDataConstants } from "./matchDataConstants";

const initialState = { 
  match: {
    idMatch: null,
    player1: null,
    player2: null,
    enabledSets: true,
    enabledPlayers: true,
    isLoading: true,
    surface: null,
    ball: null,
    matchDate: null,
    tournament: null,
    umpire: null,
    courtInnerColor: null,
    courtSurroundColor: null,
    lineColor: null,
    startTime: null,
    endTime: null
  }
};

export function matchData(state = initialState, action) {
  switch (action.type) {
    case matchDataConstants.SET:
      return {
        ...state,
        match: {
          ...state.match,
          ...action.data,
          idMatch: action.data.idMatch,
         /* player1: action.data.player1,
          player2: action.data.player2,*/
          isLoading: false
        }
      };
    case matchDataConstants.SWITCH_SETS:
      return {
        ...state,
        match: {
          ...state.match,
          enabledSets: action.value,
          isLoading: false
        }
      };
    case matchDataConstants.SWITCH_PLAYERS:
      return {
        ...state,
        match: {
          ...state.match,
          enabledPlayers: action.value,
          isLoading: false
        }
      };
    default:
      return state
  }
}