import React from "react";
import styled from "styled-components";
import Chart from "./chart";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import { ThemeContext } from "../../../providers/theme";
import useMediaQuery from "../../../hooks/useMediaQuery";
import translate from "../../../providers/i18n/translate";
import { useIntl } from "react-intl";
import Popup from "../../popup/Popup";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

const Text = styled.span`
  text-align: center;
  color: ${props => props.theme.palette.details};
`

const SunburstZoomableChart = ({ id, data, rawData, sets }) => {

  const intl = useIntl();

  const [loading, setLoading] = React.useState(true);
  const [sunburstData, setSunburstData] = React.useState(null);
  const { appliedFilters } = useSelector(state => state.selectedDashboardFilters);

  const tablet = useMediaQuery('(min-width: 600px)');
  const desktop = useMediaQuery('(min-width: 1024px)');
  const desktopPlus = useMediaQuery('(min-width: 1440px)');

  const theme = React.useContext(ThemeContext);

  const dispatch = useDispatch();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const getData = () => {
    setLoading(true);
    if (data) {
      data()
        .then((result) => {
          setSunburstData(result);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          alertMessage(err.response.data.msg, alertConstants.ERROR);
          setLoading(false);
        });
    } else {
      if (rawData) {
        setSunburstData(rawData);
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    getData();
  }, [JSON.stringify(sets), JSON.stringify(appliedFilters)]);

  return sunburstData &&
    <Root>
      <Container>
        <Popup
          title={intl.formatMessage({ id: 'forehand and backhand key shots' })}
          content={`<p>${intl.formatMessage({ id: 'help forehand and backhand key shots' })}</p>`}
          color={theme.palette.principal}
        />
        <Text>{`(${intl.formatMessage({ id: 'double click to expand' })})`}</Text>
        <Chart
          id={id}
          sets={sets}
          size={desktopPlus ? 900 : desktop ? 700 : tablet ? 700 : 500}
          data={sunburstData}
        />
      </Container>
    </Root>
};

SunburstZoomableChart.propTypes = {
  id: PropTypes.string,
  data: PropTypes.any,
  rawData: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SunburstZoomableChart;
