import styled from "styled-components";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 0 20px 10px 20px;
`

const Reference = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const Dot = styled.div`
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    background: ${props => props.color ? props.color : "#000"};
    margin-right: 0.5rem;
`

const Label = styled.span`
    width: calc(100% - 0.875rem);
    font-weight: 300;
    color: ${props => props.theme.palette.details};
    font-size: 0.875rem;
    margin-bottom: 5px;
    @media (min-width: 1024px) {
        font-size: 0.75rem;
    }
`

const Legend = ({series, metaData, labels, colors}) => {
    return (
        series &&
        <Root>
            {
                series.map((r, index) => 
                <Reference key={`reference_${index}`}>
                    <Dot color={colors[index]} />
                    <Label>{`${labels[index]}: ${r}% (${metaData[index][0]}/${metaData[index][1]})`}</Label>
                </Reference>
                )
            }
        </Root>
    );
}

export default Legend;