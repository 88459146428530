import {useLoader} from "@react-three/fiber";
import * as THREE from "three";
import {COURT_HEIGHT, COURT_WIDTH} from "../courtConfig";
import {DoubleSide} from "three";
import React from "react";

const Court = (props) => {

    const colorMap = useLoader(THREE.TextureLoader, props.texture)
    return (
        <mesh  position={[0, 0, -5]}  scale={[1, 1, 1]}>
            <planeBufferGeometry  args={[COURT_WIDTH,COURT_HEIGHT]} />
            <meshStandardMaterial map={colorMap} transparent={true} color={props.color} side={DoubleSide} />
        </mesh>
    )
}

export default Court;