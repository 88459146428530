import {useLoader} from "@react-three/fiber";
import * as THREE from "three";
import {TRIBUNE_HEIGHT, TRIBUNE_WIDTH} from "../courtConfig";
import {DoubleSide} from "three";
import React from "react";

const Tribune = (props) => {
    const colorMap = useLoader(THREE.TextureLoader, props.texture)
    return (
        <mesh  position={[props.xpos, props.ypos, 0]}  scale={[1, 1, 1]}>
            <planeBufferGeometry  args={props.isMobile ? [TRIBUNE_WIDTH / 2,TRIBUNE_HEIGHT] : [TRIBUNE_WIDTH,TRIBUNE_HEIGHT]} />
            <meshBasicMaterial map={colorMap} transparent side={DoubleSide} />
        </mesh>
    )
}

export default Tribune;