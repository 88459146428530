export const surfaces = [
    {
        name: "Acrylic",
        value: "Acrylic"
    },
    {
        name: "Asphalt",
        value: "Asphalt"
    },
    {
        name: "Carpet",
        value: "Carpet"
    },
    {
        name: "Cement",
        value: "Cement"
    },
    {
        name: "Clay",
        value: "Clay"
    },
    {
        name: "Cushioned Acrylic",
        value: "Cushioned Acrylic"
    },
    {
        name: "Grass",
        value: "Grass"
    },
    {
        name: "Green Clay",
        value: "Green Clay"
    },
    {
        name: "Hard",
        value: "Hard"
    },
    {
        name: "Porous Macadam",
        value: "Porous Macadam"
    },
    {
        name: "Red Clay",
        value: "Red Clay"
    },
    {
        name: "Shale",
        value: "Shale"
    },
    {
        name: "Snow",
        value: "Snow"
    },
    {
        name: "Synthetic Grass",
        value: "Synthetic Grass"
    },
    {
        name: "Tarmac",
        value: "Tarmac"
    },
    {
        name: "Wood",
        value: "Wood"
    }
]