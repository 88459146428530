/* General imports */
import React from 'react';
import styled from 'styled-components';
import GSMIcon from './gsm_img.png';
import translate from '../../../providers/i18n/translate';
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";

const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.palette.principal};
    border-radius: 10px;
    width: 80%;
    height: auto;
    padding: 20px;
    @media (min-width: 600px) {
        width: 100%;
        flex-direction: row;
        justify-content: center;
    }
`

const Sem = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin: 10px 20px;
    box-shadow: 2px 2px 15px rgba(0,0,0,0.8);
    border-radius: 50%;
    background: ${(props) => props.color1 && props.color2 ? `linear-gradient(0deg, ${props.color1} 0%, ${props.color1} ${parseInt(props.value) - 10}%, ${props.color2} ${parseInt(props.value) + 10}%, transparent 100%)` : '#ababab'};
    box-sizing: border-box;
`

const ContentImage = styled.div`
    position: relative;
    width: 90%;
    height: 90%;
    background: transparent;
    transition: all 500ms ease-in-out;
    img {
        width: 100%;
        height: 100%;
        transition: all 500ms ease-in-out;
        object-fit: contain;
    }
    &:hover {
        img{
            transform: rotate(360deg);
        }
    }
`

const Msg = styled.p`
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-family: museo-sans, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #FFFFFF;
    margin: 10px 0;
    @media (min-width: 1024px) {
        width: 60%;
        flex-direction: row;
        justify-content: center;
    }
`

const Semaphore = ({ data, rawData, sets, player, simulation }) => {
    const [semaphore, setSemaphore] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);
    const { appliedFilters } = useSelector(state => state.selectedDashboardFilters);

    const getColor1 = () => {
        const val = parseInt(semaphore.sum);
        switch (true) {
            case val <= 40.75:
                return '#f02e01'
                break;
            case val > 40.75 && val <= 44.25:
                return '#f17205'
                break;
            case val > 44.25 && val <= 45.25:
                return '#DCFE54'
                break;
            case val > 45.25:
                return '#07e704'
                break;
            default:
                break;
        }
    }

    const getColor2 = () => {
        const val = parseInt(semaphore.sum);
        switch (true) {
            case val <= 40.75:
                return '#ffae9b'
                break;
            case val > 40.75 && val <= 44.25:
                return '#ffd8b6'
                break;
            case val > 44.25 && val <= 45.25:
                return '#eeffa8'
                break;
            case val > 45.25:
                return '#c0ffbf'
                break;
            default:
                break;
        }
    }

    const getData = () => {
        setLoading(true);
        if (data) {
            data()
                .then(result => {
                    setSemaphore(result.semaphore);
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                    alertMessage(err.response.data.msg, alertConstants.ERROR);
                    setLoading(false);
                })
        } else {
            if (rawData) {
                setSemaphore(rawData.semaphore)
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, [JSON.stringify(sets), player, JSON.stringify(appliedFilters), JSON.stringify(simulation)]);

    return (
        <Root loading={loading ? "true" : "false"} >
            {semaphore && semaphore.sum != 0 &&
                <Container>
                    <Sem color1={getColor1()} color2={getColor2()} value={semaphore.sum} >
                        <ContentImage>
                            <img src={GSMIcon} alt="Semaphore" />
                        </ContentImage>
                    </Sem>
                    <Msg >{translate(semaphore.msg)}</Msg>
                </Container>
            }
        </Root>
    );
}

export default Semaphore;