import { TextField } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { API_BACKEND } from "../../config";
import { useFetch } from "../../hooks/useFetch";
import translate from "../../providers/i18n/translate";

const Root = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
`;

const ContentParameters = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  @media (min-width: 600px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const StyledTextField = styled(TextField)`
  width: ${(props) => (props.halfWidth ? "47.5%" : "90%")};
  @media (min-width: 600px) {
    width: ${(props) => (props.width ? props.width : "400px")};
  }
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const Action = styled.button`
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    font-family: museo-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    text-transform: uppercase;
    color: ${(props) => props.theme.palette.base};
    border-radius: 35px;
    padding: 15px 0;
    width: 100px;
    background-color: ${(props) => props.theme.palette.alternative_accent};
    box-shadow: none;
    border: ${(props) => props.border ? props.border : 'none'};
    margin: ${(props) => props.margin ? props.margin : '30px 15px;'};
    cursor: pointer;
    &:disabled {
      color: ${(props) => props.theme.palette.base};
      background: #ECECEC;
      cursor: not-allowed;
    }
    @media (min-width: 600px) {
        font-size: 1.25rem;
        width: 150px;
    }
`;

const Parameters = ({ action, simulatedParameters, clearSimulatedData }) => {
  const intl = useIntl();
  const [originalParameters, loading, setOriginalParameters] = useFetch(
    `${API_BACKEND}/simulationparameters?page=0&limit=10&filters=[]&order={"field": "id", "order": "DESC"}`
  );

  const [parameters, setParameters] = React.useState(null);
  const [changes, setChanges] = React.useState(false);

  React.useEffect(() => {
    if (simulatedParameters) {
      setParameters(simulatedParameters);
      setChanges(true)
    } else if (!parameters) {
      setParameters(originalParameters);
      setChanges(false)
    }
  }, [originalParameters, parameters, simulatedParameters])

  const onClear = (e) => {
    e.preventDefault();
    setParameters(null);
    clearSimulatedData();
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    setParameters({
      ...parameters,
      [name]: parseInt(value)
    });
    setChanges(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      historicalDUE: originalParameters,
      simulationDUE: parameters
    }
    action(formData);
  }



  return (
    !loading && parameters && (
      <Root onSubmit={onSubmit}>
        <ContentParameters>
          <StyledTextField
            key="parameter_unforcedErrorsSecondServeReturn"
            fullWidth
            required
            id="unforcedErrorsSecondServeReturn"
            name="unforcedErrorsSecondServeReturn"
            label={intl.formatMessage({
              id: "unforcedErrorsSecondServeReturn",
            })}
            type="number"
            value={parameters.unforcedErrorsSecondServeReturn}
            onChange={onChange}
            variant="outlined"
          />
          <StyledTextField
            key="parameter_unforcedErrorsThirdShotFirstServe"
            fullWidth
            required
            id="unforcedErrorsThirdShotFirstServe"
            name="unforcedErrorsThirdShotFirstServe"
            label={intl.formatMessage({
              id: "unforcedErrorsThirdShotFirstServe",
            })}
            type="number"
            value={parameters.unforcedErrorsThirdShotFirstServe}
            onChange={onChange}
            variant="outlined"
          />
          <StyledTextField
            key="parameter_unforcedErrorsThirdShotSecondServe"
            fullWidth
            required
            id="unforcedErrorsThirdShotSecondServe"
            name="unforcedErrorsThirdShotSecondServe"
            label={intl.formatMessage({
              id: "unforcedErrorsThirdShotSecondServe",
            })}
            type="number"
            value={parameters.unforcedErrorsThirdShotSecondServe}
            onChange={onChange}
            variant="outlined"
          />
          <StyledTextField
            key="parameter_unforcedErrorsFourthShotFirstServe"
            fullWidth
            required
            id="unforcedErrorsFourthShotFirstServe"
            name="unforcedErrorsFourthShotFirstServe"
            label={intl.formatMessage({
              id: "unforcedErrorsFourthShotFirstServe",
            })}
            type="number"
            value={parameters.unforcedErrorsFourthShotFirstServe}
            onChange={onChange}
            variant="outlined"
          />
          <StyledTextField
            key="parameter_unforcedErrorsFourthShotSecondServe"
            fullWidth
            required
            id="unforcedErrorsFourthShotSecondServe"
            name="unforcedErrorsFourthShotSecondServe"
            label={intl.formatMessage({
              id: "unforcedErrorsFourthShotSecondServe",
            })}
            type="number"
            value={parameters.unforcedErrorsFourthShotSecondServe}
            onChange={onChange}
            variant="outlined"
          />
          <StyledTextField
            key="parameter_doubleFaults"
            fullWidth
            required
            id="doubleFaults"
            name="doubleFaults"
            label={intl.formatMessage({
              id: "doubleFaults"
            })}
            type="number"
            value={parameters.doubleFaults}
            onChange={onChange}
            variant="outlined"
          />
          <StyledTextField
            key="parameter_totalPointsInSequences"
            fullWidth
            required
            id="totalPointsInSequences"
            name="totalPointsInSequences"
            label={intl.formatMessage({
              id: "totalPointsInSequences",
            })}
            type="number"
            value={parameters.totalPointsInSequences}
            onChange={onChange}
            variant="outlined"
          />
        </ContentParameters>
        <Actions>
          <Action disabled={!changes} onClick={onClear}>{translate("clear")}</Action>
          <Action disabled={simulatedParameters} >  {translate("simulate")}</Action>
        </Actions>
      </Root>
    )
  );
};

export default Parameters;
