import styled from "styled-components";

const Root = styled.div`

`

const MatchFilters = ({children}) => {
    return (
        <Root>
            {children}
        </Root>
    );
}

export default MatchFilters;