import React from "react";
import styled from "styled-components";
import axios from "axios";
import { formatData as formatRadarData } from "../gsmcharts/radarChart/config";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import RadarChart from "../gsmcharts/radarChart";
import { THEMES } from "../../providers/theme";

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.palette.principal};
  margin-top: 10px;
`;

const RadarReport = ({onReady}) => {
  const AMOUNT_MATCHES = 10;
  const NAME = "radarReport";

  const { token } = useAuth();

  const optionsRadar = {
    plotOptions: {
      radar: {
        height: 500,
        size: 120,
        offsetY: 0,
        offsetX: 5,
        polygons: {
          strokeColors: "transparent",
          fill: {
            colors: [THEMES.default.palette.base, THEMES.default.palette.alternative_details],
          },
        },
      },
    },
  }

  return (
    <Root>
      <RadarChart
        print
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .get(
                `${API_BACKEND}/historicalplayerskillset?amountMatches=${AMOUNT_MATCHES}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((result) => {
                const chartData = formatRadarData(result.data);
                onReady(NAME);
                resolve(chartData);
              })
              .catch((err) => {
                console.log(err);
              });
          })
        }
        type="radar"
        width={500}
        height={500}
        options={optionsRadar}
      />
    </Root>
  );
};

export default RadarReport;
