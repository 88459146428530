import styled from "styled-components";
import LogoReport from "../../assets/logoReport.png";

const Root = styled.div`
  position: relative;
  width: 210mm;
  height: 304.50mm;
  box-shadow: 0 0 2px ${(props) => props.theme.palette.details};
  margin: 10px auto;
  @media print {
    margin: auto;
    box-shadow: none;
  }
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25mm;
  background: ${(props) => props.theme.palette.alternative_accent};
  border-radius: 0 0 20px 20px;
`;

const Logo = styled.img`
  position: relative;
  width: 80%;
  height: 80%;
  object-fit: contain;
  object-position: center;
  margin: auto;
`;

const Body = styled.div`
  width: 100%;
  height: calc(
    304.50mm - 25mm - 10mm
  ); // Page's height - Header ' height - Footer ' height
  padding: 20px;
  font-size: 1rem;
`;

const Footer = styled.div`
  width: 100%;
  height: 10mm;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  color: ${(props) => props.theme.palette.details};
`;

const Page = ({ children, number, total }) => {
  return (
    <Root>
      <Header>
        <Logo src={LogoReport} alt="logo report" />
      </Header>
      <Body>{children}</Body>
      <Footer>
        <span>{`${number} / ${total}`}</span>
      </Footer>
    </Root>
  );
};

export default Page;
