import React from "react";
import axios from "axios";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { API_BACKEND } from "../../../config";
import { useAuth } from "../../../providers/authentication";
import translate from "../../../providers/i18n/translate";
import ImageProfile from "./ImageProfile";
import { ThemeContext } from "../../../providers/theme";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import { decodeToken } from "../../../hooks/jwt";
import { getDate, getDateUS } from "../../../utils/dateUtils";
import CountryInput from "./countryInput";

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 325px;
  border-radius: 0 0 50px 50px;
  background: linear-gradient(
    180deg,
    ${(props) => props.theme.palette.alternative_accent} 0%,
    ${(props) => props.theme.palette.principal} 100%
  );
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 0.6fr 0.4fr; */
  gap: 10px;
  overflow: hidden;
  padding: 20px 10px;
  margin-bottom: 10px;
  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 0.6fr 0.4fr;
    padding: 20px 50px;
    gap: 20px;
    height: 175px;
  }
  @media (min-width: 1024px) {
  }
  @media (min-width: 1440px) {
    padding: 20px 100px;
  }
`;

const ImageProfileBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1/3;
  @media (min-width: 600px) {
    grid-column: inherit;
    grid-row: 1/3;
  }
`;

const PlayerName = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(props) => props.theme.palette.base};
  font-size: 2rem;
  grid-column: 1/3;
  @media (min-width: 600px) {
    grid-column: 2/6;
    grid-row: 1/2;
    font-size: 2.75rem;
  }
  @media (min-width: 1024px) {
    font-size: 3rem;
  }
  @media (min-width: 1440px) {
    font-size: 3.25rem;
  }
`;

const Data = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.palette.accent};
`;

const DataTitle = styled.div`
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const DataContent = styled.span`
  font-size: 0.875rem;
  @media (min-width: 600px) {
    font-size: 1rem;
  }
  @media (min-width: 1024px) {
    font-size: 1rem;
  }
  @media (min-width: 1440px) {
  }
`;

const Actions = styled.div`
  position: absolute;
  top: 75px;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column ;
  gap: 5px;
  width: 200px;
  height: auto;
  @media (min-width: 600px) {
    top: inherit;
    bottom: 30px;
    right: 20px;
  }
  @media (min-width: 1024px) {
    right: 25px;
  }
  @media (min-width: 1440px) {
    flex-direction: row ;
    align-items: center;
    right: 50px;
  }
`;

const Icon = styled.button`
  border: none;
  background: transparent;
  color: ${(props) => props.theme.palette.alternative_accent};
  cursor: pointer;
  svg {
    font-size: 1.75rem;
  }
`;

const Value = styled.input`
  color: ${(props) => props.theme.palette.principal};
  width: 100px;
  padding: 8px 10px;
  background: ${(props) => `${props.theme.palette.base}AA`};
  border: none;
  outline: none;
  font-size: 0.75rem;
  text-align: center;
`;

const Header = ({ editable }) => {
  const [data, setData] = React.useState(null);
  const [oldData, setOldData] = React.useState(null);

  const { token } = useAuth();
  const intl = useIntl();
  const theme = React.useContext(ThemeContext);

  const [editing, setEditing] = React.useState(false);
  const [changes, setChanges] = React.useState(false);

  const userData = decodeToken(token);

  const dispatch = useDispatch();
  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const switchEditing = () => {
    if (editing && changes) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(intl.formatMessage({ id: "confirm changes" }))) {
        setEditing(false);
        var formdata = new FormData();
        if (data.country !== oldData.country) {
          formdata.append("country", data.country);
        }
        if (data.dateBirth !== oldData.dateBirth) {
          formdata.append("dateBirth", data.dateBirth);
        }
        if (data.height !== oldData.height) {
          formdata.append("height", data.height);
        }
        if (data.weight !== oldData.weight) {
          formdata.append("weight", data.weight);
        }
        axios
          .put(
            `${API_BACKEND}/players/${userData?.trainedPlayer?.id}`,
            formdata,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            alertMessage(
              intl.formatMessage({ id: "changes saved" }),
              alertConstants.INFO
            );
          })
          .catch((err) => {
            console.log(err);
            alertMessage(err.response.data.msg, alertConstants.ERROR);
          });
      }
    } else {
      if (!editing) {
        setOldData({ ...data });
        setEditing(true);
      } else {
        setData({ ...oldData });
        setEditing(false);
      }
    }
  };

  const cancelEditing = () => {
    setData({ ...oldData });
    setEditing(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChanges(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeCountry = (name, value) => {
    setChanges(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  React.useEffect(() => {
    const getData = () => {
      axios
        .get(`${API_BACKEND}/trainedplayerinformation`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          setData(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, [token]);

  return (
    data && (
      <Root>
        <ImageProfileBox>
          <ImageProfile pathPhoto={data.pathPhoto} id={data.id} />
        </ImageProfileBox>
        <PlayerName>{data.name}</PlayerName>
        <Data>
          <DataTitle>{translate("From")}</DataTitle>
          {editing ? (
            <CountryInput
              variant="filled"
              name="country"
              value={data.country}
              onChangeCountry={handleChangeCountry}
              label={intl.formatMessage({ id: "country" })}
              order={8}
            />
          ) : (
            <DataContent>{data.country ? data.country : "---"}</DataContent>
          )}
        </Data>
        <Data>
          <DataTitle>{translate("Born")}</DataTitle>
          {editing ? (
            <Value
              type="date"
              name="dateBirth"
              editing={editing}
              value={
                data && data.dateBirth ? getDate(new Date(data.dateBirth)) : ""
              }
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: "Add dateBirth..." })}
            />
          ) : (
            <DataContent>
              {getDateUS(new Date(data.dateBirth))}
            </DataContent>
          )}
        </Data>
        <Data>
          <DataTitle>{translate("Height")}</DataTitle>
          {editing ? (
            <Value
              name="height"
              type="number"
              step={0.01}
              min={0}
              editing={editing}
              value={data && data.height ? data.height : ""}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: "Add height..." })}
            />
          ) : (
            <DataContent>{data.height ? data.height + " m" : "---"}</DataContent>
          )}
        </Data>
        <Data>
          <DataTitle>{translate("Weight")}</DataTitle>
          {editing ? (
            <Value
              name="weight"
              type="number"
              step={0.01}
              min={0}
              editing={editing}
              value={data && data.weight ? data.weight : ""}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: "Add weight..." })}
            />
          ) : (
            <DataContent>{data.weight ? data.weight + " kg" : "---"}</DataContent>
          )}
        </Data>
        {editable && (
          <Actions>
            {editing && (
              <Icon onClick={cancelEditing}>{theme.icons.close}</Icon>
            )}
            <Icon onClick={switchEditing}>
              {editing ? theme.icons.check : theme.icons.edit}
            </Icon>
          </Actions>
        )}
      </Root>
    )
  );
};

export default Header;
