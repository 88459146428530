/* General imports */
import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import CorrectFile from '../../assets/correctFile.png';
import WrongFile from '../../assets/wrongFile.png';

/* Styled components */
const FileUpload = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: auto;
    min-height: 150px;
    align-items: center;
    justify-content: space-evenly;
    span {
        text-align: center;
    }
`

const FileZone = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 70%;
    height: 150px;
    border: 1px dashed rgba(0,0,0,0.2);
    align-items: center;
    justify-content: center;
    color: #0d0d0d;
    margin: 20px 0 5px 0;
    @media (min-width: 600px) {
        width: 400px;
        height: 200px;
    }
`

const HelpFile = styled.span`
    color: #5d5d5d;
    margin: 0 0 30px 0;
    @media (min-width: 600px) {
        margin: 0 0 20px 0;
    }
`

const ContentImage = styled.img`
    position: relative;
    width: 50px;
    height: 50px;
    margin: 0 0 20px 0;
`

const InputMessage = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    color: ${(props) => props.error ? '#EC6262' : '#0d0d0d'};
    align-items: center;
`

const FileName = styled.span`
    color: #40CBE6;
    font-family: museo-sans, sans-serif;
    font-weight: 100;
    font-style: normal;
    text-decoration: underline;
`

const Label = styled.label`
    padding: 15px 30px;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    background: ${props => props.theme.palette.alternative_accent};
    color: ${props => props.theme.palette.base};
    user-select: none;
    cursor: pointer;
    border-radius: 20px;
    font-size: 1rem;
    text-transform: uppercase;
`

const StyledButton = styled.span`
    
`

function FileInputMatch ({accept, file, error, required, onChange}) {

    
    const handleChange = (e) => {
        onChange(e);
    }
    return (
        <FileUpload>
            <input
                id="contained-button-file"
                name='attachment'
                type="file"
                accept={accept}
                style={{ display: "none" }}
                onChange={handleChange}
                required={required}
            />
            {/* <span>
                {file ? file.name : 
                    translate("no file selected")
                }
            </span> */}
            <FileZone>
                {
                    file && !error &&
                    <ContentImage src={CorrectFile} alt="Correct file" />
                }
                {
                    error ? 
                        <ContentImage src={WrongFile} alt="Error file" />
                    :    
                        file ?
                            <FileName>{file.name}</FileName>
                        : 
                            translate("no file selected")
                }
                {
                    error ?
                    <InputMessage error>
                        <span> { translate("invalid file format") } </span>
                        <span> { translate("please try again") } </span>
                    </InputMessage>
                    :
                    !file &&
                    <InputMessage>
                        <span>(.csv)</span>
                    </InputMessage>
                }
            </FileZone>
            <HelpFile> { translate("Only .csv file extension will be acepted") } </HelpFile>
            <Label htmlFor="contained-button-file">
                {
                    file ?
                    translate("replace file")
                    :
                    translate("upload file")
                }
            </Label>
        </FileUpload>
    );
}

export default FileInputMatch;