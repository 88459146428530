import { THEMES } from "../../../providers/theme/themes"

export const _options = {
  chart: {
    type: 'bar',
    with: 500,
    height: 800,
    toolbar: {
      show: false
    }
  },
  stroke: {
    colors: ['transparent'],
    width: 8,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      borderRadiusApplication: "end",
      borderRadiusWhenStacked: "last",
      horizontal: false,
      columnWidth: '75%',
      dataLabels: {
        position: 'center',
        orientation: 'horizontal',
      },
      distributed: true
    }
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: "12px",
    },
    dropShadow: {
      enabled: true,
    },
    formatter: function (val) {
      return `${val}%`
    }
  },
  xaxis: {
    labels: {
      show: false
    }
  },
  yaxis: {
    show: false
  },
  grid: { show: false },
  legend: {
    horizontalAlign: 'center',
    inverseOrder: false,
    markers: {
      radius: 10,
    },
    labels: {
      colors: THEMES.default.palette.details,
    }
  },
  responsive: [
    {
      breakpoint: THEMES.default.breakpoints.tablet,
      options: {
        chart: {
          type: 'bar',
          width: 320,
          height: 320,
          toolbar: {
            show: false
          }
        },
      },
    },
    {
      breakpoint: THEMES.default.breakpoints.desktop,
      options: {
        chart: {
          type: 'bar',
          width: 550,
          height: 400,
          toolbar: {
            show: false
          }
        },
      },
    }
  ]
}

export const formatData = (data) => {
  const dataseries = [];
  const categories = [];
  const colors = [];
  data.forEach(element => {
    if (element.effectiveness !== null) {
      dataseries.push({
        x: element.label,
        y: element.effectiveness
      });
      categories.push(element.label);
      colors.push(element.color);
    }
  });
  const result = {
    series: [
      {
        name: 'Valor',
        data: dataseries
      }
    ],
    categories: categories,
    colors: colors
  }
  return result;
}