import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import MatchFilters from "../components/layouts/MatchLayout/matchFilters";
import CheckButton from "../components/layouts/MatchLayout/matchFilters/checkButton";
import axios from "axios";
import { API_BACKEND } from "../config";
import { useParams } from "react-router-dom";
import { useAuth } from "../providers/authentication";
import PointsDynamicsChart from "../components/gsmcharts/pointsDynamicChart";
import { matchDataConstants } from "../store/reducers/matchData/matchDataConstants";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../store/action-creators";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: auto;
  width: 100%;
`;

const ContainerFilters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
`;

export default function FlowPage() {
  const intl = useIntl();

  const { token } = useAuth();
  const { id } = useParams();

  const [firstServe, setFirstServe] = React.useState(true);
  const [secondServe, setSecondServe] = React.useState(true);
  const [rally, setRally] = React.useState(true);
  const [pointLine, setPointLine] = React.useState(true);

  const { match } = useSelector((state) => state.matchData);
  const dispatch = useDispatch();
  const { switchPlayers, switchSets } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const onChangeFirstServe = (newvalue) => {
    setFirstServe(!firstServe);
  };
  const onChangeSecondServe = (newvalue) => {
    setSecondServe(!secondServe);
  };
  const onChangeRally = (newvalue) => {
    setRally(!rally);
  };
  const onChangePointLine = (newvalue) => {
    setPointLine(!pointLine);
  };

  React.useEffect(() => {
    switchPlayers(matchDataConstants.SWITCH_PLAYERS, false);
    switchSets(matchDataConstants.SWITCH_SETS, false);
  }, []);

  return (
    <Root>
      <MatchFilters>
        <ContainerFilters>
          <CheckButton
            checkid="firstServe"
            label="1ST SERVE"
            active={firstServe}
            action={onChangeFirstServe}
          />
          <CheckButton
            checkid="secondServe"
            label="2ND SERVE"
            active={secondServe}
            action={onChangeSecondServe}
          />
          <CheckButton
            checkid="rally"
            label="RALLY"
            active={rally}
            action={onChangeRally}
          />
          <CheckButton
            checkid="pointLine"
            label="MATCH LINE"
            active={pointLine}
            action={onChangePointLine}
          />
        </ContainerFilters>
      </MatchFilters>
      {match && (
        <PointsDynamicsChart
          info={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(`${API_BACKEND}/flow?idMatch=${id}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((result) => {
                  resolve(result.data);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          firstServe={firstServe}
          secondServe={secondServe}
          rally={rally}
          pointLine={pointLine}
          player1={match.player1?.name}
          player2={match.player2?.name}
        />
      )}
    </Root>
  );
}
