import styled from "styled-components";

export const StyledPopupTitle = styled.h4`
  width: 100%;
  line-height: 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.principal};
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  border-top: 1px solid ${(props) => props.theme.palette.details};
  margin-bottom: 1rem;
  padding: 5px 0;
`