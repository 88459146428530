import React from "react";
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Outlet, useParams } from "react-router-dom";
import Header from "./Header";
import Submenu from "../../components/layouts/MatchLayout/Submenu";

/* Styled components */
const Root = styled.div`
    position: relative;
    height: auto;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 10px;
    @media (min-width: 600px) {
        padding: 0 20px;
    }
    @media (min-width: 1024px) {
        padding: 0 20px;
    }
    @media (min-width: 1440px) {
        padding: 0 50px;
    }
`

export default function PlayerProfilePage()  {

    const intl = useIntl();
    
    const { id } = useParams();

    const submenues = [
        {
            name: intl.formatMessage({ id: 'radar' }),
            link: `/admin/playerprofile/${id}/radar`
        },
        {
            name: intl.formatMessage({ id: 'profile' }),
            link: `/admin/playerprofile/${id}/profile`
        }
    ]

    return(
        <Root>
            <Header id={id} editable />
            <Submenu data={submenues} />
            <Outlet />
        </Root>
    )
};