import React from "react";
import styled from "styled-components";
import Row from "./row";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    gap: 10px;
    padding: 20px 10px;
`

const Overview = ({data, rawData, sets}) => {

    const [ overviewData, setOverviewData ] = React.useState(null);

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const getData = () => {
        if(data){
            data()
            .then(result => {
                setOverviewData(result)
            })
            .catch(err => {
                console.log(err);
                alertMessage(err.response.data.msg, alertConstants.ERROR);
            })
        } else {
            if(rawData){
                setOverviewData(rawData)
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, [JSON.stringify(sets)]);


    return (
        overviewData &&
        <Root>
            {
                overviewData.map((r, index) => 
                    <Row key={`row_${index}`} data={r} />
                )
            }
        </Root>
    );
}

export default Overview;