import {COURT_HEIGHT, COURT_WIDTH} from "../courtConfig";
import {DoubleSide} from "three";
import React from "react";

const CourtBackgroundScene = ({base}) => {
    return (
        <mesh  position={[-0.045, -0.045, -5.5]}  scale={[1, 1, 1]}>
            <planeBufferGeometry  args={[COURT_WIDTH - 2.8,COURT_HEIGHT - 3.5]} />
            <meshBasicMaterial color={base} metalness={1} roughness={0} side={DoubleSide} />
        </mesh>
    )
}

export default CourtBackgroundScene;