export const _options = {
    chart: {
        type: 'rangeBar',
        with: 500,
        height: 800,
        stacked: true,
        stackType: '100%',
        toolbar: {
            show: false
        }
    },
    colors: ["#40cbe6", "#dcfe54"],
    plotOptions: {
        bar: {
            horizontal: true,
            barHeight: '100%',
            rangeBarGroupRows: true
        }
    },
    fill: {
        type: 'solid'
    },
    grid: {
        show: false,
    },
    xaxis: {
        labels: {
            show: false,
        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        }
    },
    yaxis: {
        show: false,
        labels: {
            show: false,
        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        }
    },
    legend: {
        position: 'bottom',
        labels: {
            colors: "#FFFFFF"
        }
    },
    tooltip: {
        enabled: false,
        custom: function (opts) {
            const pointsWon = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].y[0]
            const totalPoints = parseInt(opts.w.config.series[0].data[0].y) + parseInt(opts.w.config.series[1].data[0].y)
            const effectiveness = ((pointsWon / totalPoints) * 100).toFixed(2);
            return '<div style="padding: 10px; display: flex; flex-direction: column; align-items: flex-start; justify-content: center;">' +
                '<h4 style="margin-bottom: 5px;">' + opts.w.globals.seriesNames[opts.seriesIndex] + '</h4>' +
                '<span> Value: ' + effectiveness + " (" + String(pointsWon) + "/" + String(totalPoints) + ")" + '</span > ' +
                '</div>'
        },
    },
    dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
            return `${Math.round(val * 100) / 100}% (${opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].y}/${parseInt(opts.w.config.series[0].data[0].y) + parseInt(opts.w.config.series[1].data[0].y)})`
        },
        style: {
            fontSize: '14px',
            fontWeight: 'bold'
        },
    },
    responsive: [
        {
            breakpoint: 600,
            options: {
                legend: {
                    position: 'bottom',
                    labels: {
                        colors: "#FFFFFF"
                    },
                    fontSize: '10px'
                },
                dataLabels: {
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold'
                    },
                },
            },
        },
        {
            breakpoint: 1440,
            options: {
                legend: {
                    position: 'bottom',
                    labels: {
                        colors: "#FFFFFF"
                    }
                },
            },
        },
    ]
}

export const formatData = (data) => {

    const series = [
        {
            name: data.trainedPlayer.name,
            data: [
                {
                    x: `Points Won by ${data.trainedPlayer.name}`,
                    y: [
                        data.trainedPlayer.values[0]
                    ]
                }
            ]
        },
        {
            name: data.opponent.name,
            data: [
                {
                    x: `Points Won by ${data.opponent.name}`,
                    y: [
                        data.opponent.values[0]
                    ]
                }
            ]
        },
    ]

    return { series };
}