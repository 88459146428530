import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import backgroundHero from "../../assets/court_home.webp";
import translate from "../../providers/i18n/translate";
import MatchPerformanceChart from "../../components/gsmcharts/matchPerformanceChart";
import { formatHistoricalData } from "../../components/gsmcharts/matchPerformanceChart/config";
import {
  optionsHistoricalPerformance,
  performanceData,
} from "./matchPerformanceConfig";
import LeadershipChart from "../../components/gsmcharts/leadershipChart";
import { formatData as formatLeadershipData } from "../../components/gsmcharts/leadershipChart/config";
import { leadershipMarginData } from "./leaderShipMarginConfig";
import SemiCircleChart from "../../components/gsmcharts/semiCircleChart";
import { optionsSemiCircle, semiCircleData } from "./semiCircleConfig";
import { formatTotalPointsWonData } from "../../components/gsmcharts/semiCircleChart/config";
import { semOpponentData, semPlayerData } from "./semConfig";
import { formatData as formatSemiCircleData } from "../../components/gsmcharts/semiCircleChart/config";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  background-image: linear-gradient(#40cbe6, transparent),
    url(${backgroundHero});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1366px;
  margin: 50px auto 0 auto;
  padding: 0 10px;
  @media (min-width: 600px) {
    padding: 0 50px 40px 50px;
    margin: 100px auto 0 auto;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 20px 0 10px 0;
  color: ${(props) => props.theme.palette.principal};
  @media (min-width: 1024px) {
    font-size: 3rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.15rem;
  margin: 10px 0 10px 0;
  color: ${(props) => props.theme.palette.principal};
  @media (min-width: 1024px) {
    font-size: 1.5rem;
  }
`;

/* const p = styled.p`
    margin: 0 0 10px 0;
    font-size: 1rem;
    text-align: justify;
` */

const Text = styled.div`
  margin: 0 0 10px 0;
  font-size: 1rem;
  text-align: justify;
  color: ${(props) => props.theme.palette.principal};
  line-height: 1.5;
`;

const List = styled.ul`
  padding: 0 0 0 40px;
  color: ${(props) => props.theme.palette.principal};
  li {
    margin: 10px 0;
    font-size: 1rem;
  }
`;

const TextLink = styled.a`
  cursor: pointer;
  font-family: museo-sans, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #d5fc59;
  text-decoration: underline;
`;

const GlassText = styled.div`
  font-weight: bold;
  padding: 20px;
  text-align: center;
  margin: 10px 0;
  background: rgba(255, 255, 255, 0.28);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 1px solid rgba(255, 255, 255, 0.66);
  @media (min-width: 600px) {
    padding: 30px 80px;
  }
`;

const ContainerChart = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  margin: 10px auto;
  background: ${(props) => props.theme.palette.principal};
  border-radius: 20px;
  overflow: hidden;
  .apexcharts-canvas {
    margin: 0 auto;
  }
  @media (min-width: 600px) {
    height: 100%;
  }
  @media (min-width: 1024px) {
    height: 100%;
  }
  @media (min-width: 1440px) {
    height: 100%;
  }
`;

const ContainerSemiCircleChart = styled(ContainerChart)`
  max-height: 50vh;
  @media (min-width: 600px) {
    max-height: 75vh;
  }
  @media (min-width: 1024px) {
    height: 100%;
    max-height: inherit;
  }
`;

const TwoColumns = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 0 20px 0;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: ${(props) => (props.height ? props.height : "300px")};
  }
  @media (min-width: 1440px) {
    height: ${(props) => (props.height ? props.height : "350px")};
  }
`;

export default function AboutPage() {
  const intl = useIntl();

  return (
    <Root>
      <Container>
        <Title>{translate("What is GSM?")}</Title>
        <Text>
          <p>
            <b>
              {translate(
                "A tool developed by Franco Davin to enhance the performance of players of any level."
              )}
            </b>
          </p>
          <p>
            {translate(
              "Game Set Math is a science project focused on the data analysis and new statistics applied to tennis as never seen before."
            )}
          </p>
          <p>
            {translate(
              "How many times have you been told subjective features about a player, without having real data that backs up those comments? How often are opinions formed over a player&apos;s development with no certainty of the process that is going to be done?"
            )}{" "}
          </p>
          <GlassText>
            {translate(
              "This tool will help in focusing the process for the development of a player with exact data about the actual and the projected status, being efficient with the time and trainings selected for each player."
            )}
          </GlassText>
          <p>
            {translate(
              "It has been used over 20 years privately and now is going to be shared with anybody who is interested in using the technology of the 21st century."
            )}
          </p>
          <p>{translate("The following features will be available:")}</p>
        </Text>
        <List>
          <li>{translate("Rallies")}</li>
          <li>{translate("Average rallies")}</li>
          <li>
            {translate(
              "Organized rallies per situation (serving, returning), per set and per match"
            )}
          </li>
          <li>
            {translate(
              "Identification of winner points through number of rallies"
            )}
          </li>
          <li>{translate("% Of points won by first serve")}</li>
          <li>{translate("% Of points won by second serve")}</li>
          <li>{translate("Player performance rating")}</li>
          <li>{translate("Match performance rating")}</li>
          <li>{translate("Winning/losing points streaks")}</li>
          <li>{translate("Match momentum")}</li>
        </List>
        <Title>{translate("What can you get?")}</Title>
        <Subtitle>{translate("MATCH PERFORMANCE")}</Subtitle>
        <p>
          {translate(
            "It is the performance level of each of the last ten matches and the match performance average."
          )}
        </p>
        <List>
          <li>
            {translate("The desired margin of performance is established")}
          </li>
          <li>
            {translate(
              "The leadership margin shows the percentage of the points of the last ten games that depended on the player"
            )}
          </li>
          <li>
            {translate(
              "Match quality takes into account the level of performance of the two players in the last ten matches"
            )}
          </li>
        </List>
        <ContainerChart>
          <MatchPerformanceChart
            data={(query) =>
              new Promise((resolve, reject) => {
                const chartData = formatHistoricalData(
                  performanceData,
                  query.desiredMargin
                );
                resolve(chartData);
              })
            }
            type="line"
            width={500}
            height={500}
            options={optionsHistoricalPerformance}
          />
        </ContainerChart>
        <TwoColumns>
          <ContainerChart>
            <LeadershipChart
              rawData={formatLeadershipData(leadershipMarginData)}
              type="pie"
              width={500}
              height={500}
            />
          </ContainerChart>
          <ContainerSemiCircleChart>
            <SemiCircleChart
              rawData={formatTotalPointsWonData(semiCircleData)}
              type="radialBar"
              labels={["% Total Points Won"]}
              width={500}
              height={500}
              options={optionsSemiCircle}
            />
          </ContainerSemiCircleChart>
        </TwoColumns>
        <Subtitle>{translate("SERVICE EFFECTIVE MARGIN (S.E.M.) AND RETURN EFFECTIVE MARGIN (R.E.M.)")}</Subtitle>
        <p>
          {translate(
            "The service effective margin refers to the effectiveness in game services, including the performance of the first and second serve. On this basis, the probability that the service will be broken is calculated."
          )}
        </p>
        <p>
          {translate(
            "In addition, the return effective margin refers to the effectiveness in return services, including the performance of the first and second serve return. On this, basis, the probability that the opponent serve will be broken is calculated."
          )}
        </p>
        <TwoColumns>
          <ContainerSemiCircleChart>
            <SemiCircleChart
              rawData={formatSemiCircleData(semPlayerData)}
              type="radialBar"
              labels={["S.E.M."]}
              width={500}
              height={500}
            />
          </ContainerSemiCircleChart>
          <ContainerSemiCircleChart>
            <SemiCircleChart
              rawData={formatSemiCircleData(semOpponentData)}
              type="radialBar"
              labels={["R.E.M."]}
              width={500}
              height={500}
            />
          </ContainerSemiCircleChart>
        </TwoColumns>
        <Title>{translate("How can we help you?")}</Title>
        <p>
          <b>
            {translate(
              "With a technological tool with more than 20 years of development and proven results with pro and amateur players, for you to use in your organization."
            )}
          </b>
        </p>
        <List>
          <li>{translate("Access to the GSM reporting")}</li>
          <li>{translate("Matches data input")}</li>
          <li>
            {translate(
              "Weekly/Monthly reports. Recommendations and special analysis to be supplied"
            )}
          </li>
        </List>
        <p>
          <b>{translate("Check what best suits for you at:")} </b>
        </p>
        <TextLink href="mailto: contact@gamesetmath.com">
          contact@gamesetmath.com
        </TextLink>
      </Container>
    </Root>
  );
}
