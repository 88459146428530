import axios from 'axios';

export const AxiosInterceptor = () => {

  const updateHeader = (request) => {
    const token = JSON.parse(localStorage.getItem('token'));
    if(token){
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${token}`
      };
    }
    return request;
  };

  axios.interceptors.request.use((request) => {
    if (request.url?.includes('assets')) return request;
    return updateHeader(request);
  });
};