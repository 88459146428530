import { THEMES } from "../../../providers/theme/themes";


export const _options = {
    chart: {
      type: 'bar',
      with: 500,
      height: 800,
      stacked: true,
      toolbar: {
        show: false
      }
    },
    colors: ['#ff6961', '#d3fda1'],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '70%'
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        if (val === 0) {
          return ""
        }
        else if (typeof val !== "undefined" && val != null && isNaN(val) === false) {
          return Math.abs(val) + "%";
        }
        return "";
      },
      textAnchor: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '0.75rem',
        fontFamily: 'museo-sans, sans-serif',
        colors: ["#FFF"],
      },
      dropShadow: {
        enabled: true,
      }
    },
    stroke: {
      width: 1,
      colors: ["#fff"]
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    yaxis: {
      min: -100,
      max: 100,
      labels: {
        style: {
          colors: "#ffffff"
        },
      }
    },
    tooltip: {
      enabled: false
    },
    title: {
      text: 'Title here'
    },
    xaxis: {
      labels: {
        formatter: function (val) {
          return Math.abs(Math.round(val)) + "%"
        },
        style: {
          colors: "#ffffff"
        }
      }
    },
    legend: {
      labels: {
        colors: "#FFFFFF"
      }
    }
  }  

export const formatDataFirstServeIn = (data) => {
  const deuce = parseInt(data.deuceEffectiveness[2]);
  const ad = parseInt(data.adEffectiveness[2]);
  const series= [
    {
        name: 'OUT / NET',
        data: [
          deuce > 0 ? deuce - 100 : 0, 
          ad > 0 ? ad - 100 : 0
        ]
    },
    {
        name: 'IN',
        data: [ 
          deuce, 
          ad
        ]
    }
  ]
  const categories= ['DEUCE', 'AD']  

  return {series, categories};
}

export const formatDataSecondServeIn = (data) => {
  const deuce = parseInt(data.deuceEffectiveness[2]);
  const ad = parseInt(data.adEffectiveness[2]);
  const series= [
    {
        name: 'DOUBLE FAULT',
        data: [
          deuce > 0 ? deuce - 100 : 0, 
          ad > 0 ? ad - 100 : 0
        ]
    },
    {
        name: 'IN',
        data: [ 
          deuce, 
          ad
        ]
    }
  ]
  const categories= ['DEUCE', 'AD']  

  return {series, categories};
}

export const formatDataDeuceAdServe = (data) => {
  const deuce = parseInt(data.deuceEffectiveness[2]);
  const ad = parseInt(data.adEffectiveness[2]);
  const series= [
    {
        name: 'LOSE',
        data: [
          deuce > 0 ? deuce - 100 : 0, 
          ad > 0 ? ad - 100 : 0
        ]
    },
    {
        name: 'WON',
        data: [ 
          deuce, 
          ad
        ]
    }
  ]
  const categories= ['DEUCE', 'AD']  

  return {series, categories};
}