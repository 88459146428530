import React from "react";
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { ThemeContext } from "../../providers/theme";
import translate from "../../providers/i18n/translate";
import { SearchInput, Table } from "../../components/tables/table";
import axios from 'axios';
import { API_BACKEND } from '../../config';
import { useAuth } from '../../providers/authentication';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";

/* Styled components */
const Root = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    padding: 20px;
`

export default function TableSimulation({loadSimulatedData}) {

    const intl = useIntl();

    let navigate = useNavigate();

    const theme = React.useContext(ThemeContext);

    const tableRef = React.useRef();

    const [search, setSearch] = React.useState('');
    const [filters, setFilters] = React.useState([]);

    const { token } = useAuth();

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const columnsTable = [
        { title: 'ID', field: 'id', width: "8%", order: true, hidden: false },
        { title: translate("name"), field: 'name', order: false, width: "20%", hidden: false },
        { title: translate("date"), field: 'date', order: true, type: "date", width: "20%", hidden: false },
        { title: translate("description"), field: 'description', width: "50%", hidden: false, mobile: false },
        { title: translate("delete"), actions: true, width: "auto", hidden: false }
    ]

    const handleEditItem = (data) => {
        navigate(`/admin/match/${data.id}/edit`);
    }

    const handleDeleteItem = (data) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(`${intl.formatMessage({ id: 'Do you want to delete' })} ${data.name}?`)) {
            const url = `${API_BACKEND}/simulation/${data.id}`;
            axios.delete(url, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(function (response) {
                    tableRef.current.clean();
                })
                .catch(error => {
                    alertMessage(error.response.data.msg, alertConstants.ERROR);
                });
        }
    }

    const onSearchChange = (value) => {
        setSearch(value);
    }

    const handleSearch = () => {
        tableRef.current.clean();
    }

    const goToSimulation = (data) => {
        loadSimulatedData(data);
    }

    const actionsTable = {
        delete: {
            icon: theme.icons.delete,
            tooltip: intl.formatMessage({ id: 'delete' }),
            onClick: handleDeleteItem,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: false
        }
    }

    return (
        <Root>
            <Table
                ref={tableRef}
                disableTotalTitle
                columns={columnsTable}
                actionsTable={actionsTable}
                title={intl.formatMessage({ id: 'simulation' })}
                viewTable={"list"}
                disablePlain
                onRowClick={goToSimulation}
                defaultOrder={
                    {
                        field: "date",
                        order: "DESC"
                    }
                }
                data={
                    (query) => new Promise((resolve, reject) => {
                        axios.get(`${API_BACKEND}/simulation?page=${query.page - 1}&limit=${query.pageSize}&search=${search}&filters=[]&order=${JSON.stringify(query.order)}`,
                            {
                                headers: {
                                    'Authorization': `Bearer ${token}`
                                }
                            })
                            .then(result => {
                                resolve({
                                    rows: result.data.data,
                                    total: result.data.count
                                });
                            })
                            .catch(err => {
                                console.log(err);
                            })
                    })
                }
            >
                <SearchInput search={search} onChange={onSearchChange} onSearch={handleSearch} />
            </Table>
        </Root>
    )
};