import React from "react";
import styled from "styled-components";
import { useIntl } from 'react-intl';
import { useAuth } from "../../providers/authentication";
import { useNavigate, useParams } from "react-router-dom";
import { matchDataConstants } from "../../store/reducers/matchData/matchDataConstants";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import MatchData from "../../components/layouts/MatchLayout/matchData";
import translate from "../../providers/i18n/translate";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { surfaces } from "../../components/dashboardFilters/surfaceFilter/surfaces";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";

const Root = styled.div`
    position: relative;
  height: auto;
  width: 100%;
  padding: 20px;
  @media (min-width: 1024px) {
    padding: 50px;
    max-width: 1440px;
    margin: 0 auto;
  }
`

const Title = styled.h2`
    width: 100%;
    border-bottom: 1px solid ${props => props.borderColor ? props.borderColor : "#000"};
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    margin: 0 0 20px 0;
`

const FormContainer = styled.form`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    margin: 20px 0;
`

const InputContain = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: auto;
    @media (min-width: 600px) {
        width: 400px;
    }
`

const StyledTextField = styled(TextField)`
    width: ${props => props.halfWidth ? "47.5%" : "90%"};
    @media (min-width: 600px) {
        width: ${props => props.width ? props.width : "400px"};
    }
`

const StyledFormControl = styled(FormControl)`
    width: 90%;
    @media (min-width: 600px) {
        width: ${props => props.width ? props.width : "400px"};
    }
`

const Actions = styled.div`
  @media (min-width: 600px) {
    /* align-self: flex-end; */
  }
`

const StyledButton = styled.button`
    font-family: museo-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    text-transform: uppercase;
    color: ${(props) => props.color ? props.color : '#707070'};
    border-radius: 35px;
    padding: 15px 0;
    width: 100px;
    background-color: ${(props) => props.bgColor ? props.bgColor : '#40CBE6'};
    box-shadow: none;
    border: ${(props) => props.border ? props.border : 'none'};
    margin: ${(props) => props.margin ? props.margin : '30px 15px;'};
    cursor: pointer;
    &:disabled {
      color: #5D5D5D;
      background: #ECECEC;
      cursor: not-allowed;
    }
    @media (min-width: 600px) {
        font-size: 1.25rem;
        width: 150px;
    }
`

const EditMatchPage = () => {
    const { token } = useAuth();

    const intl = useIntl();

    const { id } = useParams();

    /* const [ formData, setFormData ] = React.useState(
      {
          surface: null,
          ball: "",
          tournament: "",
          umpire: "",
          matchDate: getDate(new Date()),
          startTime: '',
          endTime: ''
      }
    ) */

    const [formData, setFormData] = React.useState(null);

    const [changes, setChanges] = React.useState(false);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);
    const { switchPlayers, switchSets } = bindActionCreators(
        actionCreators,
        dispatch
    );

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        })
        setChanges(true);
    };

    const getData = () => {
        axios.get(`${API_BACKEND}/match?idMatch=${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(result => {
                setFormData(result.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const onCancel = (e) => {
        e.preventDefault();
        if (!changes) {
            navigate(-1);
        } else {
            // eslint-disable-next-line no-restricted-globals
            if (confirm(intl.formatMessage({ id: 'do you want to cancel changes?' }))) {
                navigate(-1);
            }
        }
    }

    const onSave = (e) => {
        e.preventDefault();
        const url = `${API_BACKEND}/matches`;
        axios.put(url, { ...formData, id: id },
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(function (response) {
                navigate(`/admin/matches`);
            })
            .catch(error => {
                alertMessage(error.response.data.msg, alertConstants.ERROR);
            });
    }

    React.useEffect(() => {
        getData();
        switchPlayers(matchDataConstants.SWITCH_PLAYERS, false);
        switchSets(matchDataConstants.SWITCH_SETS, false);
    }, []);

    return (
        <Root>
            <MatchData id={id} />
            <Title>{translate("Edit match")}</Title>
            {
                formData &&
                <FormContainer onSubmit={onSave}>
                    <StyledFormControl variant="outlined">
                        <InputLabel id="surface-label" >{translate("Surface")}</InputLabel>
                        <Select
                            label={intl.formatMessage({ id: 'Surface' })}
                            id="surface"
                            name="surface"
                            value={formData.surface}
                            onChange={handleChange}
                        >
                            {
                                surfaces.map((sur, index) =>
                                    <MenuItem key={`option_surface_${index}`} value={sur.value}>{translate(sur.name)}</MenuItem>
                                )
                            }
                        </Select>
                    </StyledFormControl>
                    <StyledTextField
                        id="ball"
                        name="ball"
                        label={intl.formatMessage({ id: 'Ball' })}
                        value={formData.ball}
                        onChange={handleChange}
                        variant="outlined"
                        margin="dense"
                    />
                    <StyledTextField
                        id="tournament"
                        name="tournament"
                        label={intl.formatMessage({ id: 'Tournament' })}
                        value={formData.tournament}
                        onChange={handleChange}
                        variant="outlined"
                        margin="dense"
                    />
                    <StyledTextField
                        id="umpire"
                        name="umpire"
                        label={intl.formatMessage({ id: 'Umpire' })}
                        value={formData.umpire}
                        onChange={handleChange}
                        variant="outlined"
                        margin="dense"
                    />
                    <StyledTextField
                        id="matchDate"
                        name="matchDate"
                        label={intl.formatMessage({ id: 'Match date' })}
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={formData.matchDate}
                        onChange={handleChange}
                        variant="outlined"
                        margin="dense"
                        required
                    />
                    <InputContain>
                        <StyledTextField
                            id="startTime"
                            name="startTime"
                            label={intl.formatMessage({ id: 'Start time' })}
                            type="time"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                            value={formData.startTime}
                            onChange={handleChange}
                            variant="outlined"
                            width="195px"
                            margin="dense"
                            halfWidth
                        />
                        <StyledTextField
                            id="endTime"
                            name="endTime"
                            label={intl.formatMessage({ id: 'End time' })}
                            type="time"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                            value={formData.endTime}
                            onChange={handleChange}
                            variant="outlined"
                            width="195px"
                            margin="dense"
                            halfWidth
                        />
                    </InputContain>
                    <Actions>
                        <StyledButton bgColor="transparent" border="2px solid #707070" onClick={onCancel}>
                            {translate("cancel")}
                        </StyledButton>
                        <StyledButton type="submit" color="#FFFFFF" disabled={!changes}>
                            {translate("save")}
                        </StyledButton>
                    </Actions>
                </FormContainer>
            }
        </Root>
    )

};

export default EditMatchPage;
