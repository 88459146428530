import { THEMES } from "../../../providers/theme/themes";

export const _options = {
  chart: {
    width: 500,
    height: 500,
    type: "pie",
  },
  colors: [ "#e11584", "#98BF64", "#a1045a", "#af69ef", "#F2B8C6", "#fc4c4e", "#f699cd", "#fe7d6a", "#E39FF6", "#FC9483", "#B2D3C2", , "#99EDC3" ],
  tooltip: {
    enabled: true,
    y: {
      formatter: function (val, opts) {
        return `${opts.config.fullData[opts.seriesIndex].chains} (${opts.config.fullData[opts.seriesIndex].points} pts)`;
      },
    }
  },
  legend: {
    show: true,
    position: "bottom",
    fontSize: '14px',
    labels: {
      colors: "#FFFFFF"
    },
  },
  dataLabels: {
    enabled: false,
    textAnchor: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: "1rem",
      colors: ["#5D5D5D"],
    },
    dropShadow: {
      enabled: false,
    },
  },
  stroke: {
    show: false,
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      expandOnClick: false,
      offsetX: 0,
      offsetY: 0,
      customScale: 1,
      dataLabels: {
        offset: -30,
      },
    },
  },
};

export const formatData = (data) => {
  const series = [];
  const labels = [];
  data.sequencesLength.forEach((element) => {
    series.push(element.chains);
    labels.push(`${element.name} in a row`);
  });
  return { series, labels, fullData: data.sequencesLength };
};
