import React, { useContext } from "react";
import styled from "styled-components";
import Tooltip from '@mui/material/Tooltip';
import ItemMenu from "./ItemMenu";
import { useIntl } from 'react-intl';
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../providers/theme";
import translate from "../../../providers/i18n/translate";
/* import { isExpired } from '../../hooks/jwt'; */
import { useJwt } from "../../../hooks/jwt/hook";

const Root = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: ${(props) => props.show ? "100%" : 0};
    height: 100vh;
    flex-direction: column;
    z-index: 999;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    background-color: rgba(0,0,0,0.9);
    padding: 100px 0 0 0;
    @media (min-width: 1024px) {
        position: relative;
        top: inherit;
        left: inherit;
        width: ${(props) => props.width ? props.width : "100%"};
        height: 100%;
        flex-direction: row;
        padding: 0;
        background: transparent;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    @media (min-width: 1024px) {
        width: calc(100% - 100px);
        padding: 0 50px 0 0;
    }
`

const ContentItems = styled.ul`
    position: relative;
    display: ${(props) => props.show ? "flex" : "none"};
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    order: ${(props) => props.order ? props.order : "inherit"};
    @media (min-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: ${(props) => props.padding ? props.padding : 0};
        order: inherit;
    }
`

const Enter = styled.div`
    position: absolute;
    top: 25px;
    right: 25px;
    display: flex;
    width: auto;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    background-color: transparent;
    text-transform: uppercase;
    svg {
        font-size: 2.5rem;
        color: ${props => props.theme.palette.base};
    }
    a {
        text-decoration: none;
        color: ${props => props.theme.palette.accent};
        font-weight: 400;
    }
    @media (min-width: 1024px) {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        top: inherit;
        right: inherit;
        width: auto;
        height: 100%;
        font-size: 1.15rem;
        svg {
            font-size: 2rem;
        }
        &:hover {
            svg {
                transform: scale(1.1);
            }       
        }
        a:hover {
            opacity: 0.8;
        }
    }
`

const Menu = ({width, show, switchMenu}) => {

    const intl = useIntl();

    const theme = useContext(ThemeContext);

    const token = JSON.parse(localStorage.getItem('token'));

    const {isExpired} = useJwt(token);

    const items = [
        {
            name: intl.formatMessage({ id: 'about gsm' }),
            link: '/about'
        }
    ]

    return (
        <Root width={width} show={show}>
            <Content>
                <ContentItems show={show} order={1}>
                    {
                        items && items.map((item, index) => 
                            <ItemMenu 
                                key={`menu_${index}_${item.name}`} 
                                width={100 / items.length} 
                                name={item.name} 
                                link={item.link}
                                switchMenu={switchMenu} 
                            />    
                        )
                    }
                </ContentItems>
            </Content>
            <Enter>
                {
                    !isExpired ? 
                    <Link to="/admin/dashboard">
                        <Tooltip title={intl.formatMessage({ id: 'enter' })}>
                            {theme.icons.person}
                        </Tooltip>
                    </Link>
                    :
                    <Link to="/login">
                        <Tooltip title={intl.formatMessage({ id: 'exit' })}>
                            {translate("login")}
                        </Tooltip>
                    </Link>
                }
            </Enter>
        </Root>
    );
}

export default Menu;