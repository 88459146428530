import React, {useEffect} from "react";
import styled from "styled-components";
import NoImage from '../../../../assets/no-image.png';
import { API_BACKEND } from "../../../../config";
import {useSelector, useDispatch} from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../../store/action-creators";
import { playerConstants } from "../../../../store/reducers/player/playerConstants";
import { ThemeContext } from "../../../../providers/theme";

const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.justifyContent};
    gap: 10px;
    flex-direction: column;
    @media (min-width: 600px){
      flex-direction: row;
    }
`

const SelectPlayer = styled.div`
    position: relative;
    width: 50px;
    height: 50px;
    opacity: ${props => props.enabled ? props.selected ? 1 : 0.5 : 1};
    box-shadow: 0 0 5px ${props => props.opponent ? props.theme.palette.accent : props.theme.palette.alternative_accent};
    border: 2px solid ${props => props.opponent ? props.theme.palette.accent : props.theme.palette.alternative_accent};
    border-radius: 50%;
    transition: all 300ms ease-in-out;
    user-select: none;
      @media (min-width: 600px) {
        cursor: ${props => props.enabled ? "pointer" : "default"};
        order: ${props => props.opponent ? 2 : 1};
        width: 50px;
        height: 50px;
      }
    @media (min-width: 1440px) {
      cursor: ${props => props.enabled ? "pointer" : "default"};
      order: ${props => props.opponent ? 2 : 1};
      width: 80px;
      height: 80px;
    }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const Mark = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${props => props.opponent ? props.theme.palette.accent : props.theme.palette.alternative_accent};
    top: 0;
    ${props => props.opponent ? "right: -5px" : "left: -5px"};
      @media (min-width: 600px) {
        width: 20px;
        height: 20px;
        ${props => props.opponent ? "right: -8px" : "left: -8px"};
      }
    @media (min-width: 1440px) {
        width: 30px;
        height: 30px;
        ${props => props.opponent ? "right: -5px" : "left: -5px"};
    }
`

const Name = styled.span`
    text-align: center;
    margin: 5px 0;
    font-size: 0.875rem;
    @media (min-width: 600px) {
        margin: 10px 0;
        font-size: 1rem;
        order: ${props => props.opponent ? 2 : 1};
    }
    @media (min-width: 1440px) {
        margin: 20px 0;
        font-size: 1.25rem;
    }
`

const CompressedPlayer = ({opponent, data, playerNumber, enabled}) => {

    const theme = React.useContext(ThemeContext);

    const {player} = useSelector(state => state.selectedPlayer);

    const dispatch = useDispatch();

    const { changePlayer } = bindActionCreators(actionCreators, dispatch);

    const switchPlayer = () => {
        if(enabled){
            changePlayer(playerConstants.SET, playerNumber);
        }
    }

    useEffect(() => {

    }, [enabled])

    return (
        data &&
        <Root justifyContent={opponent ? "flex-end" : "flex-start"}>
            <SelectPlayer opponent={opponent} onClick={switchPlayer} selected={playerNumber === player} enabled={enabled}>
                <ImageContainer>
                    <Image
                        src={data.pathPhoto ? `${API_BACKEND}/players/${data.id}/image` : NoImage}
                        alt={data.name}
                    />
                </ImageContainer>
                <Mark opponent={opponent}>{enabled ? playerNumber === player ? theme.icons.visibility : theme.icons.visibilityoff : null}</Mark>
            </SelectPlayer>
            <Name>{data.name}</Name>
        </Root>
    );
}

export default CompressedPlayer;

