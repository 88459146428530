import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { ThemeContext } from "../../../../providers/theme";

const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 92.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Icon = styled.div`
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.palette.alternative_accent};
  }
`;

const Value = styled.input`
  color: ${(props) => props.theme.palette.principal};
  width: 100px;
  padding: 8px 10px;
  background: ${(props) => `${props.theme.palette.base}AA`};
  border: none;
  outline: none;
  @media (min-width: 600px) {
    width: 120px;
    }
`;

const Actions = styled.div`
  display: flex;
  gap: 5px;
`;

const Award = ({ data, onNew, onChange, onDelete, editing }) => {
  const theme = React.useContext(ThemeContext);
  const intl = useIntl();

  const [ newAward, setNewAward ] = React.useState({
    name: "",
    year: ""
  })

  const handleDelete = () => {
    onDelete(data.name);
  };

  const handleNew = () => {
    onNew(newAward);
    setNewAward({
        name: "",
        year: ""
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if(data){
        onChange(data.name, name, value);
    } else {
        setNewAward({
            ...newAward,
            [name]: value
        })
    }
  };

  return (
    <Root>
      <Content>
        <span>
          <strong>
            {data ? (
              data.name
            ) : (
                <Value
                name="name"
                editing={editing}
                value={data ? data.name : newAward.name}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: "Add name..." })}
              />
            )}
          </strong>
        </span>
        <span>
          {editing ? (
            <strong>
              <Value
                type="number"
                name="year"
                editing={editing}
                value={data ? data.year : newAward.year}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: "Add year..." })}
              />
            </strong>
          ) : (
            <strong>{`${data.year} `}</strong>
          )}
        </span>
      </Content>
      <Actions>
        {editing && data && (
          <Icon onClick={handleDelete}>{theme.icons.delete}</Icon>
        )}
        {!data && <Icon onClick={handleNew}>{theme.icons.add}</Icon>}
      </Actions>
    </Root>
  );
};

export default Award;
