import styled from "styled-components";
import TennisCourt from "../components/tennisCourt/tennisCourt";
import React from "react";
import {matchDataConstants} from "../store/reducers/matchData/matchDataConstants";
import {useDispatch} from "react-redux";
import {bindActionCreators} from "redux";
import * as actionCreators from "../store/action-creators";

const Root = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`

const CourtPage = () => {

    const dispatch = useDispatch();
    const { switchPlayers, switchSets } = bindActionCreators(
        actionCreators,
        dispatch
    );

    React.useEffect(() => {
        switchPlayers(matchDataConstants.SWITCH_PLAYERS, true);
        switchSets(matchDataConstants.SWITCH_SETS, true);
    }, []);

    return (
        <Root>
            <TennisCourt />
        </Root>
    );
}

export default CourtPage;