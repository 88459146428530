import { getTooltipHistoricalPerformance } from "../../components/gsmcharts/matchPerformanceChart/config";

export const performanceData = {
    "historicalMatchPerformance": [
        {
            "id": 491,
            "opponent": "Opponent 1",
            "performance": -13.83
        },
        {
            "id": 494,
            "opponent": "Opponent 2",
            "performance": 11.58
        },
        {
            "id": 498,
            "opponent": "Opponent 3",
            "performance": -3.77
        },
        {
            "id": 521,
            "opponent": "Opponent 4",
            "performance": 16
        },
        {
            "id": 529,
            "opponent": "Opponent 5",
            "performance": 4.42
        },
        {
            "id": 533,
            "opponent": "Opponent 6",
            "performance": 29.41
        },
        {
            "id": 536,
            "opponent": "Opponent 7",
            "performance": -1.53
        },
        {
            "id": 561,
            "opponent": "Opponent 8",
            "performance": 18.18
        },
        {
            "id": 564,
            "opponent": "Opponent 9",
            "performance": -1.88
        },
        {
            "id": 563,
            "opponent": "Opponent 10",
            "performance": 2.58
        }
    ],
    "averagePerformance": "6.12"
}

export const optionsHistoricalPerformance = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return getTooltipHistoricalPerformance(
          series,
          seriesIndex,
          dataPointIndex,
          w
        );
      },
    },
  };