import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { lastMatchConstants } from "../../store/reducers/lastMatch/lastMatchConstants";
import { formatData as formatDataInRow } from "../../components/gsmcharts/unforcedErrorsInRow/config";
import UnforcedErrorsInRow from "../../components/gsmcharts/unforcedErrorsInRow";
import { useIntl } from 'react-intl';
import Popup from "../../components/popup/Popup";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  overflow: hidden;
`;

const ContainerChart = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  background: ${(props) =>
    props.background ? props.background : props.theme.palette.principal};
  border-radius: 20px;
  @media (min-width: 600px) {
    padding: 0;
    height: 275px;
  }
  @media (min-width: 1024px) {
    height: 350px;
  }
  @media (min-width: 1440px) {
    height: 425px;
  }
`;

export default function UnforcedErrorsPage() {
  const { token } = useAuth();
  const intl = useIntl();

  const lastMatch = useSelector(state => state.lastMatch);

  const dispatch = useDispatch();

  const { getLastMatchData } = bindActionCreators(actionCreators, dispatch);

  React.useEffect(() => {
    getLastMatchData(lastMatchConstants.SET, token)
  }, [])


  return (
    lastMatch.id &&
    <Root>
      <ContainerChart background="transparent">
        <Popup
          title={intl.formatMessage({ id: 'Total points in a row' })}
          content={`<p>${intl.formatMessage({ id: 'help total points in a row' })}</p>`}
        />
        <UnforcedErrorsInRow
          matchId={lastMatch.id}
          sets={"all"}
          data={() =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/unforcederrorssequences?idMatch=${lastMatch.id}&sets=all&playerNumber=1`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDataInRow(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
        />
      </ContainerChart>
    </Root>
  );
}
