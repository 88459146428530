import React from "react";
import styled from "styled-components";
import {
  DynamicBarChart,
  RallyEffectivenessChart,
} from "../../components/gsmcharts";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import { formatData as formatDynamicChartData } from "../../components/gsmcharts/dynamicBarChart/config";
import { formatData as formatRallyEffectivenessData } from "../../components/gsmcharts/rallyEffectivenessChart/config";
import translate from "../../providers/i18n/translate";
import { useSelector } from "react-redux";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  gap: 20px 20px;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: auto repeat(6, 50px 425px);
    gap: 20px;
  }
`;

const PageDescription = styled.p`
  grid-column: 1/3;
  color: ${(props) => props.theme.palette.principal};
  font-size: 1rem;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 1.25rem;
    text-align: left;
  }
`;

const Title = styled.h2`
  width: 100%;
  height: 50px;
  grid-column: inherit;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.details};
  font-size: 1.25rem;
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  @media (min-width: 1024px) {
    display: flex;
    align-items: flex-end;
    font-size: 1.5rem;
    text-align: left;
    height: 100%;
    grid-template-columns: 60% 40%;
    grid-column: 1/3;
  }
`;

const ContainerChart = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  @media (min-width: 1024px) {
  }
`;

const Rally = () => {
  const { token } = useAuth();

  const MATCHES_LIMIT = 10;

  const {appliedFilters} = useSelector(state => state.selectedDashboardFilters);

  return (
    <Root>
      <PageDescription>
        {translate(
          "Detail and effectiveness of rally lenght on serve and serve return."
        )}
      </PageDescription>
      <Title>{translate("first serve rally length")}</Title>
      <ContainerChart>
        <DynamicBarChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=serve&type=first`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDynamicChartData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          name="firstserve"
          type="bar"
        />
      </ContainerChart>
      <ContainerChart>
        <RallyEffectivenessChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=serve&type=first&rallyOrder=${query.order}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatRallyEffectivenessData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          width={500}
          height={400}
          type="bar"
        />
      </ContainerChart>
      <Title>{translate("second serve rally length")}</Title>
      <ContainerChart>
        <DynamicBarChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=serve&type=second`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDynamicChartData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          name="secondserve"
          type="bar"
        />
      </ContainerChart>
      <ContainerChart>
        <RallyEffectivenessChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=serve&type=second&rallyOrder=${query.order}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatRallyEffectivenessData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          width={500}
          height={400}
          type="bar"
        />
      </ContainerChart>
      <Title>{translate("first serve return rally length")}</Title>
      <ContainerChart>
        <DynamicBarChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=return&type=first`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDynamicChartData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          name="firstservereturn"
          type="bar"
        />
      </ContainerChart>
      <ContainerChart>
        <RallyEffectivenessChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=return&type=first&rallyOrder=${query.order}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatRallyEffectivenessData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          width={500}
          height={400}
          type="bar"
        />
      </ContainerChart>
      <Title>{translate("second serve return rally length")}</Title>
      <ContainerChart>
        <DynamicBarChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=return&type=second`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDynamicChartData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          name="secondservereturn"
          type="bar"
        />
      </ContainerChart>
      <ContainerChart>
        <RallyEffectivenessChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=return&type=second&rallyOrder=${query.order}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatRallyEffectivenessData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          width={500}
          height={400}
          type="bar"
        />
      </ContainerChart>
      <Title>{translate("rally on first serve and return")}</Title>
      <ContainerChart>
        <DynamicBarChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=serveReturn&type=first`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDynamicChartData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          name="servereturnfirst"
          type="bar"
        />
      </ContainerChart>
      <ContainerChart>
        <RallyEffectivenessChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=serveReturn&type=first&rallyOrder=${query.order}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatRallyEffectivenessData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          width={500}
          height={400}
          type="bar"
        />
      </ContainerChart>
      <Title>{translate("rally on second serve and return")}</Title>
      <ContainerChart>
        <DynamicBarChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=serveReturn&type=second`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDynamicChartData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          name="servereturnsecond"
          type="bar"
        />
      </ContainerChart>
      <ContainerChart>
        <RallyEffectivenessChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=serveReturn&type=second&rallyOrder=${query.order}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatRallyEffectivenessData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          width={500}
          height={400}
          type="bar"
        />
      </ContainerChart>
    </Root>
  );
};

export default Rally;
