import axios from "axios";
import React from "react";
import styled, { ThemeContext } from "styled-components";
import NoImage from "../../../assets/no-image.png";
import { useAuth } from "../../../providers/authentication";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import { API_BACKEND } from "../../../config";
import NoRacket from "../../../assets/no-racket.png";

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  grid-row: 1/4;
`;

const ContainerImage = styled.div`
  position: relative;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const StyledIcon = styled.label`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 99;
  cursor: pointer;
  color: ${(props) => props.theme.palette.base};
  background: ${(props) => props.theme.palette.principal};
  svg {
    font-size: 1rem;
  }
  @media (min-width: 600px) {
    right: 0;
  }
`;

const ImageRacket = ({ image, handleChange, disabled }) => {
  const theme = React.useContext(ThemeContext);

  const { token } = useAuth();
  const dispatch = useDispatch();
  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  /* const [image, setImage] = React.useState(
    pathPhoto ? `${API_BACKEND}/players/${id}/image` : null
  ); */
  const [preview, setPreview] = React.useState(null);

  const onChange = (e) => {
    e.preventDefault();
    const { files } = e.target;
    handleChange(files[0]);
  };

  React.useEffect(() => {
    if (image && typeof image !== "string") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image, preview]);

  return (
    <Root>
      <ContainerImage>
        {image ? (
          <Image src={preview ? preview : image} alt="image profile" />
        ) : (
          <Image src={NoRacket} alt="image profile" />
        )}
      </ContainerImage>
      {
        !disabled &&
        <StyledIcon htmlFor="contained-button-image-racket">{theme.icons.cam}</StyledIcon>
      }
      <input
        id="contained-button-image-racket"
        name="photo"
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={onChange}
      />
    </Root>
  );
};

export default ImageRacket;
