import React from "react";
import styled from "styled-components";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import translate from "../../providers/i18n/translate";
import UnforcedErrorsChart from "../../components/gsmcharts/unforcedErrorsChart";
import { formatData as formatDataUE } from "../../components/gsmcharts/semiCircleUEChart/config";
import { formatData as formatUEData } from "../../components/gsmcharts/unforcedErrorsChart/config";
import { formatData as formatDataUEChain } from "../../components/gsmcharts/unforcedErrorsChainChart/config";
import { formatData as formatDataTable } from "../../components/gsmcharts/unforcedErrorsTable/config";
import SemiCircleUEChart from "../../components/gsmcharts/semiCircleUEChart";
import UnforcedErrorsChainChart from "../../components/gsmcharts/unforcedErrorsChainChart";
import UnforcedErrorsTable from "../../components/gsmcharts/unforcedErrorsTable";
import { useSelector } from "react-redux";
import { useIntl } from 'react-intl';
import Popup from "../../components/popup/Popup";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  overflow: hidden;
  padding: 20px;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 50px 325px 725px;
    gap: 20px;
    overflow: hidden;
    padding: 20px 0;
  }
  @media (min-width: 1440px) {
    grid-template-rows: auto 50px 425px 900px;
  }
`;

const Title = styled.h2`
  width: 100%;
  grid-column: 1/3;
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.details};
  font-size: 1.25rem;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 1.45rem;
    text-align: left;
  }
`;

const ContainerChart = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  background: ${(props) =>
    props.background ? props.background : props.theme.palette.principal};
  border-radius: 20px;
  padding: 20px 0;
  @media (min-width: 600px) {
    height: 450px;
  }
  @media (min-width: 1024px) {
    height: 100%;
    padding: 0;
  }
  @media (min-width: 1440px) {
    height: 100%;
  }
`;

const SecuenceContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PageDescription = styled.p`
  grid-column: 1/3;
  color: ${(props) => props.theme.palette.principal};
  font-size: 1rem;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 1.25rem;
    text-align: left;
  }
`;

const UnforcedErrors = () => {
  const { token } = useAuth();
  const intl = useIntl();

  const { appliedFilters } = useSelector(
    (state) => state.selectedDashboardFilters
  );

  const MATCHES_LIMIT = 10;

  return (
    <Root>
      <PageDescription>{translate("subtitle determinant unforced errors")}</PageDescription>
      <Title>{translate("P1 (Priority 1)")}</Title>
      <ContainerChart>
        <UnforcedErrorsChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalunforcederrors?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatUEData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="rangeBar"
          width={500}
          height={500}
        />
      </ContainerChart>
      <ContainerChart>
        <SemiCircleUEChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalunforcederrors?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDataUE(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="radialBar"
          labels={["P1"]}
          width={500}
          height={500}
        />
      </ContainerChart>
      <SecuenceContainer>
        <Title>{translate("lost points in a row (LPR)")}</Title>
        <ContainerChart>
          <Popup
            title={intl.formatMessage({ id: 'Distribution of lost points in a row' })}
            content={`<p>${intl.formatMessage({ id: 'help distribution of lost points in a row' })}</p>`}
          />
          <UnforcedErrorsChainChart
            title={translate("Distribution of lost points in a row")}
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/historicallostpointsinarow?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatDataUEChain(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
            type="pie"
            width={500}
            height={500}
          />
        </ContainerChart>
        <ContainerChart background="transparent">
          <UnforcedErrorsTable
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/historicallostpointsinarow?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatDataTable(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
          />
        </ContainerChart>
      </SecuenceContainer>
      <SecuenceContainer>
        <Title>{translate("unforced errors in a row (UER)")}</Title>
        <ContainerChart>
          <Popup
            title={intl.formatMessage({ id: 'Distribution of unforced errors in a row' })}
            content={`<p>${intl.formatMessage({ id: 'help distribution of unforced errors in a row' })}</p>`}
          />
          <UnforcedErrorsChainChart
            title={translate("Distribution of unforced errors in a row")}
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/historicalunforcederrorssequences?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatDataUEChain(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
            type="pie"
            width={500}
            height={500}
          />
        </ContainerChart>
        <ContainerChart background="transparent">
          <UnforcedErrorsTable
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/historicalunforcederrorssequences?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatDataTable(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
          />
        </ContainerChart>
      </SecuenceContainer>
    </Root>
  );
};

export default UnforcedErrors;
