import styled from "styled-components";

const Root = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
`

const Dot = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: ${props => props.active ? props.theme.palette.alternative_accent : props.theme.palette.alternative_details};
    color: ${props => props.theme.palette.base};
    margin-bottom: 15px;
`

const Label = styled.span`
    color: ${props => props.active ? props.theme.palette.alternative_accent : props.theme.palette.alternative_details};
`

const Line = styled.div`
    position: absolute;
    top: 15px;
    left: calc(-50% + 20px);
    right: calc(50% + 20px);
    position: absolute;
    height: 1px;
    background: ${props => props.theme.palette.alternative_details};
    @media (min-width: 600px) {
        left: calc(-50% + 30px);
        right: calc(50% + 30px);
    }
`

const Step = ({active, label, index}) => {
    return (
        <Root>
            {
                index !== 0 && <Line />
            }
            <Dot active={active}>{index + 1}</Dot>
            <Label active={active}>{label}</Label>
        </Root>
    );
}

export default Step;