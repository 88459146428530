const exampleData = {
    series: [
        {
            name: 'LOSE',
            data: [-25, -22]
        },
        {
            name: 'WON',
            data: [ 75, 78 ]
        }
    ],
    categories: ['DEUCE', 'AD']
}

const exampleData2 = {
    "deuceEffectiveness": {
        "deuceServeWon": 25,
        "totalDeuceServePoints": 47,
        "result": 53.19
    },
    "adEffectiveness": {
        "adServeWon": 22,
        "totalAdServePoints": 43,
        "result": 51.16
    }
}

export default exampleData;