import React, {Fragment} from 'react';
import { IntlProvider } from 'react-intl';
import messages from './messages'
import {useSelector} from "react-redux";

const Provider = ({children}) => {
	const { language } = useSelector(state => state.applicationSettings);

	return (
		<IntlProvider
			locale={language}
			textComponent={Fragment}
			messages={messages[language]}
		>
			{children}
		</IntlProvider>
	)
};

export default Provider