import React from "react";
import styled from "styled-components";
import translate from "../../../providers/i18n/translate";
import axios from "axios";
import { API_BACKEND } from "../../../config";
import { useAuth } from "../../../providers/authentication";
import { ThemeContext } from "../../../providers/theme";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import CourtSVG from "../../../assets/icons/courtYellow.svg";
import { getDateUS } from "../../../utils/dateUtils";

const Root = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    gap: 20px;
    background: ${props => props.theme.palette.principal};
    border-radius: 20px;
    width: 100%;
    height: auto;
    grid-column: 1/4;
    flex-direction: column;
    @media (min-width: 600px) {
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
    }
    @media (min-width: 1024px) {
        padding: 20px;
    }
`

const Title = styled.h2`
    font-size: 1.25rem;
    color: ${props => props.theme.palette.accent};
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    align-self: center;
    @media (min-width: 600px) {
        align-self: flex-start;
    }
`
const ContentData = styled.div`
    width: 100% ;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    justify-content: space-between;
    @media (min-width: 600px) {
        flex-direction: row;
        align-self: stretch;
    }
`

const Data = styled.div`
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: ${props => props.theme.palette.accent};
    width: auto;
    svg {
        color: ${props => props.theme.palette.accent};
        margin-right: 0.5rem;
        font-size: 1.75rem;
    }
    @media (min-width: 600px) {
        font-size: 0.9rem;
    }
    @media (min-width: 1440px) {
        font-size: 1rem;
    }
`

const CourtIcon = styled.img`
    width: 1.5rem;
    height: 2rem;
    margin-right: 0.5rem;
`

const StyledButton = styled(Link)`
    width: 50px;
    display: flex;
    justify-content: center;
    padding: 5px 20px;
    border-radius: 25px;
    border: none;
    font-weight: 700;
    order: ${props => props.order ? props.order : "inherit"};
    color: ${(props) => (props.color ? props.color : "#000")};
    background: ${(props) => props.background ? props.background : "transparent"};
    font-size: 1rem;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    align-self: flex-end;
    &:hover {
        transform: scale(1.05);
    }
    @media (min-width: 600px) {
        align-items: center;
        font-size: 1em;
        margin-bottom: 0;
        order: inherit;
        align-self: center;
    }
`;

const LastMatchWithPointsChart = () => {

    const [data, setData] = React.useState(null);
    const [matchPoints, setMatchPoints] = React.useState(null);

    const { token } = useAuth();

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const getData = () => {
        axios
            .get(
                `${API_BACKEND}/lastMatch`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((result) => {
                setData(result.data);
                axios
                    .get(`${API_BACKEND}/matchpointsbyset?idMatch=${result.data.id}&sets=all`,
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        })
                    .then((result) => {
                        setMatchPoints(result.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    React.useEffect(() => {
        getData();
    }, [])
    return (
        data && matchPoints &&
        <Root>
            <Title>{translate("Last Match")}</Title>
            <ContentData>
                <Data>{theme.icons.tennis}{`Vs. ${data.opponentName}`}</Data>
                <Data>{theme.icons.cup}{`${data.opponentName === data.playerWinner ? intl.formatMessage({ id: 'LOSE' }) : intl.formatMessage({ id: 'WON' })} ${data.score}`}</Data>
                <Data>{theme.icons.calendar}{` ${getDateUS(new Date(data.date))}`}</Data>
                <Data><CourtIcon src={CourtSVG} alt="court_icon" />{data.surface ? data.surface : "---"}</Data>
                <Data>{theme.icons.ball}{`${matchPoints} `}{translate("Points")}</Data>
                <StyledButton order={3} background="#DCFE54" to={`/admin/match/${data.id}/performance`}>
                    {theme.icons.send}
                </StyledButton>
            </ContentData>

        </Root>
    );
}

export default LastMatchWithPointsChart;