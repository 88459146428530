import React from 'react';
import styled from 'styled-components';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField } from '@mui/material';

/* Styled components */
const Root = styled.div`
    width: 100%;
    height:auto;
    margin: ${(props) => props.margin ? props.margin : 0};
    order: ${(props) => props.order ? props.order : 0};
    .MuiFormControl-root{
        width: 100%;
    }
    @media (min-width: 600px) {
        ${(props) => props.width ? `width: ${props.width};` : ''}
        order: none;
  }
`

const BirthdayInput = ({ name, date, label, handleDateChange, margin, order  }) => {

    const [selectedDate, setSelectedDate] = React.useState(date ? date : null);

    const handleChange = (date) => {
        handleDateChange(name, date);
    };

    React.useEffect(() => {
        if (date) {
            setSelectedDate(date)
        }
    }, [date])

    return (
        <Root margin={margin} order={order}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    id={name}
                    name={name}
                    label={label}
                    value={selectedDate}
                    onChange={handleChange}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(props) => (
                        <TextField {...props} id={name} name={name} label={label} />
                      )}
                />
            </LocalizationProvider>
        </Root>
    );
}

export default BirthdayInput;