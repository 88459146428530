/* General imports */
import React from "react";
import styled, { keyframes } from "styled-components";
import Bar from "./bar";
import FirstServe from "./firstServe";
import PointOutcome from "./pointOutcome";
import ReturnOutcome from "./returnOutcome";
import SecondServe from "./secondServe";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import { useIntl } from 'react-intl';
import Popup from "../../../components/popup/Popup";

const FIRST_Y = '65px';

const loadingServe = (w) => keyframes`
  0% {
    width: 0;
    height: 0;
  } 
  100% {
    width: ${w}px;
    height: ${w}px;
  }
`;

const loadingLine = () => keyframes`
  0% {
    opacity: 0;
  } 
  100% {
    opacity: 1;
  }
`;

const Root = styled.figure`
  position: relative;
  display: grid;
  width: 100%;
  height: auto;
  min-width: 300px;
  min-height: 300px;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
  padding: 70px 20px 50px 20px;
  border-radius: 20px;
  margin: 0;
  background-color: #03232d;
`;

const Quadrant1 = styled.div`
  position: relative;
  display: grid;
  justify-content: flex-start;
  float: right;
  grid-auto-columns: 100%;
  grid-auto-rows: auto;
  gap: 0px;
  width: 100%;
  height: 100%;
  transform: scale(-1, 1);
  border-left: 0.5px solid rgba(255, 255, 255, 0.25);
`;

const Quadrant2 = styled.div`
  position: relative;
  display: grid;
  justify-content: flex-start;
  grid-auto-columns: 100%;
  grid-auto-rows: auto;
  gap: 0px;
  width: 100%;
  height: 100%;
`;

const Set = styled.div`
  position: relative;
  display: grid;
  justify-content: flex-start;
  grid-auto-columns: 100%;
  grid-auto-rows: auto;
`;

const SetScore = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  width: 100%;
  height: 30px;
  border-radius: 0 10px 10px 0;
  box-sizing: border-box;
  padding: 0 50px;
  z-index: 1;
  span {
    transform: ${(props) => (props.invert ? "scale(-1,1)" : "")};
    font-weight: 700;
  }
`;

const Game = styled.div`
  position: relative;
  display: grid;
  justify-content: flex-start;
  grid-auto-columns: 100%;
  grid-auto-rows: 30px;
  gap: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-bottom: 2px solid rgba(255, 255, 255, 0.85);
`;

const GameScore = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(-50%);
  display: flex;
  border-radius: 20px;
  background-color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  z-index: 5;
`;

const Serve = styled.div`
  position: absolute;
  display: ${(props) => (props.show ? "block" : "none")};
  top: 0;
  right: 0;
  width: 55px;
  height: 100%;
  z-index: 1;
  box-sizing: border-box;
  border: 2px solid rgba(255, 255, 255, 0.85);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: -7px;
    height: 100%;
    width: 5px;
    background-color: ${(props) =>
    props.quadrant === 1 ? "#40CBE6" : "#DCFE54"};
    border-radius: 0 10px 10px 0;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    border-left: 1px dashed rgba(255, 255, 255, 0.85);
  }
`;

const PointNumber = styled.span`
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 20px;
  background: transparent;
  color: #707070;
  text-align: center;
  transform: ${(props) =>
    props.invert ? "translate(-50%, -50%) scale(-1,1)" : ""};
`;

const Row = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  background: transparent;
  &:hover ${PointNumber} {
    display: block;
  }
`;

const LineChart = styled.div`
  position: absolute;
  display: ${(props) => (props.show ? "block" : "none")};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const Marker = styled.div`
  position: absolute;
  top: ${(props) => (props.yaxis ? `calc(${FIRST_Y} + ${props.yaxis}px)` : FIRST_Y)};
  left: ${(props) =>
    props.xaxis ? `calc(50% + ${props.xaxis}px)` : "calc(50% + 0px)"};
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : "#FFF")};
  font-size: 0.5rem;
  box-sizing: border-box;
`;

const FirstMarker = styled(Marker)`
  width: 8px;
  height: 8px;
  background-color: #40cbe6;
  box-sizing: border-box;
`;

const Line = styled.div`
  position: absolute;
  top: ${(props) => (props.yaxis ? `calc(${FIRST_Y} + ${props.yaxis}px)` : FIRST_Y)};
  left: ${(props) =>
    props.xaxis ? `calc(50% + ${props.xaxis}px)` : "calc(50% + 0px)"};
  width: ${(props) => (props.value ? `${props.value}px` : 0)};
  background-color: ${(props) => (props.color ? props.color : "#FFF")};
  transform: ${(props) =>
    props.angle ? `rotate(calc(${props.angle} * 1deg))` : ""};
  animation: ${(props) => loadingLine()} 2s linear forwards;
  height: 5px;
  transform-origin: left;
  border-radius: 5px;
  z-index: 2;
`;

const PointsDynamicsChart = ({
  player1,
  player2,
  info,
  rawData,
  firstServe,
  secondServe,
  rally,
  pointLine,
}) => {
  const [lineMarkers, setLineMarkers] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [pointCountNumber, setPointCountNumber] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const intl = useIntl();

  const dispatch = useDispatch();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const outcomes = {
    " In": "#FFFF00", /* (amarillo) */
    " Winner": "#00FF21", /* (verde claro) */
    " Let Winner": "#00FF21",
    " Serve Winner": "#279033", /* (verde oscuro) */
    " Ace": "#00FF21",
    " Netted": "#DF3440", /* (rojo #DF3440, antes gris #5D5D5D) */
    " Double Fault": "#DF3440", /* (rojo) */
    " Unforced Error": "#DF3440",
    " Pressing Error": "#DF3440",
    " Forcing an Error": "#279033",
    " Net-Cord": "#5D5D5D" /* (gris) */
  };

  const serveOutcomes = {
    "In": "#FFFF00",
    "In Off-Net": "#FFFF00",
    "Ace": "#00FF21",
    "Netted": "#DF3440",
    "Out Off-Net": "#5D5D5D",
    "Out-Long": "#DF3440",
    "Out-Wide": "#DF3440",
    "Out-Other": "#DF3440",
    "Out-Wrong Side": "#DF3440",
    "Serve Winner": "#279033",
    "Let Winner": "#279033",
    "Foot Fault": "#7f0000" /* (bordo) */,
    "Out Off-Net-Long": "#5D5D5D",
    "Out Off-Net-Wide": "#5D5D5D",
    "Out Off-Net-Wrong Side": "#5D5D5D",
    "Out Off-Net-Other": "#5D5D5D",
  };

  const returnOutcomes = {
    "In": "#FFFF00",
    "Forcing Error": "#279033",
    "Forcing Volley Error": "#279033",
    "Out-Other": "#DF3440",
    "Out-Long": "#DF3440",
    "Out-Wide": "#DF3440",
    "Winner": "#00FF21",
    "Volleyed": "#5D5D5D",
    "Netted": "#DF3440",
    "Netted Passing Shot": "#DF3440",
    "In Off-Net": "#5D5D5D",
    "Net Cord": "#5D5D5D",
    "Passing Shot": "#00FF21",
    "Out Off-Net": "#5D5D5D",
    "Out Off-Net-Long": "#5D5D5D",
    "Out Off-Net-Wide": "#5D5D5D",
    "Out Passing Shot-Long": "#5D5D5D",
    "Out Passing Shot-Wide": "#5D5D5D",
  };

  const MarkerInc = 10;

  const tieBreakScore = ['7-6', '6-7']

  const getData = () => {
    setLoading(true);
    if (info) {
      info()
        .then((result) => {
          setData(result);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          alertMessage(err.response.data.msg, alertConstants.ERROR);
          setLoading(false);
        });
    } else {
      if (rawData) {
        setData(rawData);
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    if (!lineMarkers && data) {
      let pointCount = 0;
      let lastXAxis = 0;
      let lastYAxis = 0;
      let newXAxis = 0;
      let catOp = 30;
      let catAd = 0;
      let hyp = 0;
      let angle = 0;
      let pointsColor = [];
      let count = 1;
      for (let indexSet = 0; indexSet < data.length; indexSet++) {
        for (
          let indexGame = 0;
          indexGame < data[indexSet].games.length;
          indexGame++
        ) {
          for (
            let indexPoint = 0;
            indexPoint < data[indexSet].games[indexGame].points.length;
            indexPoint++
          ) {
            lastYAxis = count * 30;
            newXAxis =
              data[indexSet].games[indexGame].points[indexPoint]
                .playerWinner === player1
                ? lastXAxis - MarkerInc
                : lastXAxis + MarkerInc;
            catAd = Math.abs(Math.abs(lastXAxis) - Math.abs(newXAxis));
            lastXAxis = newXAxis;
            hyp = Math.sqrt(Math.pow(catOp, 2) + Math.pow(catAd, 2));
            angle =
              data[indexSet].games[indexGame].points[indexPoint]
                .playerWinner === player1
                ? Math.asin(catOp / hyp) * -(180 / Math.PI)
                : 180 - Math.asin(catOp / hyp) * -(180 / Math.PI);
            pointsColor.push({
              xaxis: lastXAxis,
              yaxis: lastYAxis + indexGame * 2,
              catAd: catAd,
              hyp: hyp,
              angle: angle,
              color:
                data[indexSet].games[indexGame].points[indexPoint].serve
                  .name === player1
                  ? "#40CBE6"
                  : "#DCFE54",
              jump: indexSet > 0 ? indexSet * 50 : 0,
            });
            count++;
            pointCount++;
          }
        }
      }
      setLineMarkers(pointsColor);
      setPointCountNumber(pointCount);
    }
    if (!data) {
      getData();
    }
  }, [lineMarkers, data, firstServe, secondServe, rally, pointLine]);

  return (
    <Root>
      <Popup
        title={intl.formatMessage({ id: 'flow' })}
        content={`<p>${intl.formatMessage({ id: 'help flow p1' })}</p>
        <ul><li>${intl.formatMessage({ id: 'help flow li1' })}</li>
        <li>${intl.formatMessage({ id: 'help flow li2' })}</li>
        <li>${intl.formatMessage({ id: 'help flow li3' })}</li>
        <li>${intl.formatMessage({ id: 'help flow li4' })}</li>
        <li>${intl.formatMessage({ id: 'help flow li5' })}</li>
        <li>${intl.formatMessage({ id: 'help flow li6' })}</li>
        <li>${intl.formatMessage({ id: 'help flow li7' })}</li>
        <li>${intl.formatMessage({ id: 'help flow li8' })}</li></ul>
        <p>${intl.formatMessage({ id: 'help flow p2' })}</p>
        <p>${intl.formatMessage({ id: 'help flow p3' })}</p>`}
      />
      <LineChart show={pointLine}>
        <FirstMarker xaxis={0} yaxis={0} />
        {lineMarkers &&
          lineMarkers.map((lm, index) => (
            <Marker
              key={`lineMark_${index}`}
              color={lm.color}
              xaxis={lm.xaxis}
              yaxis={lm.yaxis + lm.jump}
            />
          ))}
        {lineMarkers &&
          lineMarkers.map((lm, index) => (
            <Line
              key={`lineMark_${index}`}
              color={lm.color}
              xaxis={lm.xaxis}
              yaxis={lm.yaxis + lm.jump}
              value={lm.hyp}
              angle={lm.angle}
            />
          ))}
      </LineChart>
      <Quadrant1>
        {data &&
          data.map((s, indexset) => (
            <Set key={`set_${indexset}`}>
              {s.games.map((d, indexgame) => (
                <Game key={`q1_game_${indexgame}`}>
                  {!tieBreakScore.includes(d.score) ? (
                    <Serve quadrant={1} show={d.serve === player1} />
                  ) : null}
                  {d.points.map((p, index) => {
                    return (
                      <Row key={`q1_bar_${index}`}>
                        {tieBreakScore.includes(d.score) ? (
                          <Serve quadrant={1} show={p.serve.name === player1} />
                        ) : null}
                        <PointNumber invert={true}>{`Point: ${p.idPoint - data[0].games[0].points[0].idPoint + 1
                          }`}</PointNumber>
                        <Bar
                          value={p.rally}
                          invertTooltip={true}
                          show={rally}
                        />
                        <PointOutcome
                          show={p.pointOutcome.player === player1}
                          color={outcomes[p.pointOutcome.result]}
                          msg={p.pointOutcome.result}
                          invertTooltip={true}
                        />
                        {p.serve.name === player1 ? (
                          <FirstServe
                            color={
                              serveOutcomes[p.firstServe]
                                ? serveOutcomes[p.firstServe]
                                : "transparent"
                            }
                            show={firstServe}
                            msg={p.firstServe}
                            invertTooltip={true}
                          />
                        ) : (
                          <ReturnOutcome
                            color={
                              returnOutcomes[p.returnOutcome]
                                ? returnOutcomes[p.returnOutcome]
                                : "transparent"
                            }
                            show={firstServe}
                            msg={p.returnOutcome}
                            invertTooltip={true}
                          />
                        )}

                        {p.serve.name === player1 && p.secondServe && (
                          <SecondServe
                            color={
                              serveOutcomes[p.secondServe]
                                ? serveOutcomes[p.secondServe]
                                : "transparent"
                            }
                            show={secondServe}
                            msg={p.secondServe}
                            invertTooltip={true}
                          />
                        )}
                      </Row>
                    );
                  })}
                </Game>
              ))}
              <SetScore invert={true}>
                <span>{s.score.split("-")[0]}</span>
              </SetScore>
            </Set>
          ))}
      </Quadrant1>
      <Quadrant2>
        {data &&
          data.map((s, indexset) => (
            <Set key={`set_${indexset}`}>
              {s.games.map((d, indexgame) => (
                <Game key={`q2_game_${indexgame}`}>
                  {!tieBreakScore.includes(d.score) ? (
                    <Serve quadrant={2} show={d.serve === player2} />
                  ) : null}
                  {d.points.map((p, index) => (
                    <Row key={`q2_bar_${index}`}>
                      {tieBreakScore.includes(d.score) ? (
                        <Serve quadrant={2} show={p.serve.name === player2} />
                      ) : null}
                      <PointNumber>{`Point: ${p.idPoint - data[0].games[0].points[0].idPoint + 1
                        }`}</PointNumber>
                      <Bar value={p.rally} show={rally} />
                      <PointOutcome
                        show={p.pointOutcome.player === player2}
                        color={outcomes[p.pointOutcome.result]}
                        msg={p.pointOutcome.result}
                      />
                      {p.serve.name === player2 ? (
                        <FirstServe
                          color={
                            serveOutcomes[p.firstServe]
                              ? serveOutcomes[p.firstServe]
                              : "transparent"
                          }
                          show={firstServe}
                          msg={p.firstServe}
                        />
                      ) : (
                        p.returnOutcome && (
                          <ReturnOutcome
                            color={
                              returnOutcomes[p.returnOutcome]
                                ? returnOutcomes[p.returnOutcome]
                                : "transparent"
                            }
                            show={firstServe}
                            msg={p.returnOutcome}
                          />
                        )
                      )}
                      {p.serve.name === player2 && p.secondServe && (
                        <SecondServe
                          color={
                            serveOutcomes[p.secondServe]
                              ? serveOutcomes[p.secondServe]
                              : "transparent"
                          }
                          show={secondServe}
                          msg={p.secondServe}
                        />
                      )}
                    </Row>
                  ))}
                  <GameScore>{d.score}</GameScore>
                </Game>
              ))}
              <SetScore>
                <span>{s.score.split("-")[1]}</span>
              </SetScore>
            </Set>
          ))}
      </Quadrant2>
    </Root>
  );
};

export default PointsDynamicsChart;
