import React from "react";
import styled from "styled-components";
import { useAuth } from "../../providers/authentication";
import { useNavigate } from "react-router-dom";
import Header from "../PlayerProfilePage/Header";
import { decodeToken } from "../../hooks/jwt";
import translate from "../../providers/i18n/translate";
import Accordion from "../../components/accordion";
import AccordionSummary from "../../components/accordion/AccordionSummary";
import AccordionDetails from "../../components/accordion/AccordionDetails";
import GeneralReport from "../../components/generalReport";
import { TextField, MenuItem, Modal } from "@mui/material";
import BasicEditor from "../../components/textEditors/basicEditor";
import { useIntl } from "react-intl";
import CheckInput from "../../components/inputs/CheckInput";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  max-width: ${(props) => `${props.theme.breakpoints.desktopPlus}px`};
  margin: 0 auto;
  padding: 0 5px;
  @media (min-width: 600px) {
    padding: 0 20px;
  }
  @media (min-width: 1024px) {
    padding: 0 50px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.15rem;
  margin: 0;
  svg {
    font-size: 1.15rem;
    margin-right: 1rem;
  }
`;

const Actions = styled.div`
  @media (min-width: 600px) {
    align-self: flex-end;
  }
`;

const StyledButton = styled.button`
  font-family: museo-sans, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
  color: ${(props) => (props.color ? props.color : "#707070")};
  border-radius: 35px;
  padding: 15px 0;
  width: 100px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#40CBE6")};
  box-shadow: none;
  border: ${(props) => (props.border ? props.border : "none")};
  margin: ${(props) => (props.margin ? props.margin : "30px 15px;")};
  cursor: pointer;
  &:disabled {
    color: #5d5d5d;
    background: #ececec;
    cursor: not-allowed;
  }
  @media (min-width: 600px) {
    font-size: 1.25rem;
    width: 150px;
  }
`;

const ContentEditor = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
`;

const StyledTextField = styled(TextField)`
  width: 50%;
  @media (min-width: 600px) {
    width: 25%;
  }
`

export default function ReportPage() {
  let navigate = useNavigate();
  const intl = useIntl();

  const { token } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [typeReport, setTypeReport] = React.useState("Initial");

  const [comments, setComments] = React.useState("");
  const [editorLoaded, setEditorLoaded] = React.useState(false);

  const [reportConfig, setReportConfig] = React.useState({
    performance: false,
    unforcedErrors: false,
    rallyServe: false,
    rallyReturn: false,
    rallyServeReturn: false,
    serve1stIn: false,
    serve1stEffectiveness: false,
    serve2ndPoints: false,
    serve2ndEffectiveness: false,
    sem: false,
    return1stIn: false,
    return1stEffectiveness: false,
    return2ndPoints: false,
    return2ndEffectiveness: false,
    rem: false,
    minimatches: false,
    radarReport: false,
  });

  const onChangeConfig = (name, checked) => {
    setReportConfig({
      ...reportConfig,
      [name]: checked,
    });
  };

  const userData = decodeToken(token);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditor = (e, dataeditor) => {
    setComments(dataeditor);
  };

  const onCancel = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const handleChangeTypeReport = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setTypeReport(value);
  }

  React.useEffect(() => {
    setEditorLoaded(true);
  }, []);

  return (
    <Root>
      <Header id={userData?.id} />
      <StyledTextField label="Report type" select id="typeReport" name="typeReport" value={typeReport} onChange={handleChangeTypeReport} size="small" variant="outlined" margin="normal" required>
        <MenuItem value="Initial">
          {translate("initial")}
        </MenuItem>
        <MenuItem value="Intermediate">
          {translate("intermediate")}
        </MenuItem>
        <MenuItem value="Final">
          {translate("final")}
        </MenuItem>
      </StyledTextField>
      <Accordion first>
        <AccordionSummary>
          <Title>
            <span>{translate("performance")}</span>
          </Title>
        </AccordionSummary>
        <AccordionDetails>
          <CheckInput
            checked={reportConfig.performance}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show performance",
            })}
            name="performance"
            width="auto"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Title>
            <span>{translate("determinant unforced errors")}</span>
          </Title>
        </AccordionSummary>
        <AccordionDetails>
          <CheckInput
            checked={reportConfig.unforcedErrors}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show unforced errors",
            })}
            name="unforcedErrors"
            width="auto"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Title>
            <span>{translate("rally")}</span>
          </Title>
        </AccordionSummary>
        <AccordionDetails>
          <CheckInput
            checked={reportConfig.rallyServe}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show rally serve",
            })}
            name="rallyServe"
            width="auto"
          />
          <CheckInput
            checked={reportConfig.rallyReturn}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show rally return",
            })}
            name="rallyReturn"
            width="auto"
          />
          <CheckInput
            checked={reportConfig.rallyServeReturn}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show rally serve return",
            })}
            name="rallyServeReturn"
            width="auto"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Title>
            <span>{translate("serve")}</span>
          </Title>
        </AccordionSummary>
        <AccordionDetails>
          <CheckInput
            checked={reportConfig.serve1stIn}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show serve1stIn",
            })}
            name="serve1stIn"
            width="auto"
          />
          <CheckInput
            checked={reportConfig.serve1stEffectiveness}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show serve1stEffectiveness",
            })}
            name="serve1stEffectiveness"
            width="auto"
          />
          <CheckInput
            checked={reportConfig.serve2ndPoints}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show serve2ndPoints",
            })}
            name="serve2ndPoints"
            width="auto"
          />
          <CheckInput
            checked={reportConfig.serve2ndEffectiveness}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show serve2ndEffectiveness",
            })}
            name="serve2ndEffectiveness"
            width="auto"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Title>
            <span>{translate("Service Effective Margin (S.E.M.)")}</span>
          </Title>
        </AccordionSummary>
        <AccordionDetails>
          <CheckInput
            checked={reportConfig.sem}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show sem",
            })}
            name="sem"
            width="auto"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Title>
            <span>{translate("return")}</span>
          </Title>
        </AccordionSummary>
        <AccordionDetails>
          <CheckInput
            checked={reportConfig.return1stIn}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show return1stIn",
            })}
            name="return1stIn"
            width="auto"
          />
          <CheckInput
            checked={reportConfig.return1stEffectiveness}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show return1stEffectiveness",
            })}
            name="return1stEffectiveness"
            width="auto"
          />
          <CheckInput
            checked={reportConfig.return2ndPoints}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show return2ndPoints",
            })}
            name="return2ndPoints"
            width="auto"
          />
          <CheckInput
            checked={reportConfig.return2ndEffectiveness}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show return2ndEffectiveness",
            })}
            name="return2ndEffectiveness"
            width="auto"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Title>
            <span>{translate("Return Effective Margin (R.E.M.)")}</span>
          </Title>
        </AccordionSummary>
        <AccordionDetails>
          <CheckInput
            checked={reportConfig.rem}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show rem",
            })}
            name="rem"
            width="auto"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Title>
            <span>{translate("minimatches")}</span>
          </Title>
        </AccordionSummary>
        <AccordionDetails>
          <CheckInput
            checked={reportConfig.minimatches}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show minimatches",
            })}
            name="minimatches"
            width="auto"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Title>
            <span>{translate("Summary skills")}</span>
          </Title>
        </AccordionSummary>
        <AccordionDetails>
          <CheckInput
            checked={reportConfig.radarReport}
            onChange={onChangeConfig}
            label={intl.formatMessage({
              id: "show radarReport",
            })}
            name="radarReport"
            width="auto"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion last>
        <AccordionSummary>
          <Title>
            <span>{translate("comments")}</span>
          </Title>
        </AccordionSummary>
        <AccordionDetails>
          <ContentEditor>
            <BasicEditor
              name="comments"
              value={comments}
              onChange={handleEditor}
              editorLoaded={editorLoaded}
              maxContentHeight="250px"
              minContentHeight="250px"
              placeholder={intl.formatMessage({
                id: "insert comments here...",
              })}
            />
          </ContentEditor>
        </AccordionDetails>
      </Accordion>
      <Actions>
        <StyledButton
          bgColor="transparent"
          border="2px solid #707070"
          onClick={onCancel}
        >
          {translate("cancel")}
        </StyledButton>
        <StyledButton onClick={handleOpen} color="#FFFFFF">
          {translate("export")}
        </StyledButton>
      </Actions>
      <Modal open={open} onClose={handleClose}>
        <GeneralReport id={userData?.id} typeReport={typeReport} reportConfig={reportConfig} comments={comments} handleClose={handleClose} />
      </Modal>
    </Root>
  );
}
