import React from "react";
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Stepper from "../components/stepper";
import translate from "../providers/i18n/translate";
import FileUploadMatch from '../components/inputs/fileInputMatch';
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../store/action-creators";
import { alertConstants } from "../store/reducers/messageBar/alertConstants";
import MatchMetadataInput from "../components/inputs/matchMetaDataInput";
import { getDate } from "../utils/dateUtils";
import { API_BACKEND } from "../config";
import { useAuth } from '../providers/authentication';
import axios from "axios";
import { useNavigate } from "react-router-dom";

/* Styled components */
const Root = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const Introduction = styled.p`
    width: 90%;
    font-size: 0.75rem;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.theme.palette.principal};
    margin: 20px 0 0 0;
    @media (min-width: 600px) {
        font-size: 1rem;
        margin: 25px 0 15px 0;
    }
    @media (min-width: 1024px) {
        font-size: 1.25rem;
        margin: 30px 0 20px 0;
    }
`

export default function UploadPage() {

    const intl = useIntl();

    let navigate = useNavigate();

    const [file, setFile] = React.useState(null);
    const [errorFile, setErrorFile] = React.useState(false);

    const { token } = useAuth();

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const [metaData, setMetaData] = React.useState({
        surface: '',
        ball: '',
        tournament: '',
        umpire: '',
        matchDate: getDate(new Date()),
        startTime: '',
        endTime: ''
    })

    const getSteps = () => {
        return [
            intl.formatMessage({ id: 'upload file' }),
            intl.formatMessage({ id: 'match information' }),
            intl.formatMessage({ id: 'confirm' })
        ]
    }

    const checker = (step) => {
        switch (step) {
            case 0:
                const flag = file !== null;
                if (!flag) {
                    alertMessage(intl.formatMessage({ id: 'you must upload a .csv file' }), alertConstants.ERROR)
                }
                return flag;
            case 1:
                return true;
            case 2:
                return true;
            default:
                return true;
        }
    }

    const onChangeFile = (e) => {
        const { files } = e.target;
        if (files.length > 0) {
            if (files[0].type === 'text/csv' || files[0].type === 'application/csv' || files[0].type === 'application/vnd.ms-excel') {
                setErrorFile(false);
                setFile(files[0]);
            } else {
                setErrorFile(true);
                setFile(null);
            }

        }
    }

    const onChangeMetaData = (name, value) => {
        setMetaData({
            ...metaData,
            [name]: value
        })
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <FileUploadMatch file={file} error={errorFile} onChange={onChangeFile} />
                );
            case 1:
                return (
                    <MatchMetadataInput metaData={metaData} onChangeMetaData={onChangeMetaData} />
                );
            case 2:
                return (
                    <MatchMetadataInput metaData={metaData} onChangeMetaData={onChangeMetaData} readOnly={true} />
                );
            default:
                return intl.formatMessage({ id: 'unknown step' });
        }
    }

    const finishStepper = () => {
        var formData = new FormData();
        formData.append("matchFile", file);
        formData.append("metaData", JSON.stringify(metaData));
        const url = `${API_BACKEND}/uploadMatch`;
        axios.post(url, formData,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(function (response) {
                navigate(`/admin/matches`);
            })
            .catch(error => {
                alertMessage(error.response.data.msg, alertConstants.ERROR);
            });
    }

    return (
        <Root>
            <Introduction>{translate("complete the following steps to upload your tennis match")}</Introduction>
            <Stepper getSteps={getSteps} getStepContent={getStepContent} checker={checker} finishStepper={finishStepper} />
        </Root>
    )
};