import React from "react";
import styled from "styled-components";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import translate from "../../providers/i18n/translate";
import LineAnnotationsChart from "../../components/gsmcharts/lineAnnotationsChart";
import {
  formatDataRem,
  getTooltip,
} from "../../components/gsmcharts/lineAnnotationsChart/config";
import { formatHistoricalDataREM } from "../../components/gsmcharts/semiCircleChart/config";
import SemiCircleChart from "../../components/gsmcharts/semiCircleChart";
import { useSelector } from "react-redux";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  gap: 50px 20px;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
`;

const PageTitle = styled.h2`
  grid-column: 1/3;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.principal};
  font-size: 1.25rem;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 1.5rem;
    text-align: left;
  }
`;

const PageDescription = styled.p`
  grid-column: 1/3;
  color: ${(props) => props.theme.palette.principal};
  font-size: 1rem;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 1.25rem;
    text-align: left;
  }
`;

const ContainerChart = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  background: ${(props) => props.theme.palette.principal};
  border-radius: 20px;
  padding: 20px 0;
  @media (min-width: 600px) {
    height: auto;
  }
  @media (min-width: 1024px) {
    height: 275px;
    padding: 0;
  }
  @media (min-width: 1440px) {
    height: 375px;
    padding: 0;
  }
`;

const ContainerSemiCircleChart = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  background: ${(props) => props.theme.palette.principal};
  border-radius: 20px;
  padding: 20px 0;
  @media (min-width: 600px) {
    height: 450px;
  }
  @media (min-width: 1024px) {
    height: 275px;
    padding: 0;
  }
  @media (min-width: 1440px) {
    height: 375px;
    padding: 0;
  }
`;
const Rem = () => {
  const { token } = useAuth();

  const MATCHES_LIMIT = 10;

  const {appliedFilters} = useSelector(state => state.selectedDashboardFilters);

  const optionsRem = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return getTooltip(series, seriesIndex, dataPointIndex, w);
      },
    },
  };

  return (
    <Root>
      <PageTitle>{translate("Return Effective Margin (R.E.M.)")}</PageTitle>
      <PageDescription>
        {translate(
          "The return effective margin refers to the effectiveness in game's return, including the performance of the first and second serve return. On this basis, the probability that the opponent serve will be broken is calculated."
        )}
      </PageDescription>
      <ContainerChart>
        <LineAnnotationsChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrem?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDataRem(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="line"
          width={500}
          height={500}
          options={optionsRem}
        />
      </ContainerChart>
      <ContainerSemiCircleChart>
        <SemiCircleChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrem?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatHistoricalDataREM(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="radialBar"
          labels={["R.E.M."]}
          width={500}
          height={500}
        />
      </ContainerSemiCircleChart>
    </Root>
  );
};

export default Rem;
