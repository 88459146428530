import styled, { keyframes } from "styled-components";

const Root = styled.div`
    display: grid;
    width: 100%;
    height: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    @media (min-width: 600px) {
        height: 40px;
    }
`

const loading = (w) => keyframes`
  0% {
    width: 0;
  } 
  100% {
    width: ${w}%;
  }
`

const Box = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: ${props => props.theme.palette.alternative_details}50;
    font-size: 0.65rem;
    @media (min-width: 600px) {
        font-size: 1rem;
    }
`

const CenterBox = styled(Box)`
    text-align: center;
    text-transform: uppercase;
`

const LeftBox = styled(Box)`
  border-radius: 1rem 0 0 1rem;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-radius: 1rem 0 0 1rem;
    background: ${props => props.theme.palette.alternative_accent};
    box-shadow: inset 0px 3px 6px rgba(0,0,0,0.16);
    transition: all ease-in-out;
    animation: ${props => loading(props.value)} 1s linear forwards;
    z-index: -1;
  }
`

const RightBox = styled(Box)`
  border-radius: 0 1rem 1rem 0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 0 1rem 1rem 0;
    background: ${props => props.theme.palette.accent};
    transition: all ease-in-out;
    box-shadow: inset 0px 3px 6px rgba(0,0,0,0.16);
    animation: ${props => loading(props.value)} 1s linear forwards;
    z-index: -1;
  }
`

const Row = ({data}) => {
    return (
        <Root>
            <LeftBox value={data.left.value}>{data.left.label}</LeftBox>
            <CenterBox>{data.center}</CenterBox>
            <RightBox value={data.right.value}>{data.right.label}</RightBox>
        </Root>
    );
}

export default Row;