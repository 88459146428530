import React from "react";
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import HeroBanner from "../components/heroBanner";

/* Styled components */
const Root = styled.div`
    position: relative;
    height: auto;
    width: 100%;
`

export default function HomePage()  {

    const intl = useIntl();

    return(
        <Root>
            <HeroBanner 
                pretitle={intl.formatMessage({ id: 'app.hero.pretitle' })} 
                title={intl.formatMessage({ id: 'app.hero.title' })} 
            />
        </Root>
    )
};