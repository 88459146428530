import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import { useIntl } from 'react-intl';
import { _options } from "./config";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import ReactApexChart from "react-apexcharts";
import Legend from "./Legend";
import { ThemeContext } from "../../../providers/theme";

const Root = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
`

const ContainerChart = styled.div`
    width: 100%;
    height: ${props => props.height ? `calc(${props.height}px - 50px)` : "350px"};
    .apexcharts-canvas {
        margin: 0 auto;
    }
    @media (min-width: 1024px) {
        height: 300px;
    }
`

const RadialBarChart = ({data, rawData, width, height, options, type, sets, player, legend}) => {

    const intl = useIntl();

    const [ series, setSeries ] = React.useState(null);
    const [ labels, setLabels ] = React.useState(null);
    const [ metaData, setMetaData ] = React.useState(null);

    const theme = React.useContext(ThemeContext);

    const colors = [ theme.palette.alternative_accent, theme.palette.accent ];
    
    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const getData = () => {
        if(data){
            data()
            .then(result => {
                setSeries(result.series);
                setMetaData(result.metaData)
                setLabels(result.labels);
            })
            .catch(err => {
                console.log(err);
                alertMessage(err.response.data.msg, alertConstants.ERROR);
            })
        } else {
            if(rawData){
                setSeries(rawData.series);
                setMetaData(rawData.metaData);
                setLabels(rawData.labels);
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, [JSON.stringify(sets)]);

    return (
        series &&
        <Root>
            <ContainerChart height={height}>
                <ReactApexChart
                    width="100%"
                    height="100%"
                    options={options ?
                        {
                            ..._options,
                            ...options,
                            labels: labels,
                            colors: colors,
                        }
                        :
                        {
                            ..._options, 
                            chart: {
                                ..._options.chart,
                                with: width,
                                height: height
                            },
                            labels: labels,
                            colors: colors,
                        }
                    } 
                    series={series} 
                    type={type ? type : "radialBar"} 
                />
            </ContainerChart>
            {
                legend &&
                <Legend series={series} metaData={metaData} labels={labels} colors={colors} />
            }
        </Root>
    );
}

RadialBarChart.propTypes = {
    data: PropTypes.any,
    rawData: PropTypes.any,
    width: PropTypes.number, 
    height: PropTypes.number,
    options: PropTypes.any, 
    type: PropTypes.string
}

export default RadialBarChart;