/* General imports */
import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

/* Styled components */
const Root = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    margin: 20px 0;
`

const InputContain = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: auto;
    @media (min-width: 600px) {
        width: 400px;
    }
`

const StyledTextField = styled(TextField)`
    width: ${props => props.halfWidth ? "47.5%" : "90%"};
    @media (min-width: 600px) {
        width: ${props => props.width ? props.width : "400px"};
    }
`

const StyledFormControl = styled(FormControl)`
    width: 90%;
    @media (min-width: 600px) {
        width: ${props => props.width ? props.width : "400px"};
    }
`

function MatchMetadataInput (props) {

    const handleChange = (event) => {
        const {name, value} = event.target;
        props.onChangeMetaData(name, value);
    };

    return (
        <Root>
            <StyledFormControl variant="outlined" readOnly={props.readOnly} disabled={props.readOnly}>
                <InputLabel id="surface-label" >
                    <FormattedMessage 
                        id="app.admin.stepper.surface"
                        defaultMessage="Surface" />
                </InputLabel>
                <Select
                    label="surface"
                    id="surface"
                    name="surface"
                    value={props.metaData.surface}
                    onChange={handleChange}
                    readOnly={props.readOnly}
                    disabled={props.readOnly}
                >
                    <MenuItem value="Acrylic">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.acrylic"
                            defaultMessage="Acrylic" />
                    </MenuItem>
                    <MenuItem value="Asphalt">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.asphalt"
                            defaultMessage="Asphalt" />
                    </MenuItem>
                    <MenuItem value="Carpet">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.carpet"
                            defaultMessage="Carpet" />
                    </MenuItem>
                    <MenuItem value="Cement">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.cement"
                            defaultMessage="Cement" />
                    </MenuItem>
                    <MenuItem value="Clay">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.redclay"
                            defaultMessage="Clay" />
                    </MenuItem>
                    <MenuItem value="Cushioned Acrylic">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.cushionedacrylic"
                            defaultMessage="Cushioned Acrylic" />
                    </MenuItem>
                    <MenuItem value="Grass">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.grass"
                            defaultMessage="Grass" />
                    </MenuItem>
                    <MenuItem value="Green Clay">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.greenclay"
                            defaultMessage="Green Clay" />
                    </MenuItem>
                    <MenuItem value="Hard">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.hardcourt"
                            defaultMessage="Hard" />
                    </MenuItem>
                    <MenuItem value="Porous Macadam">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.porousmacadam"
                            defaultMessage="Porous Macadam" />
                    </MenuItem>
                    <MenuItem value="Red Clay">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.redclay"
                            defaultMessage="Red Clay" />
                    </MenuItem>
                    <MenuItem value="Shale">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.shale"
                            defaultMessage="Shale" />
                    </MenuItem>
                    <MenuItem value="Snow">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.snow"
                            defaultMessage="Snow" />
                    </MenuItem>
                    <MenuItem value="Synthetic Grass">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.syntheticgrass"
                            defaultMessage="Synthetic Grass" />
                    </MenuItem>
                    <MenuItem value="Tarmac">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.tarmac"
                            defaultMessage="Tarmac" />
                    </MenuItem>
                    <MenuItem value="Wood">
                        <FormattedMessage 
                            id="app.admin.stepper.surface.wood"
                            defaultMessage="Wood" />
                    </MenuItem>
                </Select>
            </StyledFormControl>
            <StyledTextField 
                id="ball" 
                name="ball"
                label={
                    <FormattedMessage 
                        id="app.admin.stepper.ball"
                        defaultMessage="Ball" />
                } 
                value={props.metaData.ball}
                onChange={handleChange}
                variant="outlined"
                readOnly={props.readOnly}
                disabled={props.readOnly}
                margin="dense"
            />
            <StyledTextField 
                id="tournament" 
                name="tournament"
                label={
                    <FormattedMessage 
                        id="app.admin.stepper.tournament"
                        defaultMessage="Tournament" />
                } 
                value={props.metaData.tournament}
                onChange={handleChange}
                variant="outlined"
                readOnly={props.readOnly}
                disabled={props.readOnly}
                margin="dense"
            />
            <StyledTextField 
                id="umpire" 
                name="umpire"
                label={
                    <FormattedMessage 
                        id="app.admin.stepper.umpire"
                        defaultMessage="Umpire" />
                } 
                value={props.metaData.umpire}
                onChange={handleChange}
                variant="outlined"
                readOnly={props.readOnly}
                disabled={props.readOnly}
                margin="dense"
            />
            <StyledTextField
                id="matchDate"
                name="matchDate"
                label={
                    <FormattedMessage 
                        id="app.admin.stepper.matchdate"
                        defaultMessage="Match date" />
                } 
                type="date"
                InputLabelProps={{
                shrink: true,
                }}
                value={props.metaData.matchDate}
                onChange={handleChange}
                variant="outlined"
                readOnly={props.readOnly}
                disabled={props.readOnly}
                margin="dense"
            />
            <InputContain>
                <StyledTextField
                    id="startTime"
                    name="startTime"
                    label={
                        <FormattedMessage 
                            id="app.admin.stepper.starttime"
                            defaultMessage="Start time" />
                    }
                    type="time"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                    value={props.metaData.startTime}
                    onChange={handleChange}
                    variant="outlined"
                    readOnly={props.readOnly}
                    disabled={props.readOnly}
                    width="195px"
                    margin="dense"
                    halfWidth
                />
                <StyledTextField
                    id="endTime"
                    name="endTime"
                    label={
                        <FormattedMessage 
                            id="app.admin.stepper.endtime"
                            defaultMessage="End time" />
                    }
                    type="time"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                    value={props.metaData.endTime}
                    onChange={handleChange}
                    variant="outlined"
                    readOnly={props.readOnly}
                    disabled={props.readOnly}
                    width="195px"
                    margin="dense"
                    halfWidth
                />
            </InputContain>
        </Root>
    );
}

export default MatchMetadataInput;