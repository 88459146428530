import React from "react";
import styled from 'styled-components';
import translate from "../../../providers/i18n/translate";


const Root = styled.tbody`
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding: 20px;
`

const NoData = styled.h2`
    
`

const GridBody = ({rowsTable, gridCard, actions}) => {

    const handleView = (e) => {
        e.preventDefault();
        const {index} = e.currentTarget.dataset;
        actions.view.onClick(rowsTable[index]);
    }

    const handleEdit = (e) => {
        e.preventDefault();
        const {index} = e.currentTarget.dataset;
        actions.edit.onClick(rowsTable[index]);
    }

    const handleDelete = (e) => {
        e.preventDefault();
        const {index} = e.currentTarget.dataset;
        actions.delete.onClick(rowsTable[index]);
    }

    return (
        <Root>
            {
                rowsTable && rowsTable.length > 0 ?
                rowsTable.map((hc, index) => 
                <React.Fragment key={`card_${index}`}>
                    { gridCard(hc) }
                </React.Fragment>
                )
                :
                <NoData>{translate("no data")}</NoData>
            }
        </Root>
    );
}

export default GridBody;