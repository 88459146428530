import React from 'react';
import styled from "styled-components";
import { ThemeContext } from '../../../../providers/theme';

const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 4px 15px;
    border-radius: 8px;
    background: ${props => props.theme.palette.principal};
    color: ${props => props.theme.palette.base};
    font-size: 0.75rem;
    width: 100%;
    height: 100%;
    @media (min-width: 600px) {
        font-size: 0.875rem;
        padding: 8px 20px;
    }
    @media (min-width: 1440px) {
        font-size: 1.25rem;
        padding: 8px 25px;
    }
`

const Ball = styled.div`
    margin-right: 10px;
    svg {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #dfff4f;
        height: 18px;
        width: 18px;
        @media (min-width: 600px) {
            height: 24px;
            width: 24px;
        }
    }
`

const TotalPoints = ({ sets, data, rawData }) => {

    const theme = React.useContext(ThemeContext);
    const [totalPointsData, setTotalPointsData] = React.useState(null);

    const getData = () => {
        if (data) {
            data()
                .then(result => {
                    setTotalPointsData(result)
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            if (rawData) {
                setTotalPointsData(rawData)
            }
        }
    }


    React.useEffect(() => {
        getData();
    }, [JSON.stringify(sets)]);

    if(!totalPointsData) return null;

    return (
        <Root>
            <Ball>{theme.icons.ball}</Ball>
            {totalPointsData} PTS
        </Root>
    );
}

export default TotalPoints;