import React from "react";
import styled from 'styled-components';
import translate from '../providers/i18n/translate';
import { useAuth } from "../providers/authentication";
import { useParams } from "react-router-dom";
import RadialBarChart from "../components/gsmcharts/radialBarChart";
import { formatDataReturn } from "../components/gsmcharts/radialBarChart/config";
import axios from "axios";
import { API_BACKEND } from "../config";
import Overview from "../components/gsmcharts/overview";
import { formatData as formatOverviewData } from "../components/gsmcharts/overview/config";
import { matchDataConstants } from "../store/reducers/matchData/matchDataConstants";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../store/action-creators";

/* Styled components */
const Root = styled.div`
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    gap: 50px 20px;
    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
`

const ServeBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 15px;
    @media (min-width: 600px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 30px auto;
    }
`

const Title = styled.h2`
  grid-column: 1/3;
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.details};
  font-size: 1.25rem;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 1.5rem;
    text-align: left;
  }
`;

const SubTitle = styled.h3`
  border-bottom: 1px solid ${(props) => props.theme.palette.alternative_details};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.alternative_details};
  font-size: 1.25rem;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 1.5rem;
    text-align: left;
  }
`;

const ContentServeChart = styled.div`

`

const GeneralStatsBox = styled.div`
    width: 100%;
    grid-column: 1/3;
`

export default function ReturnPage() {

    const { token } = useAuth();
    const { id } = useParams();

    const { sets } = useSelector(state => state.selectedSets);
    const { player } = useSelector(state => state.selectedPlayer);

    const dispatch = useDispatch();
    const { switchPlayers, switchSets } = bindActionCreators(
        actionCreators,
        dispatch
    );

    const getSetsList = (s) => {
        let setlist = "";
        for (let index = 0; index < sets.length; index++) {
            if (sets[index] === true) {
                setlist = setlist + (index + 1);
                if (sets.length > 1 && index !== sets.length - 1) {
                    setlist = setlist + ",";
                }
            }
        }
        return setlist;
    }

    React.useEffect(() => {
        switchPlayers(matchDataConstants.SWITCH_PLAYERS, false);
        switchSets(matchDataConstants.SWITCH_SETS, true);
    }, [switchPlayers, switchSets]);

    return (
        sets &&
        <Root>
            <ServeBox>
                <Title>{translate("1st Serve Return")}</Title>
                <ContentServeChart>
                    <SubTitle>{translate("in play")}</SubTitle>
                    <RadialBarChart
                        legend
                        sets={sets}
                        player={player}
                        data={
                            (query) => new Promise((resolve, reject) => {
                                axios.get(`${API_BACKEND}/returninwon?idMatch=${id}&sets=${getSetsList(sets)}&serveReturnType=firstReturnIn`,
                                    {
                                        headers: {
                                            'Authorization': `Bearer ${token}`
                                        }
                                    })
                                    .then(result => {
                                        const chartData = formatDataReturn(result.data);
                                        resolve(chartData);
                                    })
                                    .catch(err => {
                                        console.log(err);
                                    })
                            })
                        }
                    />
                </ContentServeChart>
                <ContentServeChart>
                    <SubTitle>{translate("points won")}</SubTitle>
                    <RadialBarChart
                        legend
                        sets={sets}
                        player={player}
                        data={
                            (query) => new Promise((resolve, reject) => {
                                axios.get(`${API_BACKEND}/returninwon?idMatch=${id}&sets=${getSetsList(sets)}&serveReturnType=firstReturnWon`,
                                    {
                                        headers: {
                                            'Authorization': `Bearer ${token}`
                                        }
                                    })
                                    .then(result => {
                                        const chartData = formatDataReturn(result.data);
                                        resolve(chartData);
                                    })
                                    .catch(err => {
                                        console.log(err);
                                    })
                            })
                        }
                    />
                </ContentServeChart>
            </ServeBox>
            <ServeBox>
                <Title>{translate("2nd Serve Return")}</Title>
                <ContentServeChart>
                    <SubTitle>{translate("in play")}</SubTitle>
                    <RadialBarChart
                        legend
                        sets={sets}
                        player={player}
                        data={
                            (query) => new Promise((resolve, reject) => {
                                axios.get(`${API_BACKEND}/returninwon?idMatch=${id}&sets=${getSetsList(sets)}&serveReturnType=secondReturnIn`,
                                    {
                                        headers: {
                                            'Authorization': `Bearer ${token}`
                                        }
                                    })
                                    .then(result => {
                                        const chartData = formatDataReturn(result.data);
                                        resolve(chartData);
                                    })
                                    .catch(err => {
                                        console.log(err);
                                    })
                            })
                        }
                    />
                </ContentServeChart>
                <ContentServeChart>
                    <SubTitle>{translate("points won")}</SubTitle>
                    <RadialBarChart
                        legend
                        sets={sets}
                        player={player}
                        data={
                            (query) => new Promise((resolve, reject) => {
                                axios.get(`${API_BACKEND}/returninwon?idMatch=${id}&sets=${getSetsList(sets)}&serveReturnType=secondReturnWon`,
                                    {
                                        headers: {
                                            'Authorization': `Bearer ${token}`
                                        }
                                    })
                                    .then(result => {
                                        const chartData = formatDataReturn(result.data);
                                        resolve(chartData);
                                    })
                                    .catch(err => {
                                        console.log(err);
                                    })
                            })
                        }
                    />
                </ContentServeChart>
            </ServeBox>
            <GeneralStatsBox>
                <Title>{translate("General Serve Return Stats")}</Title>
                <Overview
                    sets={sets}
                    player={player}
                    data={
                        (query) => new Promise((resolve, reject) => {
                            axios.get(`${API_BACKEND}/returnstatistics?idMatch=${id}&sets=${getSetsList(sets)}`,
                                {
                                    headers: {
                                        'Authorization': `Bearer ${token}`
                                    }
                                })
                                .then(result => {
                                    const chartData = formatOverviewData(result.data);
                                    resolve(chartData);
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        })
                    }
                />
            </GeneralStatsBox>
        </Root>
    )
};