import React from "react";
import styled from "styled-components";
import { ThemeContext } from '../../providers/theme';
import translate from '../../providers/i18n/translate';
import { Badge } from "@mui/material";
import NotificationsSideBar from "./NotificationsSideBar";


const Root = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    outline: none;
    @media (min-width: 1024px) {
        position: relative;
        width: 100%;
        justify-content: flex-start;
        padding: 0 12.5px;
    }
`

const NotificationsIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    transition: all 200ms ease-in-out;
    opacity: ${props => props.hidden ? 0 : 1};
    outline: none;
    svg {
        transition: all 200ms ease-in-out;
        color: ${props => props.theme.palette.details};
        
    }
    .MuiBadge-badge {
        background-color: ${props => props.theme.palette.accent};
    }
    
    @media (min-width: 1024px) {
        opacity: 1;
        svg:hover {
            transform: scale(1.1);
        }
    }
`

const NotificationTag = styled.span`
    display: none;
    @media (min-width: 1024px) {
        display: inherit;
        color: ${props => props.theme.palette.base};
        text-transform: uppercase;
        transition: all 300ms ease-in-out;
        opacity: ${(props) => props.showname ? 1 : 0};
        margin: 0 0 0 10px;
        font-size: 0.875rem;
    }
`

const Notifications = ({openNav, closeNav}) => {

    const theme = React.useContext(ThemeContext);

    const notificationRef = React.useRef();

    const [ openNotifications, setOpenNotifications ] = React.useState(false);

    const [ notifications, setNotifications ] = React.useState(
        [
            {
                id: 1,
                title:  "Notification 1",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
            },
            {
                id: 2,
                title:  "Notification 2",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
            },
            {
                id: 3,
                title:  "Notification 3",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
            },
            {
                id: 4,
                title:  "Notification 4",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
            }
        ]
    )

    const onSwitchNotifications = () => {
        closeNav();
        setOpenNotifications(!openNotifications);
    }

    const onBlur = () => {
        if(openNotifications && document.activeElement !== notificationRef.current){
            onSwitchNotifications()
        }
    }

    const deleteNotification = (id) => {
        const newNotifications = notifications.filter(n => n.id !== id );
        setNotifications(prevState => [...newNotifications]);
    }

    return (
        <Root tabIndex={0} ref={notificationRef} onBlur={onBlur}>
            <NotificationsIcon   onClick={onSwitchNotifications} hidden={openNav} >
                <Badge variant="dot" invisible={notifications && notifications.length > 0 ? false : true}>
                    {theme.icons.notifications}
                </Badge>
                <NotificationTag showname={openNav ? 1 : 0}>{translate("notifications")}</NotificationTag>
            </NotificationsIcon>
            <NotificationsSideBar   open={openNotifications} notifications={notifications} deleteNotification={deleteNotification} />
        </Root>
    );
}

export default Notifications;