import styled from "styled-components";

export const StyledBox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    max-width: 90vw;
    height: auto;
    max-height: 80vh;
    background-color: #FFF;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.5);
    border-radius: 8px;
    padding: 60px 20px 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: auto;
    outline: none;
    @media (min-width: 600px) {
      max-width: 80vw;
    }
    @media (min-width: 1024px) {
      max-width: 60vw;
    }
`