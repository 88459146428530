import {COURT_HEIGHT, COURT_WIDTH} from "../courtConfig";
import {DoubleSide} from "three";
import React from "react";

const CourtBase = ({base}) => {
    return (
        <mesh  position={[0, 0, -6]}  scale={[1, 1, 1]}>
            <planeBufferGeometry  args={[COURT_WIDTH,COURT_HEIGHT]} />
            <meshBasicMaterial color={base} metalness={1} roughness={0} side={DoubleSide} />
        </mesh>
    )
}

export default CourtBase;