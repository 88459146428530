import React from "react";
import styled from "styled-components";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import { useSelector } from "react-redux";
import LineAnnotationsChart from "../gsmcharts/lineAnnotationsChart";
import SemiCircleChart from "../gsmcharts/semiCircleChart";
import {
  formatDataRem,
  getTooltip,
} from "../../components/gsmcharts/lineAnnotationsChart/config";
import { formatHistoricalDataREM } from "../../components/gsmcharts/semiCircleChart/config";

const Root = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
`;

const ContainerChart = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  background: ${(props) => props.theme.palette.principal};
  border-radius: 20px;
  padding: 0;
`;

const ContainerSemiCircleChart = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
  padding: 0;
  overflow: hidden;
  background: ${(props) => props.theme.palette.principal};
  border-radius: 20px;
`;

const Rem = ({onReady}) => {
  const { token } = useAuth();

  const MATCHES_LIMIT = 10;
  const NAME = "rem";

  const { appliedFilters } = useSelector(
    (state) => state.selectedDashboardFilters
  );

  const optionsRem = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return getTooltip(series, seriesIndex, dataPointIndex, w);
      },
    },
  };

  const allReady = [false,false];

  const handleReady = () => {
    if(allReady[0] && allReady[1]){
      onReady(NAME);
    }
  }

  React.useEffect(() => {}, [JSON.stringify(appliedFilters)]);

  return (
    <Root>
      <ContainerChart>
        <LineAnnotationsChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrem?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDataRem(result.data);
                  allReady[0] = true;
                  handleReady();
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="line"
          width={500}
          height={500}
          options={optionsRem}
        />
      </ContainerChart>
      <ContainerSemiCircleChart>
        <SemiCircleChart
          print
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalrem?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatHistoricalDataREM(result.data);
                  allReady[1] = true;
                  handleReady();
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="radialBar"
          labels={["R.E.M."]}
          width={500}
          height={500}
        />
      </ContainerSemiCircleChart>
    </Root>
  );
};

export default Rem;
