import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import styled from "styled-components";
import translate from "../../../providers/i18n/translate";
import { dashboardFiltersConstants } from "../../../store/reducers/dashboardFilters/dashboardFiltersConstants";
import { TextField } from "@mui/material";

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  padding: 14px;
  border-radius: 10px;
`;

const Title = styled.h2`
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
`;


const TournamentFilter = () => {
  const { filters } = useSelector((state) => state.selectedDashboardFilters);

  const dispatch = useDispatch();

  const { changeDashboardFilters } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const handleChange = (e) => {
    const { value } = e.target;

    const selectedPlayerWinners = filters.find(
        (fil) => fil.name === "tournament"
      );
      if (selectedPlayerWinners) {
        selectedPlayerWinners.values = [value];
        changeDashboardFilters(dashboardFiltersConstants.SET, filters);
      } else {
        const newFilter = {
          name: "tournament",
          values: [value],
          condition: "equal",
          type: "string",
        };
        changeDashboardFilters(dashboardFiltersConstants.SET, [
          ...filters,
          newFilter,
        ]);
      }
  };

  return (
    <Root>
        <Title>{translate("Tournament")}</Title>
        <TextField onChange={handleChange} value={filters.find((fil) => fil.name === "tournament") ? filters.find((fil) => fil.name === "tournament").values[0] : ""} margin="dense" size="small" placeholder="Enter tournament's name" />
      </Root>
  );
};

export default TournamentFilter;
