import React from "react";
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";
import { _options } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import translate from "../../../providers/i18n/translate";
import Popup from "../../popup/Popup";
import { useIntl } from 'react-intl';

const Root = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    opacity: ${props => props.loading === "true" ? 0.3 : 1};
    padding: 20px;
`
const Title = styled.h5`
    font-size: 1rem;
    color: ${props => props.theme.palette.accent};
    margin: 10px 0 0 0;
    width: 70%;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0 0 10px 0;
`

const ContainerChart = styled.div`
    width: 100%;
    height: 125px;
    margin: auto;
    padding: 0 20px 0 0;
    .apexcharts-canvas {
        margin: 0 auto;
    }
    @media (min-width: 1440px) {
        height: 150px;
    }
`

const PointsWonByPlayerChart = ({ data, rawData, width, height, options, type, sets, player, matchId }) => {

    const [series, setSeries] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const dispatch = useDispatch();

    const intl = useIntl();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);
    const { appliedFilters } = useSelector(state => state.selectedDashboardFilters);

    const getData = () => {
        setLoading(true);
        if (data) {
            data()
                .then(result => {
                    setSeries(result.series);
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                    alertMessage(err.response.data.msg, alertConstants.ERROR);
                    setLoading(false);
                })
        } else {
            if (rawData) {
                setSeries(rawData.series);
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, [JSON.stringify(sets), player, JSON.stringify(appliedFilters), matchId]);


    return (
        <Root loading={loading ? "true" : "false"}>
            <Popup title={intl.formatMessage({ id: 'points won by player' })} content={intl.formatMessage({ id: 'help points won by player' })} />
            <Title>{translate("Points won by player")}</Title>
            {
                series &&
                <ContainerChart>
                    <ReactApexChart
                        options={options ?
                            {
                                ..._options,
                                ...options,
                            }
                            :
                            {
                                ..._options
                            }
                        }
                        width="100%"
                        height="100%"
                        series={series}
                        type={type ? type : "rangeBar"}
                    />
                </ContainerChart>
            }
        </Root>
    );
}

export default PointsWonByPlayerChart;