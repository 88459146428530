import CourtBall from "./courtBall";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useAxios from "../../../hooks/useAxios";
import { API_BACKEND } from "../../../config";
import { getSetsList } from "../../../utils/sets";
import Tribune from "./tribune";
import CourtBase from "./CourtBase";
import Court from "./Court";
import { ballTextures } from "../ballTextures";
import CourtBackgroundScene from "./CourtBackgroundScene";
import CourtLine from "./courtLine";

const keyShotsOutcomes = {
    "In": ballTextures.normal,
    "Winner": ballTextures.ace,
    "Forcing Error": ballTextures.serveWinner,
    "Forcing Volley Error": ballTextures.serveWinner,
    "Passing Shot": ballTextures.ace,
    "Netted": ballTextures.error,
    "Netted Passing Shot": ballTextures.error,
    "Net Cord": ballTextures.net,
    "Out Off-Net": ballTextures.net,
    "Out Off-Net-Long ": ballTextures.net,
    "Out Off-Net-Wide": ballTextures.net,
    "Out-Wide": ballTextures.error,
    "Out-Long": ballTextures.error,
    "Out-Other": ballTextures.error,
    "Out Passing Shot-Wide": ballTextures.error,
    "Out Passing Shot-Long": ballTextures.error,
    "Out Passing Shot-Other": ballTextures.error,
    "Put-Away": ballTextures.error,
};

const serveOutcomes = {
    "In": ballTextures.normal,
    "In Off-Net": ballTextures.normal,
    "Ace": ballTextures.ace,
    "Netted": ballTextures.error,
    "Out Off-Net": ballTextures.net,
    "Out-Long": ballTextures.error,
    "Out-Wide": ballTextures.error,
    "Out-Other": ballTextures.error,
    "Out-Wrong Side": ballTextures.error,
    "Serve Winner": ballTextures.serveWinner,
    "Let Winner": ballTextures.serveWinner,
    "Foot Fault": ballTextures.footFault,
    "Out Off-Net-Long": ballTextures.net,
    "Out Off-Net-Wide": ballTextures.net,
    "Out Off-Net-Wrong Side": ballTextures.net,
    "Out Off-Net-Other": ballTextures.net,
};
const returnOutcomes = {
    "In": ballTextures.normal,
    "Forcing Error": ballTextures.serveWinner,
    "Forcing Volley Error": ballTextures.serveWinner,
    "Out-Other": ballTextures.error,
    "Out-Long": ballTextures.error,
    "Out-Wide": ballTextures.error,
    "Winner": ballTextures.ace,
    "Volleyed": ballTextures.net,
    "Netted": ballTextures.error,
    "Netted Passing Shot": ballTextures.error,
    "In Off-Net": ballTextures.net,
    "Net Cord": ballTextures.net,
    "Passing Shot": ballTextures.ace,
    "Out Off-Net": ballTextures.net,
    "Out Off-Net-Long": ballTextures.net,
    "Out Off-Net-Wide": ballTextures.net,
    "Out Passing Shot-Long": ballTextures.net,
    "Out Passing Shot-Wide": ballTextures.net,
};

const CourtGame = ({ courtFilters, isMobile }) => {

    const DEFAULT_COURT_BACKGROUND = '#3C638E';
    const DEFAULT_COURT_BASE = '#6C935C';
    const DEFAULT_LINE_COURT_COLOR = '#FFFFFF'
    const { id: idMatch } = useParams();
    const { sets } = useSelector((state) => state.selectedSets);
    const { player } = useSelector((state) => state.selectedPlayer);
    const { match } = useSelector(state => state.matchData);

    const [keyShots, setKeyShots] = useState([]);

    const WINNER_SHOTS = ['In', 'Winner', 'Forcing Error', 'Forcing Volley Error', 'Passing Shot']
    const TYPES_SHOTS = {
        TOPSPIN: 'Topspin/Flat',
        LOB: 'Lob',
        SLICE: 'Slice',
        DROP_SHOT: 'Drop Shot',
        VOLLEY: 'Volley',
        OVERHEAD: 'Overhead'
    }

    const { data: courtPoints, isSuccess, isLoading } = useAxios(
        `${API_BACKEND}/tenniscourtdata?idMatch=${idMatch}&sets=${getSetsList(sets)}&playerNumber=${player}`
    );

    const filterKeyShots = (items) => {
        if (!courtFilters.keyShot.forehand && !courtFilters.keyShot.backhand && !courtFilters.keyShot.winningShots && !courtFilters.keyShot.errors && !courtFilters.keyShot.topspin && !courtFilters.keyShot.lob && !courtFilters.keyShot.slice && !courtFilters.keyShot.dropShot && !courtFilters.keyShot.volley && !courtFilters.keyShot.passing && !courtFilters.keyShot.overhead && !courtFilters.keyShot.other) {
            return [];
        }
        let partialKeyShots = items;
        if (courtFilters.keyShot.forehand || courtFilters.keyShot.backhand) {
            partialKeyShots = partialKeyShots.filter(item => courtFilters.keyShot.forehand && item.hand === 'Forehand' || courtFilters.keyShot.backhand && item.hand === 'Backhand');
        }
        if (courtFilters.keyShot.winningShots || courtFilters.keyShot.errors) {
            partialKeyShots = partialKeyShots.filter(item => courtFilters.keyShot.winningShots === WINNER_SHOTS.includes(item.outcome) || courtFilters.keyShot.errors === !WINNER_SHOTS.includes(item.outcome));
        }
        if (courtFilters.keyShot.topspin || courtFilters.keyShot.lob || courtFilters.keyShot.slice || courtFilters.keyShot.dropShot || courtFilters.keyShot.volley || courtFilters.keyShot.passing || courtFilters.keyShot.overhead || courtFilters.keyShot.other) {
            partialKeyShots = partialKeyShots.filter(item =>
                (courtFilters.keyShot.topspin && (item.type === TYPES_SHOTS.TOPSPIN)) ||
                (courtFilters.keyShot.lob && (item.type === TYPES_SHOTS.LOB)) ||
                (courtFilters.keyShot.slice && (item.type === TYPES_SHOTS.SLICE)) ||
                (courtFilters.keyShot.dropShot && (item.type === TYPES_SHOTS.DROP_SHOT)) ||
                (courtFilters.keyShot.volley && (item.type === TYPES_SHOTS.VOLLEY)) ||
                (courtFilters.keyShot.overhead && (item.type === TYPES_SHOTS.OVERHEAD)) ||
                (courtFilters.keyShot.other && (!Object.values(TYPES_SHOTS).includes(item.type)))
            );
        }
        return partialKeyShots;
    }

    const getServeOutcomeTexture = (outcome) => {
        return serveOutcomes[outcome];
    }

    const getReturnOutcomeTexture = (outcome) => {
        return returnOutcomes[outcome];
    }

    const getKeyShotsOutcomeTexture = (outcome) => {
        return keyShotsOutcomes[outcome];
    }

    useEffect(() => {
        if (isSuccess) {
            setKeyShots(filterKeyShots(courtPoints.keyShot));
        }
    }, [isLoading, JSON.stringify(courtFilters)])

    return (
        isSuccess && match ?
            <group >
                <Tribune isMobile={isMobile} xpos={isMobile ? -11.05 : -12.5} ypos={0} texture={isMobile ? "/Hinchada_IZQ_Mobile.png" : "/Hinchada_IZQ_Desktop.png"} />
                <Tribune isMobile={isMobile} xpos={isMobile ? 11.05 : 12.5} ypos={0} texture={isMobile ? "/Hinchada_DER_Mobile.png" : "/Hinchada_DER_Desktop.png"} />
                <CourtBase base={match.courtSurroundColor ?? DEFAULT_COURT_BASE} />
                <CourtBackgroundScene base={match.courtInnerColor ?? DEFAULT_COURT_BACKGROUND} />
                <Court color={match.lineColor ?? DEFAULT_LINE_COURT_COLOR} texture="/ProtrackerCourt.png" />
                {
                    courtPoints.firstServe.map((ball, index) => (
                        ball && ((courtFilters.firstServe.deuce && (ball.serveCourt === 'Deuce') === courtFilters.firstServe.deuce) || (courtFilters.firstServe.ad && (ball.serveCourt === 'Ad') === courtFilters.firstServe.ad)) ?
                            <CourtBall key={`firstServe_ball_${index}`} playerShotPositionY={ball.shotPosition[1]} position={[ball.bouncePoint[0], ball.bouncePoint[1]]} color={getServeOutcomeTexture(ball.outcome)} />
                            : null
                    ))
                }
                {
                    courtPoints.firstServeReturn.map((ball, index) => (
                        ball && ((courtFilters.firstServeReturn.deuce && (ball.serveCourt === 'Deuce') === courtFilters.firstServeReturn.deuce) || (courtFilters.firstServeReturn.ad && (ball.serveCourt === 'Ad') === courtFilters.firstServeReturn.ad)) ?
                            <>
                                <CourtBall key={`firstServeReturn_ball_${index}`} playerShotPositionY={ball.shotPosition[1]} position={[ball.bouncePoint[0], ball.bouncePoint[1]]} color={getReturnOutcomeTexture(ball.outcome)} />
                                <CourtLine key={`firstServeReturn_line_${index}`} start={ball.shotPosition} end={ball.bouncePoint} />
                            </>
                            : null
                    ))
                }
                {
                    courtPoints.secondServe.map((ball, index) => (
                        ball && ((courtFilters.secondServe.deuce && (ball.serveCourt === 'Deuce') === courtFilters.secondServe.deuce) || (courtFilters.secondServe.ad && (ball.serveCourt === 'Ad') === courtFilters.secondServe.ad)) ?
                            <CourtBall key={`secondServe_ball_${index}`} playerShotPositionY={ball.shotPosition[1]} position={[ball.bouncePoint[0], ball.bouncePoint[1]]} color={getServeOutcomeTexture(ball.outcome)} />
                            : null
                    ))
                }
                {
                    courtPoints.secondServeReturn.map((ball, index) => (
                        ball && ((courtFilters.secondServeReturn.deuce && (ball.serveCourt === 'Deuce') === courtFilters.secondServeReturn.deuce) || (courtFilters.secondServeReturn.ad && (ball.serveCourt === 'Ad') === courtFilters.secondServeReturn.ad)) ?
                            <>
                                <CourtBall key={`secondServeReturn_ball_${index}`} playerShotPositionY={ball.shotPosition[1]} position={[ball.bouncePoint[0], ball.bouncePoint[1]]} color={getReturnOutcomeTexture(ball.outcome)} />
                                <CourtLine key={`secondServeReturn_line_${index}`} start={ball.shotPosition} end={ball.bouncePoint} />
                            </>
                            : null
                    ))
                }
                {
                    keyShots.map((ball, index) => (
                        ball ?
                            <>
                                <CourtBall key={`keyshot_ball_${index}`} playerShotPositionY={ball.shotPosition[1]} position={[ball.bouncePoint[0], ball.bouncePoint[1]]} color={getKeyShotsOutcomeTexture(ball.outcome)} />
                                <CourtLine key={`keyshot_line_${index}`} start={ball.shotPosition} end={ball.bouncePoint} />
                            </>
                            : null

                    ))
                }
            </group>
            : null
    );
}

export default CourtGame;