import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { countries } from '../../utils/countries';
import Box from '@mui/material/Box';

/* Styled components */
const StyledTextField = styled(TextField)`
  margin: ${(props) => props.margin ? props.margin : '15px 0!important'};
  width: ${(props) => props.width ? props.width : "100%"};
  align-self:${(props) => props.alignSelf ? props.alignSelf : "auto"};
  order: ${(props) => props.order ? props.order : 0};
  @media (min-width: 600px) {
    ${(props) => props.width ? `width: ${props.width};` : ''}
    order: none;
  }
`

const CountryInput = (props) => {

    const [value, setValue] = React.useState(props.value ? countries.find(e => e.label === props.value) : null);
    const [inputCountry, setInputCountry] = React.useState('');

    const handleChange = (event, newValue) => {
        if (newValue !== null) {
            props.onChangeCountry(props.name, newValue.label);
        }
        else { props.onChangeCountry(props.name, null) }
        setValue(newValue)
    };

    const handleInputChange = (event, newValue) => {
        setInputCountry(newValue);
    };

    return (
        <Autocomplete
            id="country-select"
            style={{ width: '100%', order:props.order }}
            options={countries}
            autoHighlight
            getOptionLabel={(option) => option.label}
            onChange={handleChange}
            onInputChange={handleInputChange}
            value={value}
            inputValue={inputCountry}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                    />
                    {option.label}
                </Box>
            )}
            renderInput={(params) => (
                <StyledTextField
                    {...params}
                    id={props.name}
                    name={props.name}
                    width={props.width}
                    fullWidth={props.width ? false : true}
                    label={props.label}
                    variant={props.variant ? props.variant : "outlined"}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}

export default CountryInput;