import React from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";

const Root = styled.li`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    cursor: pointer;
    user-select: none;
    transition: all 200ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
    @media (min-width: 1024px) {
        
    }
`

const StyledNavLink = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: ${props => props.theme.palette.details};
    font-size: 1.15rem;
    text-transform: uppercase;
    padding: 10px 12.5px;
    width: 100%;
    span {
        transition: all 300ms ease-in-out;
        opacity: ${props => props.showname ? 1 : 0};
        margin: 0 0 0 10px;
        color: ${props => props.theme.palette.base};
    }
    svg {
        transition: all 300ms ease-in-out;
        opacity: ${props => props.showname ? 1 : 0};
    }
    &.active {
        color: ${props => props.theme.palette.accent};
        span{
            color: ${props => props.theme.palette.accent};
            font-weight: 700;
        }
    }
    @media (min-width: 1024px) {
        font-size: 0.875rem;
        svg {
            opacity: 1;
        }
        &.active {
            color: ${props => props.theme.palette.principal};
            background: ${props => props.theme.palette.accent};
            span{
                color: ${props => props.theme.palette.principal};
            }
        }
    }
`

const ItemList = ({item, showname, closeNav}) => {
    return (
        <Root>
            <StyledNavLink to={item.link} onClick={closeNav} showname={showname ? 1 : 0}>
                {item.icon}
                <span>{item.name}</span>
            </StyledNavLink>
        </Root>
    );
}

export default ItemList;