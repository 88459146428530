import React from "react";
import styled from "styled-components";
import { ThemeContext } from "../../providers/theme";

const Icon = styled.div`
    position: absolute;
    display: inherit;
    top: 8px;
    right: 8px;
    cursor: pointer;
    svg {
        font-size: 0.875rem;
    }
    &:hover {
        transform: scale(1.1);
    }
    @media (min-width: 1024px) {
        display: none;
    }
`

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    border-bottom: 1px solid rgba(0,0,0,0.3);
    padding: 10px;
    &:hover {
        ${Icon} {
            display: inherit;
        }
    }
`

const Title = styled.h3`
    width: 95%;
    font-size: 1rem;
    margin: 0 0 5px 0;
`

const Text = styled.div`
    font-weight: 300;
    font-size: 0.75rem;
    padding-left: 5px;
`



const Notification = ({data, onDelete}) => {

    const theme = React.useContext(ThemeContext);

    const deleteNotification = (e) => {
        e.preventDefault();
        onDelete(data.id);
    }

    return (
        data &&
        <Root>
            <Icon onClick={deleteNotification}>{theme.icons.close}</Icon>
            <Title>{data.title}</Title>
            <Text dangerouslySetInnerHTML={{ __html: data.text }} />
        </Root>
    );
}

export default Notification;