
export const _options = {
    chart: {
      type: 'rangeBar',
      with: 500,
      height: 800,
      toolbar: {
        show: false
      }
    },
    colors: [ "#e11584", "#a1045a", "#af69ef", "#fc4c4e", "#f699cd", "#fe7d6a" ],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '50%',
        rangeBarGroupRows: true
      }
    },
    fill: {
      type: 'solid'
    },
    xaxis: {
      type: 'numeric',
      labels: {
        style: {
          colors: "#FFFFFF"
        }
      }
    },
    yaxis: {
      show: false
    },
    legend: {
      position: 'right',
      labels: {
        colors: "#FFFFFF"
      }
    },
    tooltip: {
      custom: function(opts) {
        const value = opts.y2 - opts.y1;
        return '<div style="padding: 10px; display: flex; flex-direction: column; align-items: flex-start; justify-content: center;">' +
        '<h4 style="margin-bottom: 5px;">' + opts.w.globals.seriesNames[opts.seriesIndex] + '</h4>' +
        '<span> Value: ' + value + '</span>' +
        '</div>'
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return `${val[1] - val[0]} pts`
      },
      style: {
        fontSize: '14px',
        fontWeight: 'bold'
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '70%',
              rangeBarGroupRows: true
            }
          },
          legend: {
            position: 'bottom',
            labels: {
              colors: "#FFFFFF"
            },
            fontSize: '10px'
          },
        },
      },
      {
        breakpoint: 1440,
        options: {
          legend: {
            position: 'bottom',
            labels: {
              colors: "#FFFFFF"
            }
          },
        },
      },
    ]
  }  

export const formatData = (data) => {
  
  const series = [
    {
      name: 'Second Serve Return',
      data: [
        {
          x: 'Second Serve Return',
          y: [
            0,
            data.unforcedErrorsSecondServeReturn
          ]
        },
      ]
    },
    {
      name: 'First Serve + 1',
      data: [
        {
          x: 'Third Shot',
          y: [
            0,
            data.unforcedErrorsThirdShot.firstServe
          ]
        }
      ]
    },
    {
        name: 'Second Serve + 1',
        data: [
          {
            x: 'Third Shot',
            y: [
              data.unforcedErrorsThirdShot.firstServe,
              data.unforcedErrorsThirdShot.firstServe + data.unforcedErrorsThirdShot.secondServe
            ]
          }
        ]
    },
    {
        name: 'First Serve Return + 1',
        data: [
          {
            x: 'Fourth Shot',
            y: [
              0,
              data.unforcedErrorsFourthShot.firstServe
            ]
          }
        ]
    },
    {
        name: 'Second Serve Return + 1',
        data: [
          {
            x: 'Fourth Shot',
            y: [
              data.unforcedErrorsFourthShot.firstServe,
              data.unforcedErrorsFourthShot.firstServe + data.unforcedErrorsFourthShot.secondServe
            ]
          }
        ]
    },
    {
        name: 'Double Faults',
        data: [
          {
            x: 'Double Faults',
            y: [
              0,
              data.doubleFaults
            ]
          }
        ]
    }
  ]

  return {series};
}