import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Root = styled.li`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 70px;
    width: 100%;
    margin: 0;
    padding: 0 0 0 50px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 1.25rem;
    text-transform: uppercase;
    a {
        text-decoration: none;
        color: ${props => props.theme.palette.base};
    }
    @media (min-width: 1024px) {
        width: auto;
        height: 100%;
        justify-content: center;
        font-weight: 400;
        font-size: 1.15rem;
        transition-property: all;
        transition-duration: 200ms;
        transition-timing-function: ease-in-out;
        padding: 0 30px;
        a {
            transition-property: all;
            transition-duration: 150ms;
            transition-timing-function: ease-in-out;
        }
        &:hover {
            a {
                opacity: 0.8;
            }
        }
    }
`

const StyledNavLink = styled(NavLink)`
    &.active {
        color: ${props => props.theme.palette.accent};
    }
`

const ItemMenu = ({name, link, width, switchMenu}) => {
    return (
        <Root width={`${width}%`}>
            <StyledNavLink to={link} onClick={switchMenu}>
                {name}
            </StyledNavLink>
        </Root>
    );
}

export default ItemMenu;