import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { getDateUS } from "../../utils/dateUtils";
import { ThemeContext } from "../../providers/theme";
import Page from "./Page";
import Performance from "./Performance";
import Profile from "./Profile";
import { useReactToPrint } from "react-to-print";
import UnforcedErrors from "./UnforcedErrors";
import Minimatches from "./Minimatches";
import RallyServe from "./RallyServe";
import RallyReturn from "./RallyReturn";
import RallyServeReturn from "./RallyServeReturn";
import Sem from "./Sem";
import Serve1stIn from "./Serve1stIn";
import Serve1stEffectiveness from "./Serve1stEffectiveness";
import Serve2ndPoints from "./Serve2ndPoints";
import Serve2ndEffectiveness from "./Serve2ndEffectiveness";
import Return1stIn from "./Return1stIn";
import Return1stEffectiveness from "./Return1stEffectiveness";
import Return2ndPoints from "./Return2ndPoints";
import Return2ndEffectiveness from "./Return2ndEffectiveness";
import Rem from "./Rem";
import RadarReport from "./RadarReport";
import LoadingReport from "./LoadingReport";

const Root = styled.div`
  position: absolute;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(210mm + 100px);
  height: 80vh;
  background: #fff;
  color: ${(props) => props.theme.palette.alternative_principal};
`;

const DocumentContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 14px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => `${props.theme.palette.details}90`};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.palette.alternative_details};
  }
`;

const Document = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
  @page {
    size: A4;
    margin: 0;
    padding: 0;
  }
`;

const DateContainer = styled.div`
  width: 100%;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span {
    text-transform: uppercase;
    font-weight: 300;
    margin-right: 5px;
  }
`;

const Title = styled.h2`
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1.25rem;
`;

const Subtitle = styled.h2`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.15rem;
`;

const Paragraph = styled.p`
  margin: 1rem 0;
  a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.principal};
    font-weight: 700;
  }
`;

const Enumeration = styled.ol`
  margin: 0;
  padding-left: 20px;
  li {
    margin: 10px 0;
  }
`;

const List = styled.ul`
  margin: 0;
  padding-left: 20px;
  li {
    margin: 10px 0;
  }
`;

const PrintButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #40cbe6;
  border-radius: 35px;
  background-color: #40cbe6;
  color: #fff;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 1rem;
  cursor: pointer;
  font-family: museo-sans, sans-serif;
  font-weight: 500;
  font-style: normal;
  align-self: flex-end;
  z-index: 9;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  user-select: none;
  svg {
    margin-right: 5px;
  }
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const Comments = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid ${(props) => props.theme.palette.details};
`;

const GeneralReport = React.forwardRef(({ typeReport, reportConfig, comments }, ref) => {
  const intl = useIntl();

  const theme = React.useContext(ThemeContext);

  const initializeElements = () => {
    const elementsConfig = {};
    Object.keys(reportConfig).forEach((ele) => {
      if (reportConfig[ele]) {
        elementsConfig[ele] = false;
      }
    });
    return elementsConfig;
  };

  const getPageNumber = (name) => {
    switch (name) {
      case "performance":
        return 2;
      case "determinant unforced errors":
        return reportConfig.performance ? 3 : 2;
      case "rallyServe":
        let rallyServePage = 2;
        if (reportConfig.performance) {
          rallyServePage++;
        }
        if (reportConfig.unforcedErrors) {
          rallyServePage++;
        }
        return rallyServePage;
      case "rallyReturn":
        let rallyReturnPage = 2;
        if (reportConfig.performance) {
          rallyReturnPage++;
        }
        if (reportConfig.unforcedErrors) {
          rallyReturnPage++;
        }
        if (reportConfig.rallyServe) {
          rallyReturnPage++;
        }
        return rallyReturnPage;
      case "rallyServeReturn":
        let rallyServeReturnPage = 2;
        if (reportConfig.performance) {
          rallyServeReturnPage++;
        }
        if (reportConfig.unforcedErrors) {
          rallyServeReturnPage++;
        }
        if (reportConfig.rallyServe) {
          rallyServeReturnPage++;
        }
        if (reportConfig.rallyReturn) {
          rallyServeReturnPage++;
        }
        return rallyServeReturnPage;
      case "serve1stIn":
        let serve1stPage = 2;
        if (reportConfig.performance) {
          serve1stPage++;
        }
        if (reportConfig.unforcedErrors) {
          serve1stPage++;
        }
        if (reportConfig.rallyServe) {
          serve1stPage++;
        }
        if (reportConfig.rallyReturn) {
          serve1stPage++;
        }
        if (reportConfig.rallyServeReturn) {
          serve1stPage++;
        }
        return serve1stPage;
      case "serve1stEffectiveness":
        let serve1stEffectivenessPage = 2;
        if (reportConfig.performance) {
          serve1stEffectivenessPage++;
        }
        if (reportConfig.unforcedErrors) {
          serve1stEffectivenessPage++;
        }
        if (reportConfig.rallyServe) {
          serve1stEffectivenessPage++;
        }
        if (reportConfig.rallyReturn) {
          serve1stEffectivenessPage++;
        }
        if (reportConfig.rallyServeReturn) {
          serve1stEffectivenessPage++;
        }
        if (reportConfig.serve1stIn) {
          serve1stEffectivenessPage++;
        }
        return serve1stEffectivenessPage;
      case "serve2ndPoints":
        let serve2ndPage = 2;
        if (reportConfig.performance) {
          serve2ndPage++;
        }
        if (reportConfig.unforcedErrors) {
          serve2ndPage++;
        }
        if (reportConfig.rallyServe) {
          serve2ndPage++;
        }
        if (reportConfig.rallyReturn) {
          serve2ndPage++;
        }
        if (reportConfig.rallyServeReturn) {
          serve2ndPage++;
        }
        if (reportConfig.serve1stIn) {
          serve2ndPage++;
        }
        if (reportConfig.serve1stEffectiveness) {
          serve2ndPage++;
        }
        return serve2ndPage;
      case "serve2ndEffectiveness":
        let serve2ndEffectivenessPage = 2;
        if (reportConfig.performance) {
          serve2ndEffectivenessPage++;
        }
        if (reportConfig.unforcedErrors) {
          serve2ndEffectivenessPage++;
        }
        if (reportConfig.rallyServe) {
          serve2ndEffectivenessPage++;
        }
        if (reportConfig.rallyReturn) {
          serve2ndEffectivenessPage++;
        }
        if (reportConfig.rallyServeReturn) {
          serve2ndEffectivenessPage++;
        }
        if (reportConfig.serve1stIn) {
          serve2ndEffectivenessPage++;
        }
        if (reportConfig.serve1stEffectiveness) {
          serve2ndEffectivenessPage++;
        }
        if (reportConfig.serve2ndPoints) {
          serve2ndEffectivenessPage++;
        }
        return serve2ndEffectivenessPage;
      case "sem":
        let semPage = 2;
        if (reportConfig.performance) {
          semPage++;
        }
        if (reportConfig.unforcedErrors) {
          semPage++;
        }
        if (reportConfig.rallyServe) {
          semPage++;
        }
        if (reportConfig.rallyReturn) {
          semPage++;
        }
        if (reportConfig.rallyServeReturn) {
          semPage++;
        }
        if (reportConfig.serve1stIn) {
          semPage++;
        }
        if (reportConfig.serve1stEffectiveness) {
          semPage++;
        }
        if (reportConfig.serve2ndPoints) {
          semPage++;
        }
        if (reportConfig.serve2ndEffectiveness) {
          semPage++;
        }
        return semPage;
      case "return1stIn":
        let return1stPage = 2;
        if (reportConfig.performance) {
          return1stPage++;
        }
        if (reportConfig.unforcedErrors) {
          return1stPage++;
        }
        if (reportConfig.rallyServe) {
          return1stPage++;
        }
        if (reportConfig.rallyReturn) {
          return1stPage++;
        }
        if (reportConfig.rallyServeReturn) {
          return1stPage++;
        }
        if (reportConfig.serve1stIn) {
          return1stPage++;
        }
        if (reportConfig.serve1stEffectiveness) {
          return1stPage++;
        }
        if (reportConfig.serve2ndPoints) {
          return1stPage++;
        }
        if (reportConfig.serve2ndEffectiveness) {
          return1stPage++;
        }
        if (reportConfig.sem) {
          return1stPage++;
        }
        return return1stPage;
      case "return1stEffectiveness":
        let return1stEffectivenessPage = 2;
        if (reportConfig.performance) {
          return1stEffectivenessPage++;
        }
        if (reportConfig.unforcedErrors) {
          return1stEffectivenessPage++;
        }
        if (reportConfig.rallyServe) {
          return1stEffectivenessPage++;
        }
        if (reportConfig.rallyReturn) {
          return1stEffectivenessPage++;
        }
        if (reportConfig.rallyServeReturn) {
          return1stEffectivenessPage++;
        }
        if (reportConfig.serve1stIn) {
          return1stEffectivenessPage++;
        }
        if (reportConfig.serve1stEffectiveness) {
          return1stEffectivenessPage++;
        }
        if (reportConfig.serve2ndPoints) {
          return1stEffectivenessPage++;
        }
        if (reportConfig.serve2ndEffectiveness) {
          return1stEffectivenessPage++;
        }
        if (reportConfig.sem) {
          return1stEffectivenessPage++;
        }
        if (reportConfig.return1stIn) {
          return1stEffectivenessPage++;
        }
        return return1stEffectivenessPage;
      case "return2ndPoints":
        let return2ndPage = 2;
        if (reportConfig.performance) {
          return2ndPage++;
        }
        if (reportConfig.unforcedErrors) {
          return2ndPage++;
        }
        if (reportConfig.rallyServe) {
          return2ndPage++;
        }
        if (reportConfig.rallyReturn) {
          return2ndPage++;
        }
        if (reportConfig.rallyServeReturn) {
          return2ndPage++;
        }
        if (reportConfig.serve1stIn) {
          return2ndPage++;
        }
        if (reportConfig.serve1stEffectiveness) {
          return2ndPage++;
        }
        if (reportConfig.serve2ndPoints) {
          return2ndPage++;
        }
        if (reportConfig.serve2ndEffectiveness) {
          return2ndPage++;
        }
        if (reportConfig.sem) {
          return2ndPage++;
        }
        if (reportConfig.return1stIn) {
          return2ndPage++;
        }
        if (reportConfig.return1stEffectiveness) {
          return2ndPage++;
        }
        return return2ndPage;
      case "return2ndEffectiveness":
        let return2ndEffectivenessPage = 2;
        if (reportConfig.performance) {
          return2ndEffectivenessPage++;
        }
        if (reportConfig.unforcedErrors) {
          return2ndEffectivenessPage++;
        }
        if (reportConfig.rallyServe) {
          return2ndEffectivenessPage++;
        }
        if (reportConfig.rallyReturn) {
          return2ndEffectivenessPage++;
        }
        if (reportConfig.rallyServeReturn) {
          return2ndEffectivenessPage++;
        }
        if (reportConfig.serve1stIn) {
          return2ndEffectivenessPage++;
        }
        if (reportConfig.serve1stEffectiveness) {
          return2ndEffectivenessPage++;
        }
        if (reportConfig.serve2ndPoints) {
          return2ndEffectivenessPage++;
        }
        if (reportConfig.serve2ndEffectiveness) {
          return2ndEffectivenessPage++;
        }
        if (reportConfig.sem) {
          return2ndEffectivenessPage++;
        }
        if (reportConfig.return1stIn) {
          return2ndEffectivenessPage++;
        }
        if (reportConfig.return1stEffectiveness) {
          return2ndEffectivenessPage++;
        }
        if (reportConfig.return2ndPoints) {
          return2ndEffectivenessPage++;
        }
        return return2ndEffectivenessPage;
      case "rem":
        let remPage = 2;
        if (reportConfig.performance) {
          remPage++;
        }
        if (reportConfig.unforcedErrors) {
          remPage++;
        }
        if (reportConfig.rallyServe) {
          remPage++;
        }
        if (reportConfig.rallyReturn) {
          remPage++;
        }
        if (reportConfig.rallyServeReturn) {
          remPage++;
        }
        if (reportConfig.serve1stIn) {
          remPage++;
        }
        if (reportConfig.serve1stEffectiveness) {
          remPage++;
        }
        if (reportConfig.serve2ndPoints) {
          remPage++;
        }
        if (reportConfig.serve2ndEffectiveness) {
          remPage++;
        }
        if (reportConfig.sem) {
          remPage++;
        }
        if (reportConfig.return1stIn) {
          remPage++;
        }
        if (reportConfig.return1stEffectiveness) {
          remPage++;
        }
        if (reportConfig.return2ndPoints) {
          remPage++;
        }
        if (reportConfig.return2ndEffectiveness) {
          remPage++;
        }
        return remPage;
      case "minimatches":
      case "radarReport":
        let minimatchesPage = 2;
        if (reportConfig.performance) {
          minimatchesPage++;
        }
        if (reportConfig.unforcedErrors) {
          minimatchesPage++;
        }
        if (reportConfig.rallyServe) {
          minimatchesPage++;
        }
        if (reportConfig.rallyReturn) {
          minimatchesPage++;
        }
        if (reportConfig.rallyServeReturn) {
          minimatchesPage++;
        }
        if (reportConfig.serve1stIn) {
          minimatchesPage++;
        }
        if (reportConfig.serve1stEffectiveness) {
          minimatchesPage++;
        }
        if (reportConfig.serve2ndPoints) {
          minimatchesPage++;
        }
        if (reportConfig.serve2ndEffectiveness) {
          minimatchesPage++;
        }
        if (reportConfig.sem) {
          minimatchesPage++;
        }
        if (reportConfig.return1stIn) {
          minimatchesPage++;
        }
        if (reportConfig.return1stEffectiveness) {
          minimatchesPage++;
        }
        if (reportConfig.return2ndPoints) {
          minimatchesPage++;
        }
        if (reportConfig.return2ndEffectiveness) {
          minimatchesPage++;
        }
        if (reportConfig.rem) {
          minimatchesPage++;
        }
        return minimatchesPage;
      case "comments":
        let commentsPage = 2;
        if (reportConfig.performance) {
          commentsPage++;
        }
        if (reportConfig.unforcedErrors) {
          commentsPage++;
        }
        if (reportConfig.rallyServe) {
          commentsPage++;
        }
        if (reportConfig.rallyReturn) {
          commentsPage++;
        }
        if (reportConfig.rallyServeReturn) {
          commentsPage++;
        }
        if (reportConfig.serve1stIn) {
          commentsPage++;
        }
        if (reportConfig.serve1stEffectiveness) {
          commentsPage++;
        }
        if (reportConfig.serve2ndPoints) {
          commentsPage++;
        }
        if (reportConfig.serve2ndEffectiveness) {
          commentsPage++;
        }
        if (reportConfig.sem) {
          commentsPage++;
        }
        if (reportConfig.return1stIn) {
          commentsPage++;
        }
        if (reportConfig.return1stEffectiveness) {
          commentsPage++;
        }
        if (reportConfig.return2ndPoints) {
          commentsPage++;
        }
        if (reportConfig.return2ndEffectiveness) {
          commentsPage++;
        }
        if (reportConfig.rem) {
          commentsPage++;
        }
        if (reportConfig.minimatches || reportConfig.radarReport) {
          commentsPage++;
        }
        return commentsPage;
      default:
        return 0;
    }
  };

  const getTotalPages = () => {
    const pageIntroduction = 1;
    const pageMinimatches = reportConfig.minimatches || reportConfig.radarReport ? 1 : 0;
    const pageComments = comments ? 1 : 0;
    let totalPages = pageIntroduction + reportConfig.performance + reportConfig.unforcedErrors + reportConfig.rallyServe + reportConfig.rallyReturn + reportConfig.rallyServeReturn + reportConfig.serve1stIn + reportConfig.serve1stEffectiveness + reportConfig.serve2ndPoints + reportConfig.serve2ndEffectiveness + reportConfig.sem + reportConfig.return1stIn + reportConfig.return1stEffectiveness + reportConfig.return2ndPoints + reportConfig.return2ndEffectiveness + reportConfig.rem + pageMinimatches + pageComments;
    return totalPages;
  }

  const [elements, setElements] = React.useState(initializeElements());

  const TOTAL_PAGES = Object.values(reportConfig).reduce((sum, ele) => sum + ele, 0) + 1;

  const onReady = (name) => {
    setElements((state) => {
      return {
        ...state,
        [name]: true,
      };
    });
  };

  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Root>
      {!Object.values(elements).reduce((res, el) => res && el, true) && (
        <LoadingReport elements={elements} />
      )}
      <PrintButton onClick={handlePrint}>
        {theme.icons.print}
        {translate("Print")}
      </PrintButton>
      <DocumentContainer>
        <Document ref={componentRef}>
          <Page number={1} total={getTotalPages()}>
            <DateContainer>
              <span>{`${intl.formatMessage({ id: "date" })}:`}</span>
              {getDateUS(new Date())}
            </DateContainer>
            <Profile onReady={onReady} />
            <Title>{translate("Introduction")}</Title>
            <Paragraph>
              {translate(
                "This report is a summary that contains information on the main parameters of the player in the last 10 matches released. Full information is available on the GSM WEB APP:"
              )}{" "}
              <a href="https://gamesetmath.net" target="_blank">
                https://gamesetmath.net
              </a>
              .
            </Paragraph>
            <Paragraph>
              <strong>{`${intl.formatMessage({
                id: "report type",
              })}: `}</strong>
              {typeReport}
            </Paragraph>
            <Title>{translate("How to read the report?")}</Title>
            <Enumeration type="1">
              <li>
                <Paragraph>
                  {translate(
                    "Observe if it is an initial, intermediate or final report. Regardless of the number, the initial report may correspond to the start of a full season or the start of any other mini-cycle established during the season. The intermediate reports are those made periodically during any cycle excluding the initial or final report. The final report corresponds to the end of the season or the end of a mini-cycle."
                  )}
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  {translate(
                    "The initial report shows the player parameters at the start of a cycle. By determining the level of the player at the time of starting the job, it is neither good nor bad, but only marks a starting point. The establishment of short, medium and long-term objectives will determine the intermediate and final points of the path to follow."
                  )}
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  {translate(
                    "The intermediate report show the parameters of the player in the different stages of work within a cycle. They allow comparison with the initial parameters and with other intermediate reports. It is important to note that the comparison between average reports will not necessarily express constant improvements so that at the end of the cycle there is progress in the player&apos;s level. This means that there may be setbacks and advances that are logical in a learning process. Error precedes progress, and learning new skills may first cause a drop in level of play."
                  )}
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  {translate(
                    "The final report shows the parameters of the player at the end of a cycle, it can be the final report of a season or a mini-cycle within it. This report must be compared with the initial report, to observe the progress, and in the items in which no improvements are seen, to be able to analyze the causes."
                  )}
                </Paragraph>
              </li>
            </Enumeration>
          </Page>
          {reportConfig.performance && (
            <Page number={getPageNumber("performance")} total={getTotalPages()}>
              <Subtitle>{`1) ${intl.formatMessage({
                id: "Match performance",
              })}`}</Subtitle>
              <Paragraph>
                {translate(
                  "It is the performance level of each of the last ten matches and the match performance average."
                )}
              </Paragraph>
              <List>
                <li>
                  {translate(
                    "The desired margin of performance is established."
                  )}
                </li>
                <li>
                  {translate(
                    "The leadership margin shows the percentage of the points of the last ten games that depended on the player."
                  )}
                </li>
                <li>
                  {translate(
                    "Match quality takes into account the level of performance of the two players in the last ten matches."
                  )}
                </li>
              </List>
              <Performance onReady={onReady} />
            </Page>
          )}
          {reportConfig.unforcedErrors && (
            <Page number={getPageNumber("determinant unforced errors")} total={getTotalPages()}>
              <Title>{translate("determinant unforced errors")}</Title>
              <Paragraph>{translate("subtitle determinant unforced errors")}</Paragraph>
              <UnforcedErrors onReady={onReady} />
            </Page>
          )}
          {reportConfig.rallyServe && (
            <Page number={getPageNumber("rallyServe")} total={getTotalPages()}>
              <Title>{translate("rally serve")}</Title>
              <Paragraph>
                {translate(
                  "Detail and effectiveness of rally lenght on serve."
                )}
              </Paragraph>
              <RallyServe onReady={onReady} />
            </Page>
          )}
          {reportConfig.rallyReturn && (
            <Page number={getPageNumber("rallyReturn")} total={getTotalPages()}>
              <Title>{translate("rally return")}</Title>
              <Paragraph>
                {translate(
                  "Detail and effectiveness of rally lenght on serve return."
                )}
              </Paragraph>
              <RallyReturn onReady={onReady} />
            </Page>
          )}
          {reportConfig.rallyServeReturn && (
            <Page number={getPageNumber("rallyServeReturn")} total={getTotalPages()}>
              <Title>{translate("rally serve and return")}</Title>
              <Paragraph>
                {translate(
                  "Detail and effectiveness of rally lenght on serve and serve return."
                )}
              </Paragraph>
              <RallyServeReturn onReady={onReady} />
            </Page>
          )}
          {
            reportConfig.serve1stIn &&
            <Page number={getPageNumber("serve1stIn")} total={getTotalPages()}>
              <Title>{translate("serve")}</Title>
              <Paragraph>
                {translate(
                  "Detail of the percentage of won points with first and second serve. It also includes the percentage of won points with the first and second serve on the deuce and ad court."
                )}
              </Paragraph>
              <Serve1stIn onReady={onReady} />
            </Page>
          }
          {
            reportConfig.serve1stEffectiveness &&
            <Page
              number={getPageNumber("serve1stEffectiveness")}
              total={getTotalPages()}
            >
              <Title>{translate("serve")}</Title>
              <Paragraph>
                {translate(
                  "Detail of the percentage of won points with first and second serve. It also includes the percentage of won points with the first and second serve on the deuce and ad court."
                )}
              </Paragraph>
              <Serve1stEffectiveness onReady={onReady} />
            </Page>
          }
          {
            reportConfig.serve2ndPoints &&
            <Page number={getPageNumber("serve2ndPoints")} total={getTotalPages()}>
              <Title>{translate("serve")}</Title>
              <Paragraph>
                {translate(
                  "Detail of the percentage of won points with first and second serve. It also includes the percentage of won points with the first and second serve on the deuce and ad court."
                )}
              </Paragraph>
              <Serve2ndPoints onReady={onReady} />
            </Page>
          }
          {
            reportConfig.serve2ndEffectiveness &&
            <Page
              number={getPageNumber("serve2ndEffectiveness")}
              total={getTotalPages()}
            >
              <Title>{translate("serve")}</Title>
              <Paragraph>
                {translate(
                  "Detail of the percentage of won points with first and second serve. It also includes the percentage of won points with the first and second serve on the deuce and ad court."
                )}
              </Paragraph>
              <Serve2ndEffectiveness onReady={onReady} />
            </Page>
          }
          {reportConfig.sem && (
            <Page number={getPageNumber("sem")} total={getTotalPages()}>
              <Title>{translate("Service Effective Margin (S.E.M.)")}</Title>
              <Paragraph>
                {translate(
                  "The service effective margin refers to the effectiveness in game services, including the performance of the first and second serve. On this basis, the probability that the service will be broken is calculated."
                )}
              </Paragraph>
              <Sem onReady={onReady} />
            </Page>
          )}
          {
            reportConfig.return1stIn &&
            <Page number={getPageNumber("return1stIn")} total={getTotalPages()}>
              <Title>{translate("return")}</Title>
              <Paragraph>
                {translate(
                  "Detail of the percentage of won points with first and second serve return. It also includes the percentage of won points with the first and second serve return on the deuce and ad court."
                )}
              </Paragraph>
              <Return1stIn onReady={onReady} />
            </Page>
          }
          {
            reportConfig.return1stEffectiveness &&
            <Page
              number={getPageNumber("return1stEffectiveness")}
              total={getTotalPages()}
            >
              <Title>{translate("return")}</Title>
              <Paragraph>
                {translate(
                  "Detail of the percentage of won points with first and second serve return. It also includes the percentage of won points with the first and second serve return on the deuce and ad court."
                )}
              </Paragraph>
              <Return1stEffectiveness onReady={onReady} />
            </Page>
          }
          {
            reportConfig.return2ndPoints &&
            <Page number={getPageNumber("return2ndPoints")} total={getTotalPages()}>
              <Title>{translate("return")}</Title>
              <Paragraph>
                {translate(
                  "Detail of the percentage of won points with first and second serve return. It also includes the percentage of won points with the first and second serve return on the deuce and ad court."
                )}
              </Paragraph>
              <Return2ndPoints onReady={onReady} />
            </Page>
          }
          {
            reportConfig.return2ndEffectiveness &&
            <Page
              number={getPageNumber("return2ndEffectiveness")}
              total={getTotalPages()}
            >
              <Title>{translate("return")}</Title>
              <Paragraph>
                {translate(
                  "Detail of the percentage of won points with first and second serve return. It also includes the percentage of won points with the first and second serve return on the deuce and ad court."
                )}
              </Paragraph>
              <Return2ndEffectiveness onReady={onReady} />
            </Page>
          }
          {reportConfig.rem && (
            <Page number={getPageNumber("rem")} total={getTotalPages()}>
              <Title>{translate("Return Effective Margin (R.E.M.)")}</Title>
              <Paragraph>
                {translate(
                  "The return effective margin refers to the effectiveness in game's return, including the performance of the first and second serve return. On this basis, the probability that the opponent serve will be broken is calculated."
                )}
              </Paragraph>
              <Rem onReady={onReady} />
            </Page>
          )}
          {(reportConfig.minimatches || reportConfig.radarReport) && (
            <Page number={getPageNumber("minimatches")} total={getTotalPages()}>
              {reportConfig.minimatches && (
                <React.Fragment>
                  <Title>{translate("minimatches")}</Title>
                  <Paragraph>
                    {translate(
                      "Effectiveness in points played with the first and second serve and with the return of the first and second serve."
                    )}
                  </Paragraph>
                  <Minimatches onReady={onReady} />
                </React.Fragment>
              )}
              {reportConfig.radarReport && (
                <React.Fragment>
                  <Title>{translate("summary of player skills")}</Title>
                  <RadarReport onReady={onReady} />
                </React.Fragment>
              )}
            </Page>
          )}
          {comments ? (
            <Page number={getPageNumber("comments")} total={getTotalPages()}>
              <Title>{translate("comments")}</Title>
              <Comments dangerouslySetInnerHTML={{ __html: comments }} />
            </Page>
          ) : null}
        </Document>
      </DocumentContainer>
    </Root>
  );
});

export default GeneralReport;
