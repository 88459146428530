import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from '../../providers/theme';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
/* import SelectInput from '../inputs/SelectInput';
import { API_BACKEND } from '../../config';
import axios from 'axios'; */
import BasicEditor from '../textEditors/basicEditor';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
/* import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { loadingConstants } from "../../store/reducers/loading/loadingConstants"; */

/* Styled components */
const Root = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 500px;
    height: auto;
    background-color: #FFF;
    border-radius: 8px;
    padding: 20px;
`

const Title = styled.h2`
    width: 100%;
    border-bottom: 1px solid ${props => props.borderColor ? props.borderColor : "#000"};
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    margin: 0 0 20px 0;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: ${props => props.bg ? props.bg : "#7A7A7A"};
    color: ${props => props.color ? props.color : "#FFF"};
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0 0 0;
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
    &:disabled {
        color: #FFF;
        background-color: grey;
    }
`

const StyledTextfield = styled(TextField)`
    width: ${props => props.width ? props.width : "inherit"};
`

const InputGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

const StyledEditIcon = styled.div`
    position: absolute;
    right: 100px;
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: ${props => props.color ? props.color : "#000"};
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
        color: blue;
    }
`

const StyledDeleteIcon = styled.div`
    position: absolute;
    right: 60px;
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: ${props => props.color ? props.color : "#000"};
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
        color: red;
    }
`

const StyledExitIcon = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: ${props => props.color ? props.color : "#000"};
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
        color: #000;
    }
`

const NewEvent = ({start, end, handleClose, eventData}) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const [editorLoaded, setEditorLoaded] = React.useState(false);

    const [ edit, setEdit ] = React.useState(eventData ? false : true);

    /* const dispatch = useDispatch(); */

    /* const {loadingAction} = bindActionCreators(actionCreators, dispatch); */

    const [ newEvent, setNewEvent ] = React.useState(eventData ? 
        {
            id: eventData.id,
            title: eventData.title,
            start: new Date(eventData.start),
            end: new Date(eventData.end),
            isAllDay: false,
            description: eventData.description
        }
        :
        {
            title: '',
            start: start || new Date(),
            end: end || new Date(),
            isAllDay: false,
            description: ''
        }
    )

    const handleEdit = (e) => {
        e.preventDefault();
        setEdit(!edit);
    }

    const handleDelete = (e) => {
        e.preventDefault();
        // eslint-disable-next-line no-restricted-globals
        if( confirm(intl.formatMessage({ id: 'confirm delete' })) ){
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewEvent({
            ...newEvent,
            [name]: value
        });
    }

    const handleChangeDateStart = (newValue) => {
        setNewEvent({
            ...newEvent,
            start: newValue
        });
    }

    const handleChangeDateEnd = (newValue) => {
        setNewEvent({
            ...newEvent,
            end: newValue
        });
    }

    const handleEditor = (e, dataeditor) => {
        setNewEvent({
            ...newEvent,
            description: dataeditor
        })
    }

    const handleAction = (e) => {
        e.preventDefault();
        
        
    }

    React.useEffect(() => {
        setEditorLoaded(true);
    }, [])

    return (
        <Root>
            {
                eventData && <StyledEditIcon onClick={handleEdit} color={theme.palette.details}><EditIcon /></StyledEditIcon>
            }
            {
                eventData && <StyledDeleteIcon onClick={handleDelete} color={theme.palette.details}><DeleteForeverIcon /></StyledDeleteIcon>
            }
            <StyledExitIcon onClick={handleClose} color={theme.palette.details}><CloseIcon /></StyledExitIcon>
            <Title borderColor={theme.palette.principal} color={theme.palette.principal}>{eventData? translate("edit event") : translate("new event")}</Title>
            <InputGroup>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <DateTimePicker
                        renderInput={(props) => <StyledTextfield width="45%" {...props} />}
                        label={intl.formatMessage({ id: 'start' })}
                        value={newEvent.start}
                        onChange={handleChangeDateStart}
                        disabled={!edit}
                    />
                    <DateTimePicker
                        renderInput={(props) => <StyledTextfield width="45%" {...props} />}
                        label={intl.formatMessage({ id: 'end' })}
                        value={newEvent.end}
                        onChange={handleChangeDateEnd}
                        disabled={!edit}
                    />
                </LocalizationProvider>
            </InputGroup>
            <TextField id="title" name="title" onChange={handleChange} value={newEvent.title} label={intl.formatMessage({ id: 'title' })} variant="outlined" fullWidth margin="dense" size="small" disabled={!edit} />
            <BasicEditor
                name="description"
                value={newEvent.description}
                onChange={handleEditor}
                editorLoaded={editorLoaded}
                maxContentHeight="100px"
                minContentHeight="100px"
                placeholder="Ingrese aquí la descripción del evento..."
                readOnly={!edit}
                
            />
            <Button disabled={!edit} bg={theme.palette.accent} color={theme.palette.base} onClick={handleAction}><CheckIcon /></Button>
        </Root>
    );
}

export default NewEvent;