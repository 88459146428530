import axios from "axios";
import { useState, useEffect } from "react";
import { useAuth } from "../providers/authentication";

export function useFetch(url) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useAuth();
  useEffect(() => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err)
        console.log(err)
      });
  }, [url, token]);

  return [data, loading, setData, error];
};