import axios from "axios";
import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { API_BACKEND } from "../../../config";
import { useAuth } from "../../../providers/authentication";
import translate from "../../../providers/i18n/translate";
import { ThemeContext } from "../../../providers/theme";
import CourtSVG from "../../../assets/icons/court.svg";
import { getDateUS } from "../../../utils/dateUtils";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
`

const Title = styled.h2`
    text-transform: uppercase;
    font-size: 1.5rem;
    color: ${props => props.theme.palette.alternative_accent};
    font-weight: 300;
`

const Data = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    color: ${props => props.theme.palette.details};
    svg {
        color: ${props => props.theme.palette.alternative_principal};
        margin-right: 0.5rem;
        font-size: 2rem;
    }
    @media (min-width: 600px) {
        font-size: 0.875rem;
        svg {
            font-size: 1.5rem;
        }
    }
    @media (min-width: 1024px) {
        font-size: 1rem;
        svg {
            font-size: 2rem;
        }
    }
    @media (min-width: 1440px) {
        font-size: 1.25rem;
        svg {
            font-size: 1.5rem;
        }
    }
`

const CourtIcon = styled.img`
    width: 1.45rem;
    height: 1.75rem;
    margin-right: 0.65rem;
    margin-left: 0.25rem;
    @media (min-width: 600px) {
        margin-right: 0.5rem;
        margin-left: 0;
    }
    @media (min-width: 1024px) {
        margin-left: 0.25rem;
        margin-right: 0.65rem;
    }
    @media (min-width: 1440px) {
        margin-left: 0;
        margin-right: 0.5rem;
        width: 1.45rem;
    }
`

const GoMatch = styled(Link)`
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    text-decoration: none;
    color: ${props => props.theme.palette.alternative_principal};
    text-transform: uppercase;
    align-self: flex-end;
    font-weight: 700;
    svg {
        color: ${props => props.theme.palette.alternative_accent};
        margin-left: 0.5rem;
    }
    @media (min-width: 600px) {
        font-size: 0.875rem;
        position: absolute;
        bottom: 30px;
        right: 30px;
    }
    @media (min-width: 1024px) {
        font-size: 0.875rem;
        bottom: 30px;
        right: 40px;
    }
    @media (min-width: 1440px) {
        font-size: 1rem;
        bottom: 30px;
        right: 60px;
    }
`

const LastMatch = () => {

    const [data, setData] = React.useState(null);

    const { token } = useAuth();

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const getData = () => {
        axios
            .get(
                `${API_BACKEND}/lastMatch`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((result) => {
                setData(result.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    React.useEffect(() => {
        getData();
    }, [])

    return (
        data &&
        <Root>
            <Title>{translate("Last match")}</Title>
            <Data>{theme.icons.tennis}{`Vs. ${data.opponentName}`}</Data>
            <Data>{theme.icons.cup}{`${data.opponentName === data.playerWinner ? intl.formatMessage({ id: 'LOSE' }) : intl.formatMessage({ id: 'WON' })} ${data.score}`}</Data>
            <Data>{theme.icons.calendar}{` ${getDateUS(new Date(data.date))}`}</Data>
            <Data><CourtIcon src={CourtSVG} alt="court_icon" />{data.surface ? data.surface : "---"}</Data>
            <GoMatch to={`/admin/match/${data.id}/overview`} >{translate("Go to match")}{theme.icons.arrowForward}</GoMatch>
        </Root>
    );
}

export default LastMatch;