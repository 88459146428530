import styled from "styled-components";

export const StyledPopupButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 15px;
  right: 15px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  z-index: 99;
  &:hover {
    box-shadow: 0 0 5px ${(props) => (props.color ? props.color : props.theme.palette.accent)};
    transform: scale(1.1);
  }
  svg {
    width: 100%;
    height: 100%;
    color: ${(props) => (props.color ? props.color : props.theme.palette.accent)};
  }
  @media (min-width: 600px) {
    width: 30px;
    height: 30px;
  }
`