import React from "react";
import styled from "styled-components";
import Awards from "./Awards";
import LastMatch from "./LastMatch";
import ProfileData from "./ProfileData";
import Racket from "./Racket";
import Rankings from "./Rankings";

const Root = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
    @media (min-width: 600px) {
    }
    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 0.3fr 0.4fr 0.3fr;
    }
`

const Container = styled.div`
    border-radius: 10px;
    overflow: hidden;
    background: ${props => props.theme.palette.base};
    min-height: 200px;
    width: 100%;
    padding: 20px;
    background: rgba(0,0,0,0.05);
`

const ContainerProfile = styled(Container)`
    grid-row: 1/3;
    background: ${props => `${props.theme.palette.alternative_accent}AA`};
`

const ContainerLastMatch = styled(Container)`
    background: ${props => `${props.theme.palette.accent}AA`};
`

const ContainerRacket = styled(Container)`
    background: ${props => `${props.theme.palette.accent}AA`};
`

const Profile = () => {
    return (
        <Root>
            <ContainerProfile>
                <ProfileData />
            </ContainerProfile>
            <Container>
                <Rankings />
            </Container>
            <ContainerRacket>
                <Racket />
            </ContainerRacket>
            <Container>
                <Awards />
            </Container>
            <ContainerLastMatch>
                <LastMatch />
            </ContainerLastMatch>
        </Root>
    );
}

export default Profile;