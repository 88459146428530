import { playerConstants } from "./playerConstants";

const initialState = { 
  player: 1
};

export function selectedPlayer(state = initialState, action) {
  switch (action.type) {
    case playerConstants.SET:
      return {
        ...state,
        player: action.player
      };
    default:
      return state
  }
}