import React from 'react';
import styled from 'styled-components';
import translate from '../../../providers/i18n/translate';
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";

const Root = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    opacity: ${props => props.loading === "true" ? 0.3 : 1};
`

const Total = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: ${(props) => props.theme.palette.principal};
    border-radius: 20px;
    padding: 20px;
    span {
        margin: auto;
        font-size: 7.5rem;
        font-weight: 900;
        color: ${props => props.theme.palette.base};
    }
    @media (min-width: 600px) {
        span {
            font-size: 9rem;
        }
    }
    @media (min-width: 1024px) {
        span {
            font-size: 7.5rem;
        }
    }
    @media (min-width: 1440px) {
        span {
            font-size: 9rem;
        }
    }
`

const Title = styled.h5`
    font-size: 1rem;
    color: ${props => props.theme.palette.accent};
    margin: 10px 0 0 0;
    width: 80%;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0 0 10px 0;
    @media (min-width: 600px) {
      font-size: 0.9rem;
    }
    @media (min-width: 1440px) {
      font-size: 1rem;
    }
`

const UnforcedErrorsInRow = ({ data, rawData, sets, player, matchId }) => {
    const [totalPoints, setTotalPoints] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);
    const { appliedFilters } = useSelector(state => state.selectedDashboardFilters);

    const getData = () => {
        setLoading(true);
        if (data) {
            data()
                .then(result => {
                    setTotalPoints(result.totalPoints);
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                    alertMessage(err.response.data.msg, alertConstants.ERROR);
                    setLoading(false);
                })
        } else {
            if (rawData) {
                setTotalPoints(rawData.totalPoints);
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, [JSON.stringify(sets), player, JSON.stringify(appliedFilters), matchId]);

    return (
        <Root loading={loading ? "true" : "false"}>
            <Total>
                <Title>{translate("total unforced errors in a row")}</Title>
                <span>{totalPoints}</span>
            </Total>
        </Root>
    );
}

export default UnforcedErrorsInRow;