import React from "react";
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";
import { _options } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import translate from "../../../providers/i18n/translate";

const Root = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    opacity: ${props => props.loading === "true" ? 0.3 : 1};
`

const Content = styled.div`
    position: relative;
    width: 100%;
    height: auto;
`

const ContainerChart = styled.div`
    width: 100%;
    height: 380px;
    margin: auto;
    .apexcharts-canvas {
        margin: auto;
    }
    @media (min-width: 600px) {
        height: ${props => props.print ? "450px" : "650px"};
    }
    @media (min-width: 1024px) {
        height: ${props => props.print ? "450px" : "500px"};
    }
    @media (min-width: 1440px) {
        height: ${props => props.print ? "450px" : "600px"};
    }
`

const Text = styled.p`
    position: absolute;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    color: ${props => props.theme.palette.base};
    height: auto;
    text-align: center;
    font-size: 0.875rem;
    bottom: 5%;
    @media (min-width: 600px) {
        font-size: ${props => props.print ? "2rem" : "1.25rem"};
        width: 60%;
    }
    @media (min-width: 1024px) {
        font-size: ${props => props.print ? "2rem" : "1rem"};
        width: 80%;

    }
    @media (min-width: 1440px) {
        font-size: ${props => props.print ? "2rem" : "1.5rem"};
        width: 70%;
    }
    @media print {
        font-size: 2rem;
    }
`

const SemiCircleUEChart = ({data, rawData, width, height, options, type, labels, sets, player, print}) => {

    const [ series, setSeries ] = React.useState(null);
    const [ text, setText ] = React.useState(null);
    const [ loading, setLoading ] = React.useState(false);

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);
    const {appliedFilters} = useSelector(state => state.selectedDashboardFilters);

    const getData = () => {
        setLoading(true);
        if(data){
            data()
            .then(result => {
                setSeries(result.series);
                setText(result.text);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                alertMessage(err.response.data.msg, alertConstants.ERROR);
                setLoading(false);
            })
        } else {
            if(rawData){
                setSeries(rawData.series);
                setText(rawData.text);
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, [JSON.stringify(sets), player, JSON.stringify(appliedFilters)]);


    return (
        <Root loading={loading ? "true" : "false"}>
            <Content>
            {
                series &&
                <ContainerChart print={print}>
                    <ReactApexChart 
                        options={options ?
                            {
                                ..._options,
                                ...options,
                            }
                            :
                            {
                                ..._options, 
                                chart: {
                                    ..._options.chart,
                                    with: width,
                                    height: height
                                },
                                labels: labels
                            }
                        } 
                        width="100%"
                        height="100%"
                        series={series} 
                        type={type ? type : "radialBar"} 
                    />
                </ContainerChart>
            }
            </Content>
            {
                text &&
                <Text print={print}>{translate(text)}</Text>
            }
        </Root>
    );
}

export default SemiCircleUEChart;