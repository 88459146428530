import { useState, useEffect } from 'react';
import axios from 'axios';

const useAxios = (url) => {
    const [data, setData] = useState(null);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                const response = await axios.get(url);
                setData(response.data);
                setIsSuccess(true);
            } catch (error) {
                setIsError(true);
                setError(error);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [url]);

    return { data, setData, isError, error, isSuccess, isLoading };
};

export default useAxios;