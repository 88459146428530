import React from "react";
import styled from "styled-components";
import Steps from "./Steps";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from '../../providers/theme';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
`

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 30px 0 25px 0;
`

const Action = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${props => props.orientation === "left" ? "12.5px 25px 12.5px 20px" : "12.5px 20px 12.5px 25px"};;
    border: none;
    background: transparent;
    color: ${props => props.theme.palette.details};
    border: 1px solid ${props => props.theme.palette.details};
    margin: 0 10px;
    border-radius: 20px;
    text-transform: uppercase;
    cursor: pointer;
    &:disabled {
        opacity: 0.5;
    }
    svg {
        margin: ${props => props.orientation === "left" ? "0 5px 0 0" : "0 0 0 5px"};
    }
`

const Stepper = ({getSteps, getStepContent, checker, finishStepper}) => {

    const theme = React.useContext(ThemeContext);

    const [ actualStep, setActualStep ] = React.useState(0);

    const next = () => {
        if(checker){
            if(checker(actualStep)){
                setActualStep(state => ++state);
            }
        } else {
            setActualStep(state => ++state);
        }
    }

    const prev = () => {
        setActualStep(state => --state);
    }

    const finish = () => {
        if(checker){
            if(checker(actualStep)){
                finishStepper();
            }
        } else {
            finishStepper();
        }
    }

    return (
        <Root>
            <Steps actualStep={actualStep} steps={getSteps()} />
            <Content>
                { getStepContent(actualStep) }
            </Content>
            <Actions>
                <Action orientation="left" onClick={prev} disabled={actualStep === 0}>{theme.icons.arrowBack}{translate("back")}</Action>
                <Action onClick={actualStep === getSteps().length -1 ? finish : next}>{actualStep === getSteps().length -1 ? translate("finish") : translate("next")}{theme.icons.arrowForward}</Action>
            </Actions>
        </Root>
    );
}

export default Stepper;