import { useLoader } from "@react-three/fiber";
import * as THREE from "three";
import React from "react";
import { COURT_HEIGHT, COURT_WIDTH, REAL_COURT_HEIGHT, REAL_COURT_WIDTH } from "../courtConfig";
import { ballTextures } from "../ballTextures";

const CourtBall = (props) => {
    const colorMap = useLoader(THREE.TextureLoader, props.color ?? ballTextures.net)
    const onBall = (e) => {
        e.stopPropagation();
        getX(props.position[0], props.position[1])
    }

    const isPlayerOnUpperSide = (yCoordinatePlayer) => {
        return yCoordinatePlayer >= (REAL_COURT_HEIGHT / 2);
    }

    const getX = (valueX, playerYPosition) => {
        const result = (valueX * COURT_WIDTH / REAL_COURT_WIDTH) - (COURT_WIDTH / 2);
        if (isPlayerOnUpperSide(playerYPosition)) {
            return result;
        }
        return result* (-1);
    }

    const getY = (valueY) => {
        const result = (-valueY * COURT_HEIGHT / REAL_COURT_HEIGHT) + (COURT_HEIGHT / 2);
        if (valueY >= (REAL_COURT_HEIGHT / 2)) {
            return result * (-1);
        }
        return result;
    }

    return (
        <mesh onClick={onBall} position={[getX(props.position[0], props.playerShotPositionY), getY(props.position[1]), -4.9]} >
            <sphereBufferGeometry args={[0.3, 32, 32]} />
            <meshStandardMaterial map={colorMap} />
        </mesh>
    )
}

export default CourtBall;