import styled from "styled-components";

export const StyledCloseButton = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    outline: none;
    cursor: pointer;
`