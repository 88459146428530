import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from "../../providers/theme";
import CourtIcon from '../../assets/icons/court.svg';
import TournamentIcon from '../../assets/icons/tournament.svg';
import UmpireIcon from '../../assets/icons/umpire.svg';
import { Link } from "react-router-dom";
import { useIntl } from 'react-intl';
import { getDateUS } from '../../utils/dateUtils';

const Root = styled(Link)`
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    overflow: hidden;
    text-decoration: none;
    color: ${props => props.theme.palette.alternative_principal};
    transition: all 300ms ease-in-out;
    &:hover {
        box-shadow: 0 0 10px rgba(0,0,0,0.5);
    }
`

const Header = styled.div`
    display: grid;
    width: 100%;
    height: 40px;
    align-items: center;
    text-align: center;
    grid-template-columns: 1fr 50px;
    background: ${props => props.theme.palette.accent};
    color: ${props => props.theme.palette.principal};
`

const Id = styled.div`

`

const Vs = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: ${props => props.theme.palette.principal};
    color: ${props => props.theme.palette.accent};
`

const Won = styled.div`
    background: ${props => props.won ? props.theme.palette.accent : props.theme.palette.error};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.won ? props.theme.palette.principal : props.theme.palette.accent}
`

const Body = styled.div`
    display: flex;
    width: 100%;
    height: 310px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
`

const StyledIcon = styled.img`
  box-sizing: border-box;
  padding: 0px;
  margin-right: 0.5rem;
  border: none;
  display: block;
  width: ${(props) => props.width ? props.width : "1rem"};
  height: ${(props) => props.height ? props.height : "1rem"};
`

const MatchDate = styled.span`
    font-size: 1rem;
    margin: 10px 0 0 0;
`

const Time = styled.span`
    font-size: 1rem;
    margin: 5px 0 0 0;
`

const Score = styled.span`
    padding: 10px 20px;
    border-radius: 8px;
    background: ${props => props.theme.palette.principal};
    color: ${props => props.theme.palette.base};
    font-weight: 700;
    margin: 10px 0 0 0;
`

const Title = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    margin: 15px 0 5px 0;
    text-transform: uppercase;
    font-size: 1.15rem;
`

const MatchCard = ({data}) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    return (
        <Root to={`/admin/match/${data.id}/minimatches`}>
            <Header>
                <Vs>{`Vs. ${data.playerOpponent.name}`}</Vs>
                <Won won={data.won}>{data.won ? theme.icons.check : theme.icons.close}</Won>
            </Header>
            <Body>
                <MatchDate>{getDateUS(new Date(data.date))}</MatchDate>
                <Time>{`${data.startTime} - ${data.endTime}`}</Time>
                <Score>{data.score}</Score>
                <Title><StyledIcon src={TournamentIcon} alt="tournament icon" />{translate("tournament")}</Title>
                <span>{data.tournament && data.tournament !== "" ? data.tournament : "---"}</span>
                <Title><StyledIcon src={CourtIcon} alt="court icon" />{translate("surface")}</Title>
                <span>{data.surface && data.surface !== "" ? data.surface : "---"}</span>
                <Title><StyledIcon src={UmpireIcon} alt="umpire icon" />{translate("umpire")}</Title>
                <span>{data.umpire && data.umpire !== "" ? data.umpire : "---"}</span>
            </Body>
        </Root>
    );
}

export default MatchCard;


