import {applicationConstants} from "./applicationConstants";
import {LOCALES} from "../../../providers/i18n";



const currentApplicationSettings = localStorage.getItem(applicationConstants.APPLICATION_SETTINGS_LABEL) ?? LOCALES.ENGLISH;

const initialState = {
  language: currentApplicationSettings
};

export function applicationSettings (state = initialState, action) {
  switch (action.type) {
    case applicationConstants.SET_LANGUAGE:
      return {
        ...state,
        language: action.language
      };
    default:
      return state
  }
}