import React from "react";
import styled from "styled-components";
import translate from "../../../providers/i18n/translate";
import axios from "axios";
import { API_BACKEND } from "../../../config";
import { useAuth } from "../../../providers/authentication";
import { ThemeContext } from "../../../providers/theme";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import CourtSVG from "../../../assets/icons/court.svg";
import { getDateUS } from "../../../utils/dateUtils";

const Root = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    gap: 20px;
    background: ${props => props.theme.palette.accent};
    border-radius: 10px;
    width: 100%;
    height: auto;
    grid-column: 1/4;
    flex-direction: column;
    @media (min-width: 600px) {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        padding: 20px 20px 50px 20px;
    }
    @media (min-width: 1024px) {
        padding: 20px;
        flex-direction: row;
        gap: 20px;
    }
    @media (min-width: 1440px) {
        gap: 50px;
    }
`

const Title = styled.h2`
    font-size: 1.25rem;
    color: ${props => props.theme.palette.alternative_principal};
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    @media (min-width: 600px) {
        font-size: 1.25rem;
        width: 100%;
    }
    @media (min-width: 1024px) {
        width: auto;
        font-size: 1rem;
    }
    @media (min-width: 1440px) {
        font-size: 1.25rem;
    }
`

const Data = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    color: ${props => props.theme.palette.details};
    width: 90%;
    svg {
        color: ${props => props.theme.palette.alternative_principal};
        margin-right: 0.5rem;
        font-size: 2rem;
    }
    @media (min-width: 600px) {
        font-size: 1.5rem;
        width: 45%;
        svg {
            font-size: 3rem;
        }
    }
    @media (min-width: 1024px) {
        font-size: 0.875rem;
        max-width: 18%;
        svg {
            font-size: 1.5rem;
        }
    }
    @media (min-width: 1440px) {
        font-size: 1.25rem;
        svg {
            font-size: 2rem;
        }
    }
`

const CourtIcon = styled.img`
    width: 1.85rem;
    height: 2.25rem;
    margin-right: 0.5rem;
`

const GoMatch = styled(Link)`
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    text-decoration: none;
    color: ${props => props.theme.palette.alternative_principal};
    text-transform: uppercase;
    font-weight: 700;
    align-self: flex-end;
    svg {
        color: ${props => props.theme.palette.alternative_accent};
        margin-left: 0.5rem;
    }
    @media (min-width: 600px) {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }
    @media (min-width: 1024px) {
        position: absolute;
        bottom: 15px;
        right: 10px;
        transform: translateY(50%);
        font-size: 0.85rem;
        max-width: 15%;
        svg {
            font-size: 1.25rem;
            color: ${props => props.theme.palette.alternative_accent};
            margin-left: 0.5rem;
        }
    }
    @media (min-width: 1440px) {
        font-size: 1.25rem;
        svg {
            font-size: 2rem;
            color: ${props => props.theme.palette.alternative_accent};
            margin-left: 0.5rem;
        }
    }
`

const LastMatchChart = () => {

    const [data, setData] = React.useState(null);

    const { token } = useAuth();

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const getData = () => {
        axios
            .get(
                `${API_BACKEND}/lastMatch`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((result) => {
                setData(result.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    React.useEffect(() => {
        getData();
    }, [])
    return (
        data &&
        <Root>
            <Title>{translate("Last Match")}</Title>
            <Data>{theme.icons.tennis}{`Vs. ${data.opponentName}`}</Data>
            <Data>{theme.icons.cup}{`${data.opponentName === data.playerWinner ? intl.formatMessage({ id: 'LOSE' }) : intl.formatMessage({ id: 'WON' })} ${data.score}`}</Data>
            <Data>{theme.icons.calendar}{` ${getDateUS(new Date(data.date))}`}</Data>
            <Data><CourtIcon src={CourtSVG} alt="court_icon" />{data.surface ? data.surface : "---"}</Data>
            <GoMatch to={`/admin/match/${data.id}/performance`} >{translate("Go to match")}{theme.icons.arrowForward}</GoMatch>
        </Root>
    );
}

export default LastMatchChart;