import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import styled from "styled-components";
import translate from "../../../providers/i18n/translate";
import CheckInput from "../../inputs/CheckInput";
import { dashboardFiltersConstants } from "../../../store/reducers/dashboardFilters/dashboardFiltersConstants";
import axios from "axios";
import { useAuth } from "../../../providers/authentication";
import { API_BACKEND } from "../../../config";

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  padding: 14px;
  border-radius: 10px;
`;

const Title = styled.h2`
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  margin-bottom: 0.25rem;
`;

const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  width: 100%;
  height: auto;
  overflow-y: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 14px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => `${props.theme.palette.details}90`};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.palette.alternative_details};
  }
`;

const RoundsFilter = () => {

  const { filters } = useSelector((state) => state.selectedDashboardFilters);

  const [ roundsList, setRoundsList ] = React.useState(null);

  const { token } = useAuth();

  const dispatch = useDispatch();

  const { changeDashboardFilters } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const handleChange = (name, value) => {
    const selectedRounds = filters.find((fil) => fil.name === "round");
    const newFilters = [];
    if (selectedRounds) {
      if (selectedRounds.values.includes(name)) {
        selectedRounds.values = selectedRounds.values.filter(
          (val) => val !== name
        );
      } else {
        selectedRounds.values = [...selectedRounds.values, name];
      }
      changeDashboardFilters(dashboardFiltersConstants.SET, filters);
    } else {
      newFilters.push({
        name: "round",
        values: [name],
        condition: "equal",
        type: "string",
      });
      changeDashboardFilters(dashboardFiltersConstants.SET, [...filters, ...newFilters]);
    }
  };

  const getRounds = () => {
    axios
    .get(
      `${API_BACKEND}/matches/rounds`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((result) => {
      setRoundsList(result.data);
    })
    .catch((err) => {
      console.log(err);
    });
}

  React.useEffect(() => {
    getRounds();
  }, [])

  return (
    roundsList &&
    <Root>
      <Title>{translate("Rounds")}</Title>
      <Options>
        {roundsList.map((round, index) => (
          <CheckInput
            key={`check_round_${index}`}
            checked={
              filters.find((fil) => fil.name === "round")
                ? filters
                    .find((fil) => fil.name === "round")
                    .values.includes(round.id)
                : false
            }
            onChange={handleChange}
            label={round.name}
            name={round.id}
            width="auto"
          />
        ))}
      </Options>
    </Root>
  );
};

export default RoundsFilter;
