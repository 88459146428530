import React from "react";
import styled from "styled-components";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import { useSelector } from "react-redux";
import { formatData } from "../../components/gsmcharts/miniMatchesWithSemaphoreChart/config";
import { MiniMatchesWithSemaphoreChart } from "../gsmcharts";

const Root = styled.div`
    position: relative;
    width: 100%;
    height: 400px;
    margin: auto;
    .apexcharts-canvas {
        margin: 0 auto;
    }
`;


const Minimatches = ({onReady}) => {
  const { token } = useAuth();

  const MATCHES_LIMIT = 10;
  const NAME = "minimatches";

  const { appliedFilters } = useSelector(
    (state) => state.selectedDashboardFilters
  );
  React.useEffect(() => {}, [JSON.stringify(appliedFilters)]);

  return (
    <Root>
      <MiniMatchesWithSemaphoreChart
        print
        width={500}
        height={600}
        type="bar"
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .get(
                `${API_BACKEND}/historicalminimatches?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((result) => {
                const chartData = formatData({
                    dataMiniMatches: result.data,
                    historicalMiniMatches:{}
                });
                onReady(NAME);
                resolve(chartData);
              })
              .catch((err) => {
                console.log(err);
              });
          })
        }
      />
    </Root>
  );
};

export default Minimatches;
