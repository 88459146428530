import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
/* import axios from 'axios'; */
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Logo from "../assets/logo_b.png";
import RegisterBG from "../assets/court_home.webp";
import LoginBG from "../assets/court_home.webp";
import { useAuth } from "../providers/authentication";
import { useIntl } from "react-intl";
import translate from "../providers/i18n/translate";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../store/action-creators";
import { alertConstants } from "../store/reducers/messageBar/alertConstants";
import axios from "axios";
import { API_BACKEND } from "../config";

/* Styled components */
const Root = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-auto-rows: 100vh;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  transition: 300ms ease-in-out;
  @media (min-width: 1024px) {
    grid-template-columns: 50% 50%;
  }
`;

const Box = styled.div`
  display: ${(props) => (props.display ? props.display : "flex")};
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  opacity: ${(props) => (props.opacity ? props.opacity : 0)};
  transition: 700ms ease-in-out;
  background: linear-gradient(232deg, #03232d 0%, #03232d 30%, #40cbe6 100%);
  color: #fff;
  font-family: "museo-sans, sans-serif";
  overflow-y: auto;
  @media (min-width: 1024px) {
    display: flex;
  }
`;

const BoxHeader = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 50px;
  border-bottom: 1px solid #40cbe6;
  @media (min-width: 600px) {
    padding: 0 100px;
    justify-content: flex-start;
  }
`;

const ContentLogo = styled.div`
  display: flex;
  position: relative;
  width: auto;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  @media (min-width: 1024px) {
    width: 300px;
    height: 100%;
  }
`;

const BoxImage = styled.div`
  display: none;
  position: fixed;
  width: 50%;
  height: 100vh;
  top: 0;
  left: ${(props) => (props.pos === "left" ? 0 : "50%")};
  background: ${(props) => (props.bg ? `url(${props.bg})` : "black")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 999;
  transition: 1000ms ease-in-out;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (min-width: 1024px) {
    display: flex;
  }
`;

const BoxContent = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100% - 70px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: ${(props) =>
    props.background ? props.background : "transparent"}; */

  @media (min-width: 600px) {
    background: transparent;
  }
`;

const BoxTitle = styled.h2`
  margin: 0;
  font-weight: 400;
  width: 90%;
  font-size: 4rem;
  text-align: center;
  @media (min-width: 600px) {
    width: 60%;
    text-align: left;
    font-size: 3rem;
  }
  @media (min-width: 1024px) {
    width: 60%;
    text-align: left;
  }
`;

const BoxSubTitle = styled.h2`
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  width: 90%;
  text-align: center;
  @media (min-width: 600px) {
    width: 60%;
    text-align: left;
  }
`;

const Form = styled.form`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: auto;
  min-height: 300px;
  background: transparent;
  margin-top: 10px;
  input {
    color: white;
  }
  @media (min-width: 600px) {
    width: 70%;
  }
  @media (min-width: 1366px) {
    width: 60%;
  }
`;

const StyledTextField = styled(TextField)`
  margin: ${(props) => (props.margin ? props.margin : "15px 0!important")};
  width: 100%;
  .MuiOutlinedInput-notchedOutline {
    color: #fff !important;
  }
  fieldset {
    color: #fff;
    border-color: #fff !important;
  }
  .MuiInput-underline:before {
    border-color: rgba(255, 255, 255, 0.23) !important;
  }
  .MuiInput-underline:after {
    border-color: #fff !important;
  }
  label {
    color: #fff;
    border-color: #fff;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #fff;
  }
  @media (min-width: 600px) {
    ${(props) => (props.width ? `width: ${props.width};` : "")}
  }
`;

const StyledFormControl = styled(FormControl)`
  margin: ${(props) => (props.margin ? props.margin : "15px 0!important")};
  width: 100%;
  label {
    color: #fff;
    border-color: #fff;
  }
  .MuiIconButton-root {
    color: #fff;
    border-color: #fff !important;
  }
  .MuiInput-underline:before {
    border-color: rgba(255, 255, 255, 0.23) !important;
  }
  .MuiInput-underline:after {
    border-color: #fff !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #fff;
  }
  @media (min-width: 600px) {
    ${(props) => (props.width ? `width: ${props.width};` : "")}
  }
`;

const InputGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FooterForm = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
  @media (min-width: 600px) {
    flex-direction: row;
    height: 70px;
  }
`;

const StyledButton = styled.button`
  height: 40px;
  box-sizing: border-box;
  padding: 0 30px;
  border-radius: 25px;
  border: none;
  font-weight: 900;
  color: ${(props) => (props.color ? props.color : "#000")};
  background: ${(props) =>
  props.background ? props.background : "transparent"};
  cursor: pointer;
  margin-bottom: 15px;
  @media (min-width: 600px) {
    margin-bottom: 0;
    height: 50px;
    padding: 0 40px;
  }
  @media (min-width: 1366px) {
    padding: 0 50px;
  }
`;

const RegisterAccess = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media (min-width: 600px) {
    font-size: 0.75rem;
  }
`;

const RegisterAccessButton = styled.button`
  border: none;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
  padding: 0;
  color: #dcfe54;
  align-self: center;
`;

const Error = styled.span`
  color: red;
  opacity: 0.8;
  font-size: 1.25rem;
`;

const Login = () => {
  const intl = useIntl();

  const error = null;

  let navigate = useNavigate();

  const { onLogin } = useAuth();

  const [registerError, setRegisterError] = React.useState("");

  const dispatch = useDispatch();
  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const [showPassword, setShowPassword] = React.useState({
    password: false,
    repassword: false,
    loginPassword: false,
  });

  const [value, setValue] = React.useState("right");

  const handleBoxImage = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const handleClickShowPassword = (e) => {
    const { name } = e.currentTarget;
    setShowPassword({
      ...showPassword,
      [name]: !showPassword[name],
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [registerFormData, setRegisterFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    repassword: "",
    playerName: "",
  });

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (value.includes(" ") || value.includes("*") || value.includes("/")) {
      alertMessage(
        intl.formatMessage({ id: "Blank space, *, / are not allowed." }),
        alertConstants.ERROR
      );
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const submitLogin = (e) => {
    e.preventDefault();
    onLogin({ userName: formData.username, password: formData.password }, () =>
      navigate("/admin/dashboard")
    );
  };

  const onChangeRegister = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setRegisterFormData({
      ...registerFormData,
      [name]: value,
    });
  };

  const onRegister = (e) => {
    e.preventDefault();
    if (registerFormData.password === registerFormData.repassword) {
      var formdata = new FormData();
      formdata.append("firstName", registerFormData.firstName);
      formdata.append("lastName", registerFormData.lastName);
      formdata.append("username", registerFormData.username);
      formdata.append("email", registerFormData.email);
      formdata.append("password", registerFormData.password);
      formdata.append("playerName", registerFormData.playerName);
      const url = `${API_BACKEND}/users`;
      axios
        .post(url, formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          alertMessage(
            intl.formatMessage({ id: "account created" }),
            alertConstants.INFO
          );
          setRegisterFormData({
            firstName: "",
            lastName: "",
            email: "",
            username: "",
            password: "",
            repassword: "",
            playerName: "",
          });
          setValue("right");
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            alertMessage(error.response.data.msg, alertConstants.ERROR);
            setRegisterError(error.response.data.msg);
          }
        });
    } else {
      alertMessage(
        intl.formatMessage({ id: "passwords must match" }),
        alertConstants.ERROR
      );
    }
  };

  return (
    <Root>
      <BoxImage
        pos={value}
        bg={value === "left" ? RegisterBG : LoginBG}
      ></BoxImage>
      <Box
        opacity={value === "right" ? 1 : 0}
        display={value === "right" ? "flex" : "none"}
      >
        <BoxHeader>
          <ContentLogo>
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </ContentLogo>
        </BoxHeader>
        <BoxContent background="linear-gradient(225deg, #DCFE54 0%, transparent 50%);">
          <BoxTitle>
            <FormattedMessage id="app.login.welcome" defaultMessage="Welcome" />
          </BoxTitle>
          <BoxSubTitle>
            <FormattedMessage
              id="app.login.loginaccount"
              defaultMessage="Login to your account"
            />
          </BoxSubTitle>
          <Form onSubmit={submitLogin}>
            {error && <Error>{error}</Error>}
            <StyledTextField
              fullWidth
              required
              margin="dense"
              id="username"
              name="username"
              label={intl.formatMessage({ id: "username" })}
              type="text"
              value={formData.username}
              onChange={onChange}
              variant="standard"
            />
            <StyledFormControl variant="standard">
              <InputLabel htmlFor="loginpassword">
                {translate("password")}
              </InputLabel>
              <Input
                fullWidth
                required
                id="loginpassword"
                name="password"
                type={showPassword.loginPassword ? "text" : "password"}
                autoComplete="current-password"
                value={formData.password}
                onChange={onChange}
                variant="standard"
                margin="dense"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      name="loginPassword"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword.loginPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </StyledFormControl>
            <FooterForm>
              <StyledButton background="#DCFE54" type="submit">
                <FormattedMessage
                  id="app.login.signin"
                  defaultMessage="Sign in"
                />
              </StyledButton>
              {/* <RegisterAccess>
                <span>
                  <FormattedMessage
                    id="app.login.donthaveaccount"
                    defaultMessage="Still don't have an account?"
                  />
                </span>
                <RegisterAccessButton value="left" onClick={handleBoxImage}>
                  <FormattedMessage
                    id="app.login.signup"
                    defaultMessage="Sign up here"
                  />
                </RegisterAccessButton>
              </RegisterAccess> */}
            </FooterForm>
          </Form>
        </BoxContent>
      </Box>
      <Box
        opacity={value === "right" ? 0 : 1}
        display={value === "right" ? "none" : "flex"}
      >
        <BoxHeader>
          <ContentLogo>
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </ContentLogo>
        </BoxHeader>
        <BoxContent background="linear-gradient(225deg, #40CBE6 0%, transparent 50%);">
          <BoxTitle>
            <FormattedMessage
              id="app.register.welcome"
              defaultMessage="Let's start"
            />
          </BoxTitle>
          <BoxSubTitle>
            <FormattedMessage
              id="app.register.registeraccount"
              defaultMessage="Create your account"
            />
          </BoxSubTitle>
          <Form onSubmit={onRegister}>
            {registerError !== "" && <Error>{registerError}</Error>}
            <InputGroup>
              <StyledTextField
                width="47.5%"
                required
                id="firstName"
                name="firstName"
                label={intl.formatMessage({ id: "firstname" })}
                type="text"
                value={registerFormData.firstName}
                onChange={onChangeRegister}
                variant="standard"
              />
              <StyledTextField
                width="47.5%"
                required
                id="lastName"
                name="lastName"
                label={intl.formatMessage({ id: "lastname" })}
                type="text"
                value={registerFormData.lastName}
                onChange={onChangeRegister}
                variant="standard"
              />
            </InputGroup>
            <InputGroup>
              <StyledTextField
                width="47.5%"
                required
                id="email"
                name="email"
                label={intl.formatMessage({ id: "email" })}
                type="text"
                value={registerFormData.email}
                onChange={onChangeRegister}
                variant="standard"
              />
              <StyledTextField
                width="47.5%"
                required
                id="playerName"
                name="playerName"
                label={intl.formatMessage({ id: "player name" })}
                type="text"
                value={registerFormData.playerName}
                onChange={onChangeRegister}
                variant="standard"
              />
            </InputGroup>
            <StyledTextField
              fullWidth
              required
              id="username"
              name="username"
              label={intl.formatMessage({ id: "username" })}
              type="text"
              value={registerFormData.username}
              onChange={onChangeRegister}
              variant="standard"
            />
            <InputGroup>
              <StyledFormControl variant="standard">
                <InputLabel htmlFor="password">
                  {translate("password")}
                </InputLabel>
                <Input
                  width="47.5%"
                  required
                  id="password"
                  name="password"
                  label={intl.formatMessage({ id: "password" })}
                  type={showPassword.password ? "text" : "password"}
                  value={registerFormData.password}
                  autoComplete="current-password"
                  onChange={onChangeRegister}
                  variant="standard"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        name="password"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword.password ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </StyledFormControl>
              <StyledFormControl variant="standard">
                <InputLabel htmlFor="repassword">
                  {translate("repeatPassword")}
                </InputLabel>
                <Input
                  width="47.5%"
                  required
                  id="repassword"
                  name="repassword"
                  label={intl.formatMessage({ id: "repeatPassword" })}
                  type={showPassword.repassword ? "text" : "password"}
                  value={registerFormData.repassword}
                  onChange={onChangeRegister}
                  variant="standard"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        name="repassword"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword.repassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </StyledFormControl>
            </InputGroup>
            <FooterForm>
              <StyledButton color="#fff" background="#40CBE6" type="submit">
                <FormattedMessage
                  id="app.register.signup"
                  defaultMessage="Sign up"
                />
              </StyledButton>
              <RegisterAccess>
                <span>
                  <FormattedMessage
                    id="app.register.haveanaccount"
                    defaultMessage="Already have an account?"
                  />
                </span>
                <RegisterAccessButton value="right" onClick={handleBoxImage}>
                  <FormattedMessage
                    id="app.register.signin"
                    defaultMessage="Sign in here"
                  />
                </RegisterAccessButton>
              </RegisterAccess>
            </FooterForm>
          </Form>
        </BoxContent>
      </Box>
    </Root>
  );
};

export default Login;
