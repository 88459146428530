import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { MiniMatchesWithSemaphoreChart } from "../components/gsmcharts";
import { formatData } from "../components/gsmcharts/miniMatchesWithSemaphoreChart/config";
import axios from "axios";
import { API_BACKEND } from "../config";
import { useAuth } from "../providers/authentication";
import { useParams } from "react-router-dom";
import { matchDataConstants } from "../store/reducers/matchData/matchDataConstants";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../store/action-creators";
import translate from "../providers/i18n/translate";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  padding: 20px 0;
  @media (min-width: 1024px) {
    padding: 50px 0;
  }
`;

const ContainerChart = styled.div`
  position: relative;
    width: 100%;
    height: 250px;
    margin: auto;
    .apexcharts-canvas {
        margin: 0 auto;
    }
    @media (min-width: 600px) {
        height: 400px;    
    }
    @media (min-width: 1024px) {
        height: 250px;    
    }
    @media (min-width: 1440px) {
        height: 350px;    
    }
`

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.details};
  font-size: 1.25rem;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 1.5rem;
    text-align: left;
  }
`;

export default function MiniMatchesPage() {
  const intl = useIntl();

  const { token } = useAuth();

  const { id } = useParams();

  const { sets } = useSelector((state) => state.selectedSets);
  const { player } = useSelector((state) => state.selectedPlayer);

  const dispatch = useDispatch();
  const { switchPlayers, switchSets } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const getSetsList = (s) => {
    let setlist = "";
    for (let index = 0; index < sets.length; index++) {
      if (sets[index] === true) {
        setlist = setlist + (index + 1);
        if (sets.length > 1 && index !== sets.length - 1) {
          setlist = setlist + ",";
        }
      }
    }
    return setlist;
  };

  React.useEffect(() => {
    switchPlayers(matchDataConstants.SWITCH_PLAYERS, true);
    switchSets(matchDataConstants.SWITCH_SETS, true);
  }, []);

  return (
    sets &&
    sets.length > 0 && (
      <Root>
        <Title>{translate("minimatches")}</Title>
        <ContainerChart>
          <MiniMatchesWithSemaphoreChart
            sets={sets}
            player={player}
            width={500}
            height={600}
            type="bar"
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/minimatches?idMatch=${id}&sets=${getSetsList(
                      sets
                    )}&playerNumber=${player}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    const chartData = formatData(result.data);
                    resolve(chartData);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }
          />
        </ContainerChart>
      </Root>
    )
  );
}
