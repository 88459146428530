/* General imports */
import React from 'react';
import styled from 'styled-components';
import InputImage from '../../components/inputs/inputImage';
import TextField from '@mui/material/TextField';
import BirthdayInput from '../../components/inputs/birthdayInput';
import CountryInput from '../../components/inputs/countryInput';
import axios from 'axios';
import { API_BACKEND } from '../../config';
import { useIntl } from 'react-intl';
import ChangePassword from './ChangePassword';
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";

/* Styled components */
const Root = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    min-height: 100px;
    box-sizing: border-box;
    @media (min-width: 600px) {
      display: grid;
      grid-template-columns: 0.3fr 0.35fr 0.35fr;
      gap: 10px;
      align-items: flex-start;
      justify-content: center;
    }
`

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-column: inherit;
    grid-row: inherit;
    @media (min-width: 600px) {
      grid-column: 1/2;
      grid-row: 1/5;
    }
`

const StyledTextField = styled(TextField)`
  margin: ${(props) => props.margin ? props.margin : '15px 0!important'};
  width: 100%;
  order: ${(props) => props.order ? props.order : 0};
  @media (min-width: 600px) {
    ${(props) => props.width ? `width: ${props.width};` : ''}
    order: none;
  }
`

// PROPS: setChanges, idUser
const PersonalSettings = React.forwardRef((props, ref) => {

    const intl = useIntl();

    const [personalSettingsData, setPersonalSettingsData] = React.useState(null);

    const dispatch = useDispatch();
    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setPersonalSettingsData({
            ...personalSettingsData,
            [name]: value
        });
        props.setChanges(true);
    }

    const onChangeValue = (name, value) => {
        setPersonalSettingsData({
            ...personalSettingsData,
            [name]: value
        });
        props.setChanges(true);
    }


    const onChangeUserPhoto = (files) => {
        if (files.length > 0) {
            setPersonalSettingsData({
                ...personalSettingsData,
                photo: files[0]
            });
        }
        props.setChanges(true);
    }

    const onResetUserPhoto = () => {
        setPersonalSettingsData({
            ...personalSettingsData,
            photo: null
        });
        props.setChanges(true);
    }

    const onSave = () => {
        var formdata = new FormData();
        formdata.append("firstName", personalSettingsData.firstName);
        formdata.append("lastName", personalSettingsData.lastName);
        formdata.append("dateBirth", personalSettingsData.dateBirth);
        formdata.append("country", personalSettingsData.country);
        formdata.append("username", personalSettingsData.username);
        formdata.append("email", personalSettingsData.email);
        formdata.append("phone", personalSettingsData.phone);
        formdata.append("playerName", personalSettingsData.playerName);
        formdata.append("photo", personalSettingsData.photo);
        const url = `${API_BACKEND}/users/${props.idUser}`;
        axios.put(url, formdata,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            props.setChanges(false);
            /* if (openQuitModal) {
              handleCloseQuitModal()
              handleOpenSaveModal()
              setChanges(false);
              router.back()
            }
            else {
              handleOpenSaveModal()
              setChanges(false);
            } */
          })
          .catch(error => {
            console.log(error);
            alertMessage(error.response.data.msg, alertConstants.ERROR);
          })
    }

    React.useEffect(() => {
        axios.get(`${API_BACKEND}/users/${props.idUser}`)
        .then(result => {
            setPersonalSettingsData({
                ...result.data,
                playerName: result.data.associatedPlayer.name
            });
        })
        .catch(err => {
            console.log(err);
        })
    }, [])

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    React.useImperativeHandle(ref, () => ({
        submit() {
            onSave();
        }
    }));


    return (
        personalSettingsData &&
        <Root>
            <ContentBox>
                <InputImage file={personalSettingsData.photo ? `${API_BACKEND}/users/image/${props.idUser}` : null} onChange={onChangeUserPhoto} onReset={onResetUserPhoto} />
                <ChangePassword idUser={props.idUser} />
            </ContentBox>
            <StyledTextField
                fullWidth
                required
                id="firstName"
                name="firstName"
                label={intl.formatMessage({ id: 'firstname' })}
                type='text'
                value={personalSettingsData.firstName}
                onChange={onChange}
                variant="outlined"
                order={1}
            />
            <StyledTextField
                fullWidth
                required
                id="username"
                name="username"
                label={intl.formatMessage({ id: 'username' })}
                type='text'
                value={personalSettingsData.username}
                onChange={onChange}
                variant="outlined"
                order={3}
            />
            <StyledTextField
                fullWidth
                required
                id="email"
                name="email"
                label={intl.formatMessage({ id: 'email' })}
                type='email'
                value={personalSettingsData.email}
                onChange={onChange}
                variant="outlined"
                order={5}
            />
            <BirthdayInput
                name="dateBirth"
                date={personalSettingsData.dateBirth}
                label={intl.formatMessage({ id: 'datebirth' })}
                handleDateChange={onChangeValue}
                margin="15px 0"
                order={7} />
            <StyledTextField
                fullWidth
                required
                id="lastName"
                name="lastName"
                label={intl.formatMessage({ id: 'lastname' })}
                type='text'
                value={personalSettingsData.lastName}
                onChange={onChange}
                variant="outlined"
                order={2}
            />
            <StyledTextField
                fullWidth
                required
                id="playerName"
                name="playerName"
                label={intl.formatMessage({ id: 'playername' })}
                type='text'
                value={personalSettingsData.playerName}
                onChange={onChange}
                variant="outlined"
                order={4}
            />
            <StyledTextField
                fullWidth
                id="phone"
                name="phone"
                label={intl.formatMessage({ id: 'phone' })}
                type='text'
                value={personalSettingsData.phone}
                onChange={onChange}
                variant="outlined"
                order={6}
            />
            <CountryInput
                name="country"
                value={personalSettingsData.country}
                onChangeCountry={onChangeValue}
                label={intl.formatMessage({ id: 'country' })}
                order={8}
            />
        </Root>
    );
})

export default PersonalSettings;