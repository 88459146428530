import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import Submenu from "../../components/layouts/MatchLayout/Submenu";
import { Link, Route, Routes } from "react-router-dom";
import { useAuth } from "../../providers/authentication";
import { decodeToken } from "../../hooks/jwt";
import Header from "../PlayerProfilePage/Header";
import Filters from "../../components/dashboardFilters";
import Rally from "./rally";
import Serve from "./serve";
import Return from "./return";
import Sem from "./sem";
import Rem from "./rem";
import UnforcedErrors from "./unforcedErrors";
import Minimatches from "./minimatches";
import Overview from "./overview";
import translate from "../../providers/i18n/translate";
import TotalPoints from "./TotalPoints";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useSelector } from "react-redux";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  max-width: ${(props) => `${props.theme.breakpoints.desktopPlus}px`};
  margin: 0 auto;
  @media (min-width: 1024px) {
    padding: 0 50px;
  }
`;

const NoMatches = styled.div`
  width: 100%;
  height: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: ${(props) => props.theme.palette.details};
  margin: 50px 0;
  font-size: 1.25rem;
`;

const GoUploadMatch = styled(Link)`
  color: ${(props) => props.theme.palette.alternative_details};
`;

export default function DashboardPage() {
  const intl = useIntl();

  const submenues = [
    {
      name: intl.formatMessage({ id: "performance" }),
      link: `/admin/summary`,
      exact: true,
    },
    {
      name: intl.formatMessage({ id: "P1" }),
      link: `/admin/summary/unforcederrors`,
    },
    {
      name: intl.formatMessage({ id: "M4" }),
      link: `/admin/summary/minimatches`,
    },
    {
      name: intl.formatMessage({ id: "rally" }),
      link: `/admin/summary/rally`,
    },
    {
      name: intl.formatMessage({ id: "serve" }),
      link: `/admin/summary/serve`,
    },
    {
      name: intl.formatMessage({ id: "sem" }),
      link: `/admin/summary/sem`,
    },
    {
      name: intl.formatMessage({ id: "return" }),
      link: `/admin/summary/return`,
    },
    {
      name: intl.formatMessage({ id: "rem" }),
      link: `/admin/summary/rem`,
    },
  ];

  const { token } = useAuth();

  const userData = decodeToken(token);

  const MATCHES_LIMIT = 10;

  const { appliedFilters } = useSelector(state => state.selectedDashboardFilters);

  return (
    userData && (
      <Root>
        <Header id={userData?.id} />
        {userData.numberMatches > 0 ? (
          <React.Fragment>
            <TotalPoints filters={JSON.stringify(appliedFilters)} data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `${API_BACKEND}/historicalmatchestotalpoints?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((result) => {
                    resolve(result.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            }> </TotalPoints>
            <Filters />
            <Submenu data={submenues} />
            <Routes>
              <Route index element={<Overview />} />
              <Route path="/minimatches" element={<Minimatches />} />
              <Route path="/unforcederrors" element={<UnforcedErrors />} />
              <Route path="/rally" element={<Rally />} />
              <Route path="/serve" element={<Serve />} />
              <Route path="/return" element={<Return />} />
              <Route path="/sem" element={<Sem />} />
              <Route path="/rem" element={<Rem />} />
            </Routes>
          </React.Fragment>
        ) : (
          <NoMatches>
            <h2>{translate("No matches loaded")}...</h2>
            <GoUploadMatch to="/admin/uploadmatch">
              {translate("Go to upload match")}
            </GoUploadMatch>
          </NoMatches>
        )}
      </Root>
    )
  );
}
