import React from "react";
import styled from "styled-components";
import Step from "./Step";

const Root = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(${props => props.steps}, 1fr);
    padding: 25px 0;
    text-transform: uppercase;
    font-size: 0.75rem;
`

const Steps = ({actualStep, steps}) => {

    return (
        steps &&
        <Root steps={steps.length}>
            {
                steps && steps.map((label, index) => 
                    <Step key={`step_${index}`} index={index} active={actualStep >= index} label={label} />
                )
            }
        </Root>
    );
}

export default Steps;