import axios from "axios";
import React from "react";
import styled from "styled-components";
import { API_BACKEND } from "../../../config";
import { useAuth } from "../../../providers/authentication";
import translate from "../../../providers/i18n/translate";
import { useIntl } from "react-intl";
import { ThemeContext } from "../../../providers/theme";
import { decodeToken } from "../../../hooks/jwt";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import {useFetch} from "../../../hooks/useFetch";

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;

const Title = styled.h2`
  text-transform: uppercase;
  font-size: 1.5rem;
  color: ${(props) => props.theme.palette.base};
  font-weight: 600;
`;

const Subtitle = styled.h4`
  margin: 0 0 2px 0;
  text-transform: uppercase;
  font-weight: 600;
  color: ${(props) => props.theme.palette.principal};
`;

const DataInput = styled.input`
  color: ${(props) => props.theme.palette.principal};
  width: 100%;
  padding: 8px 10px;
  background: ${(props) =>
    props.editing ? `${props.theme.palette.base}AA` : "transparent"};
  border: ${(props) => (props.editing ? "inherit" : "none")};
  outline: none;
`;

const DataSelectInput = styled.select`
  color: ${(props) => props.theme.palette.principal};
  width: 100%;
  padding: 8px 10px;
  background: ${(props) =>
    props.editing ? `${props.theme.palette.base}AA` : "transparent"};
  border: ${(props) => (props.editing ? "inherit" : "none")};
  outline: none;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.palette.details};
  margin: 0 auto;
  opacity: 0.5;
`;

const Actions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  width: 40%;
  height: auto;
`;

const Icon = styled.button`
  border: none;
  background: transparent;
  color: ${(props) => props.theme.palette.base};
  cursor: pointer;
  svg {
    font-size: 2rem;
  }
`;

const ProfileData = () => {
  const [data, setData] = React.useState(null);
  const [oldData, setOldData] = React.useState(null);

  const { token } = useAuth();
  const theme = React.useContext(ThemeContext);

  const [editing, setEditing] = React.useState(false);
  const [changes, setChanges] = React.useState(false);

  const userData = decodeToken(token);

  const [surfaces, loadingSurfaces] = useFetch(`${API_BACKEND}/matches/surfaces`);

  const dispatch = useDispatch();
  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const intl = useIntl();

  const switchEditing = () => {
    if (editing && changes) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(intl.formatMessage({ id: "confirm changes" }))) {
        setEditing(false);
        var formdata = new FormData();
        formdata.append("skillfulHand", data.hand);
        formdata.append("oneHandedBackhand", data.backhand);
        formdata.append("favoriteSurface", data.favouriteSurface);
        formdata.append("federation", data.federation);
        formdata.append("category", data.category);
        axios
          .put(
            `${API_BACKEND}/players/${userData?.trainedPlayer?.id}`,
            formdata,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            alertMessage(
              intl.formatMessage({ id: "changes saved" }),
              alertConstants.INFO
            );
          })
          .catch((err) => {
            console.log(err);
            alertMessage(err.response.data.msg, alertConstants.ERROR);
          });
      }
    } else {
      if (!editing) {
        setOldData({ ...data });
        setEditing(true);
      } else {
        setData({ ...oldData });
        setEditing(false);
      }
    }
  };

  const cancelEditing = () => {
    setData({
      ...oldData,
    });
    setEditing(false);
  };

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChanges(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  React.useEffect(() => {
    const getData = () => {
      axios
        .get(`${API_BACKEND}/trainedplayerprofile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          setData({
            hand: result.data.hand,
            backhand: result.data.backhand,
            favouriteSurface: result.data.favouriteSurface,
            federation: result.data.federation,
            category: result.data.category,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, [token]);

  return (
    data && (
      <Root>
        <Actions>
          {editing && <Icon onClick={cancelEditing}>{theme.icons.close}</Icon>}
          <Icon onClick={switchEditing}>
            {editing ? theme.icons.check : theme.icons.edit}
          </Icon>
        </Actions>
        <Title>{translate("Profile")}</Title>
        <Subtitle>{translate("Hand")}</Subtitle>
        <DataInput
          value={data.hand}
          name="hand"
          onChange={handleChange}
          editing={editing}
          disabled={!editing}
          placeholder={
            editing
              ? intl.formatMessage({ id: "Add hand" })
              : intl.formatMessage({ id: "Empty" })
          }
        />
        <Divider />
        <Subtitle>{translate("Backhand")}</Subtitle>
        <DataSelectInput
          name="backhand"
          id="backhand"
          onChange={handleChange}
          editing={editing}
          disabled={!editing}
          value={data.backhand}
          placeholder={
            editing
              ? intl.formatMessage({ id: "Add backhand" })
              : intl.formatMessage({ id: "Empty" })
          }
        >
          <option value={null}>---</option>
          <option value={true}>One Handed</option>
          <option value={false}>Two Handed</option>
        </DataSelectInput>
        <Divider />
        <Subtitle>{translate("Favourite surface")}</Subtitle>
        <DataSelectInput
          name="favouriteSurface"
          id="favouriteSurface"
          onChange={handleChange}
          editing={editing}
          disabled={!editing}
          value={data.favouriteSurface}
          placeholder={
            editing
              ? intl.formatMessage({ id: "Add favourite surface" })
              : intl.formatMessage({ id: "Empty" })
          }
        >
          <option value={null}>---</option>
          {
            !loadingSurfaces &&
            surfaces.map(sur => 
              <option key={`${sur.value}_option`} value={sur.value}>{sur.name}</option>
              )
          }
        </DataSelectInput>
        <Divider />
        <Subtitle>{translate("Federation")}</Subtitle>
        <DataInput
          value={data.federation}
          name="federation"
          onChange={handleChange}
          editing={editing}
          disabled={!editing}
          placeholder={
            editing
              ? intl.formatMessage({ id: "Add federation" })
              : intl.formatMessage({ id: "Empty" })
          }
        />
        <Divider />
        <Subtitle>{translate("Category")}</Subtitle>
        <DataInput
          value={data.category}
          name="category"
          onChange={handleChange}
          editing={editing}
          disabled={!editing}
          placeholder={
            editing
              ? intl.formatMessage({ id: "Add category" })
              : intl.formatMessage({ id: "Empty" })
          }
        />
      </Root>
    )
  );
};

export default ProfileData;
