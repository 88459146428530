import { THEMES } from "../../../providers/theme/themes";

export const _options = {
  chart: {
    type: "bar",
    width: 500,
    height: 800,
    foreColor: THEMES.default.palette.details,
    toolbar: {
      show: false,
    },
  },
  colors: ["#D5B4B4", "#8EA7E9"],
  plotOptions: {
    bar: {
      borderRadius: 10,
      horizontal: true,
      barHeight: "80%",
      borderRadiusApplication: 'end',
      dataLabels: {
        position: "center",
        orientation: "horizontal",
      },
      distributed: true,
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: "10px",
      fontWeight: 'bold'
    },
    formatter: function (val, opts) {
      let dataArray = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].y
      if (typeof (dataArray) === 'object')
        return `${dataArray[2]}% (${dataArray[0]}/${dataArray[1]})`
      else
        return val
    },
  },
  xaxis: {
    show: false,
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    }
  },
  yaxis: {
    show: false,
    axisBorder: {
      show: false,
    }
  },
  grid: {
    show: false,
    xaxis: {
      lines: {
        show: false
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    },
  },
  legend: {
    horizontalAlign: "center",
    markers: {
      radius: 10,
    },
    labels: {
      colors: THEMES.default.palette.base,
    },
  },
  tooltip: {
    enabled: true,
  },
  responsive: [
    {
      breakpoint: THEMES.default.breakpoints.tablet,
      options: {
        chart: {
          width: 200,
          height: 200,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            barHeight: "50%",
          }
        }
      },
    },
    {
      breakpoint: THEMES.default.breakpoints.desktop,
      options: {
        chart: {
          width: 175,
          height: 175,
        },
      },
    },
    {
      breakpoint: 1025,
      options: {
        chart: {
          width: 175,
          height: 175,
        },
      },
    },
    {
      breakpoint: THEMES.default.breakpoints.desktopPlus,
      options: {
        chart: {
          width: 200,
          height: 200,
        },
      },
    },
  ],
};

export const formatData = (data) => {
  const result = {
    series: [
      {
        name: "Valor",
        data: [
          {
            x: "Deuce",
            y: data.deuce
          },
          {
            x: "Ad",
            y: data.ad
          }
        ],
      },
    ],
    categories: [
      "Deuce",
      "Ad",
    ],
    semaphore: {
      sum: data.semaphore,
      msg: "",
    },
  };

  return result;
};
