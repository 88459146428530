/* General imports */
import React from 'react';
import styled, { keyframes } from 'styled-components';


const loading = (w) => keyframes`
  0% {
    width: 0;
  } 
  100% {
    width: ${w}%;
  }
`

const Tooltip = styled.div`
    position: absolute;
    display: flex;
    opacity: 0;
    align-items: center;
    justify-content: center;
    bottom: 100%;
    left: 50%;
    transform: ${(props) => props.invert ? 'translateX(-50%) scale(-1, 1)' : 'translateX(-50%)' };
    width: 30px;
    height: 0;
    background-color: rgba(255,255,255,0.85);
    transition: all 500ms ease-in-out;
    line-height: 1rem;
    font-size: 0.75rem;
    overflow-y: hidden;
    text-transform: uppercase;
    border-radius: 50%;
    z-index: 99;
`

const Root = styled.div`
    position: relative;
    display: ${(props) => props.show ? 'flex' : 'none'};
    width: 0;
    animation: ${props => loading(props.value)} 2s linear forwards;
    height: 100%;
    border-radius: 0 20px 20px 0;
    background-color: #B6BFC1;
    box-sizing: border-box;
    opacity: 0.45;
    &:hover {
        opacity: 1;
        ${Tooltip} {
            height: 30px;
            opacity: 1;
        }
    }
`

const Bar = ({value, invertTooltip, show}) => {
    return (
        value > 0 ?
        <Root value={value} show={show}>
            {
                value && 
                <Tooltip invert={invertTooltip}>
                    {value > 0 ? value : ''}
                </Tooltip>
            }
        </Root>
        :
        null
    );
}

export default Bar;