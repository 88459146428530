import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";
import { _options } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";

const Root = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    opacity: ${props => props.loading === "true" ? 0.3 : 1};
    padding: 20px;
`

const ContainerChart = styled.div`
    width: 100%;
    height: 250px;
    margin: auto;
    .apexcharts-canvas {
        margin: 0 auto;
    }
    @media (min-width: 600px) {
        height: 400px;    
    }
    @media (min-width: 1024px) {
        height: 250px;    
    }
    @media (min-width: 1440px) {
        height: 350px;    
    }
`


const LineAnnotationsChart = ({data, rawData, width, height, options, type, simulation}) => {

    const [ series, setSeries ] = React.useState(null);
    const [ categories, setCategories ] = React.useState(null);
    const [colors, setColors] = React.useState(null);
    const [ annotations, setAnnotations ] = React.useState(null);
    const [ loading, setLoading ] = React.useState(false);

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);
    const {appliedFilters} = useSelector(state => state.selectedDashboardFilters);

    const getData = () => {
        setLoading(true);
        if(data){
            data()
            .then(result => {
                setSeries(result.series);
                setCategories(result.categories);
                setColors(result.colors)
                //setAnnotations(result.annotations);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                alertMessage(err.response.data.msg, alertConstants.ERROR);
                setLoading(false);
            })
        } else {
            if(rawData){
                setSeries(rawData.series);
                setCategories(rawData.categories);
                setAnnotations(rawData.annotations);
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, [JSON.stringify(appliedFilters), JSON.stringify(simulation)]);


    return (
        <Root loading={loading ? "true" : "false"}>
            <ContainerChart>
                {
                    series && categories && 
                    <ReactApexChart 
                        options={options ?
                            {
                                ..._options,
                                xaxis: {
                                    ..._options.xaxis, 
                                    categories: categories
                                },
                                colors: colors,
                                chart: {
                                    ..._options.chart,
                                    with: width,
                                    height: height
                                },
                                annotations: annotations ? {..._options.annotations, ...annotations} : {...annotations},
                                ...options,
                            }
                            :
                            {
                                ..._options, 
                                xaxis: {
                                    ..._options.xaxis, 
                                    categories: categories
                                },
                                colors: colors,
                                chart: {
                                    ..._options.chart,
                                    with: width,
                                    height: height
                                },
                                annotations: annotations ? {..._options.annotations, ...annotations} : {...annotations}
                            }
                        } 
                        width="100%"
                        height="100%"
                        series={series} 
                        type={type ? type : "line"} 
                    />
                }
            </ContainerChart>
        </Root>
    );
}

export default LineAnnotationsChart;