import React from "react";
import styled from 'styled-components';
import {Table, SearchInput} from "../components/tables/table";
import translate from "../providers/i18n/translate";
import { ThemeContext } from "../providers/theme";
import axios from 'axios';
import { useIntl } from 'react-intl';
import { API_USERMANAGER } from "../config";
import { Modal } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import NewUser from "../components/user/NewUser";
import ChangeUserPassword from "../components/user/ChangeUserPassword";

/* Styled components */
const Root = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50px 1fr;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    @media (min-width: 1024px) {
        padding: 0 50px;
    }
`

const Title = styled.h2`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    color: ${(props) => props.color ? props.color : '#000'};
    border-bottom: 1px solid rgba(0,0,0,0.2);
`

const Content = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
`

const NewIcon = styled.div`
    position: sticky;
    right: 20px;
    top: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 50%;
    background-color: ${props => props.bg ? props.bg : "7A7A7A"};
    color: ${props => props.color ? props.color : "#FFF"};
    cursor: pointer;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    &:hover {
        transform: scale(1.1);
    }
    z-index: 99;
    @media (min-width: 1024px) {
        right: 50px;
        bottom: 50px;
    }
`

export default function UsersPage()  {

    const theme = React.useContext(ThemeContext);

    const tableRef = React.useRef();

    const intl = useIntl();

    const [ search, setSearch ] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openEditPassword, setOpenEditPassword] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        tableRef.current.clean();
        setOpen(false);
    }
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => {
        tableRef.current.clean();
        setOpenEdit(false);
    }

    const handleOpenEditPassword = () => setOpenEditPassword(true);
    const handleCloseEditPassword = () => {
        tableRef.current.clean();
        setOpenEditPassword(false);
    }

    const [ editUser, setEditUser ] = React.useState(null);

    const [ userPasswordData, setUserPasswordData ] = React.useState(null);
    

    const columnsTable = [
        /* { title: 'ID', field: 'id',width: "10%", order: true, hidden: false}, */
        { title: translate("firstName"), field: 'firstName', order: true, width: "30%",  hidden: false },
        { title: translate("lastName"), field: 'lastName', order: true, width: "30%", hidden: false },
        { title: translate("email"), field: 'email',width: "30%", hidden: false, mobile: false },
        { title: translate("actions"), actions: true, width: "auto", hidden: false }
    ]

    const handleNewItem = (data) => {
        handleOpen();
    }
    
    const handleEditItem = (data) => {
        setEditUser(data);
        handleOpenEdit()
    }

    const handleDeleteItem = (data) => {
        // eslint-disable-next-line no-restricted-globals
        if( confirm(intl.formatMessage({ id: 'confirm delete' })) ){
            axios.delete(`${API_USERMANAGER}/api/users/user?id=${data.id}&softdelete=true`, { withCredentials: true })
            .then(result => {
                tableRef.current.clean();
            })
            .catch(err => {
                console.log(err);
            })
        }
    }

    const onSearchChange = (value) => {
        setSearch(value);
    }

    const handleSearch = () => {
        tableRef.current.clean();
    }

    const handleChangePassword = (data) => {
        setUserPasswordData(data);
        handleOpenEditPassword();
    }

    const actionsTable={
        changepassword: {
            icon: theme.icons.password,
            tooltip: 'Cambiar contraseña',
            onClick: handleChangePassword,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: false
        },
        edit: {
            icon: theme.icons.edit,
            tooltip: 'Editar',
            onClick: handleEditItem,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: false
        },
        delete: {
            icon: theme.icons.delete,
            tooltip: 'Eliminar',
            onClick: handleDeleteItem,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: false
        }
    }

    return(
        <Root>
            <Title color={theme.palette.principal}>{translate("users")}</Title>
            
            <Content>
                <Table 
                    ref={tableRef}
                    columns={columnsTable}
                    actionsTable={actionsTable}
                    viewTable="list"
                    data={
                        (query) => new Promise((resolve, reject) => {
                            axios.get(`${API_USERMANAGER}/api/users/search`,{
                                params: {
                                    page: query.page - 1,
                                    limit: query.pageSize,
                                    search: search,
                                    order: query.order
                                },
                                withCredentials: true
                            })
                            .then(users => {
                                resolve(users.data);
                            })
                            .catch(err => {
                                console.log(err);
                            })
                        })
                    }
                >
                    <SearchInput search={search} onChange={onSearchChange} onSearch={handleSearch} />
                </Table>
                <NewIcon color={theme.palette.base} bg={theme.palette.accent} onClick={handleNewItem}><AddIcon /></NewIcon>
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <NewUser handleClose={handleClose} />
                </Modal>
                <Modal
                    open={openEdit}
                    onClose={handleCloseEdit}
                >
                    <NewUser userData={editUser} handleClose={handleCloseEdit} />
                </Modal>
                <Modal
                    open={openEditPassword}
                    onClose={handleCloseEditPassword}
                >
                    <ChangeUserPassword userData={userPasswordData} handleClose={handleCloseEditPassword} />
                </Modal>
            </Content>
        </Root>
    )
};