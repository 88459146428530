import React, { useLayoutEffect, useRef, useState } from "react";
import { COURT_HEIGHT, COURT_WIDTH, REAL_COURT_HEIGHT, REAL_COURT_WIDTH } from "../courtConfig";
import * as THREE from "three";

const min = (yPoint) => {
    if (yPoint > REAL_COURT_HEIGHT / 2) {
        return yPoint > REAL_COURT_HEIGHT ? REAL_COURT_HEIGHT : yPoint;
    } else {
        return yPoint > 0 ? yPoint : 0;
    }
}

const CourtLine = ({ start, end }) => {
    const ref = useRef();

    const isPlayerOnUpperSide = (yCoordinatePlayer) => {
        return yCoordinatePlayer >= (REAL_COURT_HEIGHT / 2);
    }

    const getXStartPoint = (valueX, valueY) => {
        const result = (valueX * COURT_WIDTH / REAL_COURT_WIDTH) - (COURT_WIDTH / 2);
        if (valueY <= (REAL_COURT_HEIGHT / 2)) {
            return result * (-1);
        }
        return result;
    }

    const getYStartPoint = (valueY) => {
        const result = (-valueY * COURT_HEIGHT / REAL_COURT_HEIGHT) + (COURT_HEIGHT / 2);
        if (valueY < (REAL_COURT_HEIGHT / 2)) {
            return result * (-1);
        }
        return result;
    }

    const getXEndPoint = (valueX, playerYPosition) => {
        const result = (valueX * COURT_WIDTH / REAL_COURT_WIDTH) - (COURT_WIDTH / 2);
        if (isPlayerOnUpperSide(playerYPosition)) {
            return result;
        }
        return result * (-1);
    }

    const getYEndPoint = (valueY) => {
        const result = (-valueY * COURT_HEIGHT / REAL_COURT_HEIGHT) + (COURT_HEIGHT / 2);
        if (isPlayerOnUpperSide(valueY)) {
            return result * (-1);
        }
        return result;
    }

    const [hover, setHover] = useState(false);
    const startPoint = [getXStartPoint(start[0], start[1]), getYStartPoint(start[1]), -4.9];
    const endPoint = [getXEndPoint(end[0], start[1]), getYEndPoint(end[1]), -4.9]
    useLayoutEffect(() => {
        ref.current.geometry.setFromPoints([startPoint, endPoint].map((point) => new THREE.Vector3(...point)))
    }, [startPoint, endPoint])
    return (
        <line
            ref={ref}
            onPointerOver={() => {
                setHover(true);
            }}
            onPointerOut={() => {
                setHover(false);
            }}
        >
            <bufferGeometry />
            <lineBasicMaterial color={'black'} />
        </line>
    )
}

export default CourtLine;