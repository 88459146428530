import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { API_BACKEND } from "../../config";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useAuth } from '../../providers/authentication';
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import axios from "axios";

const Root = styled.div`
    position: relative;
    height: auto;
    width: 100%;
    padding: 20px;
    @media (min-width: 1024px) {
        padding: 50px;
        max-width: 1440px;
        margin: 0 auto;
    }
`

const Title = styled.h2`
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    margin: 0 0 20px 0;
`

const FormContainer = styled.form`
display: flex;
position: relative;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
height: auto;
margin: 20px 0;
`

const StyledTextField = styled(TextField)`
width: ${props => props.halfWidth ? "47.5%" : "90%"};
@media (min-width: 600px) {
    width: ${props => props.width ? props.width : "400px"};
}
`

const StyledFormControl = styled(FormControl)`
width: 90%;
@media (min-width: 600px) {
    width: ${props => props.width ? props.width : "400px"};
}
`
const Actions = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
`

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: museo-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    text-transform: uppercase;
    color: ${(props) => props.theme.palette.base};
    border-radius: 35px;
    padding: 15px 0;
    width: 100px;
    background-color: ${(props) => props.theme.palette.alternative_accent};
    box-shadow: none;
    border: ${(props) => props.border ? props.border : 'none'};
    margin: ${(props) => props.margin ? props.margin : '30px 15px;'};
    cursor: pointer;
    &:disabled {
        color: #5D5D5D;
        background: #ECECEC;
        cursor: not-allowed;
    }
    @media (min-width: 600px) {
        font-size: 1.25rem;
    }
`

const FormSimulation = ({data, handleClose}) => {
    const intl = useIntl();
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { alertMessage } = bindActionCreators(actionCreators, dispatch);
    const navigate = useNavigate();

    const [changes, setChanges] = React.useState(false);

    const [simulationData, setSimulationData] = React.useState({
        name: '',
        description: '',
        historicalDUE: data.historicalDUE,
        simulationDUE: data.simulationDUE,
        performance: data.performance,
        minimatches: data.minimatches,
        sem: data.sem,
        rem: data.rem
    })

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSimulationData({
            ...simulationData,
            [name]: value
        })
        setChanges(true);
    };

    const onCancel = (e) => {
        e.preventDefault();
        if (!changes) {
            handleClose()
        } else {
            // eslint-disable-next-line no-restricted-globals
            if (confirm(intl.formatMessage({ id: 'do you want to cancel changes?' }))) {
                handleClose()
            }
        }
    }

    const onSave = (e) => {
        e.preventDefault();
        const url = `${API_BACKEND}/simulation`;
        axios.post(url, {
            name: simulationData.name,
            date: new Date(),
            description: simulationData.description,
            historicalDUE: JSON.stringify(simulationData.historicalDUE),
            simulationDUE: JSON.stringify(simulationData.simulationDUE),
            simulationPerformance: JSON.stringify(simulationData.performance),
            simulationMinimatches: JSON.stringify(simulationData.minimatches),
            simulationSEM: JSON.stringify(simulationData.sem),
            simulationREM: JSON.stringify(simulationData.rem)
        },
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(function (response) {
                alertMessage(intl.formatMessage({ id: "The simulation has been saved" }), alertConstants.SUCCESS);
                handleClose();
            })
            .catch(error => {
                alertMessage(error.response.data.msg, alertConstants.ERROR);
            });
    }

    return (
        <Root>
            <Title>{translate("save simulation")}</Title>
            <StyledFormControl variant="standard">
                <FormContainer onSubmit={onSave}>
                    <StyledTextField
                        id="name"
                        name="name"
                        label={intl.formatMessage({ id: 'Name' })}
                        value={simulationData.name}
                        onChange={handleChange}
                        variant="outlined"
                        margin="dense"
                        required
                    />
                    <StyledTextField
                        id="description"
                        name="description"
                        label={intl.formatMessage({ id: 'Description' })}
                        value={simulationData.description}
                        onChange={handleChange}
                        variant="outlined"
                        margin="dense"
                    />
                    <Actions>
                        <StyledButton onClick={onCancel}>
                            {translate("cancel")}
                        </StyledButton>
                        <StyledButton type="submit" disabled={!changes}>
                            {translate("save")}
                        </StyledButton>
                    </Actions>
                </FormContainer>
            </StyledFormControl>
        </Root>
    )
};
export default FormSimulation;