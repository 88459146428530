export const semiCircleData = 53.39;

export const optionsSemiCircle = {
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      hollow: {
        size: 50,
        background: "transparent",
        margin: 0,
      },
      track: {
        background: "#3C555C",
        strokeWidth: "75%",
        startAngle: -90,
        endAngle: 90,
        opacity: 0.25,
      },
      dataLabels: {
        show: true,
        name: {
          show: true,
          color: "#DCFE54",
          offsetY: -70,
          fontSize: "0.875rem",
        },
        value: {
          show: true,
          color: "#FFFFFF",
          offsetY: -25,
          fontSize: "3rem",
          formatter: function (val) {
            return val + "%";
          },
        },
      },
    },
  },
  responsive: [
    {
      breakpoint: 600,
      options: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              show: true,
              name: {
                show: true,
                color: "#DCFE54",
                offsetY: -60,
                fontSize: '0.70rem',
              },
              value: {
                show: true,
                color: "#FFFFFF",
                offsetY: -30,
                fontSize: '2.5rem',
                formatter: function (val) {
                  return val + '%'
                }
              }
            }
          }
        },
      },
    },
    {
      breakpoint: 1025,
      options: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              show: true,
              name: {
                show: true,
                color: "#DCFE54",
                offsetY: -80,
                fontSize: '0.9rem',
              },
              value: {
                show: true,
                color: "#FFFFFF",
                offsetY: -30,
                fontSize: '2.5rem',
                formatter: function (val) {
                  return val + '%'
                }
              }
            }
          }
        },
      },
    },
    {
      breakpoint: 1441,
      options: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              show: true,
              name: {
                show: true,
                color: "#DCFE54",
                offsetY: -70,
                fontSize: '1.25rem',
              },
              value: {
                show: true,
                color: "#FFFFFF",
                offsetY: -30,
                fontSize: '3rem',
                formatter: function (val) {
                  return val + '%'
                }
              }
            }
          }
        },
      },
    }
  ]
};