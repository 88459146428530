import axios from "axios";
import { API_BACKEND } from "../../config";
import {applicationConstants} from "../reducers/application/applicationConstants";

export const alertMessage = (msg, type) => {
    return (dispatch) => {
        dispatch({
            type: type ? type : "ALERT_CLEAR",
            message: msg
        });
    }
}

export const loadingAction = (type) => {
    return (dispatch) => {
        dispatch({
            type: type ? type : "LOADING_ERROR"
        });
    }
}

export const loadingProgress = (type, progress) => {
    return (dispatch) => {
        dispatch({
            type: type ? type : "LOADING_PROGRESS",
            progress: progress
        });
    }
}

export const changeSets = (type, sets) => {
    return (dispatch) => {
        dispatch({
            type: type ? type : "SETS_SET",
            sets: sets
        });
    }
}

export const changePlayer = (type, player) => {
    return (dispatch) => {
        dispatch({
            type: type ? type : "SET_PLAYER",
            player: player
        });
    }
}

export const changeMatchData = (type, data) => {
    return (dispatch) => {
        dispatch({
            type: type ? type : "SET_MATCHDATA",
            data: data
        });
    }
}

export const switchSets = (type, value) => {
    return (dispatch) => {
        dispatch({
            type: type ? type : "SWITCH_SETS",
            value: value
        });
    }
}

export const switchPlayers = (type, value) => {

    return (dispatch) => {
        dispatch({
            type: type ? type : "SWITCH_PLAYERS",
            value: value
        });
    }
}

export const changeDashboardFilters = (type, filters) => {
    return (dispatch) => {
        dispatch({
            type: type ? type : "DASHBOARDFILTERS_SET",
            filters: filters
        });
    }
}

export const applyDashboardFilters = (type, filters) => {
    return (dispatch) => {
        dispatch({
            type: type ? type : "DASHBOARDFILTERS_APPLY",
            appliedFilters: filters
        });
    }
}

export const resetDashboardFilters = (type) => {
    return (dispatch) => {
        dispatch({
            type: type ? type : "DASHBOARDFILTERS_RESET"
        });
    }
}

export const getLastMatchData = (type, token) => {


    return async (dispatch) => {
        const match = await axios.get(
            `${API_BACKEND}/lastMatch`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        let points = null;
        if (match.data.id !== null) {
            points = await axios.get(`${API_BACKEND}/matchpointsbyset?idMatch=${match.data.id}&sets=all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        }

        dispatch({
            type: type,
            lastMatch: {
                id: match.data.id,
                trainedPlayerName: match.data.trainedPlayerName,
                opponentName: match.data.opponentName,
                playerWinner: match.data.playerWinner,
                date: match.data.date,
                score: match.data.score,
                surface: match.data.surface,
                points: points !== null ? points.data : null,
            },
        });
    };
}

export const changeApplicationLanguage = (type, language) => {
    return (dispatch) => {
        localStorage.setItem(applicationConstants.APPLICATION_SETTINGS_LABEL, language);
        dispatch({
            type: type ? type : applicationConstants.SET_LANGUAGE,
            language: language
        });
    }
}