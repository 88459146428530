import { THEMES } from "../../../providers/theme/themes";

export const _options = {
  chart: {
    width: 500,
    height: 500,
    type: "pie",
  },
  tooltip: {
    enabled: false,
  },
  legend: {
    show: true,
    position: "bottom",
    markers: {
      fillColors: [THEMES.default.palette.alternative_accent, THEMES.default.palette.accent],
    },
    fontWeight: 700,
    labels: {
      colors: "#FFF"
    },
  },

  dataLabels: {
    enabled: true,
    textAnchor: "middle",
    offsetX: 0,
    offsetY: 0,
    formatter: function (val) {
      return val + "%";
    },
    style: {
      fontSize: "1rem",
      colors: ["#5D5D5D"],
    },
    dropShadow: {
      enabled: false,
    },
  },
  fill: {
    colors: [
      THEMES.default.palette.accent,
      THEMES.default.palette.alternative_accent,
    ],
  },
  stroke: {
    show: false,
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      expandOnClick: false,
      offsetX: 0,
      offsetY: 0,
      customScale: 1,
      dataLabels: {
        offset: -30,
      },
    },
  },
  responsive: [
    {
      breakpoint: THEMES.default.breakpoints.desktop,
      options: {
        dataLabels: {
          style: {
            fontSize: "1rem",
          },
        },
      },
    },
    {
      breakpoint: THEMES.default.breakpoints.desktopPlus,
      options: {
        dataLabels: {
          style: {
            fontSize: "0.70rem",
          },
        },
      },
    },
  ],
};

export const formatData = (data) => {
  var series = [];
  if(data.leadershipMarginOpponent && data.leadershipMarginTrainedPlayer ){
    series = [
      parseFloat(data.leadershipMarginOpponent),
      parseFloat(data.leadershipMarginTrainedPlayer),
    ]
  }
  const labels = [data.trainedPlayer || "Player", data.opponent || "Opponents"];
  return { series, labels };
};
