import React from "react";
import styled from "styled-components";
import LinearProgress from '@mui/material/LinearProgress';
import { useIntl } from "react-intl";

const Root = styled.span`
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    color: ${props => props.theme.palette.base};
    font-size: 2rem;
    user-select: none;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: auto;
    text-align: center;
`

const Message = ({elements}) => {
    const intl = useIntl();

    const [ percentage, setPercentage ] = React.useState(0);

    React.useEffect(() => {
        let sum = 0;
        Object.values(elements).forEach(ele => {
            sum += ele;
        });
        const newPercentage = Object.values(elements).length > 0 ? sum * 100 / Object.values(elements).length : 0;
        setPercentage(newPercentage);
    }, [elements])
    return (
        Object.values(elements).length > 0 &&
            <Root>
                {`${intl.formatMessage({ id: 'loading' })}...`}
                <LinearProgress variant="determinate" value={percentage} />
            </Root>
    );
}

export default Message;