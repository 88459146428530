import React, {useEffect} from "react";
import styled from "styled-components";
import NoImage from '../../../../assets/no-image.png';
import { API_BACKEND } from "../../../../config";
import {useSelector, useDispatch} from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../../store/action-creators";
import { playerConstants } from "../../../../store/reducers/player/playerConstants";
import { ThemeContext } from "../../../../providers/theme";

const Root = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: ${props => props.justifyContent};
`

const ImageContainer = styled.div`
    position: relative;
    width: fit-content;
    height: fit-content;
    opacity: ${props => props.enabled ? props.selected ? 1 : 0.5 : 1};
    @media (min-width: 600px) {
        cursor: ${props => props.enabled ? "pointer" : "default"};
    }
`

const Mark = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${props => props.opponent ? props.theme.palette.accent : props.theme.palette.alternative_accent};
    top: 0;
    ${props => props.opponent ? "right: -5px" : "left: -5px"};
    @media (min-width: 600px) {
        width: 25px;
        height: 25px;
        ${props => props.opponent ? "right: 0" : "left: 0"};
    }
    @media (min-width: 1440px) {
        width: 30px;
        height: 30px;
        ${props => props.opponent ? "right: 15px" : "left: 15px"};
    }
`

const Image = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    overflow: hidden;
    object-fit: cover;
    transition: all 300ms ease-in-out;
    @media (min-width: 600px) {
        width: 100px;
        height: 100px;
    }
    @media (min-width: 1440px) {
        width: 150px;
        height: 150px;
    }
`

const Name = styled.span`
    text-align: center;
    margin: 5px 0;
    font-size: 0.875rem;
    @media (min-width: 600px) {
        margin: 10px 0;
        font-size: 1rem;
    }
    @media (min-width: 1440px) {
        margin: 20px 0;
        font-size: 1.25rem;
    }
`

const ExpandedPlayer = ({opponent, data, playerNumber, enabled}) => {

    const theme = React.useContext(ThemeContext);

    const {player} = useSelector(state => state.selectedPlayer);

    const dispatch = useDispatch();

    const { changePlayer } = bindActionCreators(actionCreators, dispatch);

    const switchPlayer = () => {
        if(enabled){
            changePlayer(playerConstants.SET, playerNumber);
        }
    }

    return (
        data &&
        <Root justifyContent={opponent ? "flex-end" : "flex-start"}>
            <ImageContainer opponent={opponent} onClick={switchPlayer} selected={playerNumber === player} enabled={enabled}>
                <Image src={data.pathPhoto ? `${API_BACKEND}/players/${data.id}/image` : NoImage} alt={data.name} />
                <Mark opponent={opponent}>{enabled ? playerNumber === player ? theme.icons.visibility : theme.icons.visibilityoff : null}</Mark>
            </ImageContainer>
            <Name>{data.name}</Name>
        </Root>
    );
}

export default ExpandedPlayer;

