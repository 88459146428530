import React from 'react';
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";
import { ThemeContext } from '../../providers/theme';

const Root = styled.div`
    position: absolute;
    margin: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 16px;
    border-radius: 8px;
    background: ${props => props.theme.palette.principal};
    color: ${props => props.theme.palette.base};
    font-size: 1rem;
    font-weight: 600;
    @media (min-width: 600px) {
        padding: 8px 20px;
    }
    @media (min-width: 1440px) {
        margin: 10px 0px;
        padding: 8px 25px;
    }
`

const Ball = styled.div`
    margin-right: 10px;
    svg {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #dfff4f;
        height: 18px;
        width: 18px;
        @media (min-width: 600px) {
            height: 24px;
            width: 24px;
        }
    }
`

const TotalPoints = ({ data, rawData, filters }) => {

    const theme = React.useContext(ThemeContext);
    const [totalPointsData, setTotalPointsData] = React.useState(null);
    const dispatch = useDispatch();
    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const getData = () => {
        if (data) {
            data()
                .then(result => {
                    setTotalPointsData(result)
                })
                .catch(err => {
                    console.log(err);
                    alertMessage(err.response.data.msg, alertConstants.ERROR);
                })
        } else {
            if (rawData) {
                setTotalPointsData(rawData)
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, [filters]);

    return (
        <Root>
            <Ball>{theme.icons.ball}</Ball>
            {totalPointsData} PTS
        </Root>
    );
}

export default TotalPoints; 