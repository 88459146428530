import React from 'react';
import styled from "styled-components";
import { ThemeContext } from '../../../../providers/theme';

const Root = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    border-radius: 20px;
    padding: 5px 8px;
    background: ${props => props.active ? props.theme.palette.alternative_details : "transparent"};
    font-size: 0.75rem;
    margin: 2px;
    border: ${props => props.active ? "none" : `1px solid ${props.theme.palette.alternative_details}`};;
    opacity: ${props => props.active ? 1 : 0.5};
    outline: none;
    user-select: none;
    &:hover {
        cursor: pointer;
    }
    svg {
        margin-right: 5px;
        font-size: 0.75rem;
    }
    @media (min-width: 600px) {
        justify-content: space-around;
        font-size: 0.875rem;
        svg {
            margin-right: 5px;
            font-size: 0.875rem;
        }
    }
    @media (min-width: 1440px) {
        font-size: 1.25rem;
        padding: 10px 20px;
        svg {
            margin-right: 5px;
            font-size: 1.25rem;
        }
    }
`

const CheckButton = ({checkid, label, active, action}) => {

    const theme = React.useContext(ThemeContext);

    const handleAction = () => {
        action(checkid);
    }
    
    return (
        <Root active={active} onClick={handleAction}> {active ? theme.icons.visibility : theme.icons.visibilityoff} {label}</Root>
    );
}

export default CheckButton;