import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import Semaphore from "./semaphore";

import MiniMatchesChart from "./minimatches";

const Root = styled.div`
    position: absolute;
    display: grid;
    grid-template-columns: ${props => props.print ? "60% 40%" : "1fr"};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    gap: 1rem;
    opacity: ${props => props.loading === "true" ? 0.3 : 1};
    @media (min-width: 1024px) {
        grid-template-columns: 60% 40%;      
    }
`

const MiniMatchesWithSemaphoreChart = ({ data, rawData, width, height, options, type, sets, player, simulation, print }) => {
    return (
        <Root print={print}>
            <MiniMatchesChart width={width} height={height} data={data} rawData={rawData} options={options} type={type} sets={sets} player={player} simulation={simulation} />
            <Semaphore width="100px" widthMsg={'60%'} data={data} rawData={rawData} sets={sets} player={player} simulation={simulation} />
        </Root>
    );
}

MiniMatchesWithSemaphoreChart.propTypes = {
    data: PropTypes.any,
    rawData: PropTypes.any,
    semaphore: PropTypes.any,
    width: PropTypes.number,
    height: PropTypes.number,
    options: PropTypes.any,
    type: PropTypes.string.isRequired
}

export default MiniMatchesWithSemaphoreChart;