import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import { useIntl } from 'react-intl';
import { _options } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import { ThemeContext } from "../../../providers/theme";
import translate from "../../../providers/i18n/translate";
import axios from "axios";
import { API_BACKEND } from "../../../config";
import { useAuth } from "../../../providers/authentication";
import { Link } from "react-router-dom";
import Popup from "../../popup/Popup";


const Root = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    opacity: ${props => props.loading === "true" ? 0.3 : 1};
    padding: 20px;
    overflow: hidden;
    /* background: linear-gradient(0deg, ${props => props.theme.palette.alternative_accent} 0%, ${props => props.theme.palette.principal} 100%); */
    background: ${props => props.theme.palette.principal};
    border-radius: 20px;
`

const ContainerChart = styled.div`
    width: 100%;
    height: 300px;
    margin: auto 0;
    overflow: auto;
    /* width */
    ::-webkit-scrollbar {
    width: 14px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${props => `${props.theme.palette.accent}90`};
    border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.palette.accent}; 
    }
    @media (min-width: 600px) {
        height: 300px;
    }
    @media (min-width: 1024px) {
        height: 200px;
    }
    @media (min-width: 1440px) {
        height: 275px;
    }
`

const Title = styled.h5`
    font-size: 1rem;
    color: ${props => props.theme.palette.accent};
    margin: 10px 0 0 0;
    width: 70%;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0 0 20px 0;
`

const MatchData = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 1.25rem;
    color: ${props => props.theme.palette.base};
    line-height: 2;
    padding-right: 10px;
    user-select: none;
    @media (min-width: 600px) {
        font-size: 1.25rem;
    }
    @media (min-width: 1024px) {
        font-size: 0.875rem;
    }
    @media (min-width: 1440px) {
        font-size: 1.25rem;
    }
`

const GoMatch = styled(Link)`
    color: ${props => props.theme.palette.base};
    cursor: pointer;
    @media (min-width: 1024px) {
        svg {
            font-size: 1rem;
        }
    }
    @media (min-width: 1440px) {
        svg {
            font-size: 1.25rem;
        }
    }
`

const MatchQualityListChart = ({ height }) => {

    const { token } = useAuth();
    const intl = useIntl();
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const { appliedFilters } = useSelector(state => state.selectedDashboardFilters);

    const MATCHES_LIMIT = 10;

    const theme = React.useContext(ThemeContext);

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const getData = () => {
        setLoading(true);
        axios
            .get(
                `${API_BACKEND}/historicalmatchquality?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((result) => {
                setData(result.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                alertMessage(err.response.data.msg, alertConstants.ERROR);
                setLoading(false);
            });
    }

    React.useEffect(() => {
        getData();
    }, [JSON.stringify(appliedFilters)]);

    return (
        <Root loading={loading ? "true" : "false"}>
            <Popup
                title={intl.formatMessage({ id: 'Match Quality' })}
                content={intl.formatMessage({ id: 'help match quality ranking' })}
            />
            <Title>{translate("Match Quality")}</Title>
            <ContainerChart height={height}>
                {
                    data && data.map((dat, index) =>
                        <MatchData key={`match_index_${index}`} ><span><strong>{`#${index + 1} | `}</strong>{`vs. ${dat.opponent}`}</span><GoMatch to={`/admin/match/${dat.matchId}/performance`}>{theme.icons.visibility}</GoMatch></MatchData>
                    )
                }
            </ContainerChart>
        </Root>
    );
}

MatchQualityListChart.propTypes = {
    height: PropTypes.number
}

export default MatchQualityListChart;