import React from "react";
import styled from 'styled-components';
import translate from "../providers/i18n/translate";
import RallyBarChart from "../components/gsmcharts/rallyBarChart";
import { useAuth } from "../providers/authentication";
import { useParams } from "react-router-dom";
import axios from "axios";
import { formatDataFirstServeIn, formatDataSecondServeIn, formatDataDeuceAdServe } from "../components/gsmcharts/rallyBarChart/config";
import { formatData as formatSemiCircleData } from "../components/gsmcharts/semiCircleChart/config";
import { API_BACKEND } from "../config";
import SemiCircleChart from "../components/gsmcharts/semiCircleChart";
import { matchDataConstants } from "../store/reducers/matchData/matchDataConstants";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../store/action-creators";

/* Styled components */
const Root = styled.div`
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    gap: 10px 0;
    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(2, 50px 350px) 50px 260px;
        gap: 20px;
    }
    @media (min-width: 1440px) {
        grid-template-rows: repeat(2, 50px 450px) 50px 350px;
    }
`

const Title = styled.h2`
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.details};
  font-size: 1.25rem;
  text-align: center;
  order: ${props => props.order ? props.order : "inherit"};
  @media (min-width: 1024px) {
    order: inherit;
    font-size: 1.5rem;
    text-align: left;
  }
`;

const FullTitle = styled(Title)`
     @media (min-width: 1024px) {
        order: inherit;
        grid-column: 1/3;
  }
`

const ContainerChart = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  background: ${(props) => props.theme.palette.principal};
  border-radius: 20px;
  padding: 20px 0;
  order: ${props => props.order ? props.order : "inherit"};
  @media (min-width: 600px) {
    height: auto;
  }
  @media (min-width: 1024px) {
    padding: 0;
    order: inherit;
  }
  @media (min-width: 1440px) {
    height: 100%;
    padding: 0;
    order: inherit;
  }
`;

const ContainerSemiCircleChart = styled.div`
    position: relative;
    width: 100%;
    height: 300px;
    overflow: hidden;
    background: ${props => props.theme.palette.principal};
    border-radius: 20px;
    padding: 20px 0;
    order: ${props => props.order ? props.order : "inherit"};
    @media (min-width: 600px) {
        height: 450px;
    }
    @media (min-width: 1024px) {
        height: 100%;
        width: 50%;
        padding: 0;
        margin:0 auto;
        grid-column:1/3;
        order: inherit;
    }
`

export default function SemPage() {

    const { token } = useAuth();
    const { id } = useParams();

    const { sets } = useSelector(state => state.selectedSets);
    const { player } = useSelector(state => state.selectedPlayer);
    const { match } = useSelector(state => state.matchData);

    const dispatch = useDispatch();
    const { switchPlayers, switchSets } = bindActionCreators(
        actionCreators,
        dispatch
    );

    const getSetsList = (s) => {
        let setlist = "";
        for (let index = 0; index < sets.length; index++) {
            if (sets[index] === true) {
                setlist = setlist + (index + 1);
                if (sets.length > 1 && index !== sets.length - 1) {
                    setlist = setlist + ",";
                }
            }
        }
        return setlist;
    }

    React.useEffect(() => {
        switchPlayers(matchDataConstants.SWITCH_PLAYERS, true);
        switchSets(matchDataConstants.SWITCH_SETS, true);
    }, []);

    return (
        match && player && sets && sets.length > 0 &&
        <Root>
            <Title order={1}>{translate("Deuce and Ad Points on 1st Serve In")}</Title>
            <Title order={3}>{translate("Deuce and Ad Points on 2nd Serve In")}</Title>
            <ContainerChart order={2}>
                <RallyBarChart
                    sets={sets}
                    player={player}
                    data={
                        (query) => new Promise((resolve, reject) => {
                            axios.get(`${API_BACKEND}/deuceadfirstservein?idMatch=${id}&sets=${getSetsList(sets)}&playerNumber=${player}`,
                                {
                                    headers: {
                                        'Authorization': `Bearer ${token}`
                                    }
                                })
                                .then(result => {
                                    const chartData = formatDataFirstServeIn(result.data);
                                    resolve(chartData);
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        })
                    }
                    type="bar"
                    width={500}
                    height={500}
                />
            </ContainerChart>
            <ContainerChart order={4}>
                <RallyBarChart
                    sets={sets}
                    player={player}
                    data={
                        (query) => new Promise((resolve, reject) => {
                            axios.get(`${API_BACKEND}/deuceadsecondservein?idMatch=${id}&sets=${getSetsList(sets)}&playerNumber=${player}`,
                                {
                                    headers: {
                                        'Authorization': `Bearer ${token}`
                                    }
                                })
                                .then(result => {
                                    const chartData = formatDataSecondServeIn(result.data);
                                    resolve(chartData);
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        })
                    }
                    type="bar"
                    width={500}
                    height={500}
                />
            </ContainerChart>
            <Title order={5}>{translate("Deuce and Ad Points Won on 1st Serve In")}</Title>
            <Title order={7}>{translate("Deuce and ad points won on 2nd serve")}</Title>
            <ContainerChart order={6}>
                <RallyBarChart
                    sets={sets}
                    player={player}
                    data={
                        (query) => new Promise((resolve, reject) => {
                            axios.get(`${API_BACKEND}/deuceadserve?idMatch=${id}&sets=${getSetsList(sets)}&playerNumber=${player}&serveType=firstServe`,
                                {
                                    headers: {
                                        'Authorization': `Bearer ${token}`
                                    }
                                })
                                .then(result => {
                                    const chartData = formatDataDeuceAdServe(result.data);
                                    resolve(chartData);
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        })
                    }
                    type="bar"
                    width={500}
                    height={500}
                />
            </ContainerChart>
            <ContainerChart order={8}>
                <RallyBarChart
                    sets={sets}
                    player={player}
                    data={
                        (query) => new Promise((resolve, reject) => {
                            axios.get(`${API_BACKEND}/deuceadserve?idMatch=${id}&sets=${getSetsList(sets)}&playerNumber=${player}&serveType=secondServe`,
                                {
                                    headers: {
                                        'Authorization': `Bearer ${token}`
                                    }
                                })
                                .then(result => {
                                    const chartData = formatDataDeuceAdServe(result.data);
                                    resolve(chartData);
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        })
                    }
                    type="bar"
                    width={500}
                    height={500}
                />
            </ContainerChart>
            <FullTitle order={9}>{translate("Service Effectiveness Margin in the Match")}</FullTitle>
            <ContainerSemiCircleChart order={10}>
                <SemiCircleChart
                    player={player}
                    data={
                        (query) => new Promise((resolve, reject) => {
                            axios.get(`${API_BACKEND}/sem?idMatch=${id}&playerNumber=${player}`,
                                {
                                    headers: {
                                        'Authorization': `Bearer ${token}`
                                    }
                                })
                                .then(result => {
                                    const chartData = formatSemiCircleData(result.data);
                                    resolve(chartData);
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        })
                    }
                    type="radialBar"
                    labels={["S.E.M."]}
                    width={500}
                    height={500}
                />
            </ContainerSemiCircleChart>
        </Root>
    )
};