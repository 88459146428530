import React from "react";
import styled from "styled-components";
import translate from "../../../../providers/i18n/translate";
import axios from "axios";
import { useAuth } from "../../../../providers/authentication";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../../store/action-creators";
import { alertConstants } from "../../../../store/reducers/messageBar/alertConstants";
import { API_BACKEND } from "../../../../config";
import { THEMES } from "../../../../providers/theme/themes";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`
const Title = styled.h2`
    width: 100%;
    text-align: center;
    font-size: 0.80rem;
    font-weight: 600;
    color: ${props => props.color ? props.color : THEMES.default.palette.principal};
    margin: 0 0 20px 0;
    @media (min-width: 1024px) {
        font-size: 0.9rem;
    }
`
const Actions = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
`

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: museo-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: ${(props) => props.theme.palette.base};
    border-radius: 35px;
    padding: 15px 0;
    width: 110px;
    background-color: ${(props) => props.theme.palette.alternative_accent};
    box-shadow: none;
    border: ${(props) => props.border ? props.border : 'none'};
    margin: ${(props) => props.margin ? props.margin : '10px 10px;'};
    cursor: pointer;
    &:disabled {
        color: #5D5D5D;
        background: #ECECEC;
        cursor: not-allowed;
    }
    @media (min-width: 1024px) {
        font-size: 0.9rem;
        width: 120px;
    }
`

const ConfirmNewMargin = ({ handleClose, desiredMarginGoal, handleDesiredMargin }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { alertMessage } = bindActionCreators(actionCreators, dispatch);
    const { token } = useAuth();

    const onClick = (e) => {
        e.preventDefault();
        const url = `${API_BACKEND}/playerTraining/goal/${desiredMarginGoal.id}`;
        axios.delete(url, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(function (response) {
                alertMessage(intl.formatMessage({ id: "Your desired margin has been deleted" }), alertConstants.SUCCESS);
                handleDesiredMargin(null)
            })
            .catch(error => {
                alertMessage(error.response.data.msg, alertConstants.ERROR);
            });
    }

    const onCancel = (e) => {
        handleClose()
    }

    return (
        <Root>
            <Title>{translate("You have already set a desired margin")}</Title>
            <Title>{translate("Do you want to delete it and set up a new one?")}</Title>
            <Actions>
                <StyledButton onClick={onCancel}>
                    {translate("cancel")}
                </StyledButton>
                <StyledButton type="submit" onClick={onClick}>
                    {translate("confirm")}
                </StyledButton>
            </Actions>
        </Root>
    );
};

export default ConfirmNewMargin;
