/* General imports */
import React from "react";
import styled from "styled-components";
import GSMIcon from "./gsm_img.png";

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.background ? props.background : props.theme.palette.principal};
  border-radius: 10px;
  width: 80%;
  height: auto;
  padding: 20px;
  @media (min-width: 600px) {
    width: 100%;
    flex-direction: ${(props) =>
    props.orientation === "vertical" ? "column" : "row"};
    justify-content: center;
  }
`;

const Sem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? `calc(${props.width} + 15px)` : "100px")};
  height: ${(props) => (props.width ? `calc(${props.width} + 15px)` : "100px")};
  margin: 10px 20px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  background: ${(props) => props.color1 && props.color2 ? `linear-gradient(0deg, ${props.color1} 0%, ${props.color1} ${parseInt(props.value) - 10}%, ${props.color2} ${parseInt(props.value) + 10}%, transparent 100%)` : '#ababab'};
  box-sizing: border-box;
  @media (min-width: 1440px) {
    width: ${(props) => (props.width ? props.width : "100px")};
    height: ${(props) => (props.width ? props.width : "100px")};
  }
`;

const ContentImage = styled.div`
  position: relative;
  width: 90%;
  height: 90%;
  background: transparent;
  transition: all 500ms ease-in-out;
  img {
    width: 100%;
    height: 100%;
    transition: all 500ms ease-in-out;
    object-fit: contain;
  }
  &:hover {
    img {
      transform: rotate(360deg);
    }
  }
`;

const Semaphore = (props) => {

  const getColor1 = () => {
    return props.calculateColor1(props.value);
  };

  const getColor2 = () => {
    return props.calculateColor2(props.value);
  };

  return (
    <Root vertical={props.vertical} print={props.print}>
      <Container background={props.background} orientation={props.orientation}>
        <Sem
          color1={getColor1()}
          color2={getColor2()}
          value={props.value}
          width={props.width}
        >
          <ContentImage>
            <img src={GSMIcon} alt="Semaphore" />
          </ContentImage>
        </Sem>
      </Container>
    </Root>
  );
};

export default Semaphore;
