import React from "react";
import styled from "styled-components";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import UnforcedErrorsChart from "../../components/gsmcharts/unforcedErrorsChart";
import { formatData as formatDataUE } from "../../components/gsmcharts/semiCircleUEChart/config";
import { formatData as formatUEData } from "../../components/gsmcharts/unforcedErrorsChart/config";
import SemiCircleUEChart from "../../components/gsmcharts/semiCircleUEChart";
import { useSelector } from "react-redux";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px 0;
`;

const ContainerChart = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  background: ${(props) =>
    props.background ? props.background : props.theme.palette.principal};
  border-radius: 20px;
  padding: 20px 0;
  
`;

const SemiCircleContainer = styled.div`
  position: relative;
  width: 100%;
  background: ${(props) => props.theme.palette.principal};
  height: 350px;
  border-radius: 20px;
  overflow: hidden;
`;

const UnforcedErrors = ({onReady}) => {
  const { token } = useAuth();

  const NAME = "unforcedErrors";

  const { appliedFilters } = useSelector(
    (state) => state.selectedDashboardFilters
  );

  const MATCHES_LIMIT = 10;

  return (
    <Root>
      <ContainerChart>
        <UnforcedErrorsChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalunforcederrors?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatUEData(result.data);
                  onReady(NAME);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="rangeBar"
          width={500}
          height={500}
          print
        />
      </ContainerChart>
      <SemiCircleContainer>
        <SemiCircleUEChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalunforcederrors?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDataUE(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="radialBar"
          labels={["P1"]}
          width={500}
          height={500}
          print
        />
      </SemiCircleContainer>
    </Root>
  );
};

export default UnforcedErrors;
