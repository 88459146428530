import { StyledPopupButton } from "./styled-components/StyledPopupButton";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React, { useState } from "react";
import { StyledContent } from "./styled-components/StyledContent";
import Modal from "@mui/material/Modal";
import * as PropTypes from "prop-types";
import {StyledCloseButton} from "./styled-components/StyledCloseButton";
import {StyledBox} from "./styled-components/StyledBox";
import { ThemeContext } from '../../providers/theme';
import {StyledPopupTitle} from "./styled-components/StyledPopupTitle";
import {StyledPopupHeader} from "./styled-components/StyledPopupHeader";

const DEFAULT_TITLE = 'Insert title on Popup component';
const DEFAULT_CONTENT = '<span>Insert <strong>HTML content</strong> on Popup component</span>';

function CloseButton(props) {
    return null;
}

CloseButton.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node
};

function Popup({id, title= DEFAULT_TITLE, content=DEFAULT_CONTENT, color}) {

    const [open, setOpen] = useState(false);
    const theme = React.useContext(ThemeContext);

    const handleClick = (event) => {
        setOpen((previousOpen) => !previousOpen);
    };

    const handleClose = (_event) => {
        setOpen(false);
    }
    return (
        <>
            <StyledPopupButton aria-describedby={`modal_${id}`} type="button" color={color} onClick={handleClick} >
                <HelpOutlineIcon />
            </StyledPopupButton>
            <Modal
                id={`modal_${id}`}
                open={open}
                onClose={handleClose}
                aria-labelledby={`modal-help-title-${id}`}
                aria-describedby={`modal-help-description-${id}`}
            >
                <StyledBox>
                    <StyledPopupHeader src='/logoColorH.png' alt='header popup help logo' />
                    <StyledCloseButton onClick={handleClose}>{theme.icons.close}</StyledCloseButton>
                    <StyledPopupTitle>{title}</StyledPopupTitle>
                    <StyledContent dangerouslySetInnerHTML={{ __html: content }} />
                </StyledBox>
            </Modal>
        </>
    )
}

export default Popup;