import React from 'react';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import { ThemeContext } from '../../providers/theme';

const Box = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${(props) => props.width ? props.width : '50%'};
    height: ${(props) => props.height ? props.height : '400px'};
    background-color: #FFF;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.5);
    border-radius: 8px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.alignItems ? props.alignItems : "flex-start"};
    justify-content: ${(props) => props.justifyContent ? props.justifyContent : "flex-start"};
    overflow-y: auto;
    outline: none;
    @media (max-width: 600px) {
        width: 95%;
        padding: 30px;
    }
`

const CloseButton = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    outline: none;
    cursor: pointer;
`

const BasicModal = ({ open, handleClose, children, width, height, alignItems, justifyContent }) => {
    
    const theme = React.useContext(ThemeContext);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box width={width} height={height} alignItems={alignItems} justifyContent={justifyContent} >
                <CloseButton onClick={handleClose}>{theme.icons.close}</CloseButton>
                {
                    children ? children : <span>Insert children</span>
                }
            </Box>
        </Modal>
    );
}

export default BasicModal;