/* General imports */
import React from 'react';
import styled, { keyframes } from 'styled-components';


const loadingServe = (w) => keyframes`
  0% {
    width: 0;
    height: 0;
  } 
  100% {
    width: ${w}px;
    height: ${w}px;
  }
`

const Tooltip = styled.div`
    position: absolute;
    display: flex;
    opacity: 0;
    align-items: center;
    justify-content: center;
    bottom: 150%;
    left: 50%;
    transform: ${(props) => props.invert ? 'translateX(-50%) scale(-1, 1)' : 'translateX(-50%)' };
    width: auto;
    height: 0;
    max-width: 200px;
    min-width: 70px;
    background-color: rgba(255,255,255,0.85);
    transition: all 500ms ease-in-out;
    line-height: 1.25rem;
    font-size: 1rem;
    overflow-y: hidden;
    text-transform: uppercase;
`

const Root = styled.div`
    position: absolute;
    display: ${(props) => props.show ? 'block' : 'none'};
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 0;
    height: 0;
    animation: ${props => loadingServe(25)} 1s linear forwards;
    border-radius: 50%;
    background-color: ${(props) => props.color ? props.color : 'grey'};
    z-index: 1;
    &:hover {
        opacity: 1;
        ${Tooltip} {
            height: auto;
            opacity: 1;
        }
    }
    @media (max-width: 600px) {
        animation: ${props => loadingServe(15)} 1s linear forwards;
        right: 7.5px;
    }
`

const FirstServe = ({show, color, msg, invertTooltip}) => {

    return (
        <Root show={show} color={color}>
            {
                msg && 
                <Tooltip invert={invertTooltip}>
                    {msg}
                </Tooltip>
            }
        </Root>
    );
}

export default FirstServe;