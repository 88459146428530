import { THEMES } from "../../../providers/theme/themes";

export const _options = {
  chart: {
    type: "bar",
    with: 500,
    height: 800,
    foreColor: THEMES.default.palette.details,
    toolbar: {
      show: false,
    },
  },
  colors: ["#10A9C7", "#C4E63A", "#775DFF", "#C38154"],
  plotOptions: {
    bar: {
      borderRadius: 20,
      horizontal: true,
      barHeight: "70%",
      borderRadiusApplication: 'end',
      dataLabels: {
        position: "center",
        orientation: "horizontal",
      },
      distributed: true,
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: "12px",
    },
    formatter: function (val, opt) {
      return `${opt.w.config.series[0].data[opt.dataPointIndex].y}% (${opt.w.config.series[0].data[opt.dataPointIndex].metadata.op1
        }/${opt.w.config.series[0].data[opt.dataPointIndex].metadata.op2})`;
    },
  },
  xaxis: {
    tickAmount: 1,
    min: 0,
    max: 100,
  },
  yaxis: {
    show: false,
  },
  grid: { show: false },
  legend: {
    horizontalAlign: "center",
    markers: {
      radius: 10,
    },
    labels: {
      colors: THEMES.default.palette.details,
    },
  },
  responsive: [
    {
      breakpoint: THEMES.default.breakpoints.tablet,
      options: {
        chart: {
          type: "bar",
          width: 320,
          height: 320,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "10px",
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
          }
        }
      },
    },
    {
      breakpoint: THEMES.default.breakpoints.desktop,
      options: {
        chart: {
          type: "bar",
          width: 400,
          height: 400,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
          }
        },
      },
    },
    {
      breakpoint: THEMES.default.breakpoints.desktopPlus,
      options: {
        chart: {
          type: "bar",
          width: 450,
          height: 400,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
          }
        },
      },
    },
  ],
};

export const _optionsV2 = {
  chart: {
    type: "bar",
    width: 400,
    height: 800,
    foreColor: THEMES.default.palette.details,
    toolbar: {
      show: false,
    },
  },
  colors: ["#10A9C7", "#C4E63A", "#775DFF", "#C38154"],
  plotOptions: {
    bar: {
      borderRadius: 20,
      horizontal: true,
      barHeight: "70%",
      borderRadiusApplication: 'end',
      dataLabels: {
        position: "center",
        orientation: "horizontal",
      },
      distributed: true,
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: "12px",
    },
    formatter: function (val, opt) {
      return `${opt.w.config.series[0].data[opt.dataPointIndex].y}% (${opt.w.config.series[0].data[opt.dataPointIndex].metadata.op1
        }/${opt.w.config.series[0].data[opt.dataPointIndex].metadata.op2})`;
    },
  },
  xaxis: {
    tickAmount: 1,
    min: 0,
    max: 100,
    labels: {
      show: true,
      style: {
          fontSize: '12px',
          fontWeight: 100,
          colors: '#B6BFC1'
      },
    }
  },
  yaxis: {
    show: false,
  },
  grid: { show: false },
  legend: {
    horizontalAlign: "center",
    markers: {
      radius: 10,
    },
    labels: {
      colors: THEMES.default.palette.base,
    },
  },
  responsive: [
    {
      breakpoint: THEMES.default.breakpoints.tablet,
      options: {
        chart: {
          type: "bar",
          width: 320,
          height: 320,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "10px",
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
          }
        }
      },
    },
    {
      breakpoint: THEMES.default.breakpoints.desktop,
      options: {
        chart: {
          type: "bar",
          width: 400,
          height: 400,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
          }
        },
      },
    },
    {
      breakpoint: THEMES.default.breakpoints.desktopPlus,
      options: {
        chart: {
          type: "bar",
          width: 410,
          height: 410,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 20,
          }
        },
      },
    },
  ],
};

export const goalTemplate = {
  name: "Average Last Ten Matches",
  value: 0,
  strokeWidth: 5,
  strokeHeight: 12,
  strokeDashArray: 0,
  strokeLineCap: "round",
  strokeColor: "#B6BFC1",
};

export const formatData = (data) => {
  const result = {
    series: [
      {
        name: "Valor",
        data: [
          {
            x: "1st Serve Effectiveness",
            y: data.dataMiniMatches.firstServeEffectiveness.result,
            metadata: {
              op1: data.dataMiniMatches.firstServeEffectiveness.firstServeWon,
              op2: data.dataMiniMatches.firstServeEffectiveness.totalServe,
            },
            goals: data.historicalMiniMatches?.firstServeEffectiveness
              ? [
                {
                  ...goalTemplate,
                  value:
                    data.historicalMiniMatches?.firstServeEffectiveness
                      ?.result,
                },
              ]
              : null,
          },
          {
            x: "2nd Serve Effectiveness",
            y: data.dataMiniMatches.secondServeEffectiveness.result,
            metadata: {
              op1: data.dataMiniMatches.secondServeEffectiveness.secondServeWon,
              op2: data.dataMiniMatches.secondServeEffectiveness
                .totalSecondServeInPlay,
            },
            goals: data.historicalMiniMatches?.secondServeEffectiveness
              ? [
                {
                  ...goalTemplate,
                  value:
                    data.historicalMiniMatches?.secondServeEffectiveness
                      ?.result,
                },
              ]
              : null,
          },
          {
            x: "1st Serve Return Effectiveness",
            y: data.dataMiniMatches.firstServeReturnEffectiveness.result,
            metadata: {
              op1: data.dataMiniMatches.firstServeReturnEffectiveness
                .firstServeReturnWon,
              op2: data.dataMiniMatches.firstServeReturnEffectiveness
                .totalFirstServeReturn,
            },
            goals: data.historicalMiniMatches?.firstServeReturnEffectiveness
              ? [
                {
                  ...goalTemplate,
                  value:
                    data.historicalMiniMatches?.firstServeReturnEffectiveness
                      ?.result,
                },
              ]
              : null,
          },
          {
            x: "2nd Serve Return Effectiveness",
            y: data.dataMiniMatches.secondServeReturnEffectiveness.result,
            metadata: {
              op1: data.dataMiniMatches.secondServeReturnEffectiveness
                .secondServeReturnWon,
              op2: data.dataMiniMatches.secondServeReturnEffectiveness
                .totalSecondServeReturn,
            },
            goals: data.historicalMiniMatches?.secondServeReturnEffectiveness
              ? [
                {
                  ...goalTemplate,
                  value:
                    data.historicalMiniMatches?.secondServeReturnEffectiveness
                      ?.result,
                },
              ]
              : null,
          },
        ],
      },
    ],
    categories: [
      "1st Serve Effectiveness",
      "2nd Serve Effectiveness",
      "1st Serve Return Effectiveness",
      "2nd Serve Return Effectiveness",
    ],
    semaphore: {
      sum: (data.dataMiniMatches.sumMiniMatches.sum != undefined) ? data.dataMiniMatches.sumMiniMatches.sum : 0,
      msg: (data.dataMiniMatches.sumMiniMatches.msg != undefined) ? data.dataMiniMatches.sumMiniMatches.msg : "",
    },
  };

  return result;
};
