import { dashboardFiltersConstants } from "./dashboardFiltersConstants";

const initialState = { 
  filters: [],
  appliedFilters: []
};

export function selectedDashboardFilters(state = initialState, action) {
  switch (action.type) {
    case dashboardFiltersConstants.SET:
      return {
        ...state,
        appliedFilters: state.appliedFilters,
        filters: action.filters
      };
    case dashboardFiltersConstants.APPLY:
      return {
        ...state,
        filters: state.filters,
        appliedFilters: [...action.appliedFilters]
      };
    case dashboardFiltersConstants.RESET:
      return {
        ...initialState
      };
    default:
      return state
  }
}