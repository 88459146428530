import React from "react";
import { Snackbar } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import {useSelector, useDispatch} from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";
import { useIntl } from 'react-intl';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MessageBar = () => {

  const intl = useIntl();

  const {open, message, type} = useSelector(state => state.alertMessage);

  const dispatch = useDispatch();

  const {alertMessage} = bindActionCreators(actionCreators, dispatch);

  const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      alertMessage("", alertConstants.CLEAR)
    };
  
    const action = (
      <React.Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );

    const getSeverity = (key) => {
      switch (key) {
        case alertConstants.SUCCESS:
            return "success"
          break;
        case alertConstants.INFO:
            return "info"
          break;
        case alertConstants.WARNING:
            return "warning"
          break;
        case alertConstants.ERROR:
            return "error"
          break;
        default:
            return "info"
          break;
      }
    }


    React.useEffect(() => {

    }, [])

    return (
        <Snackbar
            anchorOrigin={{ vertical:"top", horizontal: "center" }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            action={action}
        >
          <Alert onClose={handleClose} severity={getSeverity(type)} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
    );
}

export default MessageBar;