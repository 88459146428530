import React from "react";
import styled from "styled-components";
import { ThemeContext } from '../../providers/theme';

const Root = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 50px;
    user-select: none;
    cursor: pointer;
    outline: none;
    padding: 18px;
    transition: 300ms;
    color: ${props => props.expanded ? props.theme.palette.alternative_accent : props.theme.palette.principal};
`

const Icon = styled.div`
    position: absolute;
    right: 18px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease-in-out;
    transform: ${props => props.expanded ? "rotate(-180deg)" : "rotate(0deg)"};
`

const AccordionSummary = ({children, expanded, switchAccordion}) => {
    
    const theme = React.useContext(ThemeContext);

    return (
        <Root expanded={expanded} onClick={switchAccordion}>
            {children}
            <Icon expanded={expanded}>{theme.icons.arrowDown}</Icon>
        </Root>
    );
}

export default AccordionSummary;