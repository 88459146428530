import { THEMES } from "../../../providers/theme/themes";

export const colors = ['#0E6778', '#C4E63A', '#775DFF', '#B6BFC1', '#463794', '#96B129', '#81D7E8', '#03232D', '#10A9C7']

export const _options = {
    chart: {
      id: 'rallyChart',
      type: 'bar',
      width: 200,
      height: 400,
      foreColor: THEMES.default.palette.details,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        horizontal: true,
        barHeight: '50%',
        dataLabels: {
          position: 'center'
        },
        distributed: true
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      offsetX: 0,
      style: {
        colors: ['#fff'],
        fontSize: "12px"
      },
      dropShadow: {
        enabled: true,
      }
    },
    legend: {
      horizontalAlign: 'center',
      inverseOrder: false,
      markers: {
          radius: 10,
      },
      labels: {
          colors: THEMES.default.palette.base,
      }
    },
    states: {
      normal: {
          filter: {
              type: 'desaturate'
          }
      },
      active: {
          allowMultipleDataPointsSelection: true,
          filter: {
              type: 'darken',
              value: 1
          }
      }
    },
    tooltip: {
      x: {
          show: false
      },
      y: {
          title: {
              formatter: function (val, opts) {
                  return opts.w.globals.labels[opts.dataPointIndex]
              }
          }
      }
    },
    subtitle: {
      text: 'Click on bar to see details',
      offsetX: 15,
      offsetY: 15,
      style: {
          fontSize: '12px',
          fontWeight: 600,
          color: THEMES.default.palette.base,
      },
    },
    xaxis: {
      labels: {
        show: true,
        style: {
            fontSize: '12px',
            fontWeight: 100,
            colors: '#B6BFC1'
        },
      }
    },
    yaxis: {
      show: false
    }
  }  

  export const _options_result = {
    chart: {
      type: 'bar',
      height: 400,
      width: '100%',
      foreColor: THEMES.default.palette.details,
      toolbar: {
        show: false
      },
      stacked: true
    },
    plotOptions: {
      bar: {
        borderRadius: 20,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        horizontal: false,
        columnWidth: '50%'
      }
    },
    legend: {
      show: false
    },
    xaxis: {
      labels: {
        show: true,
        style: {
            fontSize: '12px',
            fontWeight: 100,
            colors: '#B6BFC1'
        },
    }
    },
    yaxis: {
      show: false
    },
    grid: {
      yaxis: {
          lines: {
              show: false,
          }
      },
      xaxis: {
          lines: {
              show: true,
          },
      }
    },
    tooltip: {
      x: {
          formatter: function (val, opts) {
              return opts.w.globals.seriesNames[opts.seriesIndex]
          }
      },
      y: {
          title: {
              formatter: function (val, opts) {
                  return opts.w.globals.labels[opts.dataPointIndex]
              }
          }
      }
    },
    responsive: [
      {
        breakpoint: THEMES.default.breakpoints.tablet,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 10,
            }
          },
        },
      },
      {
        breakpoint: THEMES.default.breakpoints.desktop,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 10,
            }
          },
        },
      },
      {
        breakpoint: THEMES.default.breakpoints.desktopPlus,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 10,
            }
          },
        },
      },
    ],
  }

  export const formatBreakPointData = (data) => {
    const dataseries = [];
    const datacolors = [];
    data.forEach(element => {
      dataseries.push({
        x: element.label,
        y: element.total,
        color: element.color,
        rallyResult: [{
            x: "WON",
            y: element.won
          }, {
            x: "LOSE",
            y: element.lose
          }]
      })
      datacolors.push(element.color)
    });
    const result = {
      series: [{
        data: dataseries
      }],
      colors: datacolors
    }
   
    return result;
  }