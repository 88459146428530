import React from "react";
import styled from "styled-components";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import { useSelector } from "react-redux";
import { formatData as formatDynamicChartData } from "../../components/gsmcharts/dynamicBarChart/config";
import { formatData as formatRallyEffectivenessData } from "../../components/gsmcharts/rallyEffectivenessChart/config";
import { DynamicBarChart, RallyEffectivenessChart } from "../gsmcharts";
import translate from "../../providers/i18n/translate";

const Root = styled.div`
    position: relative;
  height: auto;
  width: 100%;
  display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: auto repeat(2, 50px 375px);
    gap: 20px;
`;

const Title = styled.h2`
  width: 100%;
  height: 50px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.details};
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  display: flex;
    align-items: flex-end;
    font-size: 1.5rem;
    text-align: left;
    height: 100%;
    grid-template-columns: 60% 40%;
    grid-column: 1/3;
`;

const ContainerChart = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;


const RallyServeReturn = ({ onReady }) => {
    const { token } = useAuth();

    const MATCHES_LIMIT = 10;
    const NAME = "rallyServeReturn";

    const { appliedFilters } = useSelector(
        (state) => state.selectedDashboardFilters
    );

    const allReady = [false, false, false, false];

    const handleReady = () => {
        if (allReady[0] && allReady[1] && allReady[2] && allReady[3]) {
            onReady(NAME);
        }
    }

    React.useEffect(() => { }, [JSON.stringify(appliedFilters)]);

    return (
        <Root>
            <Title>{translate("rally on first serve and return")}</Title>
            <ContainerChart>
                <DynamicBarChart
                    print
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            axios
                                .get(
                                    `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=serveReturn&type=first`,
                                    {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    }
                                )
                                .then((result) => {
                                    const chartData = formatDynamicChartData(result.data);
                                    allReady[0] = true;
                                    handleReady();
                                    resolve(chartData);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        })
                    }
                    name="servereturnfirst"
                    type="bar"
                />
            </ContainerChart>
            <ContainerChart>
                <RallyEffectivenessChart
                    print
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            axios
                                .get(
                                    `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=serveReturn&type=first&rallyOrder=${query.order}`,
                                    {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    }
                                )
                                .then((result) => {
                                    const chartData = formatRallyEffectivenessData(result.data);
                                    allReady[1] = true;
                                    handleReady();
                                    resolve(chartData);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        })
                    }
                    width={500}
                    height={400}
                    type="bar"
                />
            </ContainerChart>
            <Title>{translate("rally on second serve and return")}</Title>
            <ContainerChart>
                <DynamicBarChart
                    print
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            axios
                                .get(
                                    `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=serveReturn&type=second`,
                                    {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    }
                                )
                                .then((result) => {
                                    const chartData = formatDynamicChartData(result.data);
                                    allReady[2] = true;
                                    handleReady();
                                    resolve(chartData);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        })
                    }
                    name="servereturnsecond"
                    type="bar"
                />
            </ContainerChart>
            <ContainerChart>
                <RallyEffectivenessChart
                    print
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            axios
                                .get(
                                    `${API_BACKEND}/historicalrallylength?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}&serveReturn=serveReturn&type=second&rallyOrder=${query.order}`,
                                    {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    }
                                )
                                .then((result) => {
                                    const chartData = formatRallyEffectivenessData(result.data);
                                    allReady[3] = true;
                                    handleReady();
                                    resolve(chartData);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        })
                    }
                    width={500}
                    height={400}
                    type="bar"
                />
            </ContainerChart>
        </Root>
    );
};

export default RallyServeReturn;
