import React from "react";
import styled from "styled-components";
import { MiniMatchesWithSemaphoreChart } from "../../components/gsmcharts";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import { formatData } from "../../components/gsmcharts/miniMatchesWithSemaphoreChart/config";
import translate from "../../providers/i18n/translate";
import { useSelector } from "react-redux";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  padding: 20px 0;
  @media (min-width: 1024px) {
    padding: 20px 0;
  }
`;

const PageDescription = styled.p`
  color: ${(props) => props.theme.palette.principal};
  font-size: 1rem;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 1.25rem;
    text-align: left;
  }
`;

const ContainerChart = styled.div`
  position: relative;
    width: 100%;
    height: 250px;
    margin: auto;
    .apexcharts-canvas {
        margin: 0 auto;
    }
    @media (min-width: 600px) {
        height: 400px;    
    }
    @media (min-width: 1024px) {
        height: 250px;    
    }
    @media (min-width: 1440px) {
        height: 350px;    
    }
`

const Minimatches = () => {
  const { token } = useAuth();

  const MATCHES_LIMIT = 10;

  const {appliedFilters} = useSelector(state => state.selectedDashboardFilters);

  return (
    <Root>
      <PageDescription>{translate("Effectiveness in points played with the first and second serve and with the return of the first and second serve.")}</PageDescription>
      <ContainerChart>
      <MiniMatchesWithSemaphoreChart
        width={500}
        height={600}
        type="bar"
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .get(
                `${API_BACKEND}/historicalminimatches?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((result) => {
                const chartData = formatData({
                    dataMiniMatches: result.data,
                    historicalMiniMatches:{}
                });
                resolve(chartData);
              })
              .catch((err) => {
                console.log(err);
              });
          })
        }
      />
      </ContainerChart>
    </Root>
  );
};

export default Minimatches;
