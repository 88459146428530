import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import { useIntl } from 'react-intl';
import { _options } from "./config";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import { ThemeContext } from "../../../providers/theme";
import translate from "../../../providers/i18n/translate";
import Semaphore from "./semaphore";
import Popup from "../../popup/Popup";

const Root = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    opacity: ${props => props.loading === "true" ? 0.3 : 1};
    padding: 20px;
    overflow: hidden;
    background: ${props => props.dashboard ? props.theme.palette.principal : `linear-gradient(0deg, ${props.theme.palette.alternative_accent} 0%, ${props.theme.palette.principal} 100%) `};
    border-radius: 20px;
    justify-content: space-between;
`

const ContainerChart = styled.div`
    width: 100%;
    height: 225px;
    margin: ${props => props.margin ? props.margin : "auto 0"};
    .apexcharts-canvas {
        margin: ${props => props.margin ? props.margin : "0 auto"};
    }
    @media (min-width: 600px) {
        height: 215px;
    }
    @media (min-width: 1024px) {
        height: 175px;
    }
    @media (min-width: 1440px) {
        height: 200px;
    }
`

const Title = styled.h5`
    font-size: 1rem;
    color: ${props => props.theme.palette.accent};
    margin: 10px 0 0 0;
    width: 80%;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0 0 10px 0;
    @media (min-width: 600px) {
      font-size: 0.9rem;
    }
    @media (min-width: 1440px) {
      font-size: 1rem;
    }
`

const MatchQualityChart = ({ data, rawData, width, height, margin, fontSize, sets, dashboard, matchId }) => {

    const intl = useIntl();

    const [semaphore, setSemaphore] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const theme = React.useContext(ThemeContext);

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const getData = () => {
        setLoading(true);
        if (data) {
            data()
                .then(result => {
                    setSemaphore(result.semaphore);
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                    alertMessage(err.response.data.msg, alertConstants.ERROR);
                    setLoading(false);
                })
        } else {
            if (rawData) {
                setSemaphore(rawData.semaphore);
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, [JSON.stringify(sets), matchId]);

    return (
        <Root loading={loading ? "true" : "false"} dashboard={dashboard}>
            <Popup
                title={intl.formatMessage({ id: 'Match quality' })}
                content={`<p>${intl.formatMessage({ id: 'help match quality' })}</p>`}
            />
            <Title>{translate("Match quality")}</Title>
            <ContainerChart height={height} margin={margin}>
                {
                    semaphore && semaphore.sum && semaphore.msg &&
                    <Semaphore background="transparent" orientation="vertical" width={width} fontSize={fontSize} widthMsg={'60%'} value={semaphore.sum} msg={semaphore.msg} />
                }
            </ContainerChart>
        </Root>
    );
}

MatchQualityChart.propTypes = {
    data: PropTypes.any,
    rawData: PropTypes.any,
    width: PropTypes.number,
    height: PropTypes.number
}

export default MatchQualityChart;