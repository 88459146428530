import styled from 'styled-components'

export const StyledLanguageSlider = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  
  .application-settings-btn-label {
    margin-right: 50px;
    font-size: 1.5rem;
  }
  
.btn-container {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
  cursor: pointer;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner {
  margin: 0px;
  width: 140px;
  height: 40px;
  background-color: ${props => props.theme.palette.alternative_accent};
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  display: block;
  cursor: pointer;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  font-size: 20px;
  font-weight: 600;
  top: 7px;
  right: 20px;
  color: #FFF;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
  content: attr(data-off);
  width: 70px;
  height: 25px;
  background: #fff;
  border-radius: 26px;
  position: absolute;
  font-size: 20px;
  display: flex;
  justify-content: center;
  left: 2px;
  top: 2px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #111;
  padding: 5px 0px;
  color: #222;
  font-weight: 600;
}

.btn-color-mode-switch input[type="checkbox"] {
  cursor: pointer;
  width: 50px;
  height: 25px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner {
  background-color: ${props => props.theme.palette.alternative_accent};
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: 68px;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  left: 20px;
}
`