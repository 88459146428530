  const getLeftData = (d) => {
    switch (d.type) {
      case 1:
        return {
          value: d.values[0],
          label: d.values[0]
        }
        break;
      case 2:
        return {
          value: d.values[0][0],
          label: `${d.values[0][0]}/${d.values[0][1]}`
        }
        break;
      case 3:
        return {
          value: d.values[0][2],
          label: `${d.values[0][2]}% (${d.values[0][0]}/${d.values[0][1]})`
        }
        break;
      default:
        return {
          value: 0,
          label: 0
        };
        break;
    }
  }

  const getRightData = (d) => {
    switch (d.type) {
      case 1:
        return {
          value: d.values[1],
          label: d.values[1]
        }
        break;
      case 2:
        return {
          value: d.values[1][0],
          label: `${d.values[1][0]}/${d.values[1][1]}`
        }
        break;
      case 3:
        return {
          value: d.values[1][2],
          label: `${d.values[1][2]}% (${d.values[1][0]}/${d.values[1][1]})`
        }
        break;
      default:
        return {
          value: 0,
          label: 0
        };
        break;
    }
  }
  
  export const formatData = (data) => {
    const result = [];
    data.forEach(element => {
      result.push({
        left: getLeftData(element),
        center: element.name,
        right: getRightData(element),
      })
    });
    return result;
  }