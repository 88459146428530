export const getSetsList = (sets) => {
    let setlist = "";
    if(sets){
        for (let index = 0; index < sets.length; index++) {
            if (sets[index] === true) {
                setlist = setlist + (index + 1);
                if (sets.length > 1 && index !== sets.length - 1) {
                    setlist = setlist + ",";
                }
            }
        }
    }
    return setlist;
};