import React from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import styled from "styled-components";
import translate from "../../../../providers/i18n/translate";
import axios from "axios";
import { useAuth } from "../../../../providers/authentication";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../../store/action-creators";
import { alertConstants } from "../../../../store/reducers/messageBar/alertConstants";
import { API_BACKEND } from "../../../../config";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const Title = styled.h2`
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    margin: 0 0 20px 0;
`

const Description = styled.p`
    position: relative;
    font-size: 1rem;
    color: ${(props) => props.theme.palette.details};
    font-weight: 500;
    margin-bottom: 15px;
`;

const AverageDescription = styled.p`
    position: relative;
    align-self: center;
    font-size: 1rem;
    color: ${(props) => props.theme.palette.details};
    font-weight: 700;
    margin-bottom: 10px;
`;

const StyledRadioGroup = styled(RadioGroup)`
.MuiRadio-colorSecondary.Mui-checked {
    color: #10a9c7;
}
`;

const StyledTextField = styled(TextField)`
    @media (min-width: 600px) {
        width: ${(props) => (props.width ? props.width : "180px")};
}
`;

const Action = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    font-family: museo-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: ${(props) => props.theme.palette.base};
    border-radius: 35px;
    padding: 15px 0;
    width: 175px;
    background-color: ${(props) => props.theme.palette.alternative_accent};
    box-shadow: none;
    border: ${(props) => props.border ? props.border : 'none'};
    margin: ${(props) => props.margin ? props.margin : '15px 10px;'};
    cursor: pointer;
    &:disabled {
        color: #5D5D5D;
        background: #ECECEC;
        cursor: not-allowed;
    }
    @media (min-width: 600px) {
        font-size: 1rem;
}
`

const InputContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    width: 100%;
    height: auto;
    margin: 10px 0;
    @media (min-width: 1024px) {
        width: 75%;
        flex-direction: row;
    }
    @media (min-width: 1440px) {
        width: 55%;
        flex-direction: row;
    }
`

const NewDesiredMargin = ({ handleClose, performanceAverage, handleDesiredMargin }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { alertMessage } = bindActionCreators(actionCreators, dispatch);
    const { token } = useAuth();

    const [inputMargin, setInputMargin] = React.useState("")
    const [desiredMarginSelected, setDesiredMarginSelected] = React.useState("")

    const [marginDate, setMarginDate] = React.useState({
        startDate: '',
        endDate: ''
    })

    const handleChangeInputDesiredMargin = (e) => {
        const { value } = e.target;
        setInputMargin(value)
    }

    const handleChangeDesiredMarginSelected = (e) => {
        const { value } = e.target;
        setDesiredMarginSelected(value)
    }

    const handleChangeDate = (e) => {
        const { name, value } = e.target;
        setMarginDate({
            ...marginDate,
            [name]: value
        })
    }

    const getDesiredMarginValue = (original, desiredMarginSelected, inputMargin) => {
        switch (desiredMarginSelected) {
            case 'moderated':
                return original < 0 ? (parseFloat(original) * 0.9).toFixed(2) : (parseFloat(original) * 1.1).toFixed(2);
            case 'realistic':
                return original < 0 ? (parseFloat(original) * 0.8).toFixed(2) : (parseFloat(original) * 1.2).toFixed(2);
            case 'optimum':
                return original < 0 ? (parseFloat(original) * 0.7).toFixed(2) : (parseFloat(original) * 1.3).toFixed(2);
            case 'other':
                return inputMargin
            default:
                return original;
        }
    }

    const onClick = (e) => {
        e.preventDefault();
        let desiredMarginValue = getDesiredMarginValue(performanceAverage, desiredMarginSelected, inputMargin)
        if (marginDate.startDate && marginDate.endDate && desiredMarginValue) {
            const url = `${API_BACKEND}/playerTraining/goal`;
            axios.post(url, {
                type: "desiredMargin",
                value: desiredMarginValue,
                startDate: new Date(marginDate.startDate),
                endDate: new Date(marginDate.endDate)
            },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(function (response) {
                    alertMessage(intl.formatMessage({ id: "You have set your desired margin" }), alertConstants.SUCCESS);
                    handleDesiredMargin(desiredMarginValue)
                    handleClose();
                })
                .catch(error => {
                    alertMessage(error.response.data.msg, alertConstants.ERROR);
                });
        }
        else
            alertMessage(intl.formatMessage({ id: "You have to complete required fields" }), alertConstants.WARNING);
    }

    return (
        <Root>
            <Title>{translate("Set desired margin")}</Title>
            <Description>{translate("setdesireddescription")}</Description>
            <AverageDescription>{translate("Your performance average is: ")}{performanceAverage}</AverageDescription>
            <StyledForm onSubmit={onClick}>
                <FormControl component="fieldset" margin="0 0 10px 0">
                    <StyledRadioGroup
                        aria-label="desiredmargin"
                        value={desiredMarginSelected}
                        onChange={handleChangeDesiredMarginSelected}
                        name="radio-buttons-group"
                        required
                    >
                        <FormControlLabel
                            value="moderated"
                            control={<Radio />}
                            label={translate("moderated")}
                        />
                        <FormControlLabel
                            value="realistic"
                            control={<Radio />}
                            label={translate("realistic")}
                        />
                        <FormControlLabel
                            value="optimum"
                            control={<Radio />}
                            label={translate("optimum")}
                        />
                        <FormControlLabel
                            value="other"
                            control={
                                <Radio />
                            }
                            label={
                                desiredMarginSelected === "other" ?
                                    <StyledTextField
                                        value={inputMargin}
                                        required
                                        id="other"
                                        name="other"
                                        label={translate("other")}
                                        type="number"
                                        inputProps={{
                                            step: 0.01,
                                        }}
                                        variant="outlined"
                                        onChange={handleChangeInputDesiredMargin}
                                    /> :
                                    translate("other")
                            } />
                    </StyledRadioGroup>
                </FormControl>
                <InputContainer>
                    <StyledTextField
                        id="startDate"
                        name="startDate"
                        label={translate("Start date")}
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={marginDate.startDate}
                        onChange={handleChangeDate}
                        variant="outlined"
                        margin="dense"
                        required
                    />
                    <StyledTextField
                        id="endDate"
                        name="endDate"
                        label={translate("End date")}
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={marginDate.endDate}
                        onChange={handleChangeDate}
                        variant="outlined"
                        margin="dense"
                        required
                    />
                </InputContainer>
                <Action type="submit">
                    {translate("set margin")}
                </Action>
            </StyledForm>
        </Root>
    );
};

export default NewDesiredMargin;
