import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const Root = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 999999999;
    background-color: rgba(255,255,255,0.7);
`

const SimpleCircularLoading = () => {

    return (
        <Root>
            <CircularProgress />
        </Root>
    );
}

export default SimpleCircularLoading;