import React from "react";
import { Routes, Route } from "react-router-dom";
import { I18nProvider, LOCALES } from "./providers/i18n";
import { ThemeProvider, THEMES } from "./providers/theme";
import AuthProvider from "./providers/authentication";
import GlobalStyle from "./globalStyles";
import Protected from "./providers/authentication/protected";
// LAYOUTS
import WebLayout from "./components/layouts/WebLayout";
import AdminLayout from "./components/layouts/AdminLayout";
// PAGES
import NotFoundPage from "./pages/NotFoundPage";
import HomePage from "./pages/HomePage";
import DashboardPage from "./pages/DashboardPage";
import UploadPage from "./pages/UploadPage";
import PlayerProfilePage from "./pages/PlayerProfilePage";
import MatchesPage from "./pages/MatchesPage";
import SettingsPage from "./pages/SettingsPage";
import Login from "./pages/Login";
import UsersPage from "./pages/UsersPage";
import CalendarPage from "./pages/CalendarPage";
// COMPONENTS
import MessageBar from "./components/messageBar";
import AboutPage from "./pages/AboutPage";
import MatchLayout from "./components/layouts/MatchLayout";
import MiniMatchesPage from "./pages/MiniMatchesPage";
import RallyPage from "./pages/RallyPage";
import FlowPage from "./pages/FlowPage";
import ServePage from "./pages/ServePage";
import ReturnPage from "./pages/ReturnPage";
import SemPage from "./pages/SemPage";
import RemPage from "./pages/RemPage";
import OverviewPage from "./pages/OverviewPage";
import Private from "./providers/authentication/private";
import PrivateMatch from "./providers/authentication/privateMatch";
import UnforcedErrorsPage from "./pages/UnforcedErrorsPage";
import Profile from "./pages/PlayerProfilePage/Profile";
import Radar from "./pages/PlayerProfilePage/Radar";
import EditMatchPage from "./pages/EditMatchPage";
import ReportPage from "./pages/ReportPage";
import SimulationPage from "./pages/SimulationPage";
import Summary from "./pages/Summary";
import CourtPage from "./pages/CourtPage";

function App() {
  return (
    <React.Fragment>
      <GlobalStyle />
      <ThemeProvider theme={THEMES.default}>
        <I18nProvider>
          <AuthProvider>
            <MessageBar />
            <Routes>
              <Route path="/" element={<WebLayout />}>
                <Route index element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route
                exact
                path="/admin"
                element={
                  <Protected>
                    <AdminLayout />
                  </Protected>
                }
              >
                <Route path="/admin/dashboard" element={<Summary />} />
                <Route path="/admin/summary/*" element={<DashboardPage />} />
                <Route path="/admin/uploadmatch" element={<UploadPage />} />
                <Route path="/admin/report" element={<ReportPage />} />
                <Route path="/admin/simulation" element={<SimulationPage />} />
                <Route
                  path="/admin/playerprofile/:id"
                  element={
                    <Private>
                      <PlayerProfilePage />
                    </Private>
                  }
                >
                  <Route
                    path="/admin/playerprofile/:id/radar"
                    element={<Radar />}
                  />
                  <Route
                    path="/admin/playerprofile/:id/profile"
                    element={<Profile />}
                  />
                </Route>
                <Route path="/admin/matches" element={<MatchesPage />} />
                <Route
                  path="/admin/accountsettings/:id"
                  element={
                    <Private>
                      <SettingsPage />
                    </Private>
                  }
                />
                <Route path="/admin/users" element={<UsersPage />} />
                <Route path="/admin/calendar" element={<CalendarPage />} />
                <Route
                  path="/admin/match/:id/edit"
                  element={<EditMatchPage />}
                />
                <Route
                  path="/admin/match/:id"
                  element={
                    <PrivateMatch>
                      <MatchLayout />
                    </PrivateMatch>
                  }
                >
                  <Route
                    path="/admin/match/:id/minimatches"
                    element={<MiniMatchesPage />}
                  />
                  <Route
                    path="/admin/match/:id/rally"
                    element={<RallyPage />}
                  />
                  <Route path="/admin/match/:id/flow" element={<FlowPage />} />
                  <Route
                    path="/admin/match/:id/serve"
                    element={<ServePage />}
                  />
                  <Route path="/admin/match/:id/sem" element={<SemPage />} />
                  <Route
                    path="/admin/match/:id/return"
                    element={<ReturnPage />}
                  />
                  <Route path="/admin/match/:id/rem" element={<RemPage />} />
                  <Route
                    path="/admin/match/:id/performance"
                    element={<OverviewPage />}
                  />
                  <Route
                    path="/admin/match/:id/unforcederrors"
                    element={<UnforcedErrorsPage />}
                  />
                  <Route
                    path="/admin/match/:id/court"
                    element={<CourtPage />}
                  />
                </Route>
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </AuthProvider>
        </I18nProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
