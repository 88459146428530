import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import SurfaceFilter from "./surfaceFilter";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { dashboardFiltersConstants } from "../../store/reducers/dashboardFilters/dashboardFiltersConstants";
import PlayerWinnerFilter from "./playerWinnerFilter";
import RoundsFilter from "./roundsFilter";
import DateFilter from "./dateFilter";
import TournamentFilter from "./tournamentFilter";

const Root = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
`

const Header = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 20px;
`

const FilterButton = styled.button`
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    svg {
        font-size: 2rem;
        color: ${props => props.theme.palette.principal};
    }
`

const Body = styled.div`
    width: 100%;
    height: ${props => props.open ? "auto" : 0};
    overflow-y: hidden;
    transition: all 500ms ease-in-out;
    display: flex;
    flex-direction: column;
`

const FilterList = styled.div`
    width: 100%;
    height: 350px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 0.8fr 0.2fr;
    grid-auto-rows: 100px;
    gap: 20px;
    padding: 20px;
`

const ApplyFilter = styled.button`
    color: ${props => props.theme.palette.base};
    background: ${props => props.theme.palette.alternative_accent};
    border: none;
    outline: none;
    margin: 0 10px 0 0;
    padding: 10px 16px;
    cursor: pointer;
    text-transform: uppercase;
    display: ${props => props.hide ? "none": "inherit"};
    font-weight: 600;
    border-radius: 8px;
    font-size: 1rem;
`

const Container = styled.div`
    width: 100%;
    height: 100%;
`

const DateContainer = styled(Container)`
    grid-column: 2/3;
    grid-row: 1/2;
`

const WinnerContainer = styled(Container)`
    grid-column: 1/2;
    grid-row: 1/2;
`

const TournamentContainer = styled(Container)`
    grid-column: 1/3;
    grid-row: 2/3;
`

const SurfacesContainer = styled(Container)`
    grid-column: 3/4;
    grid-row: 1/3;
`

const RoundsContainer = styled(Container)`
    grid-column: 4/5;
    grid-row: 1/3;
`



const Filters = () => {

    const theme = React.useContext(ThemeContext);

    const [ open, setOpen ] = React.useState(false);

    const switchFilters = () => {
        setOpen(!open);
    }

    const {filters} = useSelector(state => state.selectedDashboardFilters);

    const dispatch = useDispatch();

    const { resetDashboardFilters, applyDashboardFilters } = bindActionCreators(actionCreators, dispatch);

    const applyFilters = () => {
        const filtersSelected = filters.map(fil => { return {...fil}});
        applyDashboardFilters(dashboardFiltersConstants.APPLY, filtersSelected);
    }

    const resetFilters = () => {
        resetDashboardFilters(dashboardFiltersConstants.RESET);
    }

    return (
        <Root>
            <Header>
                <ApplyFilter hide={!open} onClick={resetFilters}>{translate("reset")}</ApplyFilter>
                <ApplyFilter hide={!open} onClick={applyFilters}>{translate("apply")}</ApplyFilter>
                <FilterButton onClick={switchFilters}>{theme.icons.filterList}</FilterButton>
            </Header>
            <Body open={open}>
                {/* <FilterList>
                    <SurfaceFilter />
                    <PlayerWinnerFilter />
                    <RoundsFilter />
                    <DateFilter />
                </FilterList> */}
                <FilterList>
                    <WinnerContainer>
                        <PlayerWinnerFilter />
                    </WinnerContainer>
                    <DateContainer>
                        <DateFilter />
                    </DateContainer>
                    <TournamentContainer>
                        <TournamentFilter />
                    </TournamentContainer>
                    <SurfacesContainer>
                        <SurfaceFilter />
                    </SurfacesContainer>
                    <RoundsContainer>
                        <RoundsFilter />
                    </RoundsContainer>
                </FilterList>
                
            </Body>
        </Root>
    );
}

export default Filters;