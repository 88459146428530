import React from "react";
import styled from "styled-components";
import Message from "./Message";

const Root = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-image: url("/assets/loading.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
`



const LoadingReport = ({elements}) => {
    return (
        <Root>
            <Message elements={elements} />
        </Root>
    );
}

export default LoadingReport;