import { setsConstants } from "./setsConstants";

const initialState = { 
  sets: null
};

export function selectedSets(state = initialState, action) {
  switch (action.type) {
    case setsConstants.SET:
      return {
        ...state,
        sets: action.sets
      };
    default:
      return state
  }
}