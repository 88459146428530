import React from "react";
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";
import { _options } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";

const Root = styled.div`
    position: relative;
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    opacity: ${props => props.loading === "true" ? 0.3 : 1};
    @media (min-width: 600px) {
        height: 215px;
    }
    @media (min-width: 1024px) {
        height: 175px;
    }
    @media (min-width: 1440px) {
        height: 215px;
    }
`

const Content = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`

const ContainerChart = styled.div`
    width: 100%;
    height: 350px;
    margin: auto;
    .apexcharts-canvas {
        margin: 0 auto;
    }
    @media (min-width: 600px) {
        height: 300px;
    }
    @media (min-width: 1024px) {
        height: 250px;
    }
    @media (min-width: 1440px) {
        height: 325px;
    }
`

const Text = styled.p`
    position: absolute;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    color: ${props => props.theme.palette.base};
    height: auto;
    text-align: center;
    font-size: 1.5rem;
    bottom: 5%;
    @media (min-width: 600px) {
        width: 60%;
        font-size: 1.25rem;
    }
    @media (min-width: 1024px) {
        width: 85%;
    }
    @media (min-width: 1440px) {
        width: 60%;
    }
`

const SemiCircleRallyChart = ({ data, rawData, width, height, options, type, matchId }) => {

    const [series, setSeries] = React.useState(null);
    const [text, setText] = React.useState(null);
    const [label, setLabel] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const getData = () => {
        setLoading(true);
        if (data) {
            data()
                .then(result => {
                    setSeries(result.series);
                    setText(result.text);
                    setLabel(['RALLY ' + result.label]);
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                    alertMessage(err.response.data.msg, alertConstants.ERROR);
                    setLoading(false);
                })
        } else {
            if (rawData) {
                setSeries(rawData.series);
                setText(rawData.text);
                setLabel(rawData.label);
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, [matchId]);


    return (
        <Root loading={loading ? "true" : "false"}>
            <Content>
                {
                    series &&
                    <ContainerChart >
                        <ReactApexChart
                            options={options ?
                                {
                                    ..._options,
                                    chart: {
                                        ..._options.chart,
                                        with: width,
                                        height: height
                                    },
                                    labels: label,
                                    ...options,
                                }
                                :
                                {
                                    ..._options,
                                    chart: {
                                        ..._options.chart,
                                        with: width,
                                        height: height
                                    },
                                    labels: label
                                }
                            }
                            width="100%"
                            height="100%"
                            series={series}
                            type={type ? type : "radialBar"}
                        />
                    </ContainerChart>
                }
            </Content>
            {
                text &&
                <Text>{text}</Text>
            }
        </Root>
    );
}

export default SemiCircleRallyChart;