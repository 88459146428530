import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import styled from "styled-components";
import translate from "../../../providers/i18n/translate";
import CheckInput from "../../inputs/CheckInput";
import { surfaces } from "./surfaces";
import { dashboardFiltersConstants } from "../../../store/reducers/dashboardFilters/dashboardFiltersConstants";

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  padding: 14px;
  border-radius: 10px;
`;

const Title = styled.h2`
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  margin-bottom: 0.25rem;
`;

const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  width: 100%;
  height: auto;
  overflow-y: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 14px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => `${props.theme.palette.details}90`};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.palette.alternative_details};
  }
`;

const SurfaceFilter = () => {

  const { filters } = useSelector((state) => state.selectedDashboardFilters);

  const dispatch = useDispatch();

  const { changeDashboardFilters } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const handleChange = (name, value) => {
    const selectedSurfaces = filters.find((fil) => fil.name === "surface");
    const newFilters = [];
    if (selectedSurfaces) {
      if (selectedSurfaces.values.includes(name)) {
        selectedSurfaces.values = selectedSurfaces.values.filter(
          (val) => val !== name
        );
      } else {
        selectedSurfaces.values = [...selectedSurfaces.values, name];
      }
      changeDashboardFilters(dashboardFiltersConstants.SET, filters);
    } else {
      newFilters.push({
        name: "surface",
        values: [name],
        condition: "equal",
        type: "string",
      });
      changeDashboardFilters(dashboardFiltersConstants.SET, [...filters, ...newFilters]);
    }
  };

  return (
    <Root>
      <Title>{translate("Surfaces")}</Title>
      <Options>
        {surfaces.map((sur, index) => (
          <CheckInput
            key={`check_surface_${index}`}
            checked={
              filters.find((fil) => fil.name === "surface")
                ? filters
                    .find((fil) => fil.name === "surface")
                    .values.includes(sur.name)
                : false
            }
            onChange={handleChange}
            label={sur.name}
            name={sur.name}
            width="auto"
          />
        ))}
      </Options>
    </Root>
  );
};

export default SurfaceFilter;
