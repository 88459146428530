import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyIcon from '@mui/icons-material/Key';
import AddIcon from '@mui/icons-material/Add';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';
import GridViewIcon from '@mui/icons-material/GridView';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MenuIcon from '@mui/icons-material/Menu';
import ApiIcon from '@mui/icons-material/Api';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HexagonIcon from '@mui/icons-material/Hexagon';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SportsIcon from '@mui/icons-material/Sports';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterListIcon from '@mui/icons-material/FilterList';
import BlockIcon from '@mui/icons-material/Block';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SaveIcon from '@mui/icons-material/Save';
import TokenIcon from '@mui/icons-material/Token';
import PrintIcon from '@mui/icons-material/Print';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import styled from 'styled-components';
import SummaryIcon from '../../assets/icons/summary.svg';
import SendIcon from '@mui/icons-material/Send';

const StyledIcon = styled.img`
    box-sizing: border-box;
    padding: 0px;
    margin-right: 0.5rem;
    border: none;
    display: block;
    width: 25px;
    height: 25px;
`

export const THEMES = {
    default: {
        palette: {
            principal: "#03232D",
            alternative_principal: "#202121",
            base: "#FFFFFF",
            accent: "#dcfe54",
            alternative_accent: "#40cbe6",
            details: "#5d5d5d",
            alternative_details: "#b6bfc1",
            error: "#f02e01"
        },
        icons: {
            view: <VisibilityIcon />,
            add: <AddIcon />,
            edit: <EditIcon />,
            delete: <DeleteForeverIcon />,
            password: <KeyIcon />,
            notifications: <NotificationsIcon />,
            notificationsActive: <NotificationsActiveIcon />,
            notificationsNone: <NotificationsNoneIcon />,
            person: <PersonIcon />,
            settings: <SettingsIcon />,
            settingsOutlined: <SettingsOutlinedIcon />,
            arrowBack: <ArrowBackIcon />,
            arrowForward: <ArrowForwardIcon />,
            arrowDown: <KeyboardArrowDownIcon />,
            arrowUp: <KeyboardArrowUpIcon />,
            logout: <LogoutIcon />,
            gridView: <GridViewIcon />,
            accountCircle: <AccountCircleOutlinedIcon />,
            calendar: <CalendarTodayIcon />,
            menu: <MenuIcon />,
            api: <ApiIcon />,
            wallet: <AccountBalanceWalletIcon />,
            sale: <PointOfSaleIcon />,
            check: <CheckIcon />,
            remove: <RemoveIcon />,
            tree: <AccountTreeIcon />,
            exit: <ExitToAppIcon />,
            hexagon: <HexagonIcon />,
            info: <InfoIcon />,
            alert: <ErrorIcon />,
            close: <CloseIcon />,
            facebook: <FacebookIcon />,
            twitter: <TwitterIcon />,
            youtube: <YouTubeIcon />,
            instagram: <InstagramIcon />,
            upload: <CloudUploadIcon />,
            profile: <AssignmentIndIcon />,
            sports: <SportsIcon />,
            visibility: <VisibilityIcon />,
            visibilityoff: <VisibilityOffIcon />,
            cup: <EmojiEventsOutlinedIcon />,
            creditCard: <CreditCardIcon />,
            help: <HelpOutlineIcon />,
            tennis: <SportsTennisIcon />,
            cam: <CameraAltIcon />,
            filterList: <FilterListIcon />,
            block: <BlockIcon />,
            court: <BorderAllIcon />,
            report: <AssessmentIcon />,
            save: <SaveIcon />,
            simulation: <TokenIcon />,
            print: <PrintIcon />,
            open: <FolderOpenIcon />,
            ball: <SportsBaseballIcon />,
            summary: <StyledIcon src={SummaryIcon} alt="summary icon" />,
            send: <SendIcon />,
        },
        breakpoints: {
            tablet: 600,
            desktop: 1024,
            desktopPlus: 1440
        }
    }
}

