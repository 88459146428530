import React from "react";
import styled from "styled-components";
import Menu from "./Menu";
import Logo from "../../../assets/logo_b.png";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Root = styled.div`
    position: fixed;
    display: flex;
    width: 100%;
    height: ${(props) => props.height ? props.height : "200px"};
    z-index: 9999;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    box-shadow: 5px 0 10px rgba(255,255,255,0.5);
    background: ${props => props.theme.palette.alternative_accent};
`

const ContentLogo = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 50%;
    height: 100%;
    a {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    img {
        width: auto;
        height: 100%;
        cursor: pointer;
        object-fit: contain;
        object-position: center;
        transition-property: all;
        transition-duration: 200ms;
        transition-timing-function: ease-in-out;
    }
    img:hover {
        transform: scale(1.1);
    }
    @media (min-width: 1024px) {
        position: relative;
        top: inherit;
        left: inherit;
        transform: translate(0,0);
        width: 20%;
    }
`

const AccessMenu = styled.div`
    display: flex;
    width: auto;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    user-select: none;
    z-index: 9999;
    svg {
        font-size: 2.5rem;
        color: ${props => props.theme.palette.base};
    }
    @media (min-width: 1024px) {
        display: none;
    }
`

const WebNav = ({height}) => {

    const [ showMenu, setShowMenu ] = React.useState(false);
    const [ navAnimate, setNavAnimate ] = React.useState(false);

    const switchMenu = () => {
        setShowMenu(!showMenu);
    }

    React.useLayoutEffect(() => {
    
        const onScroll = () => {
            const scrollPos = window.scrollY;
            if (scrollPos > 100) {
                setNavAnimate(true)
            } else {
                setNavAnimate(false)
            }
        };
    
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return (
        <Root height={height} scroll={navAnimate}>
            <ContentLogo>
                <Link to="/">
                    <img src={Logo} alt="Logo" width="100%" height="100%" />
                </Link>
            </ContentLogo>
            <AccessMenu onClick={switchMenu} show={showMenu}>
                {
                    showMenu ?
                    <CloseIcon />
                    :
                    <MenuIcon/>
                }
            </AccessMenu>
            <Menu width="80%" show={showMenu} switchMenu={switchMenu} />
        </Root>
    );
}

export default WebNav;