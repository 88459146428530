import React from "react";
import styled from "styled-components";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import translate from "../../providers/i18n/translate";
import LastMatchChart from "../../components/gsmcharts/lastMatchChart";
import MatchPerformanceChart from "../../components/gsmcharts/matchPerformanceChart";
import {
  formatHistoricalData,
  getTooltipHistoricalPerformance,
} from "../../components/gsmcharts/matchPerformanceChart/config";
import { formatData as formatLeadershipData } from "../../components/gsmcharts/leadershipChart/config";
import LeadershipChart from "../../components/gsmcharts/leadershipChart";
import MatchQualityListChart from "../../components/gsmcharts/matchQualityListChart";
import SemiCircleChart from "../../components/gsmcharts/semiCircleChart";
import { formatTotalPointsWonData } from "../../components/gsmcharts/semiCircleChart/config";
import SunburstZoomableChart from "../../components/gsmcharts/sunburstZoomableChart";
import { useSelector } from "react-redux";
import { useIntl } from 'react-intl';
import Popup from "../../components/popup/Popup";

/* Styled components */
const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  padding: 20px;
  gap: 20px;
  @media (min-width: 1024px) {
    padding: 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const PageTitle = styled.h2`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.principal};
  font-size: 1.25rem;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 1.5rem;
    text-align: left;
    grid-column: 1/4;
  }
`;

const PageDescription = styled.p`
  color: ${(props) => props.theme.palette.principal};
  font-size: 1rem;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 1.25rem;
    text-align: left;
    grid-column: 1/4;
  }
`;

const ContainerChart = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  margin: auto;
  .apexcharts-canvas {
    margin: 0 auto;
  }
  @media (min-width: 600px) {
    height: 100%;
  }
  @media (min-width: 1024px) {
    height: 100%;
  }
  @media (min-width: 1440px) {
    height: 100%;
  }
`;

const ContainerPerformanceChart = styled(ContainerChart)`
  width: 100%;
  grid-column: 2/4;
  background: ${(props) => props.theme.palette.principal};
  height: 100%;
  border-radius: 20px;
`;

const ContainerSemiCircle = styled(ContainerChart)`
  background: ${(props) => props.theme.palette.principal};
  overflow: hidden;
  height: 300px;
  border-radius: 20px;
  padding: 20px 0;
  @media (min-width: 600px) {
    height: 380px;
  }
  @media (min-width: 1024px) {
    height: 300px;
  }
  @media (min-width: 1440px) {
    height: 350px;
  }
`;

const SunburstTitle = styled.h2`
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.details};
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 10px;
  @media (min-width: 1024px) {
    font-size: 1.25rem;
    text-align: left;
  }
  @media (min-width: 1440px) {
    font-size: 1.5rem;
    text-align: left;
  }
`;

const SunburstContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  grid-column: 2/4;
  grid-row: 4/6;
`

const Overview = () => {
  const { token } = useAuth();
  const intl = useIntl();
  const MATCHES_LIMIT = 10;
  const { appliedFilters } = useSelector(state => state.selectedDashboardFilters);

  const optionsHistoricalPerformance = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return getTooltipHistoricalPerformance(
          series,
          seriesIndex,
          dataPointIndex,
          w
        );
      },
    },
  };

  const optionsSemiCircle = {
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          size: 50,
          background: "transparent",
          margin: 0,
        },
        track: {
          background: "#3C555C",
          strokeWidth: "75%",
          startAngle: -90,
          endAngle: 90,
          opacity: 0.25,
        },
        dataLabels: {
          show: true,
          name: {
            show: true,
            color: "#DCFE54",
            offsetY: -70,
            fontSize: "1rem",
          },
          value: {
            show: true,
            color: "#FFFFFF",
            offsetY: -25,
            fontSize: "3rem",
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                show: true,
                name: {
                  show: true,
                  color: "#DCFE54",
                  offsetY: -60,
                  fontSize: '0.70rem',
                },
                value: {
                  show: true,
                  color: "#FFFFFF",
                  offsetY: -30,
                  fontSize: '2.5rem',
                  formatter: function (val) {
                    return val + '%'
                  }
                }
              }
            }
          },
        },
      },
      {
        breakpoint: 1023,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                show: true,
                name: {
                  show: true,
                  color: "#DCFE54",
                  offsetY: -80,
                  fontSize: '1.25rem',
                },
                value: {
                  show: true,
                  color: "#FFFFFF",
                  offsetY: -30,
                  fontSize: '3.25rem',
                  formatter: function (val) {
                    return val + '%'
                  }
                }
              }
            }
          },
        },
      },
      {
        breakpoint: 1440,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                show: true,
                name: {
                  show: true,
                  color: "#DCFE54",
                  offsetY: -55,
                  fontSize: '0.85rem',
                },
                value: {
                  show: true,
                  color: "#FFFFFF",
                  offsetY: -30,
                  fontSize: '2.5rem',
                  formatter: function (val) {
                    return val + '%'
                  }
                }
              }
            }
          },
        },
      },
      {
        breakpoint: 1441,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                show: true,
                name: {
                  show: true,
                  color: "#DCFE54",
                  offsetY: -70,
                  fontSize: '1rem',
                },
                value: {
                  show: true,
                  color: "#FFFFFF",
                  offsetY: -30,
                  fontSize: '3rem',
                  formatter: function (val) {
                    return val + '%'
                  }
                }
              }
            }
          },
        },
      }
    ]
  };

  React.useEffect(() => {
  }, [JSON.stringify(appliedFilters)])

  return (
    <Root>
      <PageTitle>{translate("Latest stats summary")}</PageTitle>
      <LastMatchChart />
      <ContainerChart>
        <LeadershipChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalleadershipmargin?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatLeadershipData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="pie"
          width={500}
          height={500}
        />
      </ContainerChart>
      <ContainerPerformanceChart>
        <Popup
          title={intl.formatMessage({ id: 'Match performance' })}
          content={`<p>${intl.formatMessage({ id: 'help match performance p1' })}</p>
          <p>${intl.formatMessage({ id: 'help match performance p2' })}</p>`}
        />
        <MatchPerformanceChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalperformance?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatHistoricalData(
                    result.data,
                    query.desiredMargin
                  );
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="line"
          width={500}
          height={500}
          options={optionsHistoricalPerformance}
        />
      </ContainerPerformanceChart>
      <MatchQualityListChart />
      <SunburstContainer>
        <SunburstTitle>{translate("Historical Forehand and Backhand Key Shots")}</SunburstTitle>
        <SunburstZoomableChart
          id="s1"
          size={600}
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalkeyshots?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  resolve(result.data);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
        />
      </SunburstContainer>
      <ContainerSemiCircle>
        <Popup
          title={intl.formatMessage({ id: '% Total Points Won' })}
          content={intl.formatMessage({ id: 'The % of points won along the last 10 matches.' })}
        />
        <SemiCircleChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalpointswon?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatTotalPointsWonData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="radialBar"
          labels={["% Total Points Won"]}
          width={500}
          height={500}
          options={optionsSemiCircle}
        />
      </ContainerSemiCircle>
    </Root>
  );
};

export default Overview;
