import { Navigate } from 'react-router-dom';
import { useAuth } from '.';
import { isExpired } from '../../hooks/jwt';
  
  
const Protected = ({ children }) => {
    const { token } = useAuth();

    if (!token || isExpired(token)) {
        return <Navigate to="/" replace />;
    }

    return children;
};


export default Protected;