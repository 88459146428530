/* General imports */
import React, {Suspense, useState} from 'react';
import styled from 'styled-components';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import CourtGame from "./components/courtGame";
import CourtFilterCheck from "./components/CourtFilterCheck";
import translate from "../../providers/i18n/translate";
import FilterListIcon from '@mui/icons-material/FilterList';

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 80vh;
`

const Filters = styled.div`
  padding: ${props => props.showFilters ? '20px' : 0};
  position: relative;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  height: ${props => props.showFilters ? '250px' : '0px'};
  overflow-y: hidden;
  transition: all 500ms ease-in-out;
`

const FilterBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  padding: 14px;
  border-radius: 10px;
`

const FilterBoxKeyShotType = styled(FilterBox)`
  grid-row: 1/3;
  grid-column: 4/5;
`

const FilterOptions = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`

const FilterTitle = styled.span`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.palette.principal};
  color: ${(props) => props.theme.palette.principal};
  font-size: 1rem;
  text-align: center;
  font-weight: 600;
  @media (min-width: 1024px) {
    font-size: 1.25rem;
    text-align: left;
  }
`

const FilterActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 30px;
`

const ShowFiltersButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${props => props.theme.palette.alternative_accent};
`

function TennisCourt(props) {

    const [ showFilters, setShowFilters ] = useState(false);

    const [ courtFilters, setCourtFilters ] = useState({
        firstServe: {
            deuce: true,
            ad: false
        },
        firstServeReturn: {
            deuce: false,
            ad: false
        },
        secondServe: {
            deuce: false,
            ad: false
        },
        secondServeReturn:{
            deuce: false,
            ad: false
        },
        keyShot: {
            forehand: false,
            backhand: false,
            winningShots: false,
            errors: false,
            topspin: false,
            lob: false,
            slice: false,
            dropShot: false,
            volley: false,
            overhead: false,
            other: false
        }
    });

    const handleSwitch = (filter, name) => {
        setCourtFilters({
            ...courtFilters,
            [filter]: {
                ...courtFilters[filter],
                [name]: !courtFilters[filter][name]
            }
        })
    }

    const onShowFilters = () => {
        setShowFilters(!showFilters);
    }

    return (
        <Root>
            <FilterActions>
                <ShowFiltersButton showFilters={showFilters} onClick={onShowFilters}>{showFilters ? translate('hide filters') : translate('show filters')}<FilterListIcon /></ShowFiltersButton>
            </FilterActions>
            <Filters showFilters={showFilters}>
                <FilterBox>
                    <FilterTitle>{translate('1st serve')}</FilterTitle>
                    <FilterOptions>
                        <CourtFilterCheck filter='firstServe' name='deuce' checked={courtFilters.firstServe.deuce} label='Deuce' onChange={handleSwitch} />
                        <CourtFilterCheck filter='firstServe' name='ad' checked={courtFilters.firstServe.ad} label='Ad' onChange={handleSwitch} />
                    </FilterOptions>
                </FilterBox>
                <FilterBox>
                    <FilterTitle>{translate('1st serve return')}</FilterTitle>
                    <FilterOptions>
                        <CourtFilterCheck filter='firstServeReturn' name='deuce' checked={courtFilters.firstServeReturn.deuce} label='Deuce' onChange={handleSwitch} />
                        <CourtFilterCheck filter='firstServeReturn' name='ad' checked={courtFilters.firstServeReturn.ad} label='Ad' onChange={handleSwitch} />
                    </FilterOptions>
                </FilterBox>
                <FilterBox>
                    <FilterTitle>{translate('key shot hand')}</FilterTitle>
                    <FilterOptions>
                        <CourtFilterCheck filter='keyShot' name='forehand' checked={courtFilters.keyShot.forehand} label='Forehand' onChange={handleSwitch} />
                        <CourtFilterCheck filter='keyShot' name='backhand' checked={courtFilters.keyShot.backhand} label='Backhand' onChange={handleSwitch} />
                    </FilterOptions>
                </FilterBox>
                <FilterBoxKeyShotType>
                    <FilterTitle>{translate('key shot type')}</FilterTitle>
                    <FilterOptions>
                        <CourtFilterCheck filter='keyShot' name='topspin' checked={courtFilters.keyShot.topspin} label='Topspin' onChange={handleSwitch} />
                        <CourtFilterCheck filter='keyShot' name='lob' checked={courtFilters.keyShot.lob} label='Lob' onChange={handleSwitch} />
                        <CourtFilterCheck filter='keyShot' name='slice' checked={courtFilters.keyShot.slice} label='Slice' onChange={handleSwitch} />
                        <CourtFilterCheck filter='keyShot' name='dropShot' checked={courtFilters.keyShot.dropShot} label='Drop Shot' onChange={handleSwitch} />
                        <CourtFilterCheck filter='keyShot' name='volley' checked={courtFilters.keyShot.volley} label='Volley' onChange={handleSwitch} />
                        <CourtFilterCheck filter='keyShot' name='overhead' checked={courtFilters.keyShot.overhead} label='Overhead' onChange={handleSwitch} />
                        <CourtFilterCheck filter='keyShot' name='other' checked={courtFilters.keyShot.other} label='Other' onChange={handleSwitch} />
                    </FilterOptions>
                </FilterBoxKeyShotType>
                <FilterBox>
                    <FilterTitle>{translate('2nd serve')}</FilterTitle>
                    <FilterOptions>
                        <CourtFilterCheck filter='secondServe' name='deuce' checked={courtFilters.secondServe.deuce} label='Deuce' onChange={handleSwitch} />
                        <CourtFilterCheck filter='secondServe' name='ad' checked={courtFilters.secondServe.ad} label='Ad' onChange={handleSwitch} />
                    </FilterOptions>
                </FilterBox>
                <FilterBox>
                    <FilterTitle>{translate('2nd serve return')}</FilterTitle>
                    <FilterOptions>
                        <CourtFilterCheck filter='secondServeReturn' name='deuce' checked={courtFilters.secondServeReturn.deuce} label='Deuce' onChange={handleSwitch} />
                        <CourtFilterCheck filter='secondServeReturn' name='ad' checked={courtFilters.secondServeReturn.ad} label='Ad' onChange={handleSwitch} />
                    </FilterOptions>
                </FilterBox>
                <FilterBox>
                    <FilterTitle>{translate('key shot outcome')}</FilterTitle>
                    <FilterOptions>
                        <CourtFilterCheck filter='keyShot' name='winningShots' checked={courtFilters.keyShot.winningShots} label='Winning Shots' onChange={handleSwitch} />
                        <CourtFilterCheck filter='keyShot' name='errors' checked={courtFilters.keyShot.errors} label='Errors' onChange={handleSwitch} />
                    </FilterOptions>
                </FilterBox>
            </Filters>
            <Canvas style={{ height: "100%", width: "100%" }} >
                <ambientLight intensity={0.3} />
                <pointLight position={[0, 0, 0]} />
                <PerspectiveCamera position={[0, 0, 30]} zoom={1}  makeDefault />
                <OrbitControls enablePan={true} enableZoom={false} enableRotate={false} />
                <Suspense fallback={null}>
                    <CourtGame courtFilters={courtFilters} />
                </Suspense>
            </Canvas>
        </Root>
    );
}

export default TennisCourt;
