import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {useEffect} from "react";

const CourtFilterCheck = ({filter, name, label, checked, onChange}) => {

    const handleChange = () => {
        onChange(filter, name);
    }

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': `court filter check ${filter} to ${name}` }}
                />
            }
            label={label}
        />
    );
}

export default CourtFilterCheck;