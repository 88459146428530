import AccessDeniedPage from '../../pages/AccessDeniedPage';
import { useJwt } from "../../hooks/jwt";
import { useAuth } from "../../providers/authentication";
import { useParams } from "react-router-dom";
  
const PrivateMatch = ({ children, nullReturn }) => {

    const { token } = useAuth();
    
    const { id } = useParams();

    /* CONSULTAR POR EL MATCH CON ESE ID Y ESE TOKEN 
    Y SI ES RECHAZADO (NO ACCESIBLE PARA EL TOKEN) ENTONCES SE PONE ACCESO DENEGADO */

    /* if (id && decodedToken && parseInt(id) !== decodedToken?.id) {
        return nullReturn ? null : <AccessDeniedPage />;
    } */

    return children;
};

export default PrivateMatch