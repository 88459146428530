import React from "react";
import axios from "axios";
import { useIntl } from "react-intl";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { getDateUS } from "../../utils/dateUtils";

const Root = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 50mm 40mm 40mm 40mm 40mm;
  margin: 2rem 0;
`;

const ImageProfile = styled.img`
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row: 1/3;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  object-fit: cover;
  object-position: center;
`;

const PlayerName = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  grid-column: 2/6;
`;

const Data = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DataTitle = styled.div`
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const DataContent = styled.span`
  font-size: 0.875rem;
`;

const Profile = ({ onReady }) => {
  const { token } = useAuth();
  const intl = useIntl();

  const NAME = "profile";

  const [data, setData] = React.useState(null);

  const getData = () => {
    axios
      .get(`${API_BACKEND}/trainedplayerinformation`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data);
        onReady(NAME);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    data && (
      <Root>
        <ImageProfile
          src={
            data.pathPhoto ? `${API_BACKEND}/players/${data.id}/image` : null
          }
          alt="image profile"
        />
        <PlayerName>{data.name}</PlayerName>
        <Data>
          <DataTitle>{translate("From")}</DataTitle>
          <DataContent>{data.country ? data.country : "---"}</DataContent>
        </Data>
        <Data>
          <DataTitle>{translate("Born")}</DataTitle>
          <DataContent>
            {getDateUS(new Date(data.dateBirth))}
          </DataContent>
        </Data>
        <Data>
          <DataTitle>{translate("Height")}</DataTitle>
          <DataContent>{data.height ? data.height : "---"}</DataContent>
        </Data>
        <Data>
          <DataTitle>{translate("Weight")}</DataTitle>
          <DataContent>{data.weight ? data.weight : "---"}</DataContent>
        </Data>
      </Root>
    )
  );
};

export default Profile;
