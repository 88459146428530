import React from "react";
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { ThemeContext } from '../../providers/theme';
import Accordion from "../../components/accordion";
import AccordionSummary from "../../components/accordion/AccordionSummary";
import AccordionDetails from "../../components/accordion/AccordionDetails";
import translate from "../../providers/i18n/translate";
import { useAuth } from "../../providers/authentication";
import { decodeToken } from "../../hooks/jwt";
import PersonalSettings from "./PersonalSettings";
import { useNavigate } from "react-router-dom";
import ApplicationSettings from "./ApplicationSettings/ApplicationSettings";

/* Styled components */
const Root = styled.form`
    position: relative;
    height: auto;
    width: 100%;
    padding: 20px;
`

const Greetings = styled.h2`
    color: ${props => props.theme.palette.principal};
    margin: 1rem 0;
`

const Title = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.15rem;
    margin: 0;
    svg {
        font-size: 1.15rem;
        margin-right: 1rem;
    }
`

const Actions = styled.div`
  @media (min-width: 600px) {
    align-self: flex-end;
  }
`

const StyledButton = styled.button`
    font-family: museo-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    color: ${(props) => props.color ? props.color : '#707070'};
    border-radius: 35px;
    padding: 15px 0;
    width: 100px;
    background-color: ${(props) => props.bgColor ? props.bgColor : '#40CBE6'};
    box-shadow: none;
    border: ${(props) => props.border ? props.border : 'none'};
    margin: ${(props) => props.margin ? props.margin : '30px 15px;'};
    cursor: pointer;
    &:disabled {
      color: #5D5D5D;
      background: #ECECEC;
      cursor: not-allowed;
    }
    @media (min-width: 600px) {
        font-size: 1.25rem;
        width: 150px;
    }
`

export default function SettingsPage()  {

    const intl = useIntl();
    const theme = React.useContext(ThemeContext);
    const { token } = useAuth();
    const personalSettingsRef = React.useRef();
    const applicationSettingsRef = React.useRef();

    const userData = decodeToken(token);

    const [changes, setChanges] = React.useState(false);

    const navigate = useNavigate();

    const onCancel = (e) => {
        e.preventDefault();
        if (!changes) {
            navigate(-1);
        }
    }

    const onSave = (e) => {
        e.preventDefault();
        personalSettingsRef.current.submit();
        applicationSettingsRef.current.submit();
    }

    return(
        <Root onSubmit={onSave}>
            <Greetings>{translate("hello", {name: userData.firstname})}</Greetings>
            <Accordion first>
                <AccordionSummary>
                    <Title>
                    {theme.icons.person}
                    <span>{translate("personal settings")}</span>
                    </Title>
                </AccordionSummary>
                <AccordionDetails>
                    <PersonalSettings ref={personalSettingsRef} setChanges={setChanges} idUser={userData.id} />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary>
                    <Title>
                        {theme.icons.notificationsNone}
                        <span>{translate("notifications")}</span>
                    </Title>
                </AccordionSummary>
                <AccordionDetails>
                    NOTIFICATIONS (SOON...)
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary>
                    <Title>
                        {theme.icons.settings}
                        <span>{translate("application")}</span>
                    </Title>
                </AccordionSummary>
                <AccordionDetails>
                    <ApplicationSettings ref={applicationSettingsRef} setChanges={setChanges} />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary>
                    <Title>
                        {theme.icons.creditCard}
                        <span>{translate("billing")}</span>
                    </Title>
                </AccordionSummary>
                <AccordionDetails>
                    BILLING (SOON...)
                </AccordionDetails>
            </Accordion>
            <Accordion last>
                <AccordionSummary>
                    <Title>
                        {theme.icons.help}
                        <span>{translate("help")}</span>
                    </Title>
                </AccordionSummary>
                <AccordionDetails>
                    HELP (SOON...)
                </AccordionDetails>
            </Accordion>
            <Actions>
              <StyledButton bgColor="transparent"  border="2px solid #707070" onClick={onCancel}>
                {translate("cancel")}
              </StyledButton>
              <StyledButton type="submit" color="#FFFFFF" disabled={!changes}>
                {translate("save")}
              </StyledButton>
            </Actions>
        </Root>
    )
};