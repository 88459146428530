import { THEMES } from "../../../providers/theme/themes"

export const _options = {
  chart: {
    with: 500,
    height: 500
  },
  plotOptions: {
    radialBar: {
      hollow: {
        size: '30%',
      },
      dataLabels: {
        name: {
          show: false
        },
        value: {
          fontSize: '1.5rem',
          offsetY: 5
        },
        total: {
          show: false
        }
      }
    },
  }
}

const formatData = (data, serveProp, returnProp) => {
  const series = [
    data[serveProp] ? data[serveProp][2] : null,
    data[returnProp] ? data[returnProp][2] : null
  ];

  const metaData = [
    data[serveProp],
    data[returnProp]
  ];

  return { series, labels: data.labels, metaData };
};

export const formatDataServe = data => formatData(data, 'servePlayer1', 'servePlayer2');
export const formatDataReturn = data => formatData(data, 'serveReturnPlayer1', 'serveReturnPlayer2');