export const _options = {
    chart: {
      type: 'radialBar',
      with: 500,
      height: 500,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          size: 50,
          background: 'transparent',
          margin: 0
        },
        track: {
          background: "#3C555C",
          strokeWidth: '75%',
          startAngle: -90,
          endAngle: 90,
          opacity: 0.25
        },
        dataLabels: {
          show: true,
          name: {
            show: true,
            color: "#DCFE54",
            offsetY: -70,
            fontSize: '1.5rem',
          },
          value: {
            show: true,
            color: "#FFFFFF",
            offsetY: -30,
            fontSize: '3rem',
            formatter: function (val) {
              return val + '%'
            }
          }
        }
      }
    },
    grid: {
      padding: {
        top: -10
      }
    },
    fill: {
      colors: "#DCFE54",
    },
    labels: ['Value'],
    tooltip: {
      enabled: true,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                show: true,
                name: {
                  show: true,
                  color: "#DCFE54",
                  offsetY: -60,
                  fontSize: '1rem',
                },
                value: {
                  show: true,
                  color: "#FFFFFF",
                  offsetY: -30,
                  fontSize: '2.5rem',
                  formatter: function (val) {
                    return val + '%'
                  }
                }
              }
            }
          },
        },
      },
      {
        breakpoint: 769,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                show: true,
                name: {
                  show: true,
                  color: "#DCFE54",
                  offsetY: -80,
                  fontSize: '1.5rem',
                },
                value: {
                  show: true,
                  color: "#FFFFFF",
                  offsetY: -30,
                  fontSize: '3rem',
                  formatter: function (val) {
                    return val + '%'
                  }
                }
              }
            }
          },
        },
      },
      {
        breakpoint: 1025,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                show: true,
                name: {
                  show: true,
                  color: "#DCFE54",
                  offsetY: -50,
                  fontSize: '1rem',
                },
                value: {
                  show: true,
                  color: "#FFFFFF",
                  offsetY: -20,
                  fontSize: '2.25rem',
                  formatter: function (val) {
                    return val + '%'
                  }
                }
              }
            }
          },
        },
      },
      {
        breakpoint: 1441,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                show: true,
                name: {
                  show: true,
                  color: "#DCFE54",
                  offsetY: -60,
                  fontSize: '1rem',
                },
                value: {
                  show: true,
                  color: "#FFFFFF",
                  offsetY: -30,
                  fontSize: '2.5rem',
                  formatter: function (val) {
                    return val + '%'
                  }
                }
              }
            }
          },
        },
      }
    ]
  }  

export const formatData = (data) => {
  const series= [data.historicalSEM[0].value];
  const text = data.historicalSEM[0].message;

  return {series, text};
}

export const formatREMData = (data) => {
  const series= [data.historicalREM[0].value];
  const text = data.historicalREM[0].message;

  return {series, text};
}

export const formatDataUE = (data) => {
  const series= [data.totalDeterminantUnforcedErrors];
  const text = null;

  return {series, text};
}

export const formatHistoricalData = (data) => {
  const series= [data.averageSEM.value];
  const text = data.averageSEM.message;

  return {series, text};
}

export const formatHistoricalDataREM = (data) => {
  const series= [data.averageREM.value];
  const text = data.averageREM.message;

  return {series, text};
}

export const formatTotalPointsWonData = (data) => {
  const series= [parseFloat(data)];
  const text = null;

  return {series, text};
}