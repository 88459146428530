import React from "react";
import styled from "styled-components";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import { useSelector } from "react-redux";
import {
  formatDataDeuceAd,
  formatDataFirstServeReturnIn,
  getTooltip,
} from "../../components/gsmcharts/lineAnnotationsChart/config";
import translate from "../../providers/i18n/translate";
import LineAnnotationsChart from "../gsmcharts/lineAnnotationsChart";

const Root = styled.div`
    position: relative;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.h2`
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.details};
  font-size: 1.5rem;
    text-align: left;
`;

const ContainerChart = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  background: ${(props) => props.theme.palette.principal};
  border-radius: 20px;
  padding: 0;
`;

const Return1stIn = ({ onReady }) => {
  const { token } = useAuth();

  const MATCHES_LIMIT = 10;
  const NAME = "return1stIn";

  const { appliedFilters } = useSelector(
    (state) => state.selectedDashboardFilters
  );

  const optionsServeReturn = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return getTooltip(series, seriesIndex, dataPointIndex, w);
      },
    },
  };

  const optionsDeuceAd = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return getTooltip(series, seriesIndex, dataPointIndex, w);
      },
    },
  };

  const allReady = [false, false];

  const handleReady = () => {
    if (allReady[0] && allReady[1]) {
      onReady(NAME);
    }
  }

  React.useEffect(() => { }, [JSON.stringify(appliedFilters)]);

  return (
    <Root>
      <Title order={1}>{translate("1st Serve Return In")}</Title>
      <ContainerChart order={2}>
        <LineAnnotationsChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalfirstservereturnin?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDataFirstServeReturnIn(result.data);
                  allReady[0] = true;
                  handleReady();
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="line"
          width={500}
          height={500}
          options={optionsServeReturn}
        />
      </ContainerChart>
      <Title order={3}>{translate("Deuce and Ad Points on 1st Serve Return In")}</Title>
      <ContainerChart order={4}>
        <LineAnnotationsChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicaldeuceadfirstservereturnin?page=0&limit=${MATCHES_LIMIT}&filters=${JSON.stringify(appliedFilters)}&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatDataDeuceAd(result.data);
                  allReady[1] = true;
                  handleReady();
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="line"
          width={500}
          height={500}
          options={optionsDeuceAd}
        />
      </ContainerChart>
    </Root>
  );
};

export default Return1stIn;
