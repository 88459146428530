import { colors } from "@mui/material";
import { THEMES } from "../../../providers/theme/themes";


export const _options = {
  chart: {
    with: 500,
    height: 800,
    type: 'line',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    }
  },
  colors: colors,
  dataLabels: {
    enabled: false,
    enabledOnSeries: [0],
    textAnchor: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '0.85rem',
      fontFamily: 'museo-sans, sans-serif',
      fontWeight: 'bold',
    },
    background: {
      enabled: true,
      foreColor: '#000',
      padding: 4,
      borderRadius: 5,
      borderColor: '#40CBE6',
      dropShadow: {
        enabled: true,
        top: 2,
        left: -0,
        blur: 6,
        color: '#40CBE6',
        opacity: 0.45
      }
    },
    formatter: function (val, opts) {
      return `${val}`
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    position: 'bottom',
    horizontalAlign: 'center',
    floating: false,
    fontSize: '14px',
    fontFamily: 'museo-sans, sans-serif',
    labels: {
      colors: '#FFFFFF',
    }
  },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      let seriesInfo = ""
      for (let index = 0; index < series.length; index++) {
        seriesInfo += '<span style="color: #7A7A7A;"> <span style="display: inline-block; width: 14px; height: 13px; margin-right: 5px; border-radius: 50%; background: ' + w.config.colors[index] + ';"></span>' +
          '<span style="margin-right: 5px;">' + w.config.series[index].name + ': </span>' +
          '<strong>' +
          series[index][dataPointIndex] +
          '</strong>' +
          '</span>'
      };
      return (
        '<div style="padding: 10px;border-radius: 8px; background: #FFF;">' +
        '<div style="background: transparent; padding: 5px; display: flex; flex-direction: column; gap: 10px; align-items: flex-start; justify-content: flex-start;">' +
        seriesInfo +
        "</div>" +
        "</div>"
      );
    }
  },
  stroke: {
    width: [3, 3, 3, 3],
    curve: 'straight',
    dashArray: [0, 4, 4, 4]
  },
  markers: {
    size: [5, 0, 0, 0],
    hover: {
      sizeOffset: 2
    },
    colors: '#40CBE6',
    strokeColor: '#40CBE6'
  },
  xaxis: {
    show: true,
    align: 'center',
    labels: {
      show: true,
      rotate: -45,
      style: {
        fontSize: '0.85rem',
        fontFamily: 'museo-sans, sans-serif',
        fontWeight: 100,
        colors: THEMES.default.palette.base
      },
      maxHeight: 100,
      trim: true,
    },
    axisBorder: {
      color: '#707070',
    },
    axisTicks: {
      show: false
    }
  },
  yaxis: {
    show: true,
    align: 'center',
    tickAmount: 8,
    forceNiceScale: true,
    axisBorder: {
      show: true,
      color: '#707070',
    },
    labels: {
      style: {
        show: true,
        fontSize: '0.85rem',
        fontFamily: 'museo-sans, sans-serif',
        fontWeight: 100,
        colors: ['#FFFFFF'],
      },
      formatter: function(val, index) {
        return Math.abs(val).toFixed(0);
      }
    }
  },
  grid: {
    borderColor: '#707070',
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false
      }
    }
  },
}

export const formatData = (data) => {
  const annotation_dm = (data.desiredMargin == null) ? null : data.desiredMargin
  const annotation_pm = data.performance.byMatch;
  const annotation_av = data.average;
  const colors = ['#40CBE6', '#00E396', '#775DFF'];
  const series = [
    {
      name: "Performance",
      data: data.performance.bySet.map(cat => {
        return cat.performance
      })
    },
    {
      name: `Average Performance`,
      data: data.performance.bySet.map(cat => {
        return annotation_pm
      })
    },
    {
      name: `Last 10 matches`,
      data: data.performance.bySet.map(cat => {
        /* return {value: cat.performance, annotation_dm, annotation_pm, annotation_av}; */
        return annotation_av
      })
    }
  ];
  if (annotation_dm) {
    series.splice(2, 0, {
      name: 'Desired',
      data: data.performance.bySet.map(cat => {
        return annotation_dm
      })
    })
    colors.splice(2, 0, '#DCFE54')
  }
  const categories = data.performance.bySet.map(cat => `SET ${cat.setNumber}`);

  return { series, categories, annotation_av, colors };
}


export const getTooltipHistoricalPerformance = (series, seriesIndex, dataPointIndex, w) => {
  let seriesInfo = ""
  for (let index = 0; index < series.length; index++) {
    seriesInfo += '<span style="color: #7A7A7A;"> <span style="display: inline-block; width: 14px; height: 13px; margin-right: 5px; border-radius: 50%; background: ' + w.config.colors[index] + ';"></span>' +
      '<span style="margin-right: 5px;">' + w.config.series[index].name + ': </span>' +
      '<strong>' +
      series[index][dataPointIndex] +
      '</strong>' +
      '</span>'
  };
  return (
    '<div style="padding: 10px;border-radius: 8px; background: #FFF;">' +
    '<div style="background: transparent; padding: 5px; display: flex; flex-direction: column; gap: 10px; align-items: flex-start; justify-content: flex-start;">' +
    seriesInfo +
    "</div>" +
    "</div>"
  );
};

export const formatHistoricalData = (data) => {
  const annotation_dm = (data.desiredMargin == null) ? null : data.desiredMargin
  const annotation_av = data.averagePerformance;
  const colors = ['#40CBE6', '#775DFF'];
  const series = [
    {
      name: "Performance",
      data: data.historicalMatchPerformance.map(dat => {
        return dat.performance
      })
    },
    {
      name: `Average Performance`,
      data: data.historicalMatchPerformance.map(cat => {
        return annotation_av
      })
    }
  ];
  if (annotation_dm) {
    series.push({
      name: 'Desired',
      data: data.historicalMatchPerformance.map(cat => {
        return annotation_dm
      })
    })
    colors.push('#DCFE54')
  }
  const categories = data.historicalMatchPerformance.map(cat => cat.opponent);

  return { series, categories, annotation_av, colors };
}

export const formatSimulationlData = (data) => {
  const annotation_dm = (data.desiredMargin == null) ? null : data.desiredMargin
  const annotation_av = data.averagePerformance;
  const colors = ['#639AFA', '#F94646'];
  const series = [
    {
      name: "Simulation Performance",
      data: data.historicalMatchPerformance.map(dat => {
        return dat.performance
      })
    },
    {
      name: `Average Simulation Performance`,
      data: data.historicalMatchPerformance.map(cat => {
        return annotation_av
      })
    }
  ];
  if (annotation_dm) {
    series.push({
      name: 'Desired',
      data: data.historicalMatchPerformance.map(cat => {
        return annotation_dm
      })
    })
    colors.push('#DCFE54')
  }
  const categories = data.historicalMatchPerformance.map(cat => cat.opponent);

  return { series, categories, annotation_av, colors };
}


