import axios from "axios";
import React from "react";
import styled from "styled-components";
import MatchPerformanceChart from "../../../components/gsmcharts/matchPerformanceChart";
import RadarChart from "../../../components/gsmcharts/radarChart";
import { formatData as formatRadarData } from "../../../components/gsmcharts/radarChart/config";
import { API_BACKEND } from "../../../config";
import { useAuth } from "../../../providers/authentication";
import {
  formatHistoricalData,
  getTooltipHistoricalPerformance,
} from "../../../components/gsmcharts/matchPerformanceChart/config";

const Root = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 0;
  flex-direction: column;
  @media (min-width: 1024px) {
      flex-direction: row;
  }
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: 450px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.palette.principal};
  @media (min-width: 1024px) {
    width: 50%;
  }
`;

const ContainerChart = styled(Container)`
  background: ${(props) => props.theme.palette.principal};
`;

const Radar = () => {
  const AMOUNT_MATCHES = 10;

  const { token } = useAuth();

  const optionsHistoricalPerformance = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return getTooltipHistoricalPerformance(
          series,
          seriesIndex,
          dataPointIndex,
          w
        );
      },
    },
  };

  return (
    <Root>
      <Container>
        <RadarChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalplayerskillset?amountMatches=${AMOUNT_MATCHES}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatRadarData(result.data);
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="radar"
          width={500}
          height={500}
        />
      </Container>
      <ContainerChart>
        <MatchPerformanceChart
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(
                  `${API_BACKEND}/historicalperformance?page=0&limit=10&filters=[]&order={"field": "date", "order": "DESC"}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((result) => {
                  const chartData = formatHistoricalData(
                    result.data,
                    query.desiredMargin
                  );
                  resolve(chartData);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          type="line"
          width={500}
          height={500}
          options={optionsHistoricalPerformance}
        />
      </ContainerChart>
    </Root>
  );
};

export default Radar;
