import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import { useIntl } from 'react-intl';
import { _options } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import ReactApexChart from "react-apexcharts";
import { ThemeContext } from "../../../providers/theme";
import translate from "../../../providers/i18n/translate";
import SimpleCircularLoading from "../../loading/simpleCircularLoading";
import Popup from "../../popup/Popup";

const Root = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    opacity: ${props => props.loading === "true" ? 0.3 : 1};
    padding: 20px;
    overflow: hidden;
    background: ${(props) => props.background ? props.background : props.theme.palette.principal};
    border-radius: 20px;
`

const ContainerChart = styled.div`
    width: 100%;
    height: 300px;
    margin: auto 0;
    .apexcharts-canvas {
        margin: 0 auto;
    }
    @media (min-width: 600px) {
        height: 300px;
    }
    @media (min-width: 1024px) {
        height: 250px;
    }
    @media (min-width: 1440px) {
        height: 300px;
    }
`

const Title = styled.h5`
    font-size: 1rem;
    color: ${props => props.theme.palette.accent};
    margin: 10px 0 0 0;
    width: 70%;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0 0 10px 0;
`

const NoData = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.palette.base};
    font-size: 2rem;
    text-transform: uppercase;
    svg {
        font-size: 4rem;
    }
`

const LeadershipChart = ({ data, rawData, width, height, options, type, sets }) => {

    const intl = useIntl();

    const [series, setSeries] = React.useState(null);
    const [labels, setLabels] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const { appliedFilters } = useSelector(state => state.selectedDashboardFilters);

    const theme = React.useContext(ThemeContext);

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const getData = () => {
        setLoading(true);
        if (data) {
            data()
                .then(result => {
                    setSeries(result.series);
                    setLabels(result.labels);
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                    alertMessage(err.response.data.msg, alertConstants.ERROR);
                    setLoading(false);
                })
        } else {
            if (rawData) {
                setSeries(rawData.series);
                setLabels(rawData.labels);
                setLoading(false);
            }
        }
    }

    React.useEffect(() => {
        getData();
    }, [JSON.stringify(sets), JSON.stringify(appliedFilters)]);

    return (
        <Root loading={loading ? "true" : "false"}>
            <Popup
                title={intl.formatMessage({ id: 'Leadership margin' })}
                content={intl.formatMessage({ id: 'help leadership margin' })}
            />
            <Title>{translate("Leadership margin")}</Title>
            <ContainerChart height={height}>
                {

                    series && labels && series.length > 0 ?
                        <ReactApexChart
                            width="100%"
                            height="100%"
                            options={options ?
                                {
                                    ..._options,
                                    chart: {
                                        ..._options.chart,
                                        with: width,
                                        height: height
                                    },
                                    labels: labels ? labels : null,
                                    ...options
                                }
                                :
                                {
                                    ..._options,
                                    chart: {
                                        ..._options.chart,
                                        with: width,
                                        height: height
                                    },
                                    labels: labels ? labels : null,
                                }
                            }
                            series={series}
                            type={type ? type : "pie"}
                        />
                        :
                        loading ?
                            <SimpleCircularLoading />
                            :
                            <NoData>
                                {theme.icons.block}
                                {translate("No data")}
                            </NoData>
                }
            </ContainerChart>
        </Root>
    );
}

LeadershipChart.propTypes = {
    data: PropTypes.any,
    rawData: PropTypes.any,
    width: PropTypes.number,
    height: PropTypes.number,
    options: PropTypes.any,
    type: PropTypes.string
}

export default LeadershipChart;