import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useAuth } from "../../providers/authentication";
import { decodeToken } from "../../hooks/jwt";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { lastMatchConstants } from "../../store/reducers/lastMatch/lastMatchConstants";
import translate from "../../providers/i18n/translate";
import Header from "../PlayerProfilePage/Header";
import LastMatchWithPointsChart from "../../components/gsmcharts/lastMatchWithPointsChart";
import UnforcedErrorsPage from "./unforcedErrors";
import { formatData as formatPointsWonData } from "../../components/gsmcharts/pointsWonByPlayerChart/config";
import PointsWonByPlayerChart from "../../components/gsmcharts/pointsWonByPlayerChart";
import MatchQualityChart from "../../components/gsmcharts/matchQualityChart";
import { formatData as formatMatchQualityData } from "../../components/gsmcharts/matchQualityChart/config";
import SemiCircleRallyChart from "../../components/gsmcharts/semiCircleRallyChart";
import { formatRallyEffectivenessData } from "../../components/gsmcharts/semiCircleRallyChart/config";
import MiniMatchesChart from "../../components/gsmcharts/miniMatchesWithSemaphoreChart/minimatches";
import { formatData } from "../../components/gsmcharts/miniMatchesWithSemaphoreChart/config";
import { DynamicBreakPointsBarChart } from "../../components/gsmcharts/";
import { formatBreakPointData } from "../../components/gsmcharts/dynamicBreakPointsBarChart/config";
import HorizontalBarChart from "../../components/gsmcharts/horizontalBarChartWithSemaphore/horizontalBarChart";
import { formatData as formatHorizontalChartWithSemData } from "../../components/gsmcharts/horizontalBarChartWithSemaphore/config";
import { ThemeContext } from "../../providers/theme";
import CourtImage from "../../assets/court_usopen.svg";
import { useIntl } from 'react-intl';
import Popup from "../../components/popup/Popup";

/* Styled components */
const Root = styled.div`
    position: relative;
    height: auto;
    width: 100%;
    max-width: ${(props) => `${props.theme.breakpoints.desktopPlus}px`};
    margin: 0 auto;
    @media (min-width: 1024px) {
      padding: 0 50px;
    }
`;

const DashboardContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 20px;
    gap: 20px;
    @media (min-width: 1024px) {
      padding: 10px 0;
    }
`;

const NoMatches = styled.div`
    width: 100%;
    height: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: ${(props) => props.theme.palette.details};
    margin: 50px 0;
    font-size: 1.25rem;
`;

const GoUploadMatch = styled(Link)`
  color: ${(props) => props.theme.palette.alternative_details};
`;

const FourColumns = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    overflow: hidden;
    @media (min-width: 600px) {
      height: 600px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      overflow: hidden;
    }
    @media (min-width: 1024px) {
      height: 275px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 1440px) {
      height: 300px;
    }
`;

const FourColumnsV2 = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    overflow: hidden;
    @media (min-width: 600px) {
      height: 800px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      overflow: hidden;
    }
    @media (min-width: 1024px) {
      height: 430px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      overflow: hidden;
    }
    @media (min-width: 1440px) {
      height: 230px;
    }
`;

const TwoColumns = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 500px;
        gap: 20px;
    }
`;

const ContainerChart = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${(props) => props.background ? props.background : props.theme.palette.principal};
  border-radius: ${props => props.borderRadius ? props.borderRadius : 0};
  padding: 0;
  order: ${props => props.order ? props.order : "inherit"};
  @media (min-width: 1024px) {
    order: inherit;
  }
`;

const GraphContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: hidden;
    background: ${(props) => props.theme.palette.principal};
    border-radius: 20px;
    justify-content: space-between ;
    order: ${props => props.order ? props.order : "inherit"};
    @media (min-width: 1024px) {
      order: inherit;
    }
`

const Image = styled.img`
    width: 100%; 
    height: 100%;
    align-items: center;
    position: relative;
`;

const SubTitle = styled.h5`
    font-size: 1rem;
    color: ${props => props.theme.palette.accent};
    margin: 10px 0 0 0;
    width: 80%;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0 0 10px 0;
    @media (min-width: 600px) {
      font-size: 0.9rem;
    }
    @media (min-width: 1440px) {
      font-size: 1rem;
    }
`

const StyledButton = styled(Link)`
    width: 50px;
    display: flex;
    justify-content: center;
    padding: 5px 20px;
    border-radius: 25px;
    border: none;
    font-weight: 700;
    order: ${props => props.order ? props.order : "inherit"};
    color: ${(props) => (props.color ? props.color : "#000")};
    background: ${(props) => props.background ? props.background : "transparent"};
    font-size: 1rem;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    align-self: flex-end;
    position:absolute ;
    bottom: 20px;
    right: 20px;
    &:hover {
        transform: scale(1.05);
    }
    @media (min-width: 600px) {
        font-size: 1em;
    }
`;

export default function Summary() {

  const { token } = useAuth();

  const intl = useIntl();

  const theme = React.useContext(ThemeContext);

  const userData = decodeToken(token);

  const lastMatch = useSelector(state => state.lastMatch);

  const dispatch = useDispatch();

  const { getLastMatchData } = bindActionCreators(actionCreators, dispatch);

  React.useEffect(() => {
    getLastMatchData(lastMatchConstants.SET, token)
  }, [])

  return (
    userData && lastMatch ? (
      <Root>
        <Header id={userData?.id} />
        {userData.numberMatches > 0 && lastMatch.id ? (
          <DashboardContainer>
            <LastMatchWithPointsChart />
            <ContainerChart borderRadius="20px">
              <PointsWonByPlayerChart
                matchId={lastMatch.id}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    axios
                      .get(
                        `${API_BACKEND}/matchpointswonbyplayer?idMatch=${lastMatch.id}&sets=all`,
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        }
                      )
                      .then((result) => {
                        const chartData = formatPointsWonData(result.data);
                        resolve(chartData);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  })
                }
                type="bar"
                width={400}
                height={400}
              />
            </ContainerChart>
            <FourColumns>
              <GraphContainer>
                <SubTitle>{translate("court")}</SubTitle>
                <Image src={CourtImage} alt="tenis court" />
                <StyledButton background="#DCFE54" to={`/admin/match/${lastMatch.id}/court`}>
                  {theme.icons.send}
                </StyledButton>
              </GraphContainer>
              <UnforcedErrorsPage matchId={lastMatch.id} />
              <MatchQualityChart
                matchId={lastMatch.id}
                width={"125px"}
                height={"125px"}
                fontSize={"1.25rem"}
                margin={"0"}
                sets={"all"}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    axios
                      .get(
                        `${API_BACKEND}/matchquality?idMatch=${lastMatch.id}&sets=all`,
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        }
                      )
                      .then((result) => {
                        const chartData = formatMatchQualityData(result.data);
                        resolve(chartData);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  })
                }
                dashboard={true}
              />
              <GraphContainer>
                <Popup
                  title={intl.formatMessage({ id: 'points won on the most often rally played' })}
                  content={`<p>${intl.formatMessage({ id: 'help points won on the most often rally played' })}</p>`}
                />
                <SubTitle>{translate("points won on the most often rally played")}</SubTitle>
                <SemiCircleRallyChart
                  matchId={lastMatch.id}
                  sets={"all"}
                  player={1}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      axios
                        .get(
                          `${API_BACKEND}/rallylengtheffectiveness?idMatch=${lastMatch.id}&sets=all&playerNumber=1&&serveReturn=serveReturn1st2nd`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then((result) => {
                          const chartData = formatRallyEffectivenessData(result.data);
                          resolve(chartData);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    })
                  }
                  type="radialBar"
                  width={500}
                  height={500}
                />
              </GraphContainer>
            </FourColumns>
            <FourColumnsV2>
              <GraphContainer order={1} borderRadius="20px">
                <SubTitle>{translate("1st Serve In")}</SubTitle>
                <HorizontalBarChart
                  width={500}
                  height={600}
                  type="bar"
                  matchId={lastMatch.id}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      axios
                        .get(
                          `${API_BACKEND}/firstservein?idMatch=${lastMatch.id}&sets=all&playerNumber=1`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then((result) => {
                          resolve(formatHorizontalChartWithSemData(result.data));
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    })
                  }
                  calcColor1={(data) => {
                    const val = parseFloat(data);
                    switch (true) {
                      case val < 50:
                        return "#f02e01";
                      case val >= 50 && val < 56:
                        return "#f17205";
                      case val >= 56 && val < 60:
                        return "#DCFE54";
                      case val >= 60 && val < 67:
                        return "#B4ED00";
                      case val >= 67:
                        return "#00ff00";
                      default:
                        break;
                    }
                  }}
                  calcColor2={(data) => {
                    const val = parseFloat(data);
                    switch (true) {
                      case val < 50:
                        return "#ffae9b";
                      case val >= 0.50 && val < 56:
                        return "#ffd8b6";
                      case val >= 56 && val < 60:
                        return "#eeffa8";
                      case val >= 60 && val < 67:
                        return "#58D68D";
                      case val >= 67:
                        return "#b2ffb2";
                      default:
                        break;
                    }
                  }}
                />
              </GraphContainer>
              <GraphContainer order={2} borderRadius="20px">
                <SubTitle>{translate("doubleFaults")}</SubTitle>
                <HorizontalBarChart
                  width={500}
                  height={600}
                  type="bar"
                  matchId={lastMatch.id}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      axios
                        .get(
                          `${API_BACKEND}/doublefaults?idMatch=${lastMatch.id}&sets=all&playerNumber=1`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then((result) => {
                          resolve(formatHorizontalChartWithSemData(result.data));
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    })
                  }
                  calcColor1={(data) => {
                    const val = parseFloat(data);
                    switch (true) {
                      case val < 50:
                        return "#f02e01";
                      case val >= 50 && val < 60:
                        return "#f17205";
                      case val >= 60 && val < 70:
                        return "#DCFE54";
                      case val >= 70 && val < 80:
                        return "#B4ED00";
                      case val >= 80:
                        return "#00ff00";
                      default:
                        break;
                    }
                  }}
                  calcColor2={(data) => {
                    const val = parseFloat(data);
                    switch (true) {
                      case val < 50:
                        return "#ffae9b";
                      case val >= 50 && val < 60:
                        return "#ffd8b6";
                      case val >= 60 && val < 70:
                        return "#eeffa8";
                      case val >= 70 && val < 80:
                        return "#58D68D";
                      case val >= 80:
                        return "#b2ffb2";
                      default:
                        break;
                    }
                  }}
                />
              </GraphContainer>
              <GraphContainer order={3} borderRadius="20px">
                <SubTitle>{translate("Deuce and Ad Points on 1st Serve Return In")}</SubTitle>
                <HorizontalBarChart
                  width={500}
                  height={600}
                  type="bar"
                  matchId={lastMatch.id}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      axios
                        .get(
                          `${API_BACKEND}/firstservereturnin?idMatch=${lastMatch.id}&sets=all&playerNumber=1`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then((result) => {
                          resolve(formatHorizontalChartWithSemData(result.data));
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    })
                  }
                  calcColor1={(data) => {
                    const val = parseFloat(data);
                    switch (true) {
                      case val < 50:
                        return "#f02e01";
                      case val >= 50 && val < 55:
                        return "#f17205";
                      case val >= 55 && val < 60:
                        return "#DCFE54";
                      case val >= 60 && val < 65:
                        return "#B4ED00";
                      case val >= 65:
                        return "#00ff00";
                      default:
                        break;
                    }
                  }}
                  calcColor2={(data) => {
                    const val = parseFloat(data);
                    switch (true) {
                      case val < 50:
                        return "#ffae9b";
                      case val >= 50 && val < 55:
                        return "#ffd8b6";
                      case val >= 55 && val < 60:
                        return "#eeffa8";
                      case val >= 60 && val < 65:
                        return "#58D68D";
                      case val >= 65:
                        return "#b2ffb2";
                      default:
                        break;
                    }
                  }}
                />
              </GraphContainer>
              <GraphContainer order={4} borderRadius="20px">
                <SubTitle>{translate("Deuce and Ad Points on 2nd Serve Return In")}</SubTitle>
                <HorizontalBarChart
                  width={500}
                  height={600}
                  type="bar"
                  matchId={lastMatch.id}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      axios
                        .get(
                          `${API_BACKEND}/secondservereturnin?idMatch=${lastMatch.id}&sets=all&playerNumber=1`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then((result) => {
                          resolve(formatHorizontalChartWithSemData(result.data));
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    })
                  }
                  calcColor1={(data) => {
                    const val = parseFloat(data);
                    switch (true) {
                      case val < 50:
                        return "#f02e01";
                      case val >= 50 && val < 60:
                        return "#f17205";
                      case val >= 60 && val < 70:
                        return "#DCFE54";
                      case val >= 70 && val < 80:
                        return "#B4ED00";
                      case val >= 80:
                        return "#00ff00";
                      default:
                        break;
                    }
                  }}
                  calcColor2={(data) => {
                    const val = parseFloat(data);
                    switch (true) {
                      case val < 50:
                        return "#ffae9b";
                      case val >= 50 && val < 60:
                        return "#ffd8b6";
                      case val >= 60 && val < 70:
                        return "#eeffa8";
                      case val >= 70 && val < 80:
                        return "#58D68D";
                      case val >= 80:
                        return "#b2ffb2";
                      default:
                        break;
                    }
                  }}
                />
              </GraphContainer>
            </FourColumnsV2>
            <TwoColumns>
              <GraphContainer order={1}>
                <SubTitle>{translate("break points")}</SubTitle>
                <DynamicBreakPointsBarChart
                  matchId={lastMatch.id}
                  sets={"all"}
                  player={1}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      axios
                        .get(
                          `${API_BACKEND}/breakpoints?idMatch=${lastMatch.id}&sets=all&playerNumber=1`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then((result) => {
                          const chartData = formatBreakPointData(result.data);
                          resolve(chartData);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    })
                  }
                  name="breakpoints"
                  type="bar"
                />
              </GraphContainer>
              <GraphContainer order={2}>
                <SubTitle>{translate("minimatches")}</SubTitle>
                <MiniMatchesChart
                  matchId={lastMatch.id}
                  sets={"all"}
                  player={1}
                  semaphoreGraph={false}
                  whiteLegend={true}
                  width={400}
                  height={600}
                  type="bar"
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      axios
                        .get(
                          `${API_BACKEND}/minimatches?idMatch=${lastMatch.id}&sets=all&playerNumber=1`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then((result) => {
                          const chartData = formatData(result.data);
                          resolve(chartData);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    })
                  }
                />
              </GraphContainer>
            </TwoColumns>
          </DashboardContainer>
        ) : (
          <NoMatches>
            <h2>{translate("No matches loaded")}...</h2>
            <GoUploadMatch to="/admin/uploadmatch">
              {translate("Go to upload match")}
            </GoUploadMatch>
          </NoMatches>
        )}
      </Root>

    ) : null
  );
}