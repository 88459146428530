import axios from "axios";
import React from "react";
import styled from "styled-components";
import { API_BACKEND } from "../../../config";
import { useAuth } from "../../../providers/authentication";
import translate from "../../../providers/i18n/translate";

import { useIntl } from "react-intl";
import { ThemeContext } from "../../../providers/theme";
import { decodeToken } from "../../../hooks/jwt";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import ImageRacket from "./ImageRacket";

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;

const Title = styled.h2`
  text-transform: uppercase;
  font-size: 1.5rem;
  color: ${(props) => props.theme.palette.alternative_accent};
  font-weight: 300;
`;

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 5px 20px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
  overflow: hidden;
  grid-row: 1/4;
`;

const Data = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  strong {
    margin-right: 5px;
  }
`;

const DataInput = styled.input`
  color: ${(props) => props.theme.palette.principal};
  width: 100%;
  padding: 8px 10px;
  background: ${(props) =>
    props.editing ? `${props.theme.palette.base}AA` : "transparent"};
  border: ${(props) => (props.editing ? "inherit" : "none")};
  outline: none;
`;

const Actions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  width: 40%;
  height: auto;
`;

const Icon = styled.button`
  border: none;
  background: transparent;
  color: ${(props) => props.theme.palette.alternative_accent};
  cursor: pointer;
  svg {
    font-size: 2rem;
  }
`;

const Racket = () => {
  const [data, setData] = React.useState(null);
  const [oldData, setOldData] = React.useState(null);

  const { token } = useAuth();

  const theme = React.useContext(ThemeContext);

  const [editing, setEditing] = React.useState(false);
  const [changes, setChanges] = React.useState(false);

  const userData = decodeToken(token);

  const [image, setImage] = React.useState(null);

  const dispatch = useDispatch();
  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const intl = useIntl();

  const switchEditing = () => {
    if (editing && changes) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(intl.formatMessage({ id: "confirm changes" }))) {
        setEditing(false);
        var formdata = new FormData();
        formdata.append("brand", data.brand);
        formdata.append("model", data.model);
        formdata.append("strings", data.strings);
        formdata.append("photo", image);
        axios
          .put(
            `${API_BACKEND}/players/${userData?.trainedPlayer?.id}/racket`,
            formdata,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            alertMessage(
              intl.formatMessage({ id: "changes saved" }),
              alertConstants.INFO
            );
          })
          .catch((err) => {
            console.log(err);
            alertMessage(err.response.data.msg, alertConstants.ERROR);
          });
      }
    } else {
      if (!editing) {
        setOldData({ ...data });
        setEditing(true);
      } else {
        setData({ ...oldData });
        setEditing(false);
      }
    }
  };

  const cancelEditing = () => {
    setData({
      ...oldData,
    });
    setEditing(false);
  };

  const getData = () => {
    axios
      .get(`${API_BACKEND}/trainedplayerracket`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data);
        setImage(result.data.photo ? `${API_BACKEND}/players/${userData?.trainedPlayer?.id}/racket/image` : null)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChanges(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeImage = (value) => {
    setImage(value);
  }

  React.useEffect(() => {
    getData();
  }, []);

  return (
    data && (
      <Root>
        <Actions>
          {editing && <Icon onClick={cancelEditing}>{theme.icons.close}</Icon>}
          <Icon onClick={switchEditing}>
            {editing ? theme.icons.check : theme.icons.edit}
          </Icon>
        </Actions>
        <Title>{translate("Racket")}</Title>
        <Container>
          <ImageRacket handleChange={handleChangeImage} image={image} disabled={!editing} />
          {/* <Image src={data.photo ? data.photo : NoRacket} alt="racket image" /> */}
          <Data>
            <strong>{translate("Brand")}:</strong>
            <DataInput
              value={data.brand}
              name="brand"
              onChange={handleChange}
              editing={editing}
              disabled={!editing}
              placeholder={
                editing
                  ? intl.formatMessage({ id: "Add brand" })
                  : intl.formatMessage({ id: "Empty" })
              }
            />
          </Data>
          <Data>
            <strong>{translate("Model")}:</strong>
            <DataInput
              value={data.model}
              name="model"
              onChange={handleChange}
              editing={editing}
              disabled={!editing}
              placeholder={
                editing
                  ? intl.formatMessage({ id: "Add model" })
                  : intl.formatMessage({ id: "Empty" })
              }
            />
          </Data>
          <Data>
            <strong>{translate("Strings")}:</strong>
            <DataInput
              value={data.strings}
              name="strings"
              onChange={handleChange}
              editing={editing}
              disabled={!editing}
              placeholder={
                editing
                  ? intl.formatMessage({ id: "Add strings" })
                  : intl.formatMessage({ id: "Empty" })
              }
            />
          </Data>
        </Container>
      </Root>
    )
  );
};

export default Racket;
