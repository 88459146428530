import React from 'react';
import axios from 'axios';
import { API_BACKEND } from '../../config';
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";
import { isTokenExpired } from '../../hooks/jwt/jwt';

const AuthContext = React.createContext();


const AuthProvider = ({children}) => {

    const [token, setToken] = React.useState(JSON.parse(localStorage.getItem('token')));

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const handleLogin = (data, cb) => { // data = {userName, password}
        axios.post(`${API_BACKEND}/login?username=${data.userName}&password=${data.password}`)
        .then(result => {
            setToken(result.data);
            localStorage.setItem("token", JSON.stringify(result.data));
            cb();
        })
        .catch(err => {
            alertMessage(err.response.data.msg, alertConstants.ERROR);
        })
    };
    
    const handleLogout = (cb) => {
        setToken(null);
        localStorage.removeItem('token');
        if(cb){
            cb();
        }
    };

    const value = {
        token,
        onLogin: handleLogin,
        onLogout: handleLogout,
      };

    React.useEffect(() => {
        if(token && isTokenExpired(token)){
            handleLogout();
        }
    }, [token]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return React.useContext(AuthContext);
};

export default AuthProvider;