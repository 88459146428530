import { combineReducers } from "redux";
import { alertMessage } from "./messageBar/alertMessageReducer";
import { loading } from "./loading/loadingReducer";
import { selectedSets } from "./sets/setsReducer";
import { selectedPlayer } from "./player/playerReducer";
import { matchData } from "./matchData/matchDataReducer";
import { selectedDashboardFilters } from "./dashboardFilters/dashboardFiltersReducer";
import { lastMatch } from "./lastMatch/lastMatchReducer";
import { applicationSettings } from "./application/applicationReducer";


const reducers = combineReducers({
    alertMessage,
    loading,
    selectedSets,
    selectedPlayer,
    matchData,
    selectedDashboardFilters,
    lastMatch,
    applicationSettings
});

export default reducers;