import React from 'react';
import styled from "styled-components";
import axios from 'axios';
import { API_BACKEND } from '../../../../config';
import { useAuth } from '../../../../providers/authentication';
import { ThemeContext } from '../../../../providers/theme';

const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 4px 15px;
    border-radius: 8px;
    background: ${props => props.theme.palette.principal};
    color: ${props => props.theme.palette.base};
    font-size: 0.75rem;
    width: 100%;
    height: 100%;
    @media (min-width: 600px) {
        font-size: 0.875rem;
        padding: 8px 20px;
    }
    @media (min-width: 1440px) {
        font-size: 1.25rem;
        padding: 8px 25px;
    }
`

const Cup = styled.div`
    margin-right: 10px;
    svg {
        color: ${props => props.winner ? props.theme.palette.alternative_accent : props.theme.palette.accent};
        height: 18px;
        width: 18px;
        @media (min-width: 600px) {
            height: 24px;
            width: 24px;
        }
    }
`

const Score = ({id, player1}) => {

    const { token } = useAuth();

    const theme = React.useContext(ThemeContext);

    const [ data, setData ] = React.useState(null);

    React.useEffect(() => {
        axios.get(`${API_BACKEND}/score?idMatch=${id}`,
        {headers: { 
            'Authorization': `Bearer ${token}`
          }
        })
        .then(result => {
            setData(result.data);
        })
        .catch(err => {
            console.log(err);
        })
    }, [])

    return (
        data && player1 &&
        <Root>
            <Cup winner={player1.name === data.playerWinner}>{theme.icons.cup}</Cup>
            {data.score}
        </Root>
    );
}

export default Score;