import { lastMatchConstants } from "./lastMatchConstants";

const initialState = {
    id: null,
    trainedPlayerName: null,
    opponentName: null,
    playerWinner: null,
    date: null,
    score: null,
    surface: null,
    points: null
};

export function lastMatch(state = initialState, action) {
    switch (action.type) {
        case lastMatchConstants.SET:
            return {
                ...state,
                ...action.lastMatch
            };
        default:
            return state
    }
}