/* General imports */
import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import {useDispatch, useSelector} from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import translate from "../../../providers/i18n/translate";
import {StyledLanguageSlider} from "./styled-components/StyledLanguageSlider";
import {LOCALES} from "../../../providers/i18n";
import {applicationConstants} from "../../../store/reducers/application/applicationConstants";

/* Styled components */
const Root = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    min-height: 70px;
    box-sizing: border-box;
    @media (min-width: 600px) {
      display: flex;
      gap: 10px;
    }
`

// PROPS: setChanges, idUser
const ApplicationSettings = React.forwardRef((props, ref) => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.applicationSettings);
    const [applicationSettingsData, setApplicationSettingsData] = React.useState({language: language});
    const { alertMessage, changeApplicationLanguage } = bindActionCreators(actionCreators, dispatch);

    const changeLang = (e) => {
        const value = e.target.checked ? LOCALES.ENGLISH : LOCALES.SPANISH;
        setApplicationSettingsData({
            ...applicationSettingsData,
            language: value
        });
        props.setChanges(true);

    };

    const onSave = () => {
        changeApplicationLanguage(applicationConstants.SET_LANGUAGE, applicationSettingsData.language);
    }

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    React.useImperativeHandle(ref, () => ({
        submit() {
            onSave();
        }
    }));


    return (
        <Root>
            <StyledLanguageSlider className="btn-container">
                <span className="application-settings-btn-label">{translate('select language')}:</span>
                <label className="switch btn-color-mode-switch">
                    <input checked={applicationSettingsData.language === LOCALES.ENGLISH} id="color_mode" name="color_mode" type="checkbox" onChange={changeLang}/>
                    <label className="btn-color-mode-switch-inner" data-off="ES" data-on="EN" htmlFor="color_mode"></label>
                </label>
            </StyledLanguageSlider>
        </Root>
    );
})

export default ApplicationSettings;