import React from "react";
import styled from "styled-components";
import WebNav from "../navbars/webnav";
/* import Footer from "../footer"; */
import { Outlet } from "react-router-dom";

const NAV_HEIGHT = "8vh";
const FOOTER_HEIGHT = "20vh";

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
`

const Body = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: ${`calc(100vh - (${FOOTER_HEIGHT} / 1.25))`};
    @media (min-width: 1024px) {
        min-height: ${`calc(100vh - ${FOOTER_HEIGHT})`};
    }
`

const WebLayout = () => {
    return (
        <Root>
            <WebNav height={NAV_HEIGHT}/>
            <Body>
                <Outlet />
            </Body>
            {/* <Footer height={FOOTER_HEIGHT} /> */}
        </Root>
    );
}

export default WebLayout;