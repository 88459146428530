import { colors } from "@mui/material";
import { THEMES } from "../../../providers/theme/themes";

export const getTooltip = (series, seriesIndex, dataPointIndex, w) => {
  let seriesInfo = ""
  for (let index = 0; index < series.length; index++) {
    seriesInfo += '<span style="color: #7A7A7A;"> <span style="display: inline-block; width: 14px; height: 13px; margin-right: 5px; border-radius: 50%; background: ' + w.config.colors[index] + ';"></span>' +
      '<span style="margin-right: 5px;">' + w.config.series[index].name + ': </span>' +
      '<strong>' +
      series[index][dataPointIndex] +
      '</strong>' +
      '</span>'
  };
  return (
    '<div style="padding: 10px;border-radius: 8px; background: #FFF;">' +
    '<div style="background: transparent; padding: 5px; display: flex; flex-direction: column; gap: 10px; align-items: flex-start; justify-content: flex-start;">' +
    seriesInfo +
    "</div>" +
    "</div>"
  );
};

export const _options = {
  chart: {
    with: 500,
    height: 800,
    type: "line",
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  colors: colors,
  dataLabels: {
    enabled: true,
    enabledOnSeries: [0, 2],
    textAnchor: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: "0.85rem",
      fontFamily: "museo-sans, sans-serif",
      fontWeight: "bold",
    },
    background: {
      enabled: true,
      foreColor: "#000",
      padding: 4,
      borderRadius: 5,
      borderColor: "#40CBE6",
      dropShadow: {
        enabled: true,
        top: 2,
        left: -0,
        blur: 6,
        color: "#40CBE6",
        opacity: 0.45,
      },
    },
    formatter: function (val, opts) {
      return `${val}`;
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    fontSize: "14px",
    fontFamily: "museo-sans, sans-serif",
    labels: {
      colors: "#FFFFFF",
    },
  },
  stroke: {
    width: [3, 3, 3, 3],
    curve: "straight",
    dashArray: [0, 4, 0, 4],
  },
  markers: {
    size: 0,
    hover: {
      sizeOffset: 6,
    },
  },
  xaxis: {
    show: true,
    align: "center",
    labels: {
      show: true,
      style: {
        fontSize: "0.65rem",
        fontFamily: "museo-sans, sans-serif",
        fontWeight: 100,
        colors: THEMES.default.palette.base,
      },
      maxHeight: 100,
      trim: true,
    },
    axisBorder: {
      color: "#707070",
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    align: "center",
    labels: {
      style: {
        show: true,
        fontSize: "0.85rem",
        fontFamily: "museo-sans, sans-serif",
        fontWeight: 100,
        colors: ["#FFFFFF"],
      },
    },
  },
  grid: {
    borderColor: "#707070",
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
};


export const formatDataFirstServeIn = (data) => {
  const annotation_firstServeInAverage = data.firstServeInEffectivenessAverage;
  const colors = ['#40CBE6', '#00E396'];
  const series = [
    {
      name: "1st Serve In",
      data: data.historicalFirstServe.map((dat) => {
        return dat.inEffectiveness;
      }),
    },
    {
      name: "1st Serve In Average",
      data: data.historicalFirstServe.map((dat) => {
        return annotation_firstServeInAverage;
      }),
    },
  ];
  const categories = data.historicalFirstServe.map((cat) => cat.opponent);

  return { series, categories, colors };
};

export const formatDataFirstServeWon = (data) => {
  const annotation_firstServePointsWonAverage = data.pointsWonEffectivenessAverage;
  const colors = ['#40CBE6', '#775DFF'];
  const series = [
    {
      name: "1st Serve Points Won",
      data: data.historicalFirstServe.map((dat) => {
        return dat.pointsWonEffectiveness;
      }),
    },
    {
      name: "1st Serve Points Won Average",
      data: data.historicalFirstServe.map((dat) => {
        return annotation_firstServePointsWonAverage;
      }),
    },
  ];
  const categories = data.historicalFirstServe.map((cat) => cat.opponent);

  return { series, categories, colors };
};

export const formatDataSecondServePoints = (data) => {
  const annotation_secondServePointsAverage = data.secondServePointsAverage;
  const colors = ['#40CBE6', '#00E396'];
  const series = [
    {
      name: "2nd Serve Points",
      data: data.historicalSecondServe.map((dat) => {
        return dat.secondServePoints;
      }),
    },
    {
      name: "2nd Serve Points Average",
      data: data.historicalSecondServe.map((dat) => {
        return annotation_secondServePointsAverage;
      }),
    },
  ];
  const categories = data.historicalSecondServe.map((cat) => cat.opponent);

  return { series, categories, colors };
};

export const formatDataSecondServeWon = (data) => {
  const annotation_pointsWonEffectivenessAverage = data.pointsWonEffectivenessAverage;
  const colors = ['#40CBE6', '#775DFF'];
  const series = [
    {
      name: "2nd Serve Points Won",
      data: data.historicalSecondServe.map((dat) => {
        return dat.pointsWonEffectiveness;
      }),
    },
    {
      name: "2nd Serve Points Won Average",
      data: data.historicalSecondServe.map((dat) => {
        return annotation_pointsWonEffectivenessAverage;
      }),
    },
  ];
  const categories = data.historicalSecondServe.map((cat) => cat.opponent);

  return { series, categories, colors };
};


export const formatDataDeuceAd = (data) => {
  const annotation_deuceEffectivenessAverage = data.deuceEffectivenessAverage;
  const annotation_adEffectivenessAverage = data.adEffectivenessAverage;
  const colors = ['#40CBE6', '#00E396', '#DCFE54', '#775DFF'];
  const series = [
    {
      name: "Deuce Court",
      data: data.historicalDeuceAd.map((dat) => {
        return dat.deuceEffectiveness;
      }),
    },
    {
      name: "Deuce Court Average",
      data: data.historicalDeuceAd.map((dat) => {
        return annotation_deuceEffectivenessAverage;
      }),
    },
    {
      name: "Ad Court",
      data: data.historicalDeuceAd.map((dat) => {
        return dat.adEffectiveness;
      }),
    },

    {
      name: "Ad Court Average",
      data: data.historicalDeuceAd.map((dat) => {
        return annotation_adEffectivenessAverage;
      }),
    },
  ];
  const categories = data.historicalDeuceAd.map((cat) => cat.opponent);

  return { series, categories, colors };
};

export const formatDataFirstServeReturnIn = (data) => {
  const annotation_effectivenessAverage = data.firstServeReturnInAverage;
  const colors = ['#40CBE6', '#00E396'];
  const series = [
    {
      name: "1st Serve Return In",
      data: data.historicalFirstServeReturnIn.map((dat) => {
        return dat.inEffectiveness;
      }),
    }
    ,
    {
      name: "1st Serve Return In Average",
      data: data.historicalFirstServeReturnIn.map((dat) => {
        return annotation_effectivenessAverage;
      }),
    },
  ];
  const categories = data.historicalFirstServeReturnIn.map((cat) => cat.opponent);

  return { series, categories, colors };
};

export const formatDataFirstServeReturn = (data) => {
  const annotation_effectivenessAverage = data.effectivenessAverage;
  const colors = ['#40CBE6', '#775DFF'];
  const series = [
    {
      name: "1st Serve Return Effectiveness",
      data: data.historicalServeReturn.map((dat) => {
        return dat.efectiveness;
      }),
    },
    {
      name: "1st Serve Return Effectiveness Average",
      data: data.historicalServeReturn.map((dat) => {
        return annotation_effectivenessAverage;
      }),
    },
  ];
  const categories = data.historicalServeReturn.map((cat) => cat.opponent);

  return { series, categories, colors };
};

export const formatDataSecondServeReturnIn = (data) => {
  const annotation_effectivenessAverage = data.secondServeReturnInAverage;
  const colors = ['#40CBE6', '#00E396'];
  const series = [
    {
      name: "2nd Serve Return In",
      data: data.historicalSecondServeReturnIn.map((dat) => {
        return dat.inEffectiveness;
      }),
    },
    {
      name: "2nd Serve Return In Average",
      data: data.historicalSecondServeReturnIn.map((dat) => {
        return annotation_effectivenessAverage;
      }),
    },
  ];
  const categories = data.historicalSecondServeReturnIn.map((cat) => cat.opponent);

  return { series, categories, colors };
};

export const formatDataSecondServeReturn = (data) => {
  const annotation_effectivenessAverage = data.effectivenessAverage;
  const colors = ['#40CBE6', '#775DFF'];
  const series = [
    {
      name: "2nd Serve Return Effectiveness",
      data: data.historicalServeReturn.map((dat) => {
        return dat.efectiveness;
      }),
    },
    {
      name: "2nd Serve Return Effectiveness Average",
      data: data.historicalServeReturn.map((dat) => {
        return annotation_effectivenessAverage;
      }),
    },
  ];
  const categories = data.historicalServeReturn.map((cat) => cat.opponent);

  return { series, categories, colors };
};

export const formatDataSem = (data) => {
  const annotation_av = data.averageSEM.value;
  const colors = ['#40CBE6', '#775DFF'];
  const series = [
    {
      name: "SEM",
      data: data.historicalSEM.map((dat) => {
        return dat.value;
      }),
    },
    {
      name: "Average",
      data: data.historicalSEM.map((dat) => {
        return annotation_av;
      }),
    },
  ];
  const categories = data.historicalSEM.map((cat) => cat.opponent);

  return { series, categories, colors };
};

export const formatDataRem = (data) => {
  const annotation_av = data.averageREM.value;
  const colors = ['#40CBE6', '#775DFF'];
  const series = [
    {
      name: "REM",
      data: data.historicalREM.map((dat) => {
        return dat.value;
      }),
    },
    {
      name: "Average",
      data: data.historicalREM.map((dat) => {
        return annotation_av;
      }),
    },
  ];
  const categories = data.historicalREM.map((cat) => cat.opponent);
  
  return { series, categories, colors };
};