import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import styled from "styled-components";
import translate from "../../../providers/i18n/translate";
import { dashboardFiltersConstants } from "../../../store/reducers/dashboardFilters/dashboardFiltersConstants";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useAuth } from "../../../providers/authentication";
import { decodeToken } from "../../../hooks/jwt";

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  padding: 14px;
  border-radius: 10px;
`;

const Title = styled.h2`
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
`;

const PlayerWinnerFilter = () => {
  const { filters } = useSelector((state) => state.selectedDashboardFilters);

  const dispatch = useDispatch();

  const { changeDashboardFilters } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const { token } = useAuth();

  const userData = decodeToken(token);

  const handleChange = (e) => {
    const { value } = e.target;

    const newFilters = [];
    if (value === "all") {
      changeDashboardFilters(
        dashboardFiltersConstants.SET,
        filters.filter((fil) => fil.name !== "playerWinner")
      );
    } else {
      const selectedPlayerWinners = filters.find(
        (fil) => fil.name === "playerWinner"
      );
      if (selectedPlayerWinners) {
        selectedPlayerWinners.condition = value;
        changeDashboardFilters(dashboardFiltersConstants.SET, filters);
      } else {
        newFilters.push({
          name: "playerWinner",
          values: [userData.trainedPlayer.id],
          condition: value,
          type: "integer",
        });
        changeDashboardFilters(dashboardFiltersConstants.SET, [
          ...filters,
          ...newFilters,
        ]);
      }
    }
  };

  return (
    userData && (
      <Root>
        <Title>{translate("Winner")}</Title>
        <Options>
          {filters && (
            <RadioGroup
              value={
                filters.find((fil) => fil.name === "playerWinner") &&
                filters.find((fil) => fil.name === "playerWinner").condition !==
                  "all"
                  ? filters.find((fil) => fil.name === "playerWinner").condition
                  : "all"
              }
              onChange={handleChange}
              name="playerWinner"
            >
              <FormControlLabel value="all" control={<Radio />} label="All" />
              <FormControlLabel
                value="equal"
                control={<Radio />}
                label={userData.trainedPlayer?.name}
              />
              <FormControlLabel
                value="distinct"
                control={<Radio />}
                label="Opponent"
              />
            </RadioGroup>
          )}
        </Options>
      </Root>
    )
  );
};

export default PlayerWinnerFilter;
