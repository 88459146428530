import { LOCALES } from "../locales";

const locale_spanish = {
  [LOCALES.SPANISH]: {
    'hello': "Hola, {name}!",
    'exit': "Salir",
    "app.login.welcome": "Bienvenido",
    "app.hero.pretitle": "Ciencia del deporte por el equipo de Franco Davín",
    "app.hero.title": "Rendimiento basado en datos",
    "app.hero.logoSubtitle": "Powered by",
    "app.login.signin": "Iniciar sesión",
    "app.login.donthaveaccount": "¿Aún no tienes cuenta?",
    "app.login.loginaccount": "Entrá a tu cuenta",
    "app.login.signup": "Registrate aquí",
    "app.register.welcome": "Comencemos",
    "app.register.registeraccount": "Creá tu cuenta",
    "app.register.signup": "Registrate",
    "app.register.haveanaccount": "¿Ya tenés una cuenta?",
    "app.register.signin": "Iniciá sesión aquí",
    'username': "Nombre de usuario",
    'password': "Contraseña",
    'firstname': "Nombres",
    'lastname': "Apellidos",
    'email': "Correo electrónico",
    'dni': "DNI",
    'repeatPassword': "Repetir contraseña",
    "click on bar to see details": "Click en la barra para ver detalles",
    "sort by": "Ordenar por",
    'points': "Puntos",
    'effectiveness': "Efectividad",
    "first serve rally length": "Cantidad de peloteos en el primer servicio",
    "second serve rally length": "Cantidad de peloteos en el segundo servicio",
    "first serve return rally length": "Cantidad de peloteos en la devolución del primer servicio",
    "second serve return rally length": "Cantidad de peloteos en la devolución del segundo servicio",
    "rally on first serve and return": "Cantidad de peloteos en el primer servicio y devolución",
    "rally on second serve and return": "Cantidad de peloteos en el segundo servicio y devolución",
    "If you keep these values ​​the probability of winning matches is very low":
      "Si mantienes estos valores la probabilidad de ganar partidos es muy baja",
    "If you keep these values ​​the probability of winning matches is low":
      "Si mantienes estos valores la probabilidad de ganar partidos es baja",
    "If you keep these values ​​you have some chances of winning matches":
      "Si mantienes estos valores tienes algunas posibilidades de ganar partidos",
    "If you keep these values ​​you have high chances of winning matches":
      "Si mantienes estos valores tienes altas posibilidades de ganar partidos",
    'serve': "Servicio",
    'sem': "M.E.S.",
    'flow': "Flujo",
    'rs': "RS",
    "4m": "4M",
    'overview': "Resumen",
    'notifications': "Notificaciones",
    'matches': "Partidos",
    "upload match": "Subir partido",
    'dashboard': "Dashboard",
    'mole': "Mole",
    "account settings": "Configuración de cuenta",
    "player profile": "Perfil del jugador",
    "General Serve Stats": "Estadísticas generales del servicio",
    "2nd Serve": "2do Servicio",
    "1st Serve": "1er Servicio",
    "Deuce and ad points won on serve and return":
      "Puntos ganados con el servicio y la devolución del servicio al lado derecho e izquierdo de la cancha",
    "Deuce and ad points won on 2nd serve":
      "Puntos ganados con el 2do. Servicio  al lado derecho e izquierdo de la cancha",
    "Service Effectiveness Margin in the Match": "Margen de Efectividad del Servicio en el Partido",
    "Return Effectiveness Margin in the Match": "Margen de Efectividad de la Devolución en el Partido",
    'setdesireddescription':
      "Este margen definirá cuánto le gustaría mejorar del juego de su jugador. Podrás establecer tu objetivo cuando quieras.",
    "Leadership margin": "Margen de Protagonismo",
    "Match General Stats": "Estadísticas Generales del Partido",
    "Match quality": "Calidad del Partido",
    "Set desired margin": "Establecer el margen deseado",
    'rally': "Peloteo",
    'minimatches': "Minipartidos",
    'M4': "M4",
    "unforced errors": "Errores No Forzados",
    "P1": "P1",
    "Total chains": "Cadenas totales",
    "Total points in a row": "Puntos totales consecutivos",
    "Distribution of unforced errors in a row":
      "Distribución de Errores No Forzados consecutivos",
    "unforced errors in a row (UER)": "Errores No Forzados Consecutivos (ENFC)",
    "P1 (Priority 1)":
      "P1 (Prioridad 1)",
    "Percentage of Determinant Unforced Errors (DUE) over Total Unforced Errors":
      "Porcentaje de Errores No Forzados Determinantes (ENFD) sobre el Total de Errores No Forzados",
    "Very good": "Muy bien",
    'Good': "Bien",
    'Excellent': "Excelente",
    'Regular': "Regular",
    'Bad': "Malo",
    "Forehand and Backhand Key Shots": "Tiros ganadores de derecha y revés",
    "double click to expand": "Doble click para expandir",
    'total': "Total",
    'search': "Buscar",
    'records': "Registros",
    'show': "Mostrar",
    'of': "de",
    'page': "página",
    'actions': "Acciones",
    'umpire': "Umpire",
    'ball': "Pelota",
    'surface': "Superficie",
    'tournament': "Torneo",
    'won': "Ganador",
    'endTime': "Fin",
    'startTime': "Inicio",
    'date': "Día",
    'opponent': "Oponente",
    'delete': "Eliminar",
    'edit': "Editar",
    'login': "Iniciar sesión",
    "about gsm": "Sobre GSM",
    'enter': "Entrar",
    'country': "País",
    'phone': "Teléfono",
    'playername': "Nombre del jugador",
    'datebirth': "Fecha de nacimiento",
    "change password": "Cambiar contraseña",
    'remove': "Remover",
    'change': "Cambiar",
    'save': "Guardar",
    'cancel': "Cancelar",
    'help': "Ayuda",
    'billing': "Facturación",
    "personal settings": "Configuraciones personales",
    'apply': "Aplicar",
    "repeat new password": "Repetir nueva contraseña",
    "new password": "Nueva contraseña",
    "current password": "Contraseña actual",
    'photo': "Foto",
    'upload': "Subir",
    'LOSE': "PERDIDO",
    "Go to match": "Ir al partido",
    "Last Match": "Último partido",
    'Weight': "Peso",
    'Height': "Altura",
    'Born': "Nacimiento",
    'From': "Nacionalidad",
    "Historical Forehand and Backhand Key Shots":
      "Tiros Definitorios de Derecha y de Revés Historicos",
    "Match Quality": "Calidad del partido",
    "This graphics belong to your player's last 10 matches.":
      "Este gráfico pertenece a los últimos 10 partidos de tu jugador.",
    "Latest stats summary": "Resumen de las estadísticas de los últimos 10 partidos",
    'Surfaces': "Superficies",
    'reset': "Limpiar",
    'return': "Devolución",
    'performance': "Rendimiento",
    "subtitle determinant unforced errors": "Como su nombre lo indica, estos son los errores no forzados durante el promedio de tus últimos diez partidos, que pueden cambiar tu juego y resultados inmediatamente. Estos son errores clave para encontrar una forma de reducirlos tanto como sea posible.",
    "No data": "Sin datos",
    'Rounds': "Rondas",
    'Tournament': "Torneo",
    'Date': "Fecha",
    'Winner': "Ganador",
    'Empty': "Vacío",
    "Add category": "Agregar categoría",
    "Add federation": "Agregar federación",
    "Add favourite surface": "Agregar superficie favorita",
    "Add backhand": "Agregar revés",
    "Add hand": "Agregar derecha",
    'WON': "WON",
    "Complete your player's profile": "Completa el perfil de jugador",
    "No have ranking information yet.": "Aún no tenemos información del ranking.",
    "Last match": "Último Partido",
    'here': "aquí",
    'Awards': "Premios",
    'Strings': "Encordado",
    'Model': "Modelo",
    'Brand': "Marca",
    'Racket': "Raqueta",
    'Rankings': "Rankings",
    'Category': "Categoría",
    'Federation': "Federación",
    "Favourite surface": "Superficie favorita",
    'Backhand': "Revés",
    'Hand': "Hand",
    'Profile': "Perfil",
    'profile': "Perfil",
    'radar': "Radar",
    "End time": "Fin",
    "Start time": "Inicio",
    "Match date": "Fecha del partido",
    'Umpire': "Umpire",
    'Ball': "Pelota",
    'Surface': "Superficie",
    'Grass': "Pasto",
    "Edit match": "Editar partido",
    "Effectiveness in the range of rallies that is repeated the most in each match (Mode)":
      "Efectividad en el rango de peloteos que más se repite en cada partido (Moda)",
    "% RM": "% RM",
    "Effectiveness in the average range of rallies":
      "Efectividad en el rango promedio de peloteos",
    "% RA": "% RA",
    "Percentage of won points": "Porcentaje de puntos ganados",
    "% WP": "% WP",
    "Percentage of won matches": "Porcentaje de partidos ganados",
    "% WM": "% WM",
    "Percentage of won points in return games":
      "Porcentaje de puntos ganados en juegos de devolución de servicio",
    "% RWP": "% RWP",
    "Percentage of won points in service games":
      "Porcentaje de puntos ganados en juegos de servicio",
    "% SWP": "% SWP",
    'References': "Referencias",
    'Skillset': "Conjunto de habilidades",
    "passwords must match": "Las contraseñas deben coincidir.",
    "player name": "Nombre del jugador",
    'export': "Exportar",
    "confirm changes": "¿Confirma los cambios?",
    "changes saved": "Cambios guardados",
    "What is GSM?": "What is GSM?",
    "A tool developed by Franco Davin to enhance the performance of players of any level.":
      "Una herramienta desarrollada por Franco Davin para mejorar el rendimiento de los jugadores de cualquier nivel.",
    "Game Set Math is a science project focused on the data analysis and new statistics applied to tennis as never seen before.":
      "Game Set Math es un proyecto científico centrado en el análisis de datos y nuevas estadísticas aplicadas al tenis como nunca antes se había visto.",
    "How many times have you been told subjective features about a player, without having real data that backs up those comments? How often are opinions formed over a player&apos;s development with no certainty of the process that is going to be done?":
      "¿Cuántas veces te han dicho características subjetivas sobre un jugador, sin tener datos reales que respalden esos comentarios? ¿Con qué frecuencia se forman opiniones sobre el desarrollo de un jugador sin tener certeza del proceso que se va a realizar?",
    "This tool will help in focusing the process for the development of a player with exact data about the actual and the projected status, being efficient with the time and trainings selected for each player.":
      "Esta herramienta ayudará a enfocar el proceso de desarrollo de un jugador con datos exactos sobre el estado actual y proyectado, siendo eficiente con los tiempos y entrenamientos seleccionados para cada jugador.",
    "It has been used over 20 years privately and now is going to be shared with anybody who is interested in using the technology of the 21st century.":
      "Se ha utilizado durante 20 años de forma privada y ahora se compartirá con cualquiera que esté interesado en utilizar la tecnología del siglo XXI.",
    "The following features will be available:":
      "Las siguientes características estarán disponibles:",
    'Rallies': "Peloteos",
    "Average rallies": "Promedio de peloteos",
    "Organized rallies per situation (serving, returning), per set and per match":
      "Peloteos organizadas por situación (servicio, devolución), por set y por partido",
    "Identification of winner points through number of rallies":
      "Identificación de puntos ganadores a través del número de peloteos",
    "% Of points won by first serve": "% de puntos ganados por primer servicio",
    "% Of points won by second serve": "% de puntos ganados por segundo servicio",
    "Player performance rating": "Clasificación de rendimiento del jugador",
    "Match performance rating": "Clasificación de rendimiento del partido",
    "Winning/losing points streaks": "Rachas de puntos ganados/perdedores",
    "Match momentum": "Match momentum",
    "What can you get?": "¿Qué puedes conseguir?",
    "MATCH PERFORMANCE": "NIVEL DE JUEGO",
    "It is the performance level of each of the last ten matches and the match performance average.":
      "Es el nivel de rendimiento de cada uno de los últimos diez partidos y el promedio de rendimiento del partido.",
    "The desired margin of performance is established":
      "Se establece el margen de rendimiento deseado",
    "The leadership margin shows the percentage of the points of the last ten games that depended on the player":
      "El margen de protagonismo muestra el porcentaje de los puntos de los últimos diez juegos que dependieron del jugador",
    "Match quality takes into account the level of performance of the two players in the last ten matches":
      "La calidad del partido tiene en cuenta el nivel de rendimiento de los dos jugadores en los últimos diez partidos.",
    "SERVICE EFFECTIVE MARGIN (S.E.M.)": "MARGEN DE EFECTIVIDAD DEL SERVICIO (M.E.S.)",
    "The service effective margin refers to the effectiveness in game services, including the performance of the first and second serve. On this basis, the probability that the service will be broken is calculated.":
      "El margen de efectividad del servicio  se refiere a la efectividad en los servicios de juego, incluyendo el primer y segundo servicio. Sobre esta base, se calcula la probabilidad de que se quiebre el servicio.",
    "How can we help you?": "¿Cómo podemos ayudarte?",
    "With a technological tool with more than 20 years of development and proven results with pro and amateur players, for you to use in your organization.":
      "Con una herramienta tecnológica con más de 20 años de desarrollo y resultados probados con jugadores profesionales y aficionados, para que la utilices en tu organización.",
    "Access to the GSM reporting": "Acceso a los informes GSM",
    "Matches data input": "Entrada de datos de cada uno de los partidos",
    "Weekly/Monthly reports. Recommendations and special analysis to be supplied":
      "Informes semanales/mensuales. Recomendaciones y análisis especiales",
    "Check what best suits for you at:": "Consulta lo que más se adapta a ti en:",
    "In addition, the return effective margin refers to the effectiveness in return services, including the performance of the first and second serve return. On this, basis, the probability that the opponent serve will be broken is calculated.":
      "Además, el margen de efectividad de devolución se refiere a la efectividad en los servicios de devolución, incluidos la devolución del primer y segundo servicio. Sobre esta base, se calcula la probabilidad de que el servicio del oponente se quiebre.",
    "This report is a summary that contains information on the main parameters of the player in the last 10 matches released. Full information is available on the GSM WEB APP:":
      "El presente reporte es un resumen que contiene información de los principales parámetros del jugador en los últimos 10 partidos relevados. La información completa se encuentra disponible en la APP WEB GSM:",
    "How to read the report?": "¿Cómo leer el reporte?",
    "Observe if it is an initial, intermediate or final report. Regardless of the number, the initial report may correspond to the start of a full season or the start of any other mini-cycle established during the season. The intermediate reports are those made periodically during any cycle excluding the initial or final report. The final report corresponds to the end of the season or the end of a mini-cycle.":
      "Observar si se trata de un informe inicial, medio o final. Independientemente del número, el informe inicial puede corresponder al inicio de una temporada completa o al inicio de cualquier otro miniciclo establecido durante la temporada. Los informes medios son los realizados periódicamente durante cualquier ciclo excluyendo el informe inicial o final. El informe final corresponde al de final de temporada o al de final de un miniciclo.",
    "The initial report shows the player parameters at the start of a cycle. By determining the level of the player at the time of starting the job, it is neither good nor bad, but only marks a starting point. The establishment of short, medium and long-term objectives will determine the intermediate and final points of the path to follow.":
      "El informe inicial refleja los parámetros del jugador al inicio de un ciclo. Al determinar el nivel del jugador al momento de iniciar el trabajo, no es ni bueno ni malo, sino solo marca un punto de partida. El establecimiento de los objetivos a corto, mediano y largo plazo determinarán los puntos intermedios y finales del camino.",
    "The intermediate report show the parameters of the player in the different stages of work within a cycle. They allow comparison with the initial parameters and with other intermediate reports. It is important to note that the comparison between average reports will not necessarily express constant improvements so that at the end of the cycle there is progress in the player&apos;s level. This means that there may be setbacks and advances that are logical in a learning process. Error precedes progress, and learning new skills may first cause a drop in level of play.":
      "Los informes medios reflejan los parámetros del jugador en las diferentes etapas de trabajo dentro de un ciclo. Permiten la comparación con los parámetros iniciales y con otros informes de nivel medio. Es importante remarcar que la comparación entre informes medios no necesariamente expresará mejorías constantes para que al final del ciclo haya un progreso en el nivel del jugador. Esto quiere decir que puede haber retrocesos y avances que son lógicos en un proceso de aprendizaje. El error precede al progreso, y el aprendizaje de nuevas habilidades puede provocar primero una baja en el nivel de juego.",
    "The final report shows the parameters of the player at the end of a cycle, it can be the final report of a season or a mini-cycle within it. This report must be compared with the initial report, to observe the progress, and in the items in which no improvements are seen, to be able to analyze the causes.":
      "El informe final refleja los parámetros del jugador al finalizar un ciclo, puede ser el informe final de una temporada o de un miniciclo dentro de esta. Este informe debe compararse con el informe inicial, para observar los progresos, y en los ítems en los cuales no se vean mejoras, poder analizar las causas.",
    "Report type": "Tipo de informe",
    "Game level": "Nivel de juego",
    "Match performance": "Nivel de juego",
    "The desired margin of performance is established.":
      "Se establece el margen de rendimiento deseado.",
    "The leadership margin shows the percentage of the points of the last ten games that depended on the player.":
      "El margen de protagonismo muestra el porcentaje de los puntos de los últimos diez juegos que dependieron del jugador.",
    "Match quality takes into account the level of performance of the two players in the last ten matches.":
      "La calidad del partido tiene en cuenta el nivel de rendimiento de los dos jugadores en los últimos diez partidos.",
    'simulation': 'Simulación',
    'report': 'Reporte',
    'determinant unforced errors': 'Errores No Forzados Determinantes (ENFD)',
    'simulate': 'Simular',
    'unforcedErrorsSecondServeReturn': 'Devolución de 2do. Servicio ',
    'unforcedErrorsThirdShotFirstServe': '1er Servicio + 1',
    'unforcedErrorsThirdShotSecondServe': '2do Servicio + 1',
    'unforcedErrorsFourthShotFirstServe': 'Devolución 1er Servicio + 1',
    'unforcedErrorsFourthShotSecondServe': 'Devolución 2do Servicio + 1',
    'doubleFaults': 'Doble faltas',
    'totalPointsInSequences': 'Puntos totales de sequencias',
    'loading': 'Cargando',
    'summary of player skills': 'Resumen de las habilidades del jugador',
    'comments': 'Comentarios',
    'Return Effective Margin (R.E.M.)': 'Margen de Efectividad de Devolución (M.E.D.)',
    'Service Effective Margin (S.E.M.)': 'Margen de Efectividad del Servicio (M.E.S.)',
    'insert comments here...': 'Insertar los comentarios aquí...',
    'generating the report': 'Generando el reporte...',
    'report type': 'Tipo de reporte',
    'content': 'Contenido',
    'Introduction': 'Introducción',
    'Print': 'Imprimir',
    'You must do the simulation before saving': 'Debes hacer la simulación antes de guardar',
    'You must wait until the simulation is over.': 'Debes esperar hasta que termine la simulación',
    "The graph shows the player's level of play from each of the last 10 matches. On this basis, the performance average is calculated.": "El gráfico muestra el nivel de juego del jugador en cada uno de los últimos 10 partidos. Sobre esta base, se calcula el promedio de rendimiento.",
    'save simulation': 'guardar simulación',
    'Name': 'Nombre',
    'Description': 'Descripción',
    'The simulation has been saved': 'La simulación ha sido guardada',
    'clear': 'limpiar',
    'rem': 'M.E.D',
    'Effectiveness in points played with the first and second serve and with the return of the first and second serve.': 'Efectividad en puntos jugados con el primer y segundo servicio y con la devolución del primer y segundo servicio.',
    'Detail and effectiveness of rally lenght on serve and serve return.': 'Detalle y efectividad de los peloteos en el servicio y la devolución del servicio.',
    'Detail and effectiveness of rally lenght on serve.': 'Detalle y efectividad de los peloteos en el servicio',
    'Detail and effectiveness of rally lenght on serve return.': 'Detalle y efectividad de los peloteos en la devolución del servicio.',
    'Detail of the percentage of won points with first and second serve. It also includes the percentage of won points with the first and second serve on the deuce and ad court.': 'Detalle del porcentaje de puntos ganados con el primer y segundo servicio. También incluye el porcentaje de puntos ganados con el primer y segundo servicio en el lado derecho e izquierdo de la cancha',
    '1st Serve In': '% 1er. Servicio',
    'Deuce and Ad Points on 1st Serve In': '% 1er. Servicio al lado derecho e izquierdo',
    'Points Won on 1st Serve In': 'Puntos ganados con el 1er. Servicio',
    'Deuce and Ad Points Won on 1st Serve In': 'Puntos ganados 1er. Servicio al lado derecho e izquierdo',
    '2nd Serve Points Played out of Total Service': '% puntos disputados con el 2do. Servicio del total de saques',
    'Deuce and Ad Points on 2nd Serve In': '% 2do. Servicio al lado derecho e izquierdo',
    '2nd Serve Effectiveness': 'Puntos ganados 2do. Servicio',
    'Deuce and Ad Points Won on 2nd Serve': 'Puntos ganados 2do. Servicio al lado derecho e izquierdo',
    'Detail of the percentage of won points with first and second serve return. It also includes the percentage of won points with the first and second serve return on the deuce and ad court.': 'Detalle del porcentaje de puntos ganados con la devolución del primer y segundo servicio. También incluye el porcentaje de puntos ganados con la devolución del primer y segundo servicio en lado derecho e izquierdo de la cancha',
    '1st Serve Return In': '% Devolución 1er. Servicio',
    'Deuce and Ad Points on 1st Serve Return In': '% Devolución 1er. Servicio al lado derecho e izquierdo',
    '1st Serve Return Effectiveness': 'Puntos ganados Devolución 1er. Servicio',
    'Deuce and Ad Points Won on 1st Serve Return In': 'Puntos ganados Devolución 1er. Servicio al lado derecho e izquierdo',
    '2nd Serve Return In': '% Devolución 2do. Servicio',
    'Deuce and Ad Points on 2nd Serve Return In': '% Devolución 2do. Servicio al lado derecho e izquierdo',
    '2nd Serve Return Effectiveness': 'Puntos ganados Devolución 2do. Servicio',
    'Deuce and Ad Points Won on 2nd Serve Return': 'Puntos ganados Devolución 2do. Servicio al lado derecho e izquierdo',
    "The return effective margin refers to the effectiveness in game's return, including the performance of the first and second serve return. On this basis, the probability that the opponent serve will be broken is calculated.": "El margen de efectividad de devolución se refiere a la efectividad en la devolución de juego, incluidos la devolución del primer y segundo servicio. Sobre esta base, se calcula la probabilidad de queibre del servicio del oponente",
    'complete the following steps to upload your tennis match': 'completa los siguientes pasos para subir tu partido de tenis',
    'upload file': 'subir archivo',
    'match information': 'información del partido',
    'confirm': 'confirmar',
    'no file selected': 'ningún archivo seleccionado',
    'Only .csv file extension will be acepted': 'Solamente se acepta la extensión de archivo .csv',
    'back': 'atrás',
    'next': 'siguiente',
    'No have awards information yet.': 'No tenemos información de premios todavía.',
    'choose file': 'Elija el archivo',
    'show performance': 'Gráfico histórico de rendimiento',
    'show unforced errors': 'Gráfico histórico Errores No Forzados Determinantes',
    'show rally serve': 'Gráfico histórico de peloteos en el servicio',
    'show rally return': 'Gráfico histórico de peloteos en la devolución del servicio',
    'show rally serve return': 'Gráfico histórico de peloteos en el servicio y la devolución del servicio',
    'rally serve': 'Peloteos en el servicio',
    'rally return': 'Peloteos en la devolución del servicio',
    'rally serve and return': 'Peloteos en el servicio y la devolución del servicio',
    'show serve1stIn': 'Gráfico histórico % 1er. Servicio',
    'show serve1stEffectiveness': 'Gráfico histórico puntos ganados de 1er. Servicio',
    'show serve2ndPoints': 'Gráfico histórico de los puntos disputados de 2do. Servicio',
    'show serve2ndEffectiveness': 'Gráfico histórico puntos ganados de 2do. Servicio',
    'show sem': 'Gráfico histórico de M.E.S.',
    'show return1stIn': 'Gráfico histórico % devolución 1er. Servicio',
    'show return1stEffectiveness': 'Gráfico histórico puntos ganados devolución 1er. Servicio',
    'show return2ndPoints': 'Gráfico histórico % 2do. Servicio',
    'show return2ndEffectiveness': 'Gráfico histórico puntos ganados devolución 2do. Servicio',
    'show rem': 'Gráfico histórico de M.E.D.',
    'show minimatches': 'Gráfico histórico de minipartidos',
    'show radarReport': 'Gráfico de habilidades',
    'Summary skills': 'Resumen de habilidades',
    'other': 'Otro',
    'set a goal': 'Establecer objetivo',
    'Your performance average is: ': ' Tu rendimiento promedio es: ',
    'You have set your desired margin': 'Ha establecido su margen deseado',
    'You have to complete required fields': 'Tienes que completar los campos obligatorios',
    'Start date': 'Fecha inicio',
    'End date': 'Fecha fin',
    'moderated': 'Moderado (+10%)',
    'realistic': 'Realista (+20%)',
    'optimum': 'Óptimo (+30%)',
    'Your desired margin has been deleted': 'Su margen deseado ha sido eliminado',
    'You have already set a desired margin': 'Ya ha establecido un margen deseado',
    'Do you want to delete it and set up a new one?': '¿Quieres eliminarlo y configurar uno nuevo?',
    'You have successfully changed your password': 'Has cambiado satisfactoriamente tu contraseña',
    'Do you want to delete match': '¿Quiere eliminar el partido',
    'You have successfully deleted your match': 'Ha eliminado su partido correctamente',
    'initial': 'Inicial',
    'intermediate': 'Intermedio',
    'final': 'Final',
    'in play': 'en juego',
    'points won': 'puntos ganados',
    '1st Serve Return': 'Devolución 1er. Servicio',
    '2nd Serve Return': 'Devolución 2do. Servicio',
    'General Serve Return Stats': 'Estadísticas generales de la devolución del servicio',
    'Add dateBirth...': 'Añadir Fecha de Nacimiento...',
    'Add height...': 'Añadir altura...',
    'Add weight...': 'Añadir Peso...',
    'summary': 'Resumen',
    'Points': 'Puntos',
    'Go to court': 'Ir a la cancha',
    'Points won by player': 'Puntos ganados por el jugador',
    'courtBase': 'Base de la cancha',
    'Go to upload match': 'Ir a subir un partido',
    'No matches loaded': 'No hay partidos cargados',
    'points won on the most often rally played': 'Puntos ganadados en la longitud del peloteo con mayor número de puntos',
    'break points': 'Puntos de quiebre',
    '1st serve': '1st Serve',
    '1st serve return': '1st Serve Return',
    'key shot hand': 'Key Shot Hand',
    'key shot type': 'Key Shot Type',
    '2nd serve': '2nd Serve',
    '2nd serve return': '2nd Serve Return',
    'key shot outcome': 'Key Shot Outcome',
    'view match': 'Ir al partido',
    'total unforced errors in a row': 'Total de Errores No Forzados Consecutivos',
    'help points won by player': 'Una referencia rápida de cuántos puntos ganó cada jugador',
    'help match quality ranking': 'Es una clasificación de la calidad de sus últimos diez partidos, determinada por la combinación de su margen de rendimiento de partido y el de sus oponentes.',
    'help leadership margin': 'Tu rendimiento en el último tiro y el de tu rival generarán este gráfico circular que muestra quién ha sido el líder durante el partido sin importar el resultado. Puedes seguir perdiendo pero siendo el que atacó más.',
    'help flow p1': 'El flow muestra cómo se jugó el partido y dónde se produjeron las diferencias que llevaron a un jugador a vencer al otro.',
    'help flow li1': "Rectángulo gris en el lado de un jugador: Esto significa que ese jugador está sacando.",
    'help flow li2': 'Círculo amarillo: serve in.',
    'help flow li3': 'Círculo rojo: serve out.',
    'help flow li4': 'Círculo verde oscuro:: forced winner',
    'help flow li5': 'Círculo verde claro: ace/winner',
    'help flow li6': 'Círculo rojo: unforced error',
    'help flow li7': 'Gráfico de barras horizontales grises: El desarrollo del punto.',
    'help flow li8': 'Línea de gráfico azul claro: Cuando un jugador gana el punto, sin importar cómo, esta línea se moverá hacia ellos.',
    'help flow p2': 'Este gráfico proporciona mucha información que se puede analizar fácilmente.',
    'help flow p3': 'cuántos errores no forzados se cometieron consecutivamente, cuántos puntos se ganaron de manera consecutiva, cómo fue el primer saque de cada jugador, ¿fueron los rallies largos?, ¿qué sucedió después de un rally largo? y muchos más.',
    'help points won on the most often rally played': 'El porcentaje de puntos ganados en la longitud de rally que más se repitió durante el último partido.',
    'help match quality': 'La combinación de tu clasificación de rendimiento en el partido y la de tu oponente generará un valor de calidad del partido, que se muestra en este logo de diferentes colores. Puede ser rojo, amarillo o verde según la calidad del partido.',
    'help match performance p1': 'Muestra cuál es tu nivel actual de rendimiento en cada partido que juegas. Este nivel puede ser negativo o positivo. No importa cuál sea el nivel mostrado, será mejor a medida que sea más alto. No hay un mínimo ni máximo para este nivel. Dependerá de diferentes indicadores obtenidos de tu juego, como ganadores, errores no forzados, efectividad del saque, efectividad de la devolución del saque, efectividad en los rallies y muchos más.',
    'help match performance p2': 'Tendrás una clasificación de rendimiento en cada partido, una línea promedio y una línea objetivo que puedes determinar tú mismo.',
    'help distribution of unforced errors in a row': 'Este gráfico circular muestra la distribución de errores no forzados consecutivos en diferentes cantidades.',
    'help total points in a row': 'Puntos totales perdidos de forma consecutiva.',
    'help total chains': 'Número de "cadenas" de errones no forzados de forma consecutiva que obtuviste en promedio en los últimos diez partidos.',
    'rally effectiveness chart': 'Rally effectiveness chart',
    'help rally effectiveness chart': 'Un gráfico de barras interactivo que muestra cuántos puntos ganaste y perdiste en cada longitud de rally mostrada. También puedes organizar esos puntos o efectividad para ordenarlos como desees.',
    'forehand and backhand key shots': 'Forehand and Backhand Key Shots',
    'help forehand and backhand key shots': 'Este gráfico circular se divide en los golpes de derecha e izquierda que has realizado en promedio durante tus últimos diez partidos, o tu último partido (dependiendo de la página en la que estés trabajando). También se mostrará cuántos de ellos perdiste y ganaste. Además, al hacer clic en los ganadores o errores no forzados, obtendrás información adicional sobre el tipo de golpe que se realizó.',
    'points won by player': 'Puntos ganados por jugador',
    'court': 'Cancha',
    'application': 'Aplicación',
    'select language': 'Seleccione el lenguaje',
    'lost points in a row (LPR)': 'Puntos perdidos consecutivos (PPC)',
    'Distribution of lost points in a row': 'Distribución de puntos perdidos consecutivos',
    'undefined title': 'Título no definido',
    'help distribution of lost points in a row': 'Este gráfico circular muestra la distribución de puntos perdidos consecutivos en diferentes cantidades.',
  },
};

export default locale_spanish;