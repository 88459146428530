import { THEMES } from "../../../providers/theme/themes";

export const _options = {
  chart: {
    width: 500,
    height: 500,
    type: "radar",
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    enabled: false,
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: "0.75rem",
      fontFamily: "museo-sans, sans-serif",
      fontWeight: "bold",
      colors: [THEMES.default.palette.principal],
    },
    background: {
      enabled: true,
      foreColor: THEMES.default.palette.base,
      borderRadius: 5,
    },
  },
  plotOptions: {
    radar: {
      height: 500,
      size: 150,
      offsetY: 0,
      offsetX: 5,
      polygons: {
        strokeColors: "transparent",
        fill: {
          colors: [THEMES.default.palette.base, THEMES.default.palette.alternative_details],
        },
      },
    },
  },
  colors: [THEMES.default.palette.alternative_accent],
  fill: {
    type: "solid",
    opacity: 0.8,
  },
  stroke: {
    show: true,
    width: 2,
    colors: [THEMES.default.palette.principal],
    dashArray: 0
  },
  markers: {
    size: 4,
    colors: [THEMES.default.palette.accent],
    strokeColor: THEMES.default.palette.accent,
    strokeWidth: 2,
  },
  yaxis: {
    min: 0,
    max: 100,
    show: false,
  },
  xaxis: {
    categories: ['% SWP', '% RWP', '% WM', '% WP', '% RA', '% RM'],
    labels: {
      style: {
        fontSize: '14px',
        colors: [THEMES.default.palette.alternative_accent, THEMES.default.palette.alternative_accent, THEMES.default.palette.alternative_accent, THEMES.default.palette.alternative_accent, THEMES.default.palette.alternative_accent, THEMES.default.palette.alternative_accent]
      }
    }
  },
  responsive: [
    {
      breakpoint: 600,
      options: {
        plotOptions: {
          radar: {
            height: 500,
            size: 100,
            offsetY: 0,
            polygons: {
              strokeColors: "transparent",
              fill: {
                colors: ["#F6F6F6", "#fff"],
              },
            },
          },
        },
      },
    },
  ],
};

export const formatData = (data) => {
  const series = [{
    name: "Player Profile",
    data: [
      data.serveWonPoints,
      data.returnWonPoints,
      data.totalWonMatches,
      data.totalWonPoints,
      data.averageRallyLengthEffectiveness,
      data.modeRallyLengthEffectiveness
    ]
  }];
  return { series };
};
