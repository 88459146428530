import React from 'react';
import styled from "styled-components";
import AccordionSummary from "./AccordionSummary";
import AccordionDetails from "./AccordionDetails";

const Root = styled.div`
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 3px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    transition: all 300ms ease-in-out;
    &:first-child {
        border-radius: ${props => props.first ? "8px 8px 0 0" : props.last && !props.expanded ? "0 0 8px 8px" : 0};
    }
    &:last-child {
        border-radius: ${props => props.last ? "0 0 8px 8px" : 0};
    }
    margin: ${props => props.expanded ? props.first ? "0 0 1rem 0" : props.last ? "1rem 0 0 0" : "1rem 0 1rem 0" : 0};
`

const Accordion = ({children, first, last}) => {

    const [ expanded, setExpanded ] = React.useState(false);

    const switchAccordion = () => {
        setExpanded(!expanded);
    }

    const accordionSummaryChildren = React.Children.map(children, (child) => {
        if(child.type === AccordionSummary){
            return React.cloneElement(child, { expanded, switchAccordion });
        }
        return null;
    })

    const accordionDetailsChildren = React.Children.map(children, (child) => {
        if(child.type === AccordionDetails){
            return React.cloneElement(child, { expanded });
        }
        return null;
    })


    return (
        <Root expanded={expanded} first={first} last={last}>
            { accordionSummaryChildren }
            { accordionDetailsChildren }
        </Root>
    );
}

export default Accordion;