import axios from "axios";
import React from "react";
import styled from "styled-components";
import { API_BACKEND } from "../../../../config";
import { useAuth } from "../../../../providers/authentication";
import translate from "../../../../providers/i18n/translate";
import { useIntl } from 'react-intl';
import Award from "./Award";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../../store/action-creators";
import { alertConstants } from "../../../../store/reducers/messageBar/alertConstants";
import { ThemeContext } from "../../../../providers/theme";
import { decodeToken } from "../../../../hooks/jwt";

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;

const Title = styled.h2`
  text-transform: uppercase;
  font-size: 1.5rem;
  color: ${(props) => props.theme.palette.alternative_accent};
  font-weight: 300;
`;

const Message = styled.p`
  font-size: 1.15rem;
  color: ${(props) => props.theme.palette.details};
`;

const Actions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  width: 40%;
  height: auto;
`;

const Icon = styled.button`
  border: none;
  background: transparent;
  color: ${(props) => props.theme.palette.alternative_accent};
  cursor: pointer;
  svg {
    font-size: 2rem;
  }
`;

const Container = styled.div`
  position: relative;
  padding: 10px 10px 0 10px;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 14px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => `${props.theme.palette.accent}90`};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.palette.accent};
  }
`;

const Awards = () => {
  const theme = React.useContext(ThemeContext);

  const [data, setData] = React.useState(null);
  const [oldData, setOldData] = React.useState(null);

  const { token } = useAuth();
  const intl = useIntl();

  const [editing, setEditing] = React.useState(false);
  const [changes, setChanges] = React.useState(false);

  const userData = decodeToken(token);

  const dispatch = useDispatch();
  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const switchEditing = () => {
    if (editing && changes) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(intl.formatMessage({ id: "confirm changes" }))) {
        setEditing(false);
        var formdata = new FormData();
        formdata.append("awards", JSON.stringify(data));
        axios
          .put(
            `${API_BACKEND}/players/${userData?.trainedPlayer?.id}`,
            formdata,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            alertMessage(
              intl.formatMessage({ id: "changes saved" }),
              alertConstants.INFO
            );
          })
          .catch((err) => {
            console.log(err);
            alertMessage(err.response.data.msg, alertConstants.ERROR);
          });
      }
    } else {
      if (!editing) {
        const oldAwards = [];
        data.forEach(element => {
            oldAwards.push({
                name: element.name,
                year: element.year
            })
        });
        setOldData([...oldAwards]);
        setEditing(true);
      } else {
        setData([...oldData]);
        setEditing(false);
      }
    }
  };

  const getData = () => {
    axios
      .get(`${API_BACKEND}/trainedplayerawards`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data.map(r => {
          return {
              name: r.name,
              year: r.year
          }
      }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelEditing = () => {
    setData([...oldData]);
    setEditing(false);
  };

  const handleChange = (award, name, value) => {
    setChanges(true);
    const newAwards = data;
    const awardToEdit = newAwards.find((r) => r.name === award);
    awardToEdit[name] = value;
    setData([...newAwards]);
  };

  const handleNew = (newAward) => {
    setChanges(true);
    const repeatedAward = data.find(r => r.name === newAward.name);
    if(!repeatedAward){
        if(newAward.name && newAward.year){
            setData([
                ...data,
                {
                  name: newAward.name,
                  year: newAward.year
                },
              ]);
        } else {
            alertMessage(intl.formatMessage({ id: "Complete name and year..." }), alertConstants.ERROR);    
        }
    } else {
        alertMessage(intl.formatMessage({ id: "The award already exists..." }), alertConstants.ERROR);
    }
    
  };

  const handleDelete = (name) => {
    setChanges(true);
    const newAwards = data.filter((r) => r.name != name);
    setData([...newAwards]);
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    data &&
    <Root>
      <Actions>
          {editing && <Icon onClick={cancelEditing}>{theme.icons.close}</Icon>}
          <Icon onClick={switchEditing}>
            {editing ? theme.icons.check : theme.icons.edit}
          </Icon>
        </Actions>
      <Title>{translate("Awards")}</Title>
      <Container>
          {data.length > 0 ? (
            data.map((aw, index) => (
              <Award
                key={`award${index}`}
                data={aw}
                onChange={handleChange}
                onDelete={handleDelete}
                editing={editing}
              />
            ))
          ) : (
            <Message>
              {intl.formatMessage({ id: "No have awards information yet." })}
            </Message>
          )}
        </Container>
        {editing && (
          <Award
            onNew={handleNew}
            editing={editing}
          />
        )}
    </Root>
  );
};

export default Awards;
